import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchBarComponent } from './search-bar.component';
import { ChipsModule } from '../chips/chips.module';

@NgModule({
    declarations: [SearchBarComponent],
    imports: [CommonModule, FormsModule, ChipsModule],
    exports: [SearchBarComponent],
})
export class SearchBarModule {}
