import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, forkJoin, BehaviorSubject, catchError } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { GlobalService } from 'src/app/services/global.service';
import { COUNTRYFLAG } from 'src/assets/images';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    api = environment.HOST.link;
    COUNTRYFLAG = COUNTRYFLAG;
    fValue: any = {};
    schoolCode =
        JSON.parse(localStorage.getItem('school_details')) &&
        JSON.parse(localStorage.getItem('school_details')).school_code
            ? JSON.parse(localStorage.getItem('school_details')).school_code
            : '';
    school_Id =
        JSON.parse(localStorage.getItem('school_details')) &&
        JSON.parse(localStorage.getItem('school_details')).school_id
            ? JSON.parse(localStorage.getItem('school_details')).school_id
            : '';
    userData = JSON.parse(localStorage.getItem('token'));
    userFormData: BehaviorSubject<any[]>;
    noRedirect = new BehaviorSubject<any>({});
    loader = new BehaviorSubject<any>(false);
    isRollNoMandatory = new BehaviorSubject<any>(false);
    vStatus = new BehaviorSubject<any>({});
    branchOrder = [];
    customFieldsValue = [];
    basicFields = [];
    degreeValue = [];
    formData: any = JSON.parse(localStorage.getItem('formData'));
    isFilled: any = {};
    activeBranch = 1;
    acadDetails = false;
    routeType: any;
    schoolFields: any;
    personalData: any = false;
    customData =
        JSON.parse(localStorage.getItem('customFieldsValue')) &&
        JSON.parse(localStorage.getItem('customFieldsValue')).length > 0
            ? true
            : false;
    pers = false;
    isRedirected = false;
    nextAddData: any = [];
    showPersonal: any = false;
    showAcademic: any = false;
    showAdditional: any = false;
    countryList = [
        {
           "label":"Afghanistan",
           "flag":`${this.COUNTRYFLAG}/AF.svg`,
           "code":"AF",
           "value":"+93"
        },
        {
           "label":"Åland Islands",
           "flag":`${this.COUNTRYFLAG}/AX.svg`,
           "code":"AX",
           "value":"+358"
        },
        {
           "label":"Albania",
           "flag":`${this.COUNTRYFLAG}/AL.svg`,
           "code":"AL",
           "value":"+355"
        },
        {
           "label":"Algeria",
           "flag":`${this.COUNTRYFLAG}/DZ.svg`,
           "code":"DZ",
           "value":"+213"
        },
        {
           "label":"American Samoa",
           "flag":`${this.COUNTRYFLAG}/AS.svg`,
           "code":"AS",
           "value":"+1684"
        },
        {
           "label":"Andorra",
           "flag":`${this.COUNTRYFLAG}/AD.svg`,
           "code":"AD",
           "value":"+376"
        },
        {
           "label":"Angola",
           "flag":`${this.COUNTRYFLAG}/AO.svg`,
           "code":"AO",
           "value":"+244"
        },
        {
           "label":"Anguilla",
           "flag":`${this.COUNTRYFLAG}/AI.svg`,
           "code":"AI",
           "value":"+1264"
        },
        {
           "label":"Antarctica",
           "flag":`${this.COUNTRYFLAG}/AQ.svg`,
           "code":"AQ",
           "value":"+672"
        },
        {
           "label":"Antigua and Barbuda",
           "flag":`${this.COUNTRYFLAG}/AG.svg`,
           "code":"AG",
           "value":"+1268"
        },
        {
           "label":"Argentina",
           "flag":`${this.COUNTRYFLAG}/AR.svg`,
           "code":"AR",
           "value":"+54"
        },
        {
           "label":"Armenia",
           "flag":`${this.COUNTRYFLAG}/AM.svg`,
           "code":"AM",
           "value":"+374"
        },
        {
           "label":"Aruba",
           "flag":`${this.COUNTRYFLAG}/AW.svg`,
           "code":"AW",
           "value":"+297"
        },
        {
           "label":"Australia",
           "flag":`${this.COUNTRYFLAG}/AU.svg`,
           "code":"AU",
           "value":"+61"
        },
        {
           "label":"Austria",
           "flag":`${this.COUNTRYFLAG}/AT.svg`,
           "code":"AT",
           "value":"+43"
        },
        {
           "label":"Azerbaijan",
           "flag":`${this.COUNTRYFLAG}/AZ.svg`,
           "code":"AZ",
           "value":"+994"
        },
        {
           "label":"Bahamas",
           "flag":`${this.COUNTRYFLAG}/BS.svg`,
           "code":"BS",
           "value":"+1242"
        },
        {
           "label":"Bahrain",
           "flag":`${this.COUNTRYFLAG}/BH.svg`,
           "code":"BH",
           "value":"+973"
        },
        {
           "label":"Bangladesh",
           "flag":`${this.COUNTRYFLAG}/BD.svg`,
           "code":"BD",
           "value":"+880"
        },
        {
           "label":"Barbados",
           "flag":`${this.COUNTRYFLAG}/BB.svg`,
           "code":"BB",
           "value":"+1246"
        },
        {
           "label":"Belarus",
           "flag":`${this.COUNTRYFLAG}/BY.svg`,
           "code":"BY",
           "value":"+375"
        },
        {
           "label":"Belgium",
           "flag":`${this.COUNTRYFLAG}/BE.svg`,
           "code":"BE",
           "value":"+32"
        },
        {
           "label":"Belize",
           "flag":`${this.COUNTRYFLAG}/BZ.svg`,
           "code":"BZ",
           "value":"+501"
        },
        {
           "label":"Benin",
           "flag":`${this.COUNTRYFLAG}/BJ.svg`,
           "code":"BJ",
           "value":"+229"
        },
        {
           "label":"Bermuda",
           "flag":`${this.COUNTRYFLAG}/BM.svg`,
           "code":"BM",
           "value":"+1441"
        },
        {
           "label":"Bhutan",
           "flag":`${this.COUNTRYFLAG}/BT.svg`,
           "code":"BT",
           "value":"+975"
        },
        {
           "label":"Bolivia, Plurinational State of bolivia",
           "flag":`${this.COUNTRYFLAG}/BO.svg`,
           "code":"BO",
           "value":"+591"
        },
        {
           "label":"Bosnia and Herzegovina",
           "flag":`${this.COUNTRYFLAG}/BA.svg`,
           "code":"BA",
           "value":"+387"
        },
        {
           "label":"Botswana",
           "flag":`${this.COUNTRYFLAG}/BW.svg`,
           "code":"BW",
           "value":"+267"
        },
        {
           "label":"Bouvet Island",
           "flag":`${this.COUNTRYFLAG}/BV.svg`,
           "code":"BV",
           "value":"+47"
        },
        {
           "label":"Brazil",
           "flag":`${this.COUNTRYFLAG}/BR.svg`,
           "code":"BR",
           "value":"+55"
        },
        {
           "label":"British Indian Ocean Territory",
           "flag":`${this.COUNTRYFLAG}/IO.svg`,
           "code":"IO",
           "value":"+246"
        },
        {
           "label":"Brunei Darussalam",
           "flag":`${this.COUNTRYFLAG}/BN.svg`,
           "code":"BN",
           "value":"+673"
        },
        {
           "label":"Bulgaria",
           "flag":`${this.COUNTRYFLAG}/BG.svg`,
           "code":"BG",
           "value":"+359"
        },
        {
           "label":"Burkina Faso",
           "flag":`${this.COUNTRYFLAG}/BF.svg`,
           "code":"BF",
           "value":"+226"
        },
        {
           "label":"Burundi",
           "flag":`${this.COUNTRYFLAG}/BI.svg`,
           "code":"BI",
           "value":"+257"
        },
        {
           "label":"Cambodia",
           "flag":`${this.COUNTRYFLAG}/KH.svg`,
           "code":"KH",
           "value":"+855"
        },
        {
           "label":"Cameroon",
           "flag":`${this.COUNTRYFLAG}/CM.svg`,
           "code":"CM",
           "value":"+237"
        },
        {
           "label":"Canada",
           "flag":`${this.COUNTRYFLAG}/CA.svg`,
           "code":"CA",
           "value":"+1"
        },
        {
           "label":"Cape Verde",
           "flag":`${this.COUNTRYFLAG}/CV.svg`,
           "code":"CV",
           "value":"+238"
        },
        {
           "label":"Cayman Islands",
           "flag":`${this.COUNTRYFLAG}/KY.svg`,
           "code":"KY",
           "value":"+345"
        },
        {
           "label":"Central African Republic",
           "flag":`${this.COUNTRYFLAG}/CF.svg`,
           "code":"CF",
           "value":"+236"
        },
        {
           "label":"Chad",
           "flag":`${this.COUNTRYFLAG}/TD.svg`,
           "code":"TD",
           "value":"+235"
        },
        {
           "label":"Chile",
           "flag":`${this.COUNTRYFLAG}/CL.svg`,
           "code":"CL",
           "value":"+56"
        },
        {
           "label":"China",
           "flag":`${this.COUNTRYFLAG}/CN.svg`,
           "code":"CN",
           "value":"+86"
        },
        {
           "label":"Christmas Island",
           "flag":`${this.COUNTRYFLAG}/CX.svg`,
           "code":"CX",
           "value":"+61"
        },
        {
           "label":"Cocos (Keeling) Islands",
           "flag":`${this.COUNTRYFLAG}/CC.svg`,
           "code":"CC",
           "value":"+61"
        },
        {
           "label":"Colombia",
           "flag":`${this.COUNTRYFLAG}/CO.svg`,
           "code":"CO",
           "value":"+57"
        },
        {
           "label":"Comoros",
           "flag":`${this.COUNTRYFLAG}/AF.svg`,
           "code":"KM",
           "value":"+269"
        },
        {
           "label":"Congo",
           "flag":`${this.COUNTRYFLAG}/CG.svg`,
           "code":"CG",
           "value":"+242"
        },
        {
           "label":"Congo, The Democratic Republic of the Congo",
           "flag":`${this.COUNTRYFLAG}/CD.svg`,
           "code":"CD",
           "value":"+243"
        },
        {
           "label":"Cook Islands",
           "flag":`${this.COUNTRYFLAG}/CK.svg`,
           "code":"CK",
           "value":"+682"
        },
        {
           "label":"Costa Rica",
           "flag":`${this.COUNTRYFLAG}/CR.svg`,
           "code":"CR",
           "value":"+506"
        },
        {
           "label":"Cote d'Ivoire",
           "flag":`${this.COUNTRYFLAG}/CI.svg`,
           "code":"CI",
           "value":"+225"
        },
        {
           "label":"Croatia",
           "flag":`${this.COUNTRYFLAG}/HR.svg`,
           "code":"HR",
           "value":"+385"
        },
        {
           "label":"Cuba",
           "flag":`${this.COUNTRYFLAG}/CU.svg`,
           "code":"CU",
           "value":"+53"
        },
        {
           "label":"Cyprus",
           "flag":`${this.COUNTRYFLAG}/CY.svg`,
           "code":"CY",
           "value":"+357"
        },
        {
           "label":"Czech Republic",
           "flag":`${this.COUNTRYFLAG}/CZ.svg`,
           "code":"CZ",
           "value":"+420"
        },
        {
           "label":"Denmark",
           "flag":`${this.COUNTRYFLAG}/DK.svg`,
           "code":"DK",
           "value":"+45"
        },
        {
           "label":"Djibouti",
           "flag":`${this.COUNTRYFLAG}/DJ.svg`,
           "code":"DJ",
           "value":"+253"
        },
        {
           "label":"Dominica",
           "flag":`${this.COUNTRYFLAG}/DM.svg`,
           "code":"DM",
           "value":"+1767"
        },
        {
           "label":"Dominican Republic",
           "flag":`${this.COUNTRYFLAG}/DO.svg`,
           "code":"DO",
           "value":"+1849"
        },
        {
           "label":"Ecuador",
           "flag":`${this.COUNTRYFLAG}/EC.svg`,
           "code":"EC",
           "value":"+593"
        },
        {
           "label":"Egypt",
           "flag":`${this.COUNTRYFLAG}/EG.svg`,
           "code":"EG",
           "value":"+20"
        },
        {
           "label":"El Salvador",
           "flag":`${this.COUNTRYFLAG}/SV.svg`,
           "code":"SV",
           "value":"+503"
        },
        {
           "label":"Equatorial Guinea",
           "flag":`${this.COUNTRYFLAG}/GQ.svg`,
           "code":"GQ",
           "value":"+240"
        },
        {
           "label":"Eritrea",
           "flag":`${this.COUNTRYFLAG}/EQ.svg`,
           "code":"ER",
           "value":"+291"
        },
        {
           "label":"Estonia",
           "flag":`${this.COUNTRYFLAG}/EE.svg`,
           "code":"EE",
           "value":"+372"
        },
        {
           "label":"Ethiopia",
           "flag":`${this.COUNTRYFLAG}/ET.svg`,
           "code":"ET",
           "value":"+251"
        },
        {
           "label":"Falkland Islands (Malvinas)",
           "flag":`${this.COUNTRYFLAG}/FK.svg`,
           "code":"FK",
           "value":"+500"
        },
        {
           "label":"Faroe Islands",
           "flag":`${this.COUNTRYFLAG}/FO.svg`,
           "code":"FO",
           "value":"+298"
        },
        {
           "label":"Fiji",
           "flag":`${this.COUNTRYFLAG}/FJ.svg`,
           "code":"FJ",
           "value":"+679"
        },
        {
           "label":"Finland",
           "flag":`${this.COUNTRYFLAG}/FI.svg`,
           "code":"FI",
           "value":"+358"
        },
        {
           "label":"France",
           "flag":`${this.COUNTRYFLAG}/FR.svg`,
           "code":"FR",
           "value":"+33"
        },
        {
           "label":"French Guiana",
           "flag":`${this.COUNTRYFLAG}/GF.svg`,
           "code":"GF",
           "value":"+594"
        },
        {
           "label":"French Polynesia",
           "flag":`${this.COUNTRYFLAG}/PF.svg`,
           "code":"PF",
           "value":"+689"
        },
        {
           "label":"French Southern Territories",
           "flag":`${this.COUNTRYFLAG}/TF.svg`,
           "code":"TF",
           "value":"+262"
        },
        {
           "label":"Gabon",
           "flag":`${this.COUNTRYFLAG}/GA.svg`,
           "code":"GA",
           "value":"+241"
        },
        {
           "label":"Gambia",
           "flag":`${this.COUNTRYFLAG}/GM.svg`,
           "code":"GM",
           "value":"+220"
        },
        {
           "label":"Georgia",
           "flag":`${this.COUNTRYFLAG}/GE.svg`,
           "code":"GE",
           "value":"+995"
        },
        {
           "label":"Germany",
           "flag":`${this.COUNTRYFLAG}/DE.svg`,
           "code":"DE",
           "value":"+49"
        },
        {
           "label":"Ghana",
           "flag":`${this.COUNTRYFLAG}/GH.svg`,
           "code":"GH",
           "value":"+233"
        },
        {
           "label":"Gibraltar",
           "flag":`${this.COUNTRYFLAG}/GI.svg`,
           "code":"GI",
           "value":"+350"
        },
        {
           "label":"Greece",
           "flag":`${this.COUNTRYFLAG}/GR.svg`,
           "code":"GR",
           "value":"+30"
        },
        {
           "label":"Greenland",
           "flag":`${this.COUNTRYFLAG}/GL.svg`,
           "code":"GL",
           "value":"+299"
        },
        {
           "label":"Grenada",
           "flag":`${this.COUNTRYFLAG}/GD.svg`,
           "code":"GD",
           "value":"+1473"
        },
        {
           "label":"Guadeloupe",
           "flag":`${this.COUNTRYFLAG}/GP.svg`,
           "code":"GP",
           "value":"+590"
        },
        {
           "label":"Guam",
           "flag":`${this.COUNTRYFLAG}/GU.svg`,
           "code":"GU",
           "value":"+1671"
        },
        {
           "label":"Guatemala",
           "flag":`${this.COUNTRYFLAG}/GT.svg`,
           "code":"GT",
           "value":"+502"
        },
        {
           "label":"Guernsey",
           "flag":`${this.COUNTRYFLAG}/GG.svg`,
           "code":"GG",
           "value":"+44"
        },
        {
           "label":"Guinea",
           "flag":`${this.COUNTRYFLAG}/GN.svg`,
           "code":"GN",
           "value":"+224"
        },
        {
           "label":"Guinea-Bissau",
           "flag":`${this.COUNTRYFLAG}/GW.svg`,
           "code":"GW",
           "value":"+245"
        },
        {
           "label":"Guyana",
           "flag":`${this.COUNTRYFLAG}/GY.svg`,
           "code":"GY",
           "value":"+592"
        },
        {
           "label":"Haiti",
           "flag":`${this.COUNTRYFLAG}/HT.svg`,
           "code":"HT",
           "value":"+509"
        },
        {
           "label":"Heard Island and Mcdonald Islands",
           "flag":`${this.COUNTRYFLAG}/HM.svg`,
           "code":"HM",
           "value":"+672"
        },
        {
           "label":"Holy See (Vatican City State)",
           "flag":`${this.COUNTRYFLAG}/VA.svg`,
           "code":"VA",
           "value":"+379"
        },
        {
           "label":"Honduras",
           "flag":`${this.COUNTRYFLAG}/HN.svg`,
           "code":"HN",
           "value":"+504"
        },
        {
           "label":"Hong Kong",
           "flag":`${this.COUNTRYFLAG}/HK.svg`,
           "code":"HK",
           "value":"+852"
        },
        {
           "label":"Hungary",
           "flag":`${this.COUNTRYFLAG}/HU.svg`,
           "code":"HU",
           "value":"+36"
        },
        {
           "label":"Iceland",
           "flag":`${this.COUNTRYFLAG}/IS.svg`,
           "code":"IS",
           "value":"+354"
        },
        {
           "label":"India",
           "flag":`${this.COUNTRYFLAG}/IN.svg`,
           "code":"IN",
           "value":"+91"
        },
        {
           "label":"Indonesia",
           "flag":`${this.COUNTRYFLAG}/ID.svg`,
           "code":"ID",
           "value":"+62"
        },
        {
           "label":"Iran, Islamic Republic of Persian Gulf",
           "flag":`${this.COUNTRYFLAG}/IR.svg`,
           "code":"IR",
           "value":"+98"
        },
        {
           "label":"Iraq",
           "flag":`${this.COUNTRYFLAG}/IQ.svg`,
           "code":"IQ",
           "value":"+964"
        },
        {
           "label":"Ireland",
           "flag":`${this.COUNTRYFLAG}/IE.svg`,
           "code":"IE",
           "value":"+353"
        },
        {
           "label":"Isle of Man",
           "flag":`${this.COUNTRYFLAG}/IM.svg`,
           "code":"IM",
           "value":"+44"
        },
        {
           "label":"Israel",
           "flag":`${this.COUNTRYFLAG}/IL.svg`,
           "code":"IL",
           "value":"+972"
        },
        {
           "label":"Italy",
           "flag":`${this.COUNTRYFLAG}/IT.svg`,
           "code":"IT",
           "value":"+39"
        },
        {
           "label":"Jamaica",
           "flag":`${this.COUNTRYFLAG}/JM.svg`,
           "code":"JM",
           "value":"+1876"
        },
        {
           "label":"Japan",
           "flag":`${this.COUNTRYFLAG}/JP.svg`,
           "code":"JP",
           "value":"+81"
        },
        {
           "label":"Jersey",
           "flag":`${this.COUNTRYFLAG}/JE.svg`,
           "code":"JE",
           "value":"+44"
        },
        {
           "label":"Jordan",
           "flag":`${this.COUNTRYFLAG}/JO.svg`,
           "code":"JO",
           "value":"+962"
        },
        {
           "label":"Kazakhstan",
           "flag":`${this.COUNTRYFLAG}/KZ.svg`,
           "code":"KZ",
           "value":"+7"
        },
        {
           "label":"Kenya",
           "flag":`${this.COUNTRYFLAG}/KE.svg`,
           "code":"KE",
           "value":"+254"
        },
        {
           "label":"Kiribati",
           "flag":`${this.COUNTRYFLAG}/KI.svg`,
           "code":"KI",
           "value":"+686"
        },
        {
           "label":"Korea, Democratic People's Republic of Korea",
           "flag":`${this.COUNTRYFLAG}/KP.svg`,
           "code":"KP",
           "value":"+850"
        },
        {
           "label":"Korea, Republic of South Korea",
           "flag":`${this.COUNTRYFLAG}/KR.svg`,
           "code":"KR",
           "value":"+82"
        },
        {
           "label":"Kosovo",
           "flag":`${this.COUNTRYFLAG}/XK.svg`,
           "code":"XK",
           "value":"+383"
        },
        {
           "label":"Kuwait",
           "flag":`${this.COUNTRYFLAG}/KW.svg`,
           "code":"KW",
           "value":"+965"
        },
        {
           "label":"Kyrgyzstan",
           "flag":`${this.COUNTRYFLAG}/KG.svg`,
           "code":"KG",
           "value":"+996"
        },
        {
           "label":"Laos",
           "flag":`${this.COUNTRYFLAG}/LA.svg`,
           "code":"LA",
           "value":"+856"
        },
        {
           "label":"Latvia",
           "flag":`${this.COUNTRYFLAG}/LV.svg`,
           "code":"LV",
           "value":"+371"
        },
        {
           "label":"Lebanon",
           "flag":`${this.COUNTRYFLAG}/LB.svg`,
           "code":"LB",
           "value":"+961"
        },
        {
           "label":"Lesotho",
           "flag":`${this.COUNTRYFLAG}/LS.svg`,
           "code":"LS",
           "value":"+266"
        },
        {
           "label":"Liberia",
           "flag":`${this.COUNTRYFLAG}/LR.svg`,
           "code":"LR",
           "value":"+231"
        },
        {
           "label":"Libyan Arab Jamahiriya",
           "flag":`${this.COUNTRYFLAG}/LY.svg`,
           "code":"LY",
           "value":"+218"
        },
        {
           "label":"Liechtenstein",
           "flag":`${this.COUNTRYFLAG}/LI.svg`,
           "code":"LI",
           "value":"+423"
        },
        {
           "label":"Lithuania",
           "flag":`${this.COUNTRYFLAG}/LT.svg`,
           "code":"LT",
           "value":"+370"
        },
        {
           "label":"Luxembourg",
           "flag":`${this.COUNTRYFLAG}/LU.svg`,
           "code":"LU",
           "value":"+352"
        },
        {
           "label":"Macao",
           "flag":`${this.COUNTRYFLAG}/MO.svg`,
           "code":"MO",
           "value":"+853"
        },
        {
           "label":"Macedonia",
           "flag":`${this.COUNTRYFLAG}/MK.svg`,
           "code":"MK",
           "value":"+389"
        },
        {
           "label":"Madagascar",
           "flag":`${this.COUNTRYFLAG}/MG.svg`,
           "code":"MG",
           "value":"+261"
        },
        {
           "label":"Malawi",
           "flag":`${this.COUNTRYFLAG}/MW.svg`,
           "code":"MW",
           "value":"+265"
        },
        {
           "label":"Malaysia",
           "flag":`${this.COUNTRYFLAG}/MY.svg`,
           "code":"MY",
           "value":"+60"
        },
        {
           "label":"Maldives",
           "flag":`${this.COUNTRYFLAG}/MV.svg`,
           "code":"MV",
           "value":"+960"
        },
        {
           "label":"Mali",
           "flag":`${this.COUNTRYFLAG}/ML.svg`,
           "code":"ML",
           "value":"+223"
        },
        {
           "label":"Malta",
           "flag":`${this.COUNTRYFLAG}/MT.svg`,
           "code":"MT",
           "value":"+356"
        },
        {
           "label":"Marshall Islands",
           "flag":`${this.COUNTRYFLAG}/MH.svg`,
           "code":"MH",
           "value":"+692"
        },
        {
           "label":"Martinique",
           "flag":`${this.COUNTRYFLAG}/MQ.svg`,
           "code":"MQ",
           "value":"+596"
        },
        {
           "label":"Mauritania",
           "flag":`${this.COUNTRYFLAG}/MR.svg`,
           "code":"MR",
           "value":"+222"
        },
        {
           "label":"Mauritius",
           "flag":`${this.COUNTRYFLAG}/MU.svg`,
           "code":"MU",
           "value":"+230"
        },
        {
           "label":"Mayotte",
           "flag":`${this.COUNTRYFLAG}/YT.svg`,
           "code":"YT",
           "value":"+262"
        },
        {
           "label":"Mexico",
           "flag":`${this.COUNTRYFLAG}/MX.svg`,
           "code":"MX",
           "value":"+52"
        },
        {
           "label":"Micronesia, Federated States of Micronesia",
           "flag":`${this.COUNTRYFLAG}/FM.svg`,
           "code":"FM",
           "value":"+691"
        },
        {
           "label":"Moldova",
           "flag":`${this.COUNTRYFLAG}/MD.svg`,
           "code":"MD",
           "value":"+373"
        },
        {
           "label":"Monaco",
           "flag":`${this.COUNTRYFLAG}/MC.svg`,
           "code":"MC",
           "value":"+377"
        },
        {
           "label":"Mongolia",
           "flag":`${this.COUNTRYFLAG}/MN.svg`,
           "code":"MN",
           "value":"+976"
        },
        {
           "label":"Montenegro",
           "flag":`${this.COUNTRYFLAG}/ME.svg`,
           "code":"ME",
           "value":"+382"
        },
        {
           "label":"Montserrat",
           "flag":`${this.COUNTRYFLAG}/MS.svg`,
           "code":"MS",
           "value":"+1664"
        },
        {
           "label":"Morocco",
           "flag":`${this.COUNTRYFLAG}/MA.svg`,
           "code":"MA",
           "value":"+212"
        },
        {
           "label":"Mozambique",
           "flag":`${this.COUNTRYFLAG}/MZ.svg`,
           "code":"MZ",
           "value":"+258"
        },
        {
           "label":"Myanmar",
           "flag":`${this.COUNTRYFLAG}/MM.svg`,
           "code":"MM",
           "value":"+95"
        },
        {
           "label":"Namibia",
           "flag":`${this.COUNTRYFLAG}/NA.svg`,
           "code":"NA",
           "value":"+264"
        },
        {
           "label":"Nauru",
           "flag":`${this.COUNTRYFLAG}/NR.svg`,
           "code":"NR",
           "value":"+674"
        },
        {
           "label":"Nepal",
           "flag":`${this.COUNTRYFLAG}/NP.svg`,
           "code":"NP",
           "value":"+977"
        },
        {
           "label":"Netherlands",
           "flag":`${this.COUNTRYFLAG}/NL.svg`,
           "code":"NL",
           "value":"+31"
        },
        {
           "label":"Netherlands Antilles",
           "flag":`${this.COUNTRYFLAG}/NL.svg`,
           "code":"AN",
           "value":"+599"
        },
        {
           "label":"New Caledonia",
           "flag":`${this.COUNTRYFLAG}/NC.svg`,
           "code":"NC",
           "value":"+687"
        },
        {
           "label":"New Zealand",
           "flag":`${this.COUNTRYFLAG}/NZ.svg`,
           "code":"NZ",
           "value":"+64"
        },
        {
           "label":"Nicaragua",
           "flag":`${this.COUNTRYFLAG}/NI.svg`,
           "code":"NI",
           "value":"+505"
        },
        {
           "label":"Niger",
           "flag":`${this.COUNTRYFLAG}/NE.svg`,
           "code":"NE",
           "value":"+227"
        },
        {
           "label":"Nigeria",
           "flag":`${this.COUNTRYFLAG}/NG.svg`,
           "code":"NG",
           "value":"+234"
        },
        {
           "label":"Niue",
           "flag":`${this.COUNTRYFLAG}/NU.svg`,
           "code":"NU",
           "value":"+683"
        },
        {
           "label":"Norfolk Island",
           "flag":`${this.COUNTRYFLAG}/NF.svg`,
           "code":"NF",
           "value":"+672"
        },
        {
           "label":"Northern Mariana Islands",
           "flag":`${this.COUNTRYFLAG}/MP.svg`,
           "code":"MP",
           "value":"+1670"
        },
        {
           "label":"Norway",
           "flag":`${this.COUNTRYFLAG}/NO.svg`,
           "code":"NO",
           "value":"+47"
        },
        {
           "label":"Oman",
           "flag":`${this.COUNTRYFLAG}/OM.svg`,
           "code":"OM",
           "value":"+968"
        },
        {
           "label":"Pakistan",
           "flag":`${this.COUNTRYFLAG}/PK.svg`,
           "code":"PK",
           "value":"+92"
        },
        {
           "label":"Palau",
           "flag":`${this.COUNTRYFLAG}/PW.svg`,
           "code":"PW",
           "value":"+680"
        },
        {
           "label":"Palestinian Territory, Occupied",
           "flag":`${this.COUNTRYFLAG}/PS.svg`,
           "code":"PS",
           "value":"+970"
        },
        {
           "label":"Panama",
           "flag":`${this.COUNTRYFLAG}/PA.svg`,
           "code":"PA",
           "value":"+507"
        },
        {
           "label":"Papua New Guinea",
           "flag":`${this.COUNTRYFLAG}/PG.svg`,
           "code":"PG",
           "value":"+675"
        },
        {
           "label":"Paraguay",
           "flag":`${this.COUNTRYFLAG}/PY.svg`,
           "code":"PY",
           "value":"+595"
        },
        {
           "label":"Peru",
           "flag":`${this.COUNTRYFLAG}/PE.svg`,
           "code":"PE",
           "value":"+51"
        },
        {
           "label":"Philippines",
           "flag":`${this.COUNTRYFLAG}/PH.svg`,
           "code":"PH",
           "value":"+63"
        },
        {
           "label":"Pitcairn",
           "flag":`${this.COUNTRYFLAG}/PN.svg`,
           "code":"PN",
           "value":"+64"
        },
        {
           "label":"Poland",
           "flag":`${this.COUNTRYFLAG}/PL.svg`,
           "code":"PL",
           "value":"+48"
        },
        {
           "label":"Portugal",
           "flag":`${this.COUNTRYFLAG}/PT.svg`,
           "code":"PT",
           "value":"+351"
        },
        {
           "label":"Puerto Rico",
           "flag":`${this.COUNTRYFLAG}/PR.svg`,
           "code":"PR",
           "value":"+1939"
        },
        {
           "label":"Qatar",
           "flag":`${this.COUNTRYFLAG}/QA.svg`,
           "code":"QA",
           "value":"+974"
        },
        {
           "label":"Romania",
           "flag":`${this.COUNTRYFLAG}/RO.svg`,
           "code":"RO",
           "value":"+40"
        },
        {
           "label":"Russia",
           "flag":`${this.COUNTRYFLAG}/RU.svg`,
           "code":"RU",
           "value":"+7"
        },
        {
           "label":"Rwanda",
           "flag":`${this.COUNTRYFLAG}/RW.svg`,
           "code":"RW",
           "value":"+250"
        },
        {
           "label":"Reunion",
           "flag":`${this.COUNTRYFLAG}/RE.svg`,
           "code":"RE",
           "value":"+262"
        },
        {
           "label":"Saint Barthelemy",
           "flag":`${this.COUNTRYFLAG}/BL.svg`,
           "code":"BL",
           "value":"+590"
        },
        {
           "label":"Saint Helena, Ascension and Tristan Da Cunha",
           "flag":`${this.COUNTRYFLAG}/SH.svg`,
           "code":"SH",
           "value":"+290"
        },
        {
           "label":"Saint Kitts and Nevis",
           "flag":`${this.COUNTRYFLAG}/KN.svg`,
           "code":"KN",
           "value":"+1869"
        },
        {
           "label":"Saint Lucia",
           "flag":`${this.COUNTRYFLAG}/LC.svg`,
           "code":"LC",
           "value":"+1758"
        },
        {
           "label":"Saint Martin",
           "flag":`${this.COUNTRYFLAG}/MF.svg`,
           "code":"MF",
           "value":"+590"
        },
        {
           "label":"Saint Pierre and Miquelon",
           "flag":`${this.COUNTRYFLAG}/PM.svg`,
           "code":"PM",
           "value":"+508"
        },
        {
           "label":"Saint Vincent and the Grenadines",
           "flag":`${this.COUNTRYFLAG}/VC.svg`,
           "code":"VC",
           "value":"+1784"
        },
        {
           "label":"Samoa",
           "flag":`${this.COUNTRYFLAG}/WS.svg`,
           "code":"WS",
           "value":"+685"
        },
        {
           "label":"San Marino",
           "flag":`${this.COUNTRYFLAG}/SM.svg`,
           "code":"SM",
           "value":"+378"
        },
        {
           "label":"Sao Tome and Principe",
           "flag":`${this.COUNTRYFLAG}/ST.svg`,
           "code":"ST",
           "value":"+239"
        },
        {
           "label":"Saudi Arabia",
           "flag":`${this.COUNTRYFLAG}/SA.svg`,
           "code":"SA",
           "value":"+966"
        },
        {
           "label":"Senegal",
           "flag":`${this.COUNTRYFLAG}/SN.svg`,
           "code":"SN",
           "value":"+221"
        },
        {
           "label":"Serbia",
           "flag":`${this.COUNTRYFLAG}/RS.svg`,
           "code":"RS",
           "value":"+381"
        },
        {
           "label":"Seychelles",
           "flag":`${this.COUNTRYFLAG}/SC.svg`,
           "code":"SC",
           "value":"+248"
        },
        {
           "label":"Sierra Leone",
           "flag":`${this.COUNTRYFLAG}/SL.svg`,
           "code":"SL",
           "value":"+232"
        },
        {
           "label":"Singapore",
           "flag":`${this.COUNTRYFLAG}/SG.svg`,
           "code":"SG",
           "value":"+65"
        },
        {
           "label":"Slovakia",
           "flag":`${this.COUNTRYFLAG}/SK.svg`,
           "code":"SK",
           "value":"+421"
        },
        {
           "label":"Slovenia",
           "flag":`${this.COUNTRYFLAG}/SI.svg`,
           "code":"SI",
           "value":"+386"
        },
        {
           "label":"Solomon Islands",
           "flag":`${this.COUNTRYFLAG}/SB.svg`,
           "code":"SB",
           "value":"+677"
        },
        {
           "label":"Somalia",
           "flag":`${this.COUNTRYFLAG}/SO.svg`,
           "code":"SO",
           "value":"+252"
        },
        {
           "label":"South Africa",
           "flag":`${this.COUNTRYFLAG}/ZA.svg`,
           "code":"ZA",
           "value":"+27"
        },
        {
           "label":"South Sudan",
           "flag":`${this.COUNTRYFLAG}/SS.svg`,
           "code":"SS",
           "value":"+211"
        },
        {
           "label":"South Georgia and the South Sandwich Islands",
           "flag":`${this.COUNTRYFLAG}/GS.svg`,
           "code":"GS",
           "value":"+500"
        },
        {
           "label":"Spain",
           "flag":`${this.COUNTRYFLAG}/ES.svg`,
           "code":"ES",
           "value":"+34"
        },
        {
           "label":"Sri Lanka",
           "flag":`${this.COUNTRYFLAG}/LK.svg`,
           "code":"LK",
           "value":"+94"
        },
        {
           "label":"Sudan",
           "flag":`${this.COUNTRYFLAG}/SD.svg`,
           "code":"SD",
           "value":"+249"
        },
        {
           "label":"Surilabel",
           "flag":`${this.COUNTRYFLAG}/SR.svg`,
           "code":"SR",
           "value":"+597"
        },
        {
           "label":"Svalbard and Jan Mayen",
           "flag":`${this.COUNTRYFLAG}/SJ.svg`,
           "code":"SJ",
           "value":"+47"
        },
        {
           "label":"Eswatini",
           "flag":`${this.COUNTRYFLAG}/SZ.svg`,
           "code":"SZ",
           "value":"+268"
        },
        {
           "label":"Sweden",
           "flag":`${this.COUNTRYFLAG}/SE.svg`,
           "code":"SE",
           "value":"+46"
        },
        {
           "label":"Switzerland",
           "flag":`${this.COUNTRYFLAG}/CH.svg`,
           "code":"CH",
           "value":"+41"
        },
        {
           "label":"Syrian Arab Republic",
           "flag":`${this.COUNTRYFLAG}/SY.svg`,
           "code":"SY",
           "value":"+963"
        },
        {
           "label":"Taiwan",
           "flag":`${this.COUNTRYFLAG}/TW.svg`,
           "code":"TW",
           "value":"+886"
        },
        {
           "label":"Tajikistan",
           "flag":`${this.COUNTRYFLAG}/TJ.svg`,
           "code":"TJ",
           "value":"+992"
        },
        {
           "label":"Tanzania, United Republic of Tanzania",
           "flag":`${this.COUNTRYFLAG}/TZ.svg`,
           "code":"TZ",
           "value":"+255"
        },
        {
           "label":"Thailand",
           "flag":`${this.COUNTRYFLAG}/TH.svg`,
           "code":"TH",
           "value":"+66"
        },
        {
           "label":"Timor-Leste",
           "flag":`${this.COUNTRYFLAG}/TL.svg`,
           "code":"TL",
           "value":"+670"
        },
        {
           "label":"Togo",
           "flag":`${this.COUNTRYFLAG}/TG.svg`,
           "code":"TG",
           "value":"+228"
        },
        {
           "label":"Tokelau",
           "flag":`${this.COUNTRYFLAG}/TK.svg`,
           "code":"TK",
           "value":"+690"
        },
        {
           "label":"Tonga",
           "flag":`${this.COUNTRYFLAG}/TO.svg`,
           "code":"TO",
           "value":"+676"
        },
        {
           "label":"Trinidad and Tobago",
           "flag":`${this.COUNTRYFLAG}/TT.svg`,
           "code":"TT",
           "value":"+1868"
        },
        {
           "label":"Tunisia",
           "flag":`${this.COUNTRYFLAG}/TN.svg`,
           "code":"TN",
           "value":"+216"
        },
        {
           "label":"Turkey",
           "flag":`${this.COUNTRYFLAG}/TR.svg`,
           "code":"TR",
           "value":"+90"
        },
        {
           "label":"Turkmenistan",
           "flag":`${this.COUNTRYFLAG}/TM.svg`,
           "code":"TM",
           "value":"+993"
        },
        {
           "label":"Turks and Caicos Islands",
           "flag":`${this.COUNTRYFLAG}/TC.svg`,
           "code":"TC",
           "value":"+1649"
        },
        {
           "label":"Tuvalu",
           "flag":`${this.COUNTRYFLAG}/TV.svg`,
           "code":"TV",
           "value":"+688"
        },
        {
           "label":"Uganda",
           "flag":`${this.COUNTRYFLAG}/UG.svg`,
           "code":"UG",
           "value":"+256"
        },
        {
           "label":"Ukraine",
           "flag":`${this.COUNTRYFLAG}/UA.svg`,
           "code":"UA",
           "value":"+380"
        },
        {
           "label":"United Arab Emirates",
           "flag":`${this.COUNTRYFLAG}/AE.svg`,
           "code":"AE",
           "value":"+971"
        },
        {
           "label":"United Kingdom",
           "flag":`${this.COUNTRYFLAG}/GB.svg`,
           "code":"GB",
           "value":"+44"
        },
        {
           "label":"United States",
           "flag":`${this.COUNTRYFLAG}/US.svg`,
           "code":"US",
           "value":"+1"
        },
        {
           "label":"Uruguay",
           "flag":`${this.COUNTRYFLAG}/UY.svg`,
           "code":"UY",
           "value":"+598"
        },
        {
           "label":"Uzbekistan",
           "flag":`${this.COUNTRYFLAG}/UZ.svg`,
           "code":"UZ",
           "value":"+998"
        },
        {
           "label":"Vanuatu",
           "flag":`${this.COUNTRYFLAG}/VU.svg`,
           "code":"VU",
           "value":"+678"
        },
        {
           "label":"Venezuela, Bolivarian Republic of Venezuela",
           "flag":`${this.COUNTRYFLAG}/VE.svg`,
           "code":"VE",
           "value":"+58"
        },
        {
           "label":"Vietnam",
           "flag":`${this.COUNTRYFLAG}/VN.svg`,
           "code":"VN",
           "value":"+84"
        },
        {
           "label":"Virgin Islands, British",
           "flag":`${this.COUNTRYFLAG}/VG.svg`,
           "code":"VG",
           "value":"+1284"
        },
        {
           "label":"Virgin Islands, U.S.",
           "flag":`${this.COUNTRYFLAG}/VI.svg`,
           "code":"VI",
           "value":"+1340"
        },
        {
           "label":"Wallis and Futuna",
           "flag":`${this.COUNTRYFLAG}/WF.svg`,
           "code":"WF",
           "value":"+681"
        },
        {
           "label":"Yemen",
           "flag":`${this.COUNTRYFLAG}/YE.svg`,
           "code":"YE",
           "value":"+967"
        },
        {
           "label":"Zambia",
           "flag":`${this.COUNTRYFLAG}/ZA.svg`,
           "code":"ZM",
           "value":"+260"
        },
        {
           "label":"Zimbabwe",
           "flag":`${this.COUNTRYFLAG}/ZW.svg`,
           "code":"ZW",
           "value":"+263"
        }
     ]
    schoolDetails: any = JSON.parse(localStorage.getItem('school_details'));
    constructor(
        private httpClient: HttpClient,
        public loginservice: LoginService,
        public globalService: GlobalService,
    ) {}
    options() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.userData.token,
        });
        return { headers };
    }
    options2() {
        const headers = new HttpHeaders({
            Authorization: this.userData.token,
        });
        return { headers };
    }
    getcountryList() {
        return this.countryList;
    }
    customFieldsWithBranchnSchool(userTokenData, branch_id, _s_id) {
        return this.httpClient.get(
            this.api + '/branch/' + branch_id + '/school/' + this.getSclId() + '/studentcustomfields',
            this.loginservice.options(userTokenData),
        );
    }
    getAllCustomFields(userTokenData, branchIds, sId): Observable<any[]> {
        const tr = [];
        branchIds.map((branch: any) => {
            tr.push(this.customFieldsWithBranchnSchool(userTokenData, branch.branch_id, sId));
        });
        return forkJoin(tr);
    }
    getGenericMandatoryFields(data: any) {
        this.userData = data;
        return this.httpClient.get(
            this.api +
                '/users/getprofile?s_id=' +
                data.student_ppa_profiles[0].s_profile_id +
                '&school_id=' +
                this.getSclId(),
            this.loginservice.options(this.userData),
        );
    }
    getSclId() {
        if (
            JSON.parse(localStorage.getItem('school_details')) &&
            JSON.parse(localStorage.getItem('school_details')).school_id
        ) {
            return JSON.parse(localStorage.getItem('school_details')).school_id;
        }
        return '';
    }

    formatDob(dob) {
        if (dob && dob.length > 10) {
            return dob.substring(0, 10);
        } else {
            return dob;
        }
    }

    getFormValues(form, type) {
        if (
            (Object.entries(this.fValue).length !== 0 && this.fValue.constructor === Object) ||
            this.formData.constructor === Object
        ) {
            let vl;
            if (this.fValue.name || this.formData.name) {
                vl = this.fValue.name ? this.fValue.name : this.formData.name;
                vl = vl.split('$');
            }
            for (const element of form) {
                if ((element.name === 'firstname' || element.name === 'lastname') && vl) {
                    element.value = element.name === 'firstname' ? vl[0] : vl[1];
                    const name: any = this.formData.name ? this.formData.name.split('$') : '';
                    if (element.name === 'firstname') {
                        element.disabled = name && name[0] && name[0] !== '' ? true : false;
                    }
                    if (element.name === 'lastname') {
                        element.disabled = name && name[1] && name[1] !== '' ? true : false;
                    }
                } else if (element === 'profile_pic') {
                    return this.fValue[element] ? this.fValue[element] : this.formData[element];
                } else if (element === 'country_code') {
                    if(this.fValue[element]){
                        return this.fValue[element]
                    } else if(this.formData && this.formData.phone_number){
                        return  this.formData.phone_number.code
                    }else{
                        return '';
                    }                    
                } else if (element.name === 'dob') {
                    element.value = this.fValue[element.name]
                        ? this.formatDob(this.fValue[element.name])
                        : this.formatDob(this.formData[element.name]);
                        if(this.formData[element.name]){
                            if(this.formData[element.name] != ''){
                                element.disabled = true;
                            }else{
                                element.disabled = false;
                            }
                        }else{
                            element.disabled = false;
                        }
                } else if (element.name === 'phone') {
                    element.value = this.fValue[element.name] ? this.fValue[element.name] : this.formData[element.name];
                            if(this.formData[element.name] && this.formData && this.formData.phone_number){
                                if(this.formData[element.name] !== ''){
                                    element.disabled = true;
                                }else{
                                    element.disabled = false;
                                }
                            }else{
                                element.disabled = false;
                            }
                } else {
                    if (type) {
                        if (element.name === 'current_backlogs') {
                            if(this.fValue[element.name] || this.fValue[element.name] === 0){
                                element.value =this.fValue[element.name];
                            }else if (this.formData.student_ppa_profiles[0][element.name] ||
                                this.formData.student_ppa_profiles[0][element.name] === 0){
                                    element.value =this.formData.student_ppa_profiles[0][element.name];
                                }else{
                                    element.value = '';
                                }
                            if(this.formData.student_ppa_profiles[0][element.name] ||
                                this.formData.student_ppa_profiles[0][element.name] === 0){
                                    if(this.formData.student_ppa_profiles[0][element.name] !== ''){
                                        element.disabled =true;
                                    }
                                    else{
                                        element.disabled = false;
                                    }
                                }else{
                                    element.disabled = false;
                                }
                        } else if (element.name === 'backlog_history' || element.name === 'interested_for_placement') {
                            element.value =
                                this.fValue[element.name] === true || this.fValue[element.name] === false
                                    ? this.fValue[element.name]
                                    : this.formData.student_ppa_profiles[0][element.name];
                            element.disabled = [true, false].includes(
                                this.formData.student_ppa_profiles[0][element.name],
                            )
                                ? true
                                : false;
                        } else {
                            element.value = this.fValue[element.name]
                                ? this.fValue[element.name]
                                : this.formData.student_ppa_profiles[0][element.name];
                            element.disabled = this.formData.student_ppa_profiles[0][element.name] ? true : false;
                        }
                    } else {
                        element.value = this.fValue[element.name]
                            ? this.fValue[element.name]
                            : this.formData[element.name];
                            if(this.formData[element.name]){
                                if(this.formData[element.name] != ''){
                                    element.disabled = true;
                                }else {
                                    element.disabled = false;
                                }
                            }else{
                                element.disabled = false;
                            }
                    }
                }
            }
        }
    }

    setDegreeValue(opted) {
        this.formData = JSON.parse(localStorage.getItem('formData'));
        const degData: any = [];
        if (this.degreeValue.length) {
            for (let i = 0; i < opted.length; i++) {
                const txt: any = 'is_' + opted[i].key.replace('_marks', '') + '_percentage';
                for (let j = 0; j < this.degreeValue.length; j++) {
                    if (opted[i].value === this.degreeValue[j].degree) {
                        const data: any = {
                            degree: opted[i].value,
                            detail_type: null,
                            marks: this.degreeValue[j].marks
                                ? this.degreeValue[j].marks
                                : this.formData.student_ppa_profiles[0][opted[i].key],
                            label: opted[i].value,
                            disabled: this.formData.student_ppa_profiles[0][opted[i].key] ? true : false,
                            not: this.degreeValue[j].not ? true : false,
                            mandatory: this.degreeValue[j].mandatory,
                            error: false,
                        };
                        if ((data.marks || data.marks === 0) && data.marks !== 'NA') {
                            data.detail_type = this.degreeValue[j].detail_type
                                ? this.degreeValue[j].detail_type
                                : this.formData.student_ppa_profiles[0][txt];
                        } else {
                            data.detail_type = null;
                        }
                        degData.push(data);
                    } else {
                        if (this.formData.student_ppa_profiles[0][opted[i].key]) {
                            const data: any = {
                                degree: opted[i].value,
                                detail_type: null,
                                marks: this.formData.student_ppa_profiles[0][opted[i].key],
                                label: opted[i].value,
                                disabled: this.formData.student_ppa_profiles[0][opted[i].key] ? true : false,
                                not: this.degreeValue[j].not ? true : false,
                                mandatory: this.degreeValue[j].mandatory,
                                error: false,
                            };
                            if ((data.marks || data.marks === 0) && data.marks !== 'NA') {
                                data.detail_type = this.formData.student_ppa_profiles[0][txt];
                            } else {
                                data.detail_type = null;
                            }
                            degData.push(data);
                        }
                    }
                }
            }
        } else {
            for (const element of opted) {
                const txt: any = 'is_' + element.key.replace('_marks', '') + '_percentage';
                this.fValue[element.key] = this.formData.student_ppa_profiles[0][element.key];
                this.fValue[txt] = this.formData.student_ppa_profiles[0][txt];
                const data: any = {
                    degree: element.value,
                    detail_type: null,
                    marks: this.formData.student_ppa_profiles[0][element.key],
                    label: element.value,
                    disabled: this.formData.student_ppa_profiles[0][element.key] ? true : false,
                    not: false,
                };
                if ((data.marks || data.marks === 0) && data.marks !== 'NA') {
                    data.detail_type = this.formData.student_ppa_profiles[0][txt];
                } else {
                    data.detail_type = null;
                }
                degData.push(data);
            }
        }
        this.degreeValue = undefined;
        const dg = degData.filter((v, i, a) => a.findIndex((t) => t.degree === v.degree) === i);
        this.degreeValue = [...dg];
    }

    setDegreeValueOnNext(value) {
        this.degreeValue = undefined;
        this.degreeValue = value;
    }

    getStudentPermissionList(userTokenData) {
        return this.httpClient.get(this.api + '/student/permissionlist', this.loginservice.options(userTokenData));
    }

    blockSideNavRedirection() {
        this.globalService.blockRedirection();
        this.noRedirect.next({ status: true });
    }

    allowSideNavRedirection() {
        localStorage.removeItem('noRedirect');
        this.noRedirect.next({ status: false });
    }

    unfilledBasicFilled(userData) {
        const basic: any = JSON.parse(localStorage.getItem('basicFiels'));
        const firstName: any = basic.find((e: any) => e.label === 'First Name');
        const lastname: any = basic.find((e: any) => e.label === 'Last Name');
        const phone: any = basic.find((e: any) => e.label === 'Phone No');
        const gender: any = basic.find((e: any) => e.label === 'Gender');
        const dob: any = basic.find((e: any) => e.label === 'dob');
        const profile: any = basic.find((e: any) => e.label === 'Profile Picture');
        const ppaData = JSON.parse(localStorage.getItem('ppaData'));
        let fname: any = null;
        let lname: any = null;
        if (userData && userData.name !== null) {
            const name: any = userData.name.split('$');
            if (name && name.length && name[0]) {
                fname = name[0];
            }
            if (name && name.length && name[1]) {
                lname = name[1];
            }
        }
        if (ppaData.verification_status >= 2) {
            if (
                (firstName.value && fname === null) ||
                (lastname.value && lname === null) ||
                (dob.value && userData.dob === null) ||
                (dob.value && !userData.dob) ||
                (gender.value && !userData.gender) ||
                (phone.value && (userData.phone_number === null || userData.phone === null)) ||
                (profile.value &&
                    (!userData.profile_pic ||
                        userData.profile_pic ===
                            'https://smartica-media.s3.amazonaws.com/assets/common-images/profile-default.jpg'))
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            if (
                (firstName.hide && fname === null) ||
                (lastname.hide && lname === null) ||
                (dob.hide && userData.dob === null) ||
                (dob.hide && !userData.dob) ||
                (gender.hide && !userData.gender) ||
                (phone.hide && (userData.phone_number === null || userData.phone === null)) ||
                (profile.hide &&
                    (!userData.profile_pic ||
                        userData.profile_pic ===
                            'https://smartica-media.s3.amazonaws.com/assets/common-images/profile-default.jpg'))
            ) {
                return true;
            } else {
                return false;
            }
        }
    }

    checkRedirect(res, branchCustomFields, branchList, userFormData, userTokenData): Observable<any> {
        this.resetVariable();
        this.formData = userFormData;
        this.basicFields = res.school_student_custom_fields.basic_info_field;
        this.schoolFields = res.school_student_custom_fields.custom_fields;
        const bc: any = branchCustomFields;
        const ud: any = userFormData.student_custom_fields;
        localStorage.setItem('basicFiels', JSON.stringify(res.school_student_custom_fields.basic_info_field));
        if (this.unfilledBasicFilled(res)) {
            this.personalData = true;
            this.acadDetails = this.checkAcad(res, 'check', userTokenData);
            this.isFilled.acad = !this.acadDetails;
            if (bc && bc.length > 0 && typeof branchCustomFields[0] !== 'undefined') {
                this.getCustomArray(ud, bc, branchList).subscribe((v: any) => {
                    const dataLength: any = v.dataToFIll && v.dataToFIll.length && v.dataToFIll.find((f) => f.length);
                    if (((v && v.isCustom) || this.showAcademic) && dataLength) {
                        if (!(v && v.mandatoryUnfilled)) {
                            this.isFilled.add = true;
                        }
                        this.showAdditional = true;
                        localStorage.setItem('customFieldsValue', JSON.stringify(v.dataToFIll));
                        this.customData = true;
                        this.blockSideNavRedirection();
                        return of('personal');
                    } else {
                        this.customData = false;
                    }
                });
            } else {
                this.customData = false;
            }
            this.pers = true;
            this.blockSideNavRedirection();
            return of('personal');
        } else if (this.checkAcad(res, undefined, userTokenData)) {
            this.isFilled.per = true;
            this.acadDetails = true;
            if (bc && bc.length > 0 && typeof branchCustomFields[0] !== 'undefined') {
                this.getCustomArray(ud, bc, branchList).subscribe((v: any) => {
                    const dataLength: any = v.dataToFIll && v.dataToFIll.length && v.dataToFIll.find((f) => f.length);
                    if (v && v.isCustom && dataLength) {
                        if (!(v.mandatoryUnfilled)) {
                            this.isFilled.add = true;
                        }
                        this.showAdditional = true;
                        localStorage.setItem('customFieldsValue', JSON.stringify(v.dataToFIll));
                        this.customData = true;
                        this.blockSideNavRedirection();
                        return of('acad');
                    } else {
                        this.customData = false;
                    }
                });
            } else {
                this.customData = false;
            }
            this.blockSideNavRedirection();
            return of('acad');
        } else {
            this.isFilled.per = true;
            this.isFilled.acad = true;
            this.getCustomArray(ud, bc, branchList).subscribe((v: any) => {
                const dataLength: any = v.dataToFIll && v.dataToFIll.length && v.dataToFIll.find((f) => f.length);
                const ppaData = JSON.parse(localStorage.getItem('ppaData'));
                if (
                    (v.isCustom && this.showAcademic && this.checkAcad(res, 'check', userTokenData) && dataLength) ||
                    v.mandatoryUnfilled ||
                    (ppaData.verification_status < 2 && v.isCustom)
                ) {
                    this.showAdditional = true;
                    localStorage.setItem('customFieldsValue', JSON.stringify(v.dataToFIll));
                    this.customData = true;
                    return of(true);
                } else {
                    this.isFilled.add = true;
                    this.customData = false;
                    return of(false);
                }
            });
            if (this.customData === true) {
                this.blockSideNavRedirection();
                return of('custom');
            } else {
                this.isFilled.add = true;
                return of(false);
            }
        }
    }
    checkAcad(res: any, _check, userTokenData: any) {
        let basicFields: any = res.school_student_custom_fields.basic_info_field;
        let personalFields: any;
        const studentPPA: any = res.student_ppa_profiles[0];
        if( res && res.student_ppa_profiles[0] && res.student_ppa_profiles[0].verification_status){
          this.vStatus.next({ verification_status: res.student_ppa_profiles[0].verification_status })
        }
        else{
          return '';
        }
        const userData: any = userTokenData;
        const purpose: any = userData.purpose;
        let notValid: any = true;
        const validArray: any = [];
        let vr: any;
        if(purpose === 'Placement Process App' && this.schoolDetails.admission_report_permission.success) {
            vr = [
                '10th Percentage',
                '12th Percentage',
                'Diploma Percentage',
                'UG CGPA',
                'PG CGPA',
                'SSLC Board',
                'SSLC District',
                'SSLC State',
                'HSC Board',
                'HSC District',
                'HSC State',
                'Registration Number',
                'Roll No',
                'Year of Admission',
                'Backlogs History',
                'Current Backlogs',
                'Interested for Placement',
                'Work Experience',
        ];
        } else {
            vr = [
                '10th Percentage',
                '12th Percentage',
                'Diploma Percentage',
                'UG CGPA',
                'PG CGPA',
                'Registration Number',
                'Roll No',
                'Backlogs History',
                'Current Backlogs',
                'Interested for Placement',
                'Work Experience',
            ];
        }
        personalFields = res.school_student_custom_fields.basic_info_field.filter((f: any) => {
            return !vr.includes(f.label) && f.label !== 'Email ID';
        });
        this.showPersonal = personalFields.find((p) => p.hide === true) ? true : false;
        basicFields = res.school_student_custom_fields.basic_info_field.filter((f: any) => {
            return vr.includes(f.label);
        });
        this.showAcademic = basicFields.find((p) => p.hide === true) ? true : false;
        basicFields.forEach((ef: any) => {
            if (ef.label === '10th Percentage') {
                validArray.push(this.validate(studentPPA, 'tenth_marks', ef.value, ef.hide));
            } else if (ef.label === '12th Percentage') {
                validArray.push(this.validate(studentPPA, 'twelfth_marks', ef.value, ef.hide));
            } else if (ef.label === 'Diploma Percentage') {
                validArray.push(this.validate(studentPPA, 'diploma_marks', ef.value, ef.hide));
            } else if (ef.label === 'UG CGPA') {
                validArray.push(this.validate(studentPPA, 'ug_marks', ef.value, ef.hide));
            } else if (ef.label === 'PG CGPA') {
                validArray.push(this.validate(studentPPA, 'pg_marks', ef.value, ef.hide));
            } else if (ef.label === 'Registration Number' || ef.label === 'Roll No') {
                validArray.push(this.validate(studentPPA, 'roll_no', ef.value, ef.hide));
            } else if (ef.label === 'Backlogs History') {
                validArray.push(this.validate(studentPPA, 'backlog_history', ef.value, ef.hide, true));
            } else if (ef.label === 'Current Backlogs') {
                validArray.push(this.validate(studentPPA, 'current_backlogs', ef.value, ef.hide, false));
            } else if (ef.label === 'Interested for Placement') {
                validArray.push(this.validate(studentPPA, 'interested_for_placement', ef.value, ef.hide, true));
            } else if(purpose === 'Placement Process App' && this.schoolDetails.admission_report_permission.success && ef.label === 'SSLC Board') {
                validArray.push(this.validate(studentPPA, 'sslc_board', ef.value, ef.hide));
            } else if (purpose === 'Placement Process App' && this.schoolDetails.admission_report_permission.success && ef.label === 'SSLC District') {
                validArray.push(this.validate(studentPPA, 'sslc_district', ef.value, ef.hide));
            } else if (purpose === 'Placement Process App' && this.schoolDetails.admission_report_permission.success && ef.label === 'SSLC State') {
                validArray.push(this.validate(studentPPA, 'sslc_state', ef.value, ef.hide));
            } else if (purpose === 'Placement Process App' && this.schoolDetails.admission_report_permission.success && ef.label === 'HSC Board') {
                validArray.push(this.validate(studentPPA, 'hsc_board', ef.value, ef.hide));
            } else if (purpose === 'Placement Process App' && this.schoolDetails.admission_report_permission.success && ef.label === 'HSC District') {
                validArray.push(this.validate(studentPPA, 'hsc_district', ef.value, ef.hide));
            } else if (purpose === 'Placement Process App' && this.schoolDetails.admission_report_permission.success && ef.label === 'HSC State') {
                validArray.push(this.validate(studentPPA, 'hsc_state', ef.value, ef.hide));
            } else if (purpose === 'Placement Process App' && this.schoolDetails.admission_report_permission.success && ef.label === 'Year of Admission') {
                validArray.push(this.validate(studentPPA, 'year_of_admission', ef.value, ef.hide));
            } else if (ef.label === 'Work Experience') {
                validArray.push(this.validate(studentPPA, 'work_experience', ef.value, ef.hide));
            }
        });
        if (purpose === 'Recruitment') {
            let enableDegree : any = this.schoolDetails && this.schoolDetails.schools_metadata && this.schoolDetails.schools_metadata.enable_degree_list;
            validArray.push(this.validate(studentPPA, 'degree', enableDegree, false));
            if (validArray.push(this.validate(studentPPA, 'degree', enableDegree, false)) && enableDegree) {
                this.showAcademic = true;
            }
        }
        notValid = validArray.findIndex((e) => e === false) > -1 ? true : false;
        return notValid;
    }

    validate(value, key, mandatory, hide: any, bool?: any) {
        const ppaData = JSON.parse(localStorage.getItem('ppaData'));
        if (mandatory) {
            if (bool) {
                if ((value[key] === true || value[key] === false) && value[key] !== 'NA') {
                    return true;
                } else {
                    return false;
                }
            } else if ((value[key] || value[key] === 0) && value[key] !== 'NA') {
                return true;
            } else {
                return false;
            }
        } else {
            if (hide && ppaData.verification_status < 2) {
                if (bool) {
                    if ((value[key] === true || value[key] === false) && value[key] !== 'NA') {
                        return true;
                    } else {
                        return false;
                    }
                } else if ((value[key] || value[key] === 0) && value[key] !== 'NA') {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }
    }

    getCustomArray(ud, bc, branchList): Observable<any[]> {
        const dataToFIll: any = [];
        let isCustom: any = false;
        let mandatoryUnfilled: any = false;
        if (ud) {
            for (let i = 0; i < branchList.length; i++) {
                if (ud.find((f) => f.branch_id === branchList[i].branch_id)) {
                    for (let j = 0; j < ud.length; j++) {
                        const brn = ud[j].branch_id;
                        if (branchList[i].branch_id === brn) {
                            if (bc[i] && bc[i].length > 0) {
                                for (let k = 0; k < bc[i].length; k++) {
                                    if (ud[j] && ud[j].fields.findIndex((p) => p.cf_id === bc[i][k].cf_id) === -1) {
                                        if (!bc[i][k].hide && bc[i][k].type !== 'dob' && !bc[i][k].archive) {
                                            dataToFIll[i] ? dataToFIll[i].push(bc[i][k]) : dataToFIll.push([bc[i][k]]);
                                            isCustom = true;
                                            if (bc[i][k].mandatory) {
                                                mandatoryUnfilled = true;
                                            }
                                        }
                                    } else {
                                        if (!bc[i][k].hide && bc[i][k].type !== 'dob' && !bc[i][k].archive) {
                                            if (bc[i][k].mandatory) {
                                                const id: any = ud[j].fields.findIndex((p) => p.cf_id === bc[i][k].cf_id);
                                                if (
                                                    (ud[j].fields[id].answer && ud[j].fields[id].answer.length === 0) ||
                                                    [true, false, undefined, '', null].includes(
                                                        ud[j].fields[id].answer,
                                                    ) ||
                                                    (ud[j].fields[id].type === 'policy' &&
                                                        ud[j].fields[id].answer &&
                                                        new Date(ud[j].fields[id].answer) <
                                                            new Date(bc[i][k].date_modified))
                                                ) {
                                                    dataToFIll[i]
                                                        ? dataToFIll[i].push(bc[i][k])
                                                        : dataToFIll.push([bc[i][k]]);
                                                    isCustom = true;
                                                    mandatoryUnfilled = true;
                                                } else {
                                                    const field: any = bc[i][k];
                                                    field.answer = ud[j].fields[id].answer;
                                                    field.disable = true;
                                                    dataToFIll[i]
                                                        ? dataToFIll[i].push(field)
                                                        : dataToFIll.push([field]);
                                                    isCustom = true;
                                                }
                                            } else {
                                                const id: any = ud[j].fields.findIndex((p) => p.cf_id === bc[i][k].cf_id);
                                                if (
                                                    (ud[j].fields[id].answer && ud[j].fields[id].answer.length === 0) ||
                                                    [true, false, undefined, '', null].includes(ud[j].fields[id].answer)
                                                ) {
                                                    dataToFIll[i]
                                                        ? dataToFIll[i].push(bc[i][k])
                                                        : dataToFIll.push([bc[i][k]]);
                                                } else {
                                                    const field: any = bc[i][k];
                                                    field.answer = ud[j].fields[id].answer;
                                                    field.disable = true;
                                                    dataToFIll[i]
                                                        ? dataToFIll[i].push(field)
                                                        : dataToFIll.push([field]);
                                                    isCustom = true;
                                                }
                                            }
                                        }
                                    }
                                }

                                if (!dataToFIll[i]) {
                                    dataToFIll[i] = [];
                                }
                            } else {
                                if (!dataToFIll[i]) {
                                    dataToFIll[i] = [];
                                }
                            }
                        }
                    }
                } else {
                    if (bc[i]) {
                        for (let k = 0; k < bc[i].length - 1; k++) {
                            if (!bc[i][k].hide && bc[i][k].type !== 'dob' && !bc[i][k].archive) {
                                dataToFIll[i] ? dataToFIll[i].push(bc[i][k]) : dataToFIll.push([bc[i][k]]);
                                isCustom = true;
                                if (bc[i][k].mandatory) {
                                    mandatoryUnfilled = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < branchList.length; i++) {
                if (bc[i]) {
                    for (let k = 0; k < bc[i].length - 1; k++) {
                        if (!bc[i][k].hide && bc[i][k].type !== 'dob' && !bc[i][k].archive) {
                            dataToFIll[i] ? dataToFIll[i].push(bc[i][k]) : dataToFIll.push([bc[i][k]]);
                            isCustom = true;
                            if (bc[i][k].mandatory) {
                                mandatoryUnfilled = true;
                            }
                        }
                    }
                    if (!dataToFIll[i]) {
                        dataToFIll[i] = [];
                    }
                }
            }
        }
        const data: any = {
            dataToFIll,
            isCustom,
            mandatoryUnfilled,
        };
        return of(data);
    }

    getAllBranchFields(..._dt): Observable<any> {
        let branchCustomFields = [];
        let observableBatch = [];
        for (const element of this.userData.school_branch_department_users) {
            observableBatch.push(
                this.customFieldsWithBranchnSchool(
                    this.userData,
                    element.branch_id,
                    this.school_Id,
                ).subscribe((res: any) => {
                    branchCustomFields.push(res.studentCustomFields.student_custom_fields.fields);
                }),
            );
        }
        return forkJoin(observableBatch);
    }

    generateCustomFieldsForDB(newFilled: any) {
        const userToken: any = JSON.parse(localStorage.getItem('token'));
        const userformData: any = JSON.parse(localStorage.getItem('formData'));
        const branchIds: any = userToken.school_branch_department_users.map((m) => m.branch_id);
        const oldFilled: any = userformData.student_custom_fields;
        const fV: any = [];
        branchIds.forEach((eB: any, _i: any) => {
            const oFind: any = oldFilled ? oldFilled.find((z) => z.branch_id === eB) : null;
            const unFormatFind: any = newFilled ? newFilled.find((z) => z.branch_id.value === eB) : null;
            let nFind: any;
            if (unFormatFind) {
                nFind = {};
                nFind.fields = unFormatFind.fields;
                nFind.branch_id = eB;
            }
            const sFind: any = this.schoolFields.find((z) => z.branch_id === eB);
            const branchField: any = {
                branch_id: eB,
                fields: [],
            };
            if (sFind && sFind.fields.length) {
                sFind.fields.forEach((eSclField: any) => {
                    let oV: any;
                    let nV: any;
                    const field: any = eSclField;
                    if (
                        nFind &&
                        nFind.fields &&
                        nFind.fields.length &&
                        nFind.fields.find((z) => z.cf_id === eSclField.cf_id)
                    ) {
                        if (eSclField.type === 'resume') {
                            nV = nFind.fields.find((z) => z.type === eSclField.type);
                        } else {
                            nV = nFind.fields.find((z) => z.cf_id === eSclField.cf_id);
                        }
                    } else if (
                        oFind &&
                        oFind.fields &&
                        oFind.fields.length &&
                        oFind.fields.find((z) => z.cf_id === eSclField.cf_id)
                    ) {
                        if (eSclField.type === 'resume') {
                            oV = oFind.fields.find((z) => z.type === eSclField.type);
                        } else {
                            oV = oFind.fields.find((z) => z.cf_id === eSclField.cf_id);
                        }
                    }

                    if (nV && ![true, false, undefined, null, ''].includes(nV.answer)) {
                        field.answer = nV.answer;
                        branchField.fields.push(field);
                    } else if (oV && ![true, false, undefined, null, ''].includes(oV.answer)) {
                        field.answer = oV.answer;
                        branchField.fields.push(field);
                    }
                });
            }
            if (branchField && branchField.fields && branchField.fields.length) {
                fV.push(branchField);
            }
        });
        return fV;
    }

    saveData(d) {
        return this.httpClient.post(this.api + '/v2/users/update', d, this.options2());
    }

    uploadFile(value, type, name, _suffix, branch_id: boolean) {
        return new Promise((resolved, _rejected) => {
            let typeV;
            let file;
            let urls;
            if (branch_id && type === 'pdf') {
                const bt =
                    this.branchOrder.length > 0 &&
                    this.branchOrder[this.activeBranch - 1] &&
                    this.branchOrder[this.activeBranch - 1].value
                        ? this.branchOrder[this.activeBranch - 1].value
                        : JSON.parse(localStorage.getItem('branches'))[this.activeBranch - 1].value;
                file =
                    name +
                    this.school_Id +
                    '/' +
                    this.userData.email +
                    '-' +
                    bt +
                    '-' +
                    this.userData.user_id +
                    '-resume';
                typeV = 'application/pdf';
            }
            const pay = {
                file_name: file,
                type: typeV,
                Bucket_name : this.globalService.assets_bucket
            };
            this.loader.next(true);
            this.getSignedUrl(pay).subscribe(
                (url: any) => {
                    this.uploadUsingSignedUrl(url.data.url, value).subscribe(
                        (r: any) => {
                            this.loader.next(false);
                            if (r && r.status === 200) {
                                urls = environment.s3Objectstudentassets + pay.file_name;
                                resolved(urls);
                            } else {
                                resolved(false);
                            }
                        },
                        (_error) => {
                            this.loader.next(false);
                        },
                    );
                },
                (_error) => {
                    this.loader.next(false);
                },
            );
        });
    }

    updateToken(upTo: any) {
        const userToken: any = JSON.parse(localStorage.getItem('token'));
        const ppaData: any = JSON.parse(localStorage.getItem('ppaData'));

        if (
            upTo &&
            upTo.profile_pic &&
            upTo.profile_pic !== environment.s3Objectstudentassets + 'assets/common-images/profile-default.jpg'
        ) {
            userToken.profile_pic = upTo.profile_pic;
        }

        if (upTo && upTo.name && upTo.name.length) {
            userToken.name = upTo.name;
        }

        if (upTo && upTo.gender) {
            userToken.gender = upTo.gender;
        }

        if (upTo && upTo.dob) {
            userToken.dob = upTo.dob;
        }

        if (upTo && upTo.roll_no) {
            userToken.roll_no = upTo.roll_no;
            ppaData.roll_no = upTo.roll_no;
        }

        if (upTo && upTo.degree) {
            ppaData.degree = upTo.degree;
            userToken.student_ppa_profiles[0].degree = upTo.degree;
        }
        if (upTo && upTo.phone && upTo.country_code) {
            userToken.phone = upTo.phone;
            userToken.phone_number = {
                code: upTo.country_code,
                number: upTo.phone,
            };
        }
        if (upTo && upTo.sslc_board) {
            ppaData.sslc_board = upTo.sslc_board;
            userToken.student_ppa_profiles[0].sslc_board = upTo.sslc_board;
        }
        if (upTo && upTo.sslc_district) {
            ppaData.sslc_district = upTo.sslc_district;
            userToken.student_ppa_profiles[0].sslc_district = upTo.sslc_district;
        }
        if (upTo && upTo.sslc_state) {
            ppaData.sslc_state = upTo.sslc_state;
            userToken.student_ppa_profiles[0].sslc_state = upTo.sslc_state;
        }
        if (upTo && upTo.hsc_board) {
            ppaData.hsc_board = upTo.hsc_board;
            userToken.student_ppa_profiles[0].hsc_board = upTo.hsc_board;
        }
        if (upTo && upTo.hsc_district) {
            ppaData.hsc_district = upTo.hsc_district;
            userToken.student_ppa_profiles[0].hsc_district = upTo.hsc_district;
        }
        if (upTo && upTo.hsc_state) {
            ppaData.hsc_state = upTo.hsc_state;
            userToken.student_ppa_profiles[0].hsc_state = upTo.hsc_state;
        }
        if (upTo && upTo.year_of_admission) {
            ppaData.year_of_admission = upTo.year_of_admission;
            userToken.student_ppa_profiles[0].year_of_admission = upTo.year_of_admission;
        }
        localStorage.removeItem('ppaData');
        localStorage.setItem('ppaData', JSON.stringify(ppaData));
        localStorage.removeItem('token');
        localStorage.setItem('token', JSON.stringify(userToken));
    }

    resetVariable() {
        this.fValue = {};
        this.branchOrder = [];
        this.customFieldsValue = [];
        this.basicFields = [];
        this.degreeValue = [];
        this.isFilled = {};
        this.activeBranch = 1;
        this.acadDetails = false;
        this.showAdditional = false;
        this.personalData = false;
        this.customData = false;
        this.nextAddData = [];
    }

    fillAddData(index, data) {
        if (this.nextAddData[index - 1]) {
            this.nextAddData[index - 1] = data;
        } else {
            this.nextAddData.push(data);
        }
    }

    getSignedUrl(p): Observable<any> {
        const pay = this.api + '/getsignedurl';
        return this.httpClient.post(pay, p, this.options());
    }
    getSignedUrlForAnyType(p): Observable<any> {
        const pay = this.api + '/get_signedurl_all';
        return this.httpClient.post(pay, p, this.options());
    }

    getTaSignedUrl(p): Observable<any> {
        const pay = this.api + '/get_ta_signed_url';
        return this.httpClient.post(pay, p, this.options());
    }

    uploadUsingSignedUrl(url, payload): Observable<any> {
        return this.httpClient.put(url, payload, { observe: 'response' });
    }
    uploadUsingSignedUrlWithOptions(url, payload): Observable<any> {
        return this.httpClient.put(url, payload, { observe: 'response' });
    }
    studentCustomFields(branchId, userId) {
        return this.httpClient.get(
            this.api + '/branch/' + branchId + '/studentcustomfields/' + userId,
            this.loginservice.options(null),
        );
    }
    schoolCustomFields(branchId, schoolId) {
        return this.httpClient
            .get(
                this.api + '/branch/' + branchId + '/school/' + schoolId + '/studentcustomfields',
                this.loginservice.options(null),
            )
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }

    publicProfileCustomFields(payload) {
        return this.httpClient.post(this.api + '/publicprofile/studentcustomfields', payload, this.loginservice.options(null));
    }

    getPpaProfile(sProfileId: any) {
        return this.httpClient.get(
            this.api + '/users/getprofile?s_id=' + sProfileId + '&school_id=' + this.getSclId(),
            this.loginservice.options(null),
        );
    }
    updateRollNo(payload) {
        return this.httpClient.post(this.api + '/users/updaterollno', payload, this.loginservice.options(null));
    }
    changesRequest(payload) {
        return this.httpClient.post(this.api + '/users/requestchanges', payload, this.loginservice.options(null));
    }
    profilePublish(payload) {
        return this.httpClient.put(this.api + '/users/ppapublish', payload, this.options());
    }
    changePassword(payload, _userData) {
        return this.httpClient.post(this.api + '/users/reset', payload, this.loginservice.options(null));
    }
    generateOtp(payload) {
        return this.httpClient.post(this.api + '/users/generateOtp', payload, this.loginservice.options(null));
    }
    sendOtp(payload) {
        return this.httpClient.post(this.api + '/users/sendOtp', payload, this.loginservice.options(null));
    }
    verifyOtp(payload) {
        return this.httpClient.post(this.api + '/users/verifyOtp', payload, this.loginservice.options(null));
    }
    validateOtp(payload) {
        return this.httpClient.post(this.api + '/users/validateOtp', payload, this.loginservice.options(null));
    }
    reasonReject(reasonPayload) {
        return this.httpClient.post(this.api + '/users/reasonreject', reasonPayload, this.loginservice.options(null));
    }
    changeEmailID(payload) {
        payload.school_code = this.schoolCode;
        return this.httpClient.post(this.api + '/users/resetEmailId', payload, this.loginservice.options(null));
    }
    getEmailFromId(payload)
    {
        return this.httpClient.post(this.api + '/publicprofile/getPublicProfileEmail', payload, this.loginservice.options(null));
    }
}
