import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "@services/login.service";
import {
  aiLogo,
  courseImg,
  loginImg,
  prevIcon,
  signupImg,
  Rect,
  neoLogo,
} from "../../../assets/images/";

interface NavListProps {
  text: string;
  icon: string;
  activeIcon: string;
  active: boolean;
  url: string;
  type?: string;
}

@Component({
  selector: "app-public-sidebar",
  templateUrl: "./public-sidebar.component.html",
  styleUrls: ["./public-sidebar.component.less"],
})
export class PublicSidebarComponent implements OnInit {
  neoLogo=neoLogo;
  aiLogo = aiLogo;
  prevIcon = prevIcon;
  @Input() toggleBtn: boolean;
  @Output() sideBarHidden = new EventEmitter<boolean>();

  navList: NavListProps[] = [
    {
      text: "Courses",
      type: "course",
      icon: courseImg,
      activeIcon: courseImg,
      active: true,
      url: "/course",
    },
    {
      text: "Login",
      type: "login",
      icon: loginImg,
      activeIcon: loginImg,
      active: false,
      url: "/login",
    },
    {
      text: "Signup",
      type: "signup",
      icon: signupImg,
      activeIcon: signupImg,
      active: false,
      url: "/signup",
    },
  ];
  userData: any;
  localSchoolData: any;
  loader: boolean;
  path: any;
  rect=Rect;

  get queryParameter(): string {
    let result;
    this.route.queryParams.subscribe((params) => (result = params.type));
    return result;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService
  ) {
    if (this.isLoggedIn()) {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("/firebase-messaging-sw.js");
      }
    }
  }

  async ngOnInit() {
    this.path = window.location.pathname;
    this.userData = JSON.parse(localStorage.getItem("token"));
    this.localSchoolData = JSON.parse(localStorage.getItem("school_details"));
    if (this.isLoggedIn()) {
      if (this.path.includes("mycourses")) {
        const urlPath = this.queryParameter || "mycourses";
        this.changeActiveState("/" + urlPath);
      } else {
        this.changeActiveState(this.path);
      }
    }
  }

  isLoggedIn() {
    const lcstorage = JSON.parse(this.loginService.loginStatus());
    if (lcstorage) {
      return true;
    }
    return false;
  }

  navigateToURL(item: NavListProps, subType = "mycourses") {
    const { url, type } = item;
    if (type === "mycourse") {
      this.router.navigateByUrl("/mycourses?type=" + subType);
    } else {
      this.router.navigateByUrl(url);
    }

    this.changeActiveState(url);
    this.sideBarHidden.emit(false);
  }

  changeActiveState(url: string) {
    this.navList.forEach((navListItem: NavListProps) => {
      if (url === navListItem.url) {
        navListItem.active = true;
      } else {
        navListItem.active = false;
      }
    });
  }

  handleToggleBtn() {
    this.sideBarHidden.emit(false);
  }
}
