import {
    Component,
    Input,
    ViewEncapsulation,
    OnInit,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProfileService } from 'src/app/containers/init-form/init_form.service';
import { GlobalService } from '@services/global.service';
import { fileUpload,file,eye,x,info1,descIconActive, descIcon } from 'src/assets/images';
// text,email,tel,textarea,password,
@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.html',
    styleUrls: ['./../profile-form-elements.less'],
    encapsulation: ViewEncapsulation.None,
})
export class FileUploadComponent implements OnInit, OnChanges {
    @Input() field: any = {};
    @Input() form: FormGroup;
    @Input() max434;
    @Output() infoFileFormat = new EventEmitter<any>();
    @Output() OfferUpload = new EventEmitter<any>();
    val = 'icon-person';
    fileUpload = fileUpload;
    uploaded = false;
    alreadyFilled = false;
    showView;
    file=file;
    descIcon=descIcon;
    descIconActive=descIconActive;
    name;
    required;
    eye=eye;
    x=x;
    info1=info1;
    dispFile = false;
    validity;
    loader;
    pc: any;
    errorMessage: any;
    download: boolean;
    viewLink: any;
    localUserData;
    localBranches;
    selectedFileTypes;
    fileTypeData = {};
    allFileTypes = {
        pdf: ['application/pdf'],
        document: ['.txt', '.rtf', '.doc', '.docx', '.odt'],
        spreadsheet: ['.xls', '.xlsx', 'csv'],
        image: ['.jpg', '.png', '.gif', '.tiff', '.ico', '.svg', '.webp'],
        audio: ['.mp3', '.wav', '.ogg'],
        video: ['.avi', '.mov', '.mp4', '.ogg', '.wmv', '.webm'],
        presentation: ['.ppt', '.pptx'],
    };
    get isValid() {
        return this.form.controls[this.field.name].valid;
    }
    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }

    constructor(private serv: ProfileService, public globalService: GlobalService) {}
    ngOnInit() {
        if(window.innerWidth<=1024) {
            this.showView = true;
        }
        if (this.field.answer && this.field.answer !==true) {
            this.field.value = this.field.answer;
            this.field.customError.error = false;
            this.form.controls[this.field.name].setValue(this.field.answer);
            if(this.field.type !== 'resume') {
                this.alreadyFilled = true;
            }
        }
        if(this.field.value !== true && this.field.value !== '' && this.field.value !== false && this.field.value!==undefined && this.field.value!==null) {
            this.uploaded = true;
            this.name = (this.field.answer && this.field.answer.name) ?this.field.answer.name:'Resume';  
        } else {
            this.field.value = null;
        }
        if(this.field.driveOffer && this.field.fileName) {
            this.name = this.field.fileName;
        }
        if (this.field.value && this.field.value.media_type) {
            if (
                this.field.value.media_type.includes('audio') ||
                this.field.value.media_type.includes('video') ||
                this.field.value.media_type.includes('image') ||
                this.field.value.media_type.includes('pdf')
            ) {
                this.download = true;
            }
        }
        if(this.field.type === 'resume') {
            if(this.field.value === true) {
                this.field.value = null;
            }
        }
        this.required = this.form.controls[this.field.name].hasError('required');
    }

    uploadResume(event, _type?) {
        this.loader = true;
        this.setName(event);
        if (
            event.target.files &&
            event.target.files[0] &&
            event.target.files[0].type &&
            event.target.files[0].type.includes('pdf')
        ) {
            if ((this.field.files_size && event.target.files[0].size < this.field.files_size) ||
                !this.field.files_size) {
                this.serv.uploadFile(event.target.files[0], 'pdf', 'resumes/', '-resume', true).then((r: any) => {
                    this.loader = false;
                    if (r) {
                        this.field.customError.error = false;
                        this.form.controls[this.field.name].setValue(r);
                        this.field.value = r;
                        this.alreadyFilled = false;
                    } else {
                        this.field.customError.error = true;
                        this.field.customError.message = 'Upload a valid .pdf file';
                    }
                });
            } else {
                this.loader = false;
                this.field.customError.error = true;
                this.field.customError.message = 'Upload a file less than ' + this.field.files_size / Math.pow(10, 6) + 'MB';
            }
        } else {
            this.loader = false;
            this.field.customError.error = true;
            this.field.customError.message = 'Upload a valid .pdf file';
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            setTimeout(() => {
                this.setVal(this.field.value);
            }, 500);
        }
    }
    setVal(o) {
        this.form.controls[this.field.name].setValue(o);
    }

    onUploadImg(event) {
        if(this.field.driveOffer) {
            const e = {event:event, type:this.field.name}
            this.OfferUpload.emit(e);
            this.setName(event);
        }
        if (event && event.target && event.target.files && event.target.files.length && !this.field.driveOffer) {
            this.validity = this.checkFileValid(event.target.files[0]);
            if(this.field.driveOffer) {
                this.validity = 'supported';
            }
            if (this.validity === `fileformat-notsupported`) {
                this.errorMessage = `Selected file (${event.target.files[0].name}) format is not supported for upload`;
                this.field.customError.error = true;
                this.field.customError.message = 'Upload a file with valid file format';
            } else if (this.validity === 'filesize-notsupported') {
                this.errorMessage = `Selected file (${event.target.files[0].name}) size is not supported for upload`;
                this.field.customError.error = true;
                this.field.customError.message =
                    'Upload a file less than ' + this.field.file_size / Math.pow(10, 6) + 'MB';
            } else {
                let mediaquestions: any;
                this.errorMessage = null;
                mediaquestions = {
                    source: URL.createObjectURL(event.target.files[0]),
                    file: event.target.files[0],
                    type: event.target.files[0].type,
                    media_type: this.field.upload_type
                        ? this.field.upload_type
                        : event.target.files[0].type.split('/')[0],
                    data: 'input',
                    name: event.target.files[0].name,
                    size: event.target.files[0].size,
                    url: URL.createObjectURL(event.target.files[0]),
                };
                this.setName(event);
                if (
                    mediaquestions.media_type.includes('audio') ||
                    mediaquestions.media_type.includes('video') ||
                    mediaquestions.media_type.includes('image') ||
                    mediaquestions.media_type.includes('pdf')
                ) {
                    this.download = true;
                }
                this.field.value = mediaquestions;
                this.field.customError.error = false;
                this.form.controls[this.field.name].setValue(mediaquestions);
                this.alreadyFilled = false;
                this.viewLink = undefined;
            }
        }
    }

    setName(event) {
        this.uploaded = true;
        this.name = event.target.files[0].name;
        const lastIndex = this.name.lastIndexOf('.');
        let before = this.name.slice(0, lastIndex);
        const after = this.name.slice(lastIndex, this.name.length);
        if(before.length>12) {
            before = before.substring(0,14)+'...';
        }
        this.name =  before + after;
    }

    checkFileValid(event) {
        if (this.field && this.field.file_type && this.field.allow_type) {
            let selected: any;
            Object.keys(this.field.file_type).forEach((key: any) => {
                if (this.field.file_type[key]) {
                    selected = true;
                }
            });
            if (this.field.file_type.audio) {
                if (
                    event.type.includes('mp3') ||
                    event.type.includes('ogg') ||
                    event.type.includes('wav') ||
                    event.type.includes('mpeg') ||
                    event.type.includes('audio')
                ) {
                    if (event.size <= this.field.file_size) {
                        this.field.upload_type = 'audio';
                        return 'supported';
                    } else {
                        this.field.value = null;
                        return 'filesize-notsupported';
                    }
                }
            }
            if (this.field.file_type.video) {
                if (
                    event.type.includes('mp4') ||
                    event.type.includes('3gp') ||
                    event.type.includes('ogg') ||
                    event.type.includes('webm') ||
                    event.type.includes('video')
                ) {
                    if (event.size <= this.field.file_size) {
                        this.field.upload_type = 'video';
                        return 'supported';
                    } else {
                        this.field.value = null;
                        return 'filesize-notsupported';
                    }
                }
            }
            if (this.field.file_type.document) {
                if (
                    event.type.includes('rtf') ||
                    event.type.includes('.document') ||
                    event.type.includes('plain') ||
                    event.type.includes('msword') ||
                    event.type.includes('.text')
                ) {
                    if (event.size <= this.field.file_size) {
                        this.field.upload_type = 'document';
                        return 'supported';
                    } else {
                        this.field.value = null;
                        return 'filesize-notsupported';
                    }
                }
            }
            if (this.field.file_type.presentation) {
                if (event.type.includes('powerpoint') || event.type.includes('presentation')) {
                    if (event.size <= this.field.file_size) {
                        this.field.upload_type = 'presentation';
                        return 'supported';
                    } else {
                        this.field.value = null;
                        return 'filesize-notsupported';
                    }
                }
            }
            if (this.field.file_type.spreadsheet) {
                if (event.type.includes('excel') || event.type.includes('sheet') || event.type.includes('csv')) {
                    if (event.size <= this.field.file_size) {
                        this.field.upload_type = 'spreadsheet';
                        return 'supported';
                    } else {
                        this.field.value = null;
                        return 'filesize-notsupported';
                    }
                }
            }
            if (this.field.file_type.pdf) {
                if (event.type.includes('pdf')) {
                    if (event.size <= this.field.file_size) {
                        this.field.upload_type = 'pdf';
                        return 'supported';
                    } else {
                        this.field.value = null;
                        return 'filesize-notsupported';
                    }
                }
            }
            if (this.field.file_type.image) {
                if (
                    event.type.includes('png') ||
                    event.type.includes('gif') ||
                    event.type.includes('jpeg') ||
                    event.type.includes('tiff') ||
                    event.type.includes('svg') ||
                    event.type.includes('webp') ||
                    event.type.includes('icon')
                ) {
                    if (event.size <= this.field.file_size) {
                        this.field.upload_type = 'image';
                        return 'supported';
                    } else {
                        this.field.value = null;
                        return 'filesize-notsupported';
                    }
                }
            }
            if (selected) {
                this.field.value = null;
                return 'fileformat-notsupported';
            } else {
                if (event.size <= this.field.file_size) {
                    if (event.type.includes('pdf')) {
                        this.field.upload_type = 'pdf';
                    }
                    return 'supported';
                } else {
                    this.field.value = null;
                    return 'filesize-notsupported';
                }
            }
        } else {
            if (event.size <= this.field.file_size) {
                if (event.type.includes('pdf')) {
                    this.field.upload_type = 'pdf';
                }
                return 'supported';
            } else {
                this.field.value = null;
                return 'filesize-notsupported';
            }
        }
    }

    InitialviewFile() {
        this.localUserData = JSON.parse(localStorage.getItem('token'));
        this.localBranches = JSON.parse(localStorage.getItem('branches'));
        const userData = JSON.parse(localStorage.getItem('token'));
        
        let source;
        if(this.field.source) {
            source = this.field.source
        } else {
            source =    'student_custom_fields/' +
                        this.localUserData.school_id +
                        '/' +
                        this.localBranches[0].value +
                        '/' +
                        this.localUserData.user_id +
                        '/' +
                        this.field.cf_id +
                        '/' +
                        this.field.answer.name;
        }   
        const payload: any = {
            file_name: source,
            user_id: userData.user_id,
        };
        if(payload.file_name.includes('student_custom_fields')) {
            if(payload.file_name.includes('/Drive/')) {
                payload.Bucket_name = this.globalService.drive_bucket;
            }
            else {
                payload.Bucket_name = this.globalService.assets_bucket;
            }
        }
        this.globalService.getPrivateSignedDownloadUrl(payload).subscribe(
            (res: any) => {
                if (res.success) {
                    this.viewLink = res.url;
                    this.setFile();
                }
            },
        );
    }

    setFile() {
        this.dispFile = true;
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = this.viewLink;
        link.target = '_blank';
        link.click();
        document.body.removeChild(link);
    }

    viewFile() {
        if(this.field.driveOffer) {
            this.viewLink = this.field.value;
            this.setFile();
        } else {
            if(this.alreadyFilled) {
                this.InitialviewFile();
            } else {
                if(this.field.type === 'file_upload') {
                    if (this.field.value && this.field.value.source && this.viewLink === undefined) {
                        this.viewLink = this.field.value.source;
                    } else if (this.field.answer && this.field.answer.source && this.viewLink === undefined) {
                        this.viewLink = this.field.answer.source;
                    }
                }
                if(this.field.type === 'resume') {
                    this.viewLink = this.field.value;
                }
                this.setFile();
            }    
        }
    }

    deleteFile() {
        this.uploaded = false;
        this.dispFile = false;
        const element: any = document.getElementById('uploadMedia-' + this.field.cf_id);
        element.value = '';
        if (this.field.value) {
            this.field.value = null;
        }
        if (this.field.answer) {
            this.field.answer = null;
        }
        this.form.controls[this.field.name].setValue(null);
    }

    openFileInfo(event) {
        const labelDiv = document.getElementById('fileLabel');
        const position = labelDiv.getBoundingClientRect();
        this.fileTypeData = {top: Math.trunc(position.top), left: Math.trunc(position.left)};
        if(window.innerWidth<640) {
            this.fileTypeData["top"] = this.fileTypeData["top"] -20 ;
        }
        this.infoFile(this.field);
    }

    closeFileInfo(event) {
        this.fileTypeData["toolTip"]=false;
        this.infoFileFormat.emit(this.fileTypeData);
    }

    infoFile(event) {
        if (event && event.file_type) {
            this.selectedFileTypes = [];
            Object.keys(event.file_type).forEach((each: any) => {
                if (event.file_type[each]) {
                    this.selectedFileTypes.push({
                        key: each,
                        value: this.allFileTypes[each].join(', '),
                    });
                }
            });
            this.fileTypeData["selectedFileTypes"] = this.selectedFileTypes;
            this.fileTypeData["toolTip"]=true;
            this.infoFileFormat.emit(this.fileTypeData);
        }
    }
}
