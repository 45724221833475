import { BehaviorSubject, Observable, map, catchError } from "rxjs";
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoginService } from './login.service';
import { GlobalService } from 'src/app/services/global.service';

@Injectable({
    providedIn: 'root',
})

export class TeamService {
    private teamsSubject: BehaviorSubject<any>;

    api = environment.HOST.link;

    constructor(private http: HttpClient, public loginservice: LoginService, public globalService: GlobalService) {
        this.teamsSubject = new BehaviorSubject([]);
    }

    getAllTeams(c_id): Observable<any> {
        let params: HttpParams = new HttpParams();
        params.set('dataIds', this.loginservice.schoolId);
        let options: any = this.loginservice.options(null);
        options.search = params;
        return this.http
            .get(
                this.api + '/team/getCourseDetails/' + c_id,
                options,
            )
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }



    createTeam(payload) {
        return this.http.post(this.api + '/team', payload, this.loginservice.options(null)).pipe(catchError(this.loginservice.handleError.bind(this)))
            .pipe(map(() => this.getAllTeams(payload.c_id))); //This is ok , since the teams size will be few hundrends max.
    }
    getTeamChannelID(payload) {
        return this.http.post(this.api + '/team/getTeamChannelID', payload, this.loginservice.options(null)).pipe(catchError(this.loginservice.handleError.bind(this)))
             //This is ok , since the teams size will be few hundrends max.
    }

    joinTeam(rest) {
        return this.http.post(this.api + '/team/add-to-team', rest, this.loginservice.options(null)).pipe(catchError(this.loginservice.handleError.bind(this)))
    }

    getGitAcessToken(auth_code: string) {
        return this.http.post(this.api + "/team/github-getGitAccessToken", {
          code: auth_code,
        }, this.loginservice.options(null))
      }

    getGitUserDetails(payload) {
        this.http.post(this.api + "/team/github-getUserDetails" ,payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this))).subscribe(
                result => this.teamsSubject.next(result),
                err => this.teamsSubject.error(err)
            );
        return this.teamsSubject.asObservable();
    }

    inviteStudentToRepo(payload) {
        return this.http.post(this.api + '/team/invite-student-to-repo', payload, this.loginservice.options(null)).pipe(catchError(this.loginservice.handleError.bind(this)))
    }

    exitTeam(payload) {
        return this.http.post(this.api + '/team/exitTeam', payload, this.loginservice.options(null)).pipe(catchError(this.loginservice.handleError.bind(this)))
    }


}