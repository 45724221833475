import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner.component';
import { DialogModule } from '../dialog/dialog.module';
import { ButtonModule } from '../button/button.module';
import { DialogSidebarModule } from '../dialog-sidebar/dialog-sidebar.module';
@NgModule({
    declarations: [BannerComponent],
    imports: [CommonModule, DialogModule, ButtonModule, DialogSidebarModule],
    exports: [BannerComponent],
})
export class BannerModule {}

