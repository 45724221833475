import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginService } from '@services/login.service';
import { TestService } from '@services/test.service';
import { GlobalService } from '@services/global.service';

@Injectable()
export class AuthGuard implements CanActivate {
    private schoolMetaData: any;

    constructor(
        private globalService: GlobalService,
        private testService: TestService,
        private loginservice: LoginService,
        private router: Router,
    ) {}

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let token: any = this.loginservice.loginStatus();
        if (token) {
            if (localStorage.getItem('noRedirect')) {
                return false;
            }
            const schoolDetail = JSON.parse(localStorage.getItem('school_details'));
            const redirectPath = localStorage.getItem('redirect');
            // if((redirectPath && !schoolDetail.schools_metadata.enableTwoFactorAuthentication && !state.url.includes('dashboard')) ||
            //    (redirectPath &&
            //     schoolDetail.schools_metadata.enableTwoFactorAuthentication &&
            //     this.globalService.verifiedOtp && !state.url.includes('dashboard'))
            // ) {
            //     this.router.navigateByUrl('/dashboard');
            //     return false;
            // }
            if (!(this.loginservice.schoolId || this.loginservice.branchId || this.loginservice.instituteType)) {
                token = JSON.parse(token);
                if (token && token.school_branch_department_users && token.school_branch_department_users.length) {
                    this.loginservice.schoolId = token.school_branch_department_users[0].school_id;
                    this.loginservice.branchId = token.school_branch_department_users.map((data) => data['branch_id']);
                    this.loginservice.instituteType = token.institute_type;
                }
            }

            // copy paste handler
            // this.testService.copyPasteHandler();

            if (!this.schoolMetaData) {
                const SchoolDetail = JSON.parse(localStorage.getItem('school_details'));
                this.schoolMetaData =
                SchoolDetail && SchoolDetail.schools_metadata
                        ? SchoolDetail.schools_metadata
                        : undefined;
            }

            if (this.schoolMetaData) {
                if (
                    (state.url.includes('type=mycourses') && !this.schoolMetaData.allow_courses) ||
                    (state.url.includes('type=myprojects') && !this.schoolMetaData.allow_course_projects) ||
                    (state.url.includes('type=myquizzes') && !this.schoolMetaData.allow_quizzes) ||
                    (state.url.includes('type=myexams') && !this.schoolMetaData.allow_exams) ||
                    (state.url.includes('type=mypractices') && !this.schoolMetaData.allow_practices) ||
                    (state.url.includes('type=mylearningpaths') && !this.schoolMetaData.allow_learning_paths) ||
                    (state.url.includes('type=myhackathons') && !this.schoolMetaData.allow_hackathons) ||
                    (state.url.includes('type=myassessments') && !this.schoolMetaData.allow_assessments) ||
                    (state.url.includes('type=mylabs') && !this.schoolMetaData.allow_lab_courses) ||
                    (state.url.includes('type=mychallenges') && !this.schoolMetaData.allow_challenges) ||
                    (state.url.includes('type=mytests') && !this.schoolMetaData.allow_company_specific_tests) ||
                    (state.url.includes('drive') && !this.schoolMetaData.allow_drives) ||
                    (state.url.includes('contest') && !this.schoolMetaData.allow_contest) ||
                    (state.url.includes('feeds') && !this.schoolMetaData.allow_feeds) ||
                    (state.url.includes('dashboard') && !this.schoolMetaData.allow_dashboard) ||
                    (state.url.includes('leaderboard') && !this.schoolMetaData.allow_leaderboard) ||
                    (state.url.includes('attendancesettings') && !this.schoolMetaData.allow_attendance) ||
                    (state.url.includes('chat') && !this.schoolMetaData.allow_engagement) ||
                    (state.url.includes('certificates') && !this.schoolMetaData.allow_certificates) ||
                    (state.url.includes('profile') && this.schoolMetaData.disableProfile)
                ) {
                    this.router.navigateByUrl(this.globalService.defaultRedirection());
                    return false;
                }
            }
            return true;
        } else {
            this.testService.removeCopyPasteHandler();
            this.router.navigateByUrl('/login');
            return false;
        }
    }
}
