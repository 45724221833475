import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
    @Input() type: any;
    @Input() loaderStyle: any;
    @ViewChild('loader', { static: false }) loader: any;
    loader2: any;
    ngOnInit() {
        this.loader2 = environment.s3Objectstudentassets + 'assets/common-images/spinner.gif';
    }
}
