import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compactNumFormat'
})
export class CompactNumFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const formatter = new Intl.NumberFormat('en-IN', {
      //@ts-ignore
      notation: "compact",
      compactDisplay: "short",
    }).format(value);
    return formatter;
  }

}
