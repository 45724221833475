import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'policy',
    templateUrl: './policy.html',
    styleUrls: ['./../profile-form-elements.less'],
})
export class PolicyComponent {
    selectedValue;
    @Input() field: any = {};
    @Input() form: FormGroup;
    @Output() termsChecked = new EventEmitter<any>();
    value = [];
    get isValid() {
        return this.form.controls[this.field.name].valid;
    }
    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }
    ngOnInit() {
        if (this.field.question.length) {
            this.field.question = this.removeLine(this.field.question);
        }
        this.form.controls[this.field.name].setValue(null);
        this.field.answer = null;
        this.field.value = null;
    }

    removeLine(s) {
        s = s.replaceAll('</p><p>', '');
        return s;
    }

    checked() {
        if (this.selectedValue) {
            const date = new Date().toISOString();
            this.field.answer = date;
            this.form.controls[this.field.name].setValue(date);
        }
        else
        {
            const date = null;
            this.field.answer = date;
            this.form.controls[this.field.name].setValue(date);
        }
        this.termsChecked.emit(this.selectedValue);
    }
}
