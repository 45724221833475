import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { TestService } from '@services/test.service';
import { GlobalService } from '@services/global.service';
import { DriveService } from '@services/drive.service';


import {
  certificateImg,
  certificateFilledImg,
  contestImg,
  contestFilledImg,
  openIde,
  openIdeFilled,
  leaderboardImg,
  leaderboardFilledImg,
  dashboardImg,
  dashboardFilledImg,
  courseImg,
  courseFilledImg,
  marketplace,
  marketplaceFilled,
  engagementImg,
  engagementFilledImg,
  whiteThreeDotImg,
  drivesImg,
  drivesFilledImg,
  neoLogo,
  prevIcon,
  Rect,
  quizzesIcon,
  quizzesActiveIcon,
  examsActiveIcon,
  examsIcon,
  navPracticesActiveIcon,
  navPracticesIcon,
  learningPathActiveIcon,
  learningPathIcon,
  navAssessmentActiveIcon,
  navAssessmentIcon,
  labsIcon,
  labsActiveIcon,
  challengeIcon,
  challengeActiveIcon,
  companySpecificTest,
  companySpecificTestActive,
  HackathonActiveIcon,
  HackathonIcon,
  project,
  projectFilledImg
} from '../../../assets/images/';
import { LoginService } from '@services/login.service';
import { ChatService } from '@services/chat.service';

interface showListProps {
  text: string;
  icon: string;
  active: boolean;
  url: string;
  type?: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
})
export class SidebarComponent implements OnInit,OnChanges {
  neoLogo = neoLogo;
  prevIcon = prevIcon;
  threeDotsIcon=whiteThreeDotImg;
  sclMD: any = {};
  navList:any = [];
  popupList: any = [];
  showList: any = [];
  afterIconList: number;
  @Input() toggleBtn: boolean;
  @Output() sideBarHidden = new EventEmitter<boolean>();
  active;
  userData: any;
  localSchoolData: any;
  loader: boolean;
  cartClicked: any;
  path: any;
  navHeight;
  @Input() moreListHide:boolean;
  @Input() mobile;
  @Input() cart;
  @Input() screenHeight;
  @Output() moreListHandle = new EventEmitter<boolean>();
  @ViewChild('sidebar', { static: true }) sidebar: ElementRef;
  @ViewChild('iconList',{static : true}) iconList: ElementRef;
  @ViewChild('listLayout',{static : true}) listLayout: ElementRef;

  rect = Rect;
  schoolLogo :any;
  get queryParameter(): string {
    let result;
    this.route.queryParams.subscribe(params =>
      result = params.type);
    return result;
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private testService: TestService,
              private globalService: GlobalService,
              private loginService: LoginService,
              private chatService: ChatService,
              private driveService : DriveService) {
    if (this.isLoggedIn()) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js');
      }
    }
  }

  async ngOnInit() {
    this.path = window.location.pathname;
    this.cartClicked=false;
    this.navHeight = this.sidebar.nativeElement.offsetHeight;
    this.userData = JSON.parse(localStorage.getItem('token'));
    this.localSchoolData = JSON.parse(localStorage.getItem('school_details'));
    if (this.isLoggedIn()) {
      if (this.localSchoolData && this.localSchoolData.schools_metadata) {
        this.schoolLogo = this.localSchoolData.school_logo;
        this.sclMD = this.localSchoolData.schools_metadata;
        this.navList = [
          {
            text: 'Dashboard',
            type: 'dashboard',
            icon: dashboardImg,
            activeIcon: dashboardFilledImg,
            active: true,
            url: '/dashboard',
            enableIcon :  this.sclMD.allow_dashboard,
          },
          {
            text: 'Courses',
            type: 'mycourse',
            subType : 'mycourses',
            icon: courseImg,
            activeIcon: courseFilledImg,
            active: false,
            url: '/mycourses?type=mycourses',
            enableIcon : this.sclMD.allow_courses,
          },
          // {
          //   text: this.driveService.drivesNameFormatView.drivesNamelist.label,
          //   type: 'drives',
          //   icon: drivesImg,
          //   activeIcon: drivesFilledImg,
          //   active: false,
          //   url: '/drives',
          //   enableIcon : this.sclMD.allow_drives,
          // },
          // { text: 'Contest', icon: contestImg, activeIcon: contestFilledImg, active: false, url: '/mycontest',enableIcon : this.sclMD.allow_contest,
        // },
          { text: 'Open IDE', icon: openIde, activeIcon: openIdeFilled, active: false, url: '/ide',enableIcon : this.sclMD.allow_openide,
        },
          // {
          //   text: 'Engagement',
          //   icon: engagementImg,
          //   activeIcon: engagementFilledImg,
          //   active: false,
          //   url: '/chat',
          //   enableIcon : this.sclMD.allow_engagement,
          // },
          // {
          //   text: 'Marketplace',
          //   icon: marketplace,
          //   activeIcon: marketplaceFilled,
          //   active: false,
          //   url: '/course',
          //   enableIcon : true,
          // },
          // {
          //   text: 'Academics & Examination',
          //   type: 'examsandresults',
          //   icon: dashboardImg,
          //   activeIcon: dashboardFilledImg,
          //   active: false,
          //   url: '/examsandresults',
          //   enableIcon :  this.sclMD.allow_coe,
          // },
          // {
          //   text: 'Certificates',
          //   icon: certificateImg,
          //   activeIcon: certificateFilledImg,
          //   active: false,
          //   url: '/certificates',
          //   enableIcon : this.sclMD.allow_certificates,
          // },
          // {
          //   text: 'Leaderboard',
          //   icon: leaderboardImg,
          //   activeIcon: leaderboardFilledImg,
          //   active: false,
          //   url: '/leaderboard',
          //   enableIcon : this.sclMD.allow_leaderboard,
          // },
          // {
          //   text: 'Projects',
          //   type: 'mycourse',
          //   subType : 'myprojects',
          //   icon: project,
          //   activeIcon: projectFilledImg,
          //   active: false,
          //   url: '/mycourses?type=myprojects',
          //   enableIcon : this.sclMD.allow_course_projects,
          // },
          {
            text: 'Assessments',
            type: 'mycourse',
            subType : 'myquizzes',
            icon: quizzesIcon,
            activeIcon: quizzesActiveIcon,
            active: false,
            url: '/mycourses?type=myquizzes',
            enableIcon : this.sclMD.allow_quizzes,
          },
          // {
          //   text: 'Exams',
          //   type: 'mycourse',
          //   subType : 'myexams',
          //   icon: examsIcon,
          //   activeIcon: examsActiveIcon,
          //   active: false,
          //   url: '/mycourses?type=myexams',
          //   enableIcon : this.sclMD.allow_exams,
          // },
          // {
          //   text: 'Practices',
          //   type: 'mycourse',
          //   subType : 'mypractices',
          //   icon: navPracticesIcon,
          //   activeIcon: navPracticesActiveIcon,
          //   active: false,
          //   url: '/mycourses?type=mypractices',
          //   enableIcon : this.sclMD.allow_practices,
          // },
          // {
          //   text: 'Learning Paths',
          //   type: 'mycourse',
          //   subType : 'mylearningpaths',
          //   icon: learningPathIcon,
          //   activeIcon: learningPathActiveIcon,
          //   active: false,
          //   url: '/mycourses?type=mylearningpaths',
          //   enableIcon : this.sclMD.allow_learning_paths,
          // },
          // {
          //   text: 'Hackathons',
          //   type: 'mycourse',
          //   subType : 'myhackathons',
          //   icon: HackathonIcon,
          //   activeIcon: HackathonActiveIcon,
          //   active: false,
          //   url: '/mycourses?type=myhackathons',
          //   enableIcon : this.sclMD.allow_hackathons,
          // },
          // {
          //   text: 'Assessments',
          //   type: 'mycourse',
          //   subType : 'myassessments',
          //   icon: navAssessmentIcon,
          //   activeIcon: navAssessmentActiveIcon,
          //   active: false,
          //   url: '/mycourses?type=myassessments',
          //   enableIcon : this.sclMD.allow_assessments,
          // },
          // {
          //   text: 'Labs',
          //   type: 'mycourse',
          //   subType : 'mylabs',
          //   icon: labsIcon,
          //   activeIcon: labsActiveIcon,
          //   active: false,
          //   url: '/mycourses?type=mylabs',
          //   enableIcon : this.sclMD.allow_lab_courses,
          // },
          // {
          //   text: 'Challenges',
          //   type: 'mycourse',
          //   subType : 'mychallenges',
          //   icon: challengeIcon,
          //   activeIcon: challengeActiveIcon,
          //   active: false,
          //   url: '/mycourses?type=mychallenges',
          //   enableIcon : this.sclMD.allow_challenges,
          // },
          // {
          //   text: 'Company Specific Test',
          //   type: 'mycourse',
          //   subType : 'mytests',
          //   icon: companySpecificTest,
          //   activeIcon: companySpecificTestActive,
          //   active: false,
          //   url: '/mycourses?type=mytests',
          //   enableIcon : this.sclMD.allow_company_specific_tests,
          // },
          // {
          //   text: 'Go to NERD',
          //   type: 'neoPAT',
          //   subType : 'neoPAT',
          //   icon: dashboardImg,
          //   activeIcon: dashboardFilledImg,
          //   active: false,
          //   url: 'neoPAT',
          //   enableIcon : this.sclMD.allow_neoPAT,
          // }
        ];
        this.popupList = [
        ];
        this.afterIconList =this.navList.filter(obj => obj.enableIcon).length;
      }
      this.navList.forEach((each)=>{
        if(each.enableIcon) {
          this.showList.push(each);
        }
      })
      if (this.path.includes('mycourses')) {
        const urlPath = this.queryParameter || 'mycourses';
        this.changeActiveState(`/mycourses?type=${urlPath}`);
      } else {
        this.changeActiveState(this.path);
      }
      if (
        this.localSchoolData?.allow_engagement &&
        this.userData.enable_features?.allow_engagement
      ) {
        this.chatService.setUserData();
        await this.chatService.getPubNub().then((success: any) => {
          if (!this.path.includes('chat') && success) {
            this.chatService.getSubscriptionList();
          }
        });
      }
      this.adjustScreen();
    }
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (ev.url.includes('mycourses')) {
          const urlPath = this.queryParameter || 'mycourses';
          this.changeActiveState(`/mycourses?type=${urlPath}`);
        } else if (ev.url === '/profile') {
          this.changeActiveState(ev.url);
        } else if (ev.url.includes('/mydrivedetails')) {
          this.changeActiveState(`/drives`);
        } else if (ev.url.includes('/mycdetails')) {
          this.changeActiveState(`/mycontest`);
        }else if (ev.url.includes('/leaderboard')) {
          this.changeActiveState(`/leaderboard`);
        }
      }
    });

  }
  async ngOnChanges() {
    this.navHeight = this.sidebar.nativeElement.offsetHeight;
    this.adjustScreen();
    this.cartClicked=this.cart.isTrusted;
  }

  adjustScreen() {
    let allList = this.showList.concat(this.popupList);
    if(this.navHeight<640) {
      let remainingList = 6-Math.round((690-this.navHeight)/50);
      if(remainingList<2) {
        remainingList = 2;
      }
      this.popupList = [];
      this.popupList = allList.splice(remainingList,allList.length);
      this.showList = allList;
    }
    if (!this.mobile && this.navHeight>=640) {
      this.popupList = allList.splice(6,allList.length);
      this.showList = allList;
    } else if (this.popupList.length>0 && this.mobile) {
      this.showList = this.showList.concat(this.popupList);
      this.popupList = [];
    }
    if(!this.mobile) {
      this.toggleBtn = false;
    }
    this.updateNavPopup();
  }

  isLoggedIn() {
    const lcstorage = JSON.parse(this.loginService.loginStatus());
    if (lcstorage) {
      return true;
    }
    return false;
  }

  navigateToURL(item: showListProps, subType) {
    this.cartClicked=false;
    const { url, type } = item;
    if (type === 'mycourse') {
      this.router.navigateByUrl(url);
        this.changeActiveState(url);
    } else if (url === '/ide') {
      window.open(window.location.protocol + url, '_blank');
    } else if (url === 'neoPAT') {
      this.neoPATRedirection();
    } else {
      this.router.navigateByUrl(url);
        this.changeActiveState(url);
    }
    this.sideBarHidden.emit(false);
    if(this.moreListHide){
      this.moreListHandle.emit(this.moreListHide);
    }
  }

  updateNavPopup() {
    if (this.popupList.length > 0) {
      this.popupList.forEach((item, i) => {
        if(item.active === true) {
          const removedPopItem = this.popupList.splice(i, 1)
          const removedNavItem = this.showList.pop();
          this.showList.push(removedPopItem[0]);
          this.popupList.unshift(removedNavItem);
        }
      });
    }
  }
  changeActiveState(url: string) {
    this.showList.forEach((showListItem: showListProps) => {
      if(url === showListItem.url) {
        showListItem.active = true;
      } else if(url==='/mydrivedetails' && showListItem.url==='/drives') {
          showListItem.active = true;
      } else if(url==='/mycdetails' && showListItem.url==='/mycontest') {
        showListItem.active = true;
      } else {
        showListItem.active = false;
      }
    });

    if (this.popupList.length > 0) {
      this.popupList.forEach((popListItem: showListProps) => {
        if(url === popListItem.url) {
          popListItem.active = true;
        } else {
          popListItem.active = false;
        }
      });
    }
    this.updateNavPopup();
  }
  neoPATRedirection() {
    this.loader = true;
    const { user_id, school_id, email } = this.userData;
    const payload: any = {
      user_id,
      school_id,
      school_code: this.localSchoolData.school_code,
      email,
    };
    this.globalService.getCaptchaToken('qrcodefileupload').then(
      async (token: string) => {
        const CryptoJS = await this.testService.getCryptoJS();
        const encryptPayload: any = {
          a: token,
          b: CryptoJS.AES.encrypt(JSON.stringify(payload), 'up4dywqpkBd7ykuw?kr*Ru>*+B5!s@&kKUZVTu').toString()
        };
        this.globalService.neoPATRedirection(encryptPayload).subscribe((res: any) => {
          if (res && res.success) {
            try {
              const bytes = CryptoJS.AES.decrypt(res.data, 'VAN@?Ydm<E*FrBv^9t~B*2r>+zY9eTGZ8uN$xSw2');
              const encryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
              if (encryptedData && encryptedData.tokenid) {
                this.loader = false;
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = `https://neopat.examly.io/loginredirect?email=${this.userData.email}&tokenid=${encryptedData.tokenid}`;
                link.target = '_blank';
                link.click();
                this.path = window.location.pathname;
                if (this.path.includes('mycourses')) {
                  const urlPath = this.queryParameter || 'mycourses';
                  this.changeActiveState(`/mycourses?type=${urlPath}`);
                } else {
                  this.changeActiveState(this.path);
                }
                document.body.removeChild(link);
              } else {
                this.normalNeoPATRedirect();
              }
            } catch (error) {
              this.normalNeoPATRedirect();
            }
          } else {
            this.normalNeoPATRedirect();
          }
        }, (_failed: any) => {
          this.normalNeoPATRedirect();
        },
        );
      });
  }

  normalNeoPATRedirect() {
    this.loader = false;
    this.globalService.commonGrowl.next({
      severity: 'error',
      summary: 'Attention!',
      detail: 'You are not enrolled.',
    });
    this.path = window.location.pathname;
    if (this.path.includes('mycourses')) {
      const urlPath = this.queryParameter || 'mycourses';
      this.changeActiveState(`/mycourses?type=${urlPath}`);
    } else {
      this.changeActiveState(this.path);
    }
  }

  handleToggleBtn() {
    this.sideBarHidden.emit(false);
  }

  handlePopup(){
    this.moreListHide=!this.moreListHide;
    this.moreListHandle.emit(this.moreListHide);
  }
}
