import { Component, Input, ViewEncapsulation, AfterViewInit, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from '@services/global.service';
import { closeIcon, showEyeIcon, hideEyeIcon } from 'src/assets/images';

@Component({
    selector: 'email',
    templateUrl: './email.html',
    styleUrls: ['./../profile-form-elements.less'],
    encapsulation: ViewEncapsulation.None,
})
export class EmailComponent implements AfterViewInit, OnChanges {
    @Input() field: any = {};
    @Input() form: FormGroup;
    @Output() backTo = new EventEmitter();
    @Output() Forgot = new EventEmitter();
    path: string = "";
    closeIcon = closeIcon;
    showEyeIcon = showEyeIcon;
    hideEyeIcon = hideEyeIcon;

    get isValid() {
        return this.form.controls[this.field.name].valid;
    }
    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }

    ngAfterViewInit() {
        this.setVal(this.field.value);
    }

    constructor(private router: Router, public globalService: GlobalService) {
        this.path = window.location.pathname;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            setTimeout(() => {
                this.setVal(this.field.value);
            }, 500);
        }
    }

    setVal(o) {
        this.form.controls[this.field.name].setValue(o);
    }

    changeValue() {
        if (this.field.customError) {
            this.field.customError.error = false;
            this.field.customError.message = '';
        }
    }

    changeuppercase(event, field, name){
        if((field.type === 'shortanswer' || field.type === 'longanswer' || field.type === 'text') && field.fieldcase_type){
            if(field.fieldcase_type === 'capitalcase'){
                this.form.get(name).setValue(this.form.get(name).value.toUpperCase());
            }
            if(field.fieldcase_type === 'smallcase'){
                this.form.get(name).setValue(this.form.get(name).value.toLowerCase());
            }
            if(field.fieldcase_type === 'titlecase'){
                this.form.get(name).setValue(this.form.get(name).value.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' '));
            }
        }
    }

    checkError() {
        if (
            this.form.get(this.field.name) &&
            this.form.get(this.field.name).touched &&
           ( this.form.get(this.field.name).hasError('required')||this.field.mandatory)
        ) {
            return true;
        } else if (
            this.form.get(this.field.name) &&
            this.form.get(this.field.name).touched &&
            this.form.get(this.field.name).hasError(this.field.error)
        ) {
            return true;
        } else if (this.field.customError && this.field.customError.error) {
            return true;
        } else return false;
    }

    navigate() {
        this.router.navigateByUrl(this.field.description.url);
    }

    forgot(){
        this.Forgot.emit();
    }

    backto(event) {
        this.backTo.emit(event);
    }

    iconAction() {
        this.field.icon.icon = this.field.type === 'password' ? 'icon-unlock' : 'icon-ios-locked';
        this.field.type = this.field.type === 'password' ? 'text' : 'password';
    }

    showAction() {
        this.field.type = this.field.type === 'password' ? 'text' : 'password';
    }
}