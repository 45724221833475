import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { LoginService } from 'src/app/services/login.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { filterClose } from 'src/assets/images';
@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.less'],
    animations: [
        trigger('dialog', [
            transition('void => *', [style({ transform: 'scale3d(.3, .3, .3)' }), animate(100)]),
            transition('* => void', [animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))]),
        ]),
    ],
})
export class DialogComponent implements OnInit {
    @Input() closable : boolean;
    @Input() visible: boolean;
    @Input() header;
    @Input() subHeader:any;
    @Input() src;
    @Input() value: any;
    @Input() chipsprop: any;
    @Input() checkselect: boolean;
    @Input() contentData: any;
    @Input() videoCourse: any;
    @Input() headRequired: any = true;
    @Input() addbtn: any;
    @Input() detailsbtn: any;
    @Input() sArray: any;
    @Input() pArray: any;
    @Input() cArray: any;
    @Input() iArray: any;
    @Input() sortdefaultArray: any;
    @Input() catdefaultArray: any;
    @Input() progdefaultArray: any;
    @Output() applychipsvalue = new EventEmitter<any>();
    @Output() removechipval = new EventEmitter<any>();
    @Output() removechip: EventEmitter<any> = new EventEmitter<any>();
    @Input() insdefaultArray: any;
    @Input() testForm: any = {};
    @Input() ngStyleClass: any;
    @Output() apply = new EventEmitter<any>();
    @Output() dChange = new EventEmitter<any>();
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() sendKey = new EventEmitter<any>();
    @Output() submitDialog = new EventEmitter<any>();
    @Input() headerStyle: any;
    @Input() position: any;
    @Input() line: any;
    cancelNow = { multipleBtn: false, btnName: 'Clear', id: 'dcancel', type: 'dialog' };
    applyNow = { multipleBtn: false, btnName: 'Apply', id: 'dapply', type: 'dialog' };
    schoolData: any;
    dyn: any = {};
    sharedEmails: any;
    clientWidth: any;
    filterClose=filterClose;
    confiormLogOff: boolean;
    @HostListener('window:resize', ['$event'])
    getScreenSize(_$event) {
        this.clientWidth = window.innerWidth;
    }
    constructor(
        public loginservice: LoginService,
        public cartservice: CartService,
        public router: Router,
        public globalservice: GlobalService,
    ) {
        
        this.schoolData = JSON.parse(localStorage.getItem('school_details'));
    }

    ngOnInit() {
        if(this.closable === false) {
            this.closable = false;
        } else {
            this.closable = true;
        }
        this.clientWidth = window.innerWidth;
        if (this.videoCourse && this.videoCourse.added) {
            this.addbtn.btnName = 'Added';
        }
    }    
    close() {
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }

    removeOpt(value) {
        const temp = this.value.find((e) => {
            return e.value === value;
        });
        temp.check = false;
        this.removechip.emit(value);
    }

    applyChips() {
        this.value = this.value.filter((e) => {
            return e.check;
        });

        this.applychipsvalue.emit(this.value);
    }

    addTocart(_event, type) {
        if (this.videoCourse && this.videoCourse.id) {
            if (type === 'cart' && !this.videoCourse.added) {
                const payload = {
                    id: this.videoCourse.id,
                    school_code: this.schoolData.school_code,
                    googleToken: '',
                };
                this.globalservice.getCaptchaToken('addtoCart').then((token: string) => {
                    payload.googleToken = token;
                    this.cartservice.checkProductValidity(payload).subscribe((resp: any) => {
                        if (resp && resp.success) {
                            if (resp.message === 'InValid') {
                                this.videoCourse.productvalidity = 'InValid';
                            }
                        }
                        if (resp && resp.message === 'Valid') {
                            this.videoCourse.productvalidity = 'Valid';
                        } else {
                            this.videoCourse.productvalidity = 'InValid';
                        }
                        if (resp && resp.message === 'Valid') {
                            this.videoCourse.productvalidity = 'Valid';
                        } else {
                            this.videoCourse.productvalidity = 'InValid';
                        }
                        const localPayload = {
                            id: this.videoCourse.id,
                            list: this.videoCourse,
                        };
                        this.videoCourse.added = true;
                        this.addbtn.btnName = 'Added';
                        let cartIds: any = JSON.parse(localStorage.getItem('cart'));
                        if (cartIds && cartIds.length) {
                            cartIds = cartIds.map((m) => m.id);
                        } else {
                            cartIds = [];
                        }
                        if (
                            localStorage.getItem('token') &&
                            localPayload.list &&
                            localPayload.list.id &&
                            !cartIds.includes(localPayload.list.id)
                        ) {
                            this.cartservice.addCartItemToDB({ id: localPayload.list.id }).subscribe();
                        }
                        this.cartservice.storeLocal(localPayload);
                        this.cartservice.updateCartlength();
                    });
                });
            } else if (type === 'details') {
                this.router.navigate(['course/product'], { queryParams: { id: this.videoCourse.id } });
            }
        }
    }

    getFilter(event, type) {
        this.dChange.emit({ data: event, type: type });
    }
    applyDialog(_event, value) {
        if (value) {
            this.apply.emit(true);
        } else {
            this.apply.emit(false);
        }
        this.close();
    }
    emitKey(event) {
        this.sendKey.emit(event);
    }
    emitSubmit(event) {
        this.submitDialog.emit(event);
    }
    emitEmails() {
        this.submitDialog.emit(this.sharedEmails);
    }
    confiormLogedOff(){
        this.globalservice.confirmFlagLogOff.next(true); 
    }
}
