import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-field-builder',
    templateUrl: './field-builder.html',
    styleUrls: ['./field-builder.less'],
})
export class FieldBuilderComponent implements OnInit{
    @Input() field: any;
    @Input() form: any;
    @Input() layout: any;
    @Input() origin: any;
    @Input() ngStyleClass: any;
    @Input() padding: any;
    @Input() errorSignup;
    @Input() floatLabel;
    @Input() widthTextArea;
    @Input() hideLabel;
    @Input() p10;
    @Input() p1;
    @Input() time;
    @Input() max380;
    @Input() max434;
    @Input() otpError;
    @Output() setgender = new EventEmitter();
    @Output() enableother = new EventEmitter();
    @Output() setvalue = new EventEmitter();
    @Output() termschecked = new EventEmitter<any>();
    @Output() radioResponse = new EventEmitter<any>();
    @Output() setresdrive = new EventEmitter<any>();
    @Output() backTo = new EventEmitter();
    @Output() inputField = new EventEmitter();
    @Output() Forgot = new EventEmitter();
    @Output() infoFileFormat = new EventEmitter<any>();
    @Output() OfferUpload = new EventEmitter<any>();
    isCart: boolean = window.location.pathname === '/cart';
    path: any = window.location.pathname;
    radioType: any;
    autocopy: any;
    get isValid() {
        return this.form.controls[this.field.name].valid;
    }
    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }

    ngOnInit() {
        if (this.path && this.path.includes('profile')) {
            this.radioType = true;
        } else {
            this.radioType = false;
        }
    }

    selectedvalue(event) {
        this.setvalue.emit(event);
    }

    termsChecked(event) {
        this.termschecked.emit(event);
    }

    setGender(event) {
        this.setgender.emit(event);
    }

    enableOther(event) {
        this.enableother.emit(event);
    }

    backto(event) {
        this.backTo.emit(event)
    }

    forgot() {
        this.Forgot.emit();
    }

    setResDrive(event) {
        this.setresdrive.emit(event);
    }

    fileFormatInfos(event) {
        this.infoFileFormat.emit(event);
    }

    offerUpload(event) {
        this.OfferUpload.emit(event);
    }

    radio(event){
        this.radioResponse.emit(event);
    }
    inputValue(event){
        this.inputField.emit(event);
    }
}
