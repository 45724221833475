import { environment } from '../../environments/environment';

let assets_bucket;
if (environment.production) {
  // assets_bucket = 'smartica-users-asset';
  assets_bucket = 'images-smartica-co-in.s3.ap-south-1';
} else {
  // assets_bucket = 'smartica-users-asset'; 
  assets_bucket = 'images-smartica-co-in.s3.ap-south-1';
}

const prefix = `https://${assets_bucket}.amazonaws.com/images` 
// const prefix = `https://${assets_bucket}.s3.amazonaws.com/assets/images` 
const COMMON = '/assets/images/common'
const CERTIFICATEIMAGES = `${prefix}/certificateImages`;
const CHATPREFIX=`${prefix}/chat`;
const MARKETDETAIL=`${prefix}/marketDetail`
const DASHBOARD=`${prefix}/Dashboard`
const COURSELISTPREFIX = '/assets/images/courseList'
const DRIVELISTPREFIX = '/assets/images/driveList'
const CONTESTLISTPREFIX = '/assets/images/contestList'
const SIDEBARPREFIX = '/assets/images/sidebar'
const CONTESTDETAILPREFIX = '/assets/images/contestDetail'
const COURSETESTPREFIX='/assets/images/courseTest'
const AUTHDIR = `${prefix}/auth`;
const COURSELISTDIR = `${prefix}/courseList`;
const COURSEDETAILDIR = `${prefix}/courseDetail`;
const COURSETESTDIR = `${prefix}/courseTest`;
const MARKETPLACE = `${prefix}/marketPlace`;
const CHAT = `${prefix}/engagementPage`;
const COMMONICONS = `${prefix}/icons`;
const FIRSTPROFILE = `${prefix}/firstProfile`;
const SIDEBARDIR = `${prefix}/sidebar`;
const DRIVEDETAILDIR = `${prefix}/driveDetails`;
const CONTESTLISTDIR = `${prefix}/contestList`;
const CONTESTDIR = `${prefix}/contest`;
const COUNTRYFLAG = `${prefix}/countryFlags`;
const avatarLarge = `${AUTHDIR}/avatar_large.jpeg`;
const loginBg = `${AUTHDIR}/login_bg.svg`;
const notifyIcon = `${AUTHDIR}/notify.svg`;
const showEyeIcon = `${COMMONICONS}/show_eye.svg`;
const hideEyeIcon = `${COMMONICONS}/hide_eye.svg`;
const waveBg = `${AUTHDIR}/wave.svg`;
const successIcon = `${prefix}/icons/success-cc.png`;
const failureIcon = `${AUTHDIR}/failure.svg`;
const loginPageBg = `${AUTHDIR}/page_bg.jpg`;
const loginFormBg = `${AUTHDIR}/form_bg.svg`;
const notAttend = `${CONTESTLISTPREFIX}/notAttend.svg`;
const selectedContest = `${CONTESTLISTPREFIX}/selectedContest.svg`;
const notSelectedContest = `${CONTESTLISTPREFIX}/notSelectedContest.svg`;
const noOfContest = `${CONTESTLISTPREFIX}/noOfContest.svg`;
const book = `${CONTESTLISTDIR}/book.svg`;
const idea = `${CONTESTLISTDIR}/idea.svg`;
const ends_On = `${CONTESTLISTPREFIX}/ends_On.svg`;
const prize = `${CONTESTDETAILPREFIX}/prize.svg`;
const users = `${CONTESTLISTPREFIX}/users.svg`;
const courseIcon_1 = `${COURSELISTDIR}/course_icon_1.svg`;
const courseIcon_2 = `${COURSELISTDIR}/course_icon_2.svg`;
const courseIcon_3 = `${COURSELISTDIR}/course_icon_3.svg`;
const userImg = `${COURSELISTDIR}/completed.svg`;
const arrowDown = `${COMMONICONS}/arrow_down.svg`;
const arrowUp = `${COMMONICONS}/arrow_up.svg`;
const assessmentIcon = `${COURSELISTDIR}/assessment.svg`;
const practiceTestIcon = `${COURSELISTDIR}/practice_test.svg`;
const practiceTestIcons = `${MARKETDETAIL}/marketAssessment.svg`;
const validityIconMobiles = `${MARKETDETAIL}/validity_icon_Mobile.svg`;
const validityIcons = `${MARKETDETAIL}/validity_icon.svg`;
const practiceTestIconMobile = `${MARKETDETAIL}/validity_icon.svg`;
const timerIcon = `${COURSELISTDIR}/timer_icon.svg`;
const validityIcon = `${COURSELISTDIR}/validity_icon.svg`;
const resetIcon = `${COURSELISTDIR}/reset.svg`;
const tickIcon = `${COURSELISTDIR}/tick.svg`;
const bestSellerTag = `${COURSELISTDIR}/best_seller_tag.svg`;
const cardNoteBook = `${COURSELISTDIR}/card-icon-1.svg`;
const cardWriting = `${COURSELISTDIR}/card-icon-2.svg`;
const cardPrompt = `${COURSELISTDIR}/card-icon-3.svg`;
const descIcon = `${COURSELISTDIR}/desc-icon.png`;
const superBadge = `${COMMONICONS}/superBadges.svg`;
const badge = `${COMMONICONS}/badges.svg`;
const courseDate = `${COURSELISTDIR}/course-date.svg`;
const filterIcon = `${COMMONICONS}/filter-icon.svg`;
const filterDefault = `${COURSELISTDIR}/default-filter.svg`;
const leftArrow = `${COMMONICONS}/left_arrow.svg`;
const rightArrow = `${COMMONICONS}/right_arrow.svg`;
const descIconActive = `${COURSELISTDIR}/desc-icon-active.svg`;
const filterClose = `${COMMONICONS}/close.svg`;
const driveTimer = `${COURSELISTDIR}/drive-timer.svg`;
const durationIcon = `${DRIVELISTPREFIX}/duration-icon.svg`;
const driveLocation = `${COURSELISTDIR}/drive-location.svg`;
const driveMoney = `${DRIVELISTPREFIX}/money.svg`;
const drivePattern = `${COURSELISTDIR}/drive-pattern.png`;
const eligible = `${DRIVELISTPREFIX}/eligible.svg`;
const whiteDownArrow = `${COURSELISTDIR}/white_down_arrow.svg`;
const selected = `${DRIVELISTPREFIX}/selected.svg`;
const notAttended = `${DRIVELISTPREFIX}/not-attended.svg`;
const notSelected = `${DRIVELISTPREFIX}/not-selected.svg`;
const consumedBadgeIcon = `${COMMONICONS}/badges.svg`;
const consumedSuperBadgeIcon = `${COMMONICONS}/superbadges.svg`;
const courseEnrolled = `${COURSELISTPREFIX}/courseEnrolled.svg`;
const courseCompleted = `${COURSELISTPREFIX}/courseCompleted.svg`;
const superBadges = `${COMMONICONS}/superBadges.svg`;
const badges = `${COMMONICONS}/badges.svg`;
const consumedBg = `${COMMON}/consumed-bg.svg`;
const star = `${COMMONICONS}/star.svg`;
const starFilled = `${COMMONICONS}/star_filled.svg`;
const campus = `${COMMONICONS}/campus.svg`;
const batch = `${COMMONICONS}/batch.svg`;
const department = `${COMMONICONS}/department.svg`;
const degree = `${COMMONICONS}/degree.svg`;
const retakeImg = `${COURSEDETAILDIR}/retake.svg`;
const sectionLockImg = `${COURSEDETAILDIR}/section_lock.png`;
const proctorImg = `${COURSEDETAILDIR}/proctor.png`;
const assessmentImg = `${COURSEDETAILDIR}/assessment.png`;
const projectImg = `${COURSEDETAILDIR}/project.png`;
const moduleCompImg = `${COURSEDETAILDIR}/module_completed.png`;
const testCompImg = `${COURSEDETAILDIR}/test_completed.png`;
const moduleNotCompletedImg = `${COURSEDETAILDIR}/module_not_completed.svg`;
const completedImg = `${COURSEDETAILDIR}/completed.svg`;
const notCompletedImg = `${COURSEDETAILDIR}/not_completed.svg`;
const inprogress = `${COURSEDETAILDIR}/inprogress.svg`;
const arrowDownImg = `${COMMONICONS}/arrow_down.svg`;
const collapseIcon = `${COURSEDETAILDIR}/collapse.svg`;
const expandIcon = `${COURSEDETAILDIR}/expand.svg`;
const rectangleIcon = `${COURSEDETAILDIR}/rectangle.svg`;
const contentAudio = `${COURSEDETAILDIR}/content_audio.svg`;
const contentExcel = `${COURSEDETAILDIR}/content_excel.svg`;
const contentDownload = `${COURSEDETAILDIR}/content_download.svg`;
const timeSpent = `${COURSEDETAILDIR}/time_spent.png`;
const viewsIcon = `${COURSEDETAILDIR}/views_icon.svg`;
const expandImg = `${COURSEDETAILDIR}/expand_icon.svg`;
const projectArrow = `${COURSEDETAILDIR}/project_arrow.svg`;
const projectTeam1 = `${COURSEDETAILDIR}/project_team1.svg`;
const projectTeam2 = `${COURSEDETAILDIR}/project_team2.svg`;
const projectTeam3 = `${COURSEDETAILDIR}/project_team3.svg`;
const attemptTimeSpentIcon = `${COURSEDETAILDIR}/attempt_time_spent.svg`;
const sidebarOpen = `${COURSEDETAILDIR}/sidebar_open.svg`;
const sidebarClose = `${COURSEDETAILDIR}/sidebar_close.svg`;
const excelDownload = `${COURSEDETAILDIR}/excel_content.svg`;
const playImg = `${COURSEDETAILDIR}/play.svg`;
const pauseImg = `${COURSEDETAILDIR}/pause.svg`;
const membersImg = `${COURSEDETAILDIR}/members.svg`;
const teamDetailImg = `${COURSEDETAILDIR}/team_detail.svg`;
const githubImg = `${COURSEDETAILDIR}/github.svg`;
const unmuteImg = `${COURSEDETAILDIR}/unmute.svg`;
const muteImg = `${COURSEDETAILDIR}/mute.svg`;
const lineLeft = `${COURSEDETAILDIR}/line_left.svg`;
const lineRight = `${COURSEDETAILDIR}/line_right.svg`;
const videoType = `${COURSEDETAILDIR}/video_type.svg`;
const splitters = `${COURSEDETAILDIR}/splitter.svg`;
const faqIcon = `${COURSEDETAILDIR}/faq.svg`;
const reloadIcon = `${COURSEDETAILDIR}/reload.svg`;
const pauseFilled = `${COURSEDETAILDIR}/pause_filled.svg`;
const menuDots = `${COURSEDETAILDIR}/menu_dots.svg`;
const maximize = `${COURSEDETAILDIR}/maximize.svg`;
const minimize = `${COURSEDETAILDIR}/minimize.svg`;
const certificateImg = `${SIDEBARPREFIX}/certificate.svg`;
const certificateFilledImg = `${SIDEBARPREFIX}/certificate_filled.svg`;
const paperPen = `${COURSEDETAILDIR}/paper_pen.svg`;
const titleBanner = `${COURSEDETAILDIR}/title_banner.png`;
const contestImg = `${SIDEBARPREFIX}/contest.svg`;
const contestFilledImg = `${SIDEBARPREFIX}/contest_filled.svg`;
const openIde = `${SIDEBARPREFIX}/open_ide.svg`;
const openIdeFilled = `${SIDEBARPREFIX}/open_ide_filled.svg`;
const leaderboardImg = `${SIDEBARPREFIX}/leaderboard.svg`;
const leaderboardFilledImg = `${SIDEBARPREFIX}/leaderboard_filled.svg`;
const dashboardImg = `${SIDEBARPREFIX}/dashboard.svg`;
const dashboardFilledImg = `${SIDEBARPREFIX}/dashboard_filled.svg`;
const courseImg = `${SIDEBARPREFIX}/course.svg`;
const courseFilledImg = `${SIDEBARPREFIX}/course_filled.svg`;
const marketplace = `${SIDEBARPREFIX}/marketplace.svg`;
const marketplaceFilled = `${SIDEBARPREFIX}/marketplace_filled.svg`;
const engagementImg = `${SIDEBARPREFIX}/engagement.svg`;
const engagementFilledImg = `${SIDEBARPREFIX}/engagement_filled.svg`;
const headerbg = `${SIDEBARPREFIX}/headerbg.svg`;
const whiteThreeDotImg = `${SIDEBARPREFIX}/white-three-dot.svg`;
const drivesImg = `${SIDEBARPREFIX}/drives.svg`;
const drivesFilledImg = `${SIDEBARPREFIX}/drives_filled.svg`;
const project = `${SIDEBARPREFIX}/project.svg`;
const projectFilledImg = `${SIDEBARPREFIX}/project_filled.svg`;
const splitterIcon = `${COMMONICONS}/splitter_icon.svg`;
const splitterBarIcon = `${COURSETESTDIR}/splitter_bar_icon.svg`;
const arrowDownIcon = `${COMMONICONS}/arrow_down.svg`;
const clockIcon = `${COURSETESTDIR}/clock.svg`;
const nextIcon = `${COURSETESTPREFIX}/next.svg`;
const prevIcon = `${COURSETESTPREFIX}/prev.svg`;
const bookmarkIcon = `${COURSETESTPREFIX}/bookmark.svg`;
const reportErrorIcon = `${prefix}/icons/error-cc.png`;
const closeIcon = `${COMMONICONS}/close.svg`;
const removeIcon = `${COMMONICONS}/remove.svg`;
const proctoringIcon = `${COURSETESTPREFIX}/proctoring.svg`;
const bookmarkedIcon = `${COURSETESTPREFIX}/bookmarked.svg`;
const bookmarkFilledIcon = `${COURSETESTPREFIX}/bookmark_filled.svg`;
const lockedIcon = `${COURSETESTDIR}/locked.svg`;
const deleteIcon = `${COURSETESTDIR}/delete.svg`;
const startIcon = `${COURSETESTDIR}/start.svg`;
const warningIcon = `${COURSETESTDIR}/warning.svg`;
const chromeIcon = `${COMMONICONS}/chrome.svg`;
const firefoxIcon = `${COMMONICONS}/firefox.svg`;
const answerLockedImg = `${COURSETESTDIR}/answer_locked.svg`;
const threeDotsIcon = `${COURSETESTDIR}/three_dots.svg`;
const clearIcon = `${COURSETESTDIR}/clear.svg`;
const addIcon = `${COURSETESTDIR}/add.svg`;
const questionIcon = `${COURSETESTDIR}/question-icon.svg`;
const imageIcon = `${COURSETESTDIR}/image-icon.svg`;
const infoIcon = `${COURSETESTDIR}/info-icon.svg`;
const deleteBlackIcon = `${COMMONICONS}/delete-black.svg`;
const eyeIcon = `${COURSETESTPREFIX}/eye.png`;
const whiteIcon = `${COURSETESTDIR}/white.svg`;
const darkIcon = `${COURSETESTPREFIX}/dark.svg`;
const testStart = `${COURSETESTDIR}/teststart.png`;
const internetLost = `${COURSETESTDIR}/internet-lost.svg`;
const fullScreenIcon = `${COURSETESTDIR}/full_screen.svg`;
const fAQIcon = `${COURSETESTDIR}/faq.svg`;
const addFileIcon = `${COURSETESTPREFIX}/add_file.svg`;
const compilerInsIcon = `${COURSETESTDIR}/compiler_instruction.svg`;
const instructionIcon = `${COURSETESTDIR}/instruction.svg`;
const animationDown = `${COURSETESTPREFIX}/animation_down.svg`;
const animationUp = `${COURSETESTPREFIX}/animation_up.svg`;
const sectionClose = `${COURSETESTDIR}/section_close.svg`;
const sectionOpen = `${COURSETESTDIR}/section_open.svg`;
const testPattern = `${COURSETESTDIR}/pattern.png`;
const VectorCal = `${COURSETESTDIR}/Vector_cal.svg`;
const successIcon2 = `${prefix}/icons/success-cc.png`;
const successIcon3 = `${prefix}/icons/success-cc.png`;
const VectorDel = `${COURSETESTDIR}/Vector_del.svg`;
const reaction1 = `${COURSETESTDIR}/reaction1.svg`;
const reaction2 = `${COURSETESTDIR}/reaction2.svg`;
const reaction3 = `${COURSETESTDIR}/reaction3.svg`;
const reaction4 = `${COURSETESTDIR}/reaction4.svg`;
const reaction5 = `${COURSETESTDIR}/reaction5.svg`;
const callAttendIcon = `${COURSETESTDIR}/call_attend.svg`;
const callDeclineIcon = `${COURSETESTDIR}/call_decline.svg`;
const videoCallIcon = `${COURSETESTDIR}/video_call.svg`;
const testStartIcon = `${COURSETESTDIR}/test_start.svg`;
const speedometerGIF = `${COURSETESTDIR}/speedometer.gif`;
const callPattern = `${COURSETESTDIR}/call_pattern.svg`;
const analysisNotFound = `${prefix}/resultAnalysis/analysisNotFound.svg`;
const analysisNotPublished = `${prefix}/resultAnalysis/analysisNotPublished.svg`;
const checkURL = `${prefix}/resultAnalysis/checkUrl.svg`;
const authFormBg = `${AUTHDIR}/form_bg.png`;
const hackathonLabel =`${CONTESTDIR}/hackathon.svg`;
const recuirementLabel = `${CONTESTDIR}/recruitment.svg`;
const preAssesmentLabel = `${CONTESTDIR}/pre_assesment.svg`;
const generalLabel = `${CONTESTDIR}/general.svg`;
const certificationLabel = `${CONTESTDIR}/certication.svg`;

const leadPoints = `${COMMONICONS}/leaderboard-points.svg`;
const firstMedal = `${COMMONICONS}/first-medal.svg`;
const secondMedal = `${COMMONICONS}/second-medal.svg`;
const thirdMedal = `${COMMONICONS}/third-medal.svg`;

const cartImg = "/assets/images/cart.svg";
const cartIcon = "/assets/images/cart-icon.svg";
const cartIcon2 = "/assets/images/cart-icon-2.svg";
const camera = `${COMMONICONS}/camera.png`;
const linkShare = `${MARKETPLACE}/link-share.svg`;
const fbShare = `${MARKETPLACE}/fb-share.svg`;
const linkedInShare = `${MARKETPLACE}/linkedin-share.svg`;
const whatsappShare = `${MARKETPLACE}/whatsapp-share.svg`;
const payIcon = `${MARKETPLACE}/pay.svg`;
const couponIcon = `${MARKETPLACE}/coupon.svg`;
const iconBg = `${MARKETPLACE}/icon-bg.svg`;
const editorTool = `${CHATPREFIX}/editor_tool.svg`;
const attachIcon = `${CHATPREFIX}/attach.svg`;
const sendIcon = `${CHATPREFIX}/send.svg`;
const chatbg = `${CHATPREFIX}/chatBg.png`;
const smiley = `${CHATPREFIX}/smiley.svg`;
const editor = `${CHATPREFIX}/editor.svg`;
const fileChat = `${CHATPREFIX}/file.svg`;
const download = `${CHATPREFIX}/download.svg`;
const downloadFile=`${COMMON}/download_Icon.svg`;
const video = `${CHATPREFIX}/video.svg`;
const editchat = `${CHATPREFIX}/editchat.svg`;
const deletechat = `${CHATPREFIX}/deletechat.svg`;
const tickmark = `${CHATPREFIX}/tickmark.svg`;
const closeEdit = `${CHATPREFIX}/closeEdit.svg`;
const downEdit = `${CHATPREFIX}/downEdit.svg`;
const uploadedIcon = `${CHAT}/uploaded.svg`;
const profType = `${DRIVEDETAILDIR}/proftype.svg`;
const jobType = `${DRIVEDETAILDIR}/jobtype.svg`;
const location = `${DRIVEDETAILDIR}/location.svg`;
const driveDate = `${DRIVEDETAILDIR}/date.svg`;
const hrRect = `${DRIVEDETAILDIR}/hrRect.svg`;
const success = `${prefix}/icons/success-cc.png`;
const error = `${prefix}/icons/error-cc.png`;
const optIn = `${DRIVEDETAILDIR}/optin.svg`;
const optOut = `${DRIVEDETAILDIR}/optout.svg`;
const driveResume = `${DRIVEDETAILDIR}/resume.svg`;
const driveUpload = `${DRIVEDETAILDIR}/upload.svg`;
const driveDownload = `${DRIVEDETAILDIR}/download.svg`;
const dialogClose = `${COMMONICONS}/close.svg`;
const threedot=`${CHATPREFIX}/three_dot.svg`
const accountSettingsImg = `${COMMONICONS}/account-settings.svg`;
const cartIcon2GreyFill = `${COMMONICONS}/cart-icon-2-greyfill.svg`;
const bellImg = `${COMMONICONS}/bell.svg`;
const searchIcon = `${COMMONICONS}/search_icon.svg`;
const aiLogo = `${COMMONICONS}/ai.png`;
const hamburger = `${COMMONICONS}/hamburger.svg`;
const neoLogo = `${COMMONICONS}/neo_logo.svg`;
const profileImg = `${COMMONICONS}/profile_pic.svg`;
const Rect = `${SIDEBARPREFIX}/Rect.svg`;
const ellipsisImg = `${COMMONICONS}/ellipsis.svg`;
const attendanceImg = `${COMMONICONS}/attendance.svg`;
const profileSideNav = `${COMMONICONS}/profile.svg`;
const settingsImg = `${COMMONICONS}/settings.svg`;
const backArrowImg = `${COMMONICONS}/back_arrow.svg`;
const noData = `${COMMONICONS}/no-data.svg`;
const inputHistoryImg = `${COMMONICONS}/icons_clock.svg`;
const inputDeleteImg = `${COMMONICONS}/input_delete.svg`;
const info = `${COMMONICONS}/info.svg`;
const attend = `${prefix}/attend.svg`;
const loginImg = `${COMMONICONS}/login.svg`;
const signupImg = `${COMMONICONS}/signup.svg`;
const resultAnalysis = `${COMMONICONS}/result-analysis.png`;
const ipAddress =`${COMMONICONS}/ip-address.png`; 
const bannerPattern =`${COMMONICONS}/banner-pattern.png`; 
const osUsed = `${COMMONICONS}/os-used.png`; 
const tabSwitch = `${COMMONICONS}/tab-switch.png`; 
const raTimer = `${COMMONICONS}/duration.png`;
const raDuration = `${COMMONICONS}/calender.png`;
const growlSuccessIcon = `${prefix}/icons/success-growl.svg`;
const growlFailureIcon = `${prefix}/icons/failure_growl.svg`;
const growlWarnIcon = `${COMMONICONS}/warn_growl.svg`;
const growlCloseIcon = `${COMMONICONS}/close.svg`;
const raLocation = `${COMMONICONS}/location.png`;
const raResumeCount = `${COMMONICONS}/resume_count.png`;
const defaultDrive =  `${COMMONICONS}/default_drive_icon.svg`;
const noDataAvailable =  `${COMMONICONS}/no_data.svg`;
const wrongAns = `${COMMONICONS}/wrong_ans.png`;
const correctAns = `${COMMONICONS}/correct_ans.png`;
const nextBlueIcon = `${COMMONICONS}/right_arrow.svg`;
const prevBlueIcon = `${COMMONICONS}/left_arrow.svg`;
const codeDownload =`${COMMONICONS}/code-download.svg`;
const calc = `${COURSETESTDIR}/calc.svg`;
const fileIcon = `${COURSETESTDIR}/file_icon.svg`;
const hintIcon = `${COURSETESTPREFIX}/hint_icon.svg`;
const arrowIcon = `${COURSETESTPREFIX}/arrow.svg`;
const uploadIcon = `${COMMONICONS}/upload_icon.svg`;
const uploadQRIcon = `${COURSETESTDIR}/upload_qr_icon.svg`;
const pauseTestIcon = `${COURSETESTDIR}/pauseTestIcon.png`;
const calculatorIcon = `${COURSETESTPREFIX}/calculator_icon.svg`;
const reportIcon = `${COURSETESTDIR}/report_error.png`;
const testClearIcon = `${COURSETESTDIR}/test-clear.svg`;
const sectionCompleted = `${COURSETESTDIR}/sec-completed.svg`;
const speedTest = `${COURSETESTDIR}/speed.svg`;
const copyIcon = `${COURSETESTPREFIX}/copy.svg`;
const alertIcon = `${COURSETESTPREFIX}/alert.svg`;
const calenderIcon = `${COURSETESTDIR}/calendar.svg`;
const environmentCheck = `${COURSETESTDIR}/environment_check.svg`;
const requirementCheck = `${COURSETESTDIR}/requirement_check.jpeg`;
const checkErrorIcon = `${prefix}/icons/error-cc.png`;
const date = `${FIRSTPROFILE}/date.svg`;
const info1 = `${FIRSTPROFILE}/info1.svg`;
const resumeImg = `${FIRSTPROFILE}/resume_image.svg`;
const addImg = `${FIRSTPROFILE}/add-img.svg`;
const fileUpload = `${FIRSTPROFILE}/fileUpload.svg`;
const initBack = `${FIRSTPROFILE}/init_back.svg`;
const down = `${COMMONICONS}/arrow_down.svg`;
const emptyProfile = `${FIRSTPROFILE}/empty_profile.svg`;
const maleProfile = `${FIRSTPROFILE}/male_profile.svg`;
const femaleProfile = `${FIRSTPROFILE}/female_profile.svg`;
const file = `${FIRSTPROFILE}/file.png`;
const eye = `${FIRSTPROFILE}/eye.png`;
const resDownload = `${FIRSTPROFILE}/resume_download.png`;
const x = `${FIRSTPROFILE}/x.svg`;
const contestDateIcon = `${CONTESTDETAILPREFIX}/date.svg`;
const contestPrizeIcon = `${CONTESTDETAILPREFIX}/prize.svg`;
const contestProfileIcon = `${CONTESTDETAILPREFIX}/profile.svg`;
const contestUserIcon = `${CONTESTDETAILPREFIX}/user.svg`;
const contestMoneyIcon = `${CONTESTDIR}/money.svg`;
const practiceIcon = `${COMMONICONS}/write.svg`;
const back = `${COMMONICONS}/back.svg`;
const nextActive = `${COURSETESTPREFIX}/next_active.svg`;
const prevActive = `${COMMONICONS}/prev_active.svg`;
const lineImg = `${COMMONICONS}/line_img.svg`;
const rankOne = `${COMMONICONS}/rank_one.svg`;
const rankTwo = `${COMMONICONS}/rank_two.svg`;
const rankThree = `${COMMONICONS}/rank_three.svg`;
const firstRankMedal = `${COMMONICONS}/first_rank_medal.svg`;
const secondRankMedal = `${COMMONICONS}/second-rank-medal.svg`;
const thirdRankMedal = `${COMMONICONS}/third_rank_medal.svg`;
const point = `${COMMONICONS}/point.svg`;
const leaderboardMenu = `${COMMONICONS}/leaderboard_menu.svg`;
const rankBook = `${COMMONICONS}/rank_book.svg`;
const examsIcon = `${COMMONICONS}/exams.svg`;
const examsActiveIcon = `${COMMONICONS}/exams-active.svg`;
const navPracticesIcon = `${COMMONICONS}/practices.svg`;
const navPracticesActiveIcon = `${COMMONICONS}/practices-active.svg`;
const learningPathIcon = `${COMMONICONS}/learning-path.svg`;
const learningPathActiveIcon = `${COMMONICONS}/learning-path-active.svg`;
const navAssessmentIcon = `${COMMONICONS}/assessment.svg`;
const navAssessmentActiveIcon = `${COMMONICONS}/assessment-active.svg`;
const labsIcon = `${COMMONICONS}/labs.svg`;
const labsActiveIcon = `${COMMONICONS}/labs-active.svg`;
const challengeIcon = `${COMMONICONS}/challenge.svg`;
const challengeActiveIcon = `${COMMONICONS}/challenge-active.svg`;
const companySpecificTest = `${COMMONICONS}/company-specific-test.svg`;
const companySpecificTestActive = `${COMMONICONS}/company-specific-test-active.svg`;
const quizzesIcon = `${COMMONICONS}/quizzes.svg`;
const quizzesActiveIcon = `${COMMONICONS}/quizzes-active.svg`;
const HackathonIcon = `${COMMONICONS}/hackathon.svg`;
const HackathonActiveIcon = `${COMMONICONS}/hackathon-active.svg`;
const backgroundMorning = `${COMMONICONS}/profile-bg-morn.svg`;
const backgroundAfternoon = `${COMMONICONS}/background-afternoon.png`;
const backgroundEvening = `${COMMONICONS}/background-evening.png`;
const backgroundNight = `${COMMONICONS}/background-night.png`;
const profileEdit = `${COMMONICONS}/profile-edit-icon.svg`;
const profileLock = `${COMMONICONS}/profile_lock.svg`;
const edit = `${COMMONICONS}/edit.svg`;
const backgroundMorningDesktop = `${COMMONICONS}/background-morning-desktop.svg`;
const backgroundNoonDesktop = `${COMMONICONS}/background-noon-desktop.png`;
const backgroundEveningDesktop = `${COMMONICONS}/background-evening-desktop.png`;
const backgroundNightDesktop = `${COMMONICONS}/background-night-desktop.png`;
const toastPatternIcon = `${COMMONICONS}/toast_pattern.svg`;
const startDate = `${COMMON}/start_Date.svg`;
const endDate = `${COMMON}/end_Date.svg`;
const tests = `${COMMON}/tests.svg`;
const assessment = `${COURSELISTPREFIX}/assessment.svg`;
const practiceTest = `${COURSELISTPREFIX}/practice_test.svg`;
const backBtn = `${COMMON}/back_Button.svg`;
const noInternetFound = `/assets/images/no_internet.svg`;
const notAvailable = `/assets/images/not_available.svg`;
const courseBanner = `/assets/images/courseBanner.png`;
const badgesBlue = `${COMMON}/badgesBlue.svg`;
const endDateRed = `${COMMON}/endDateRed.svg`;
const completed = `${COMMONICONS}/completed.svg`;
const enrolled = `${COMMONICONS}/enrolled.svg`;
const expired = `${COMMONICONS}/expired.svg`;
const inProgress = `${COMMONICONS}/inProgress.svg`;
const Eligible = `${DASHBOARD}/Eligible.svg`;
const Applied = `${DASHBOARD}/Applied.svg`;
const hand = `${DASHBOARD}/hand.svg`;
const placed = `${DASHBOARD}/placed.svg`;
const waiting = `${DASHBOARD}/waiting.svg`;
const rejected = `${DASHBOARD}/rejected.svg`;
const Location = `${DASHBOARD}/Location.svg`;
const startDateGreen = `${COMMON}/startDateGreen.svg`;
const testBlue = `${COMMON}/testBlue.svg`;
const cost = `${MARKETDETAIL}/cost.svg`;
const clock = `${MARKETDETAIL}/clock.svg`;
const validity = `${MARKETDETAIL}/validity.svg`;
const background = `${COMMON}/background.svg`;
const payment = `${MARKETDETAIL}/payment.svg`;
const line = `${MARKETDETAIL}/line.svg`;
const cart = `${MARKETDETAIL}/cart.svg`;
const bestseller = `${MARKETDETAIL}/bestseller.svg`;
const assessment_Test = `${MARKETDETAIL}/assessment_Test.svg`;
const certificate_icon_1 = `${CERTIFICATEIMAGES}/certificate_icon_1.svg`;
const certificate_icon_2 = `${COMMON}/certificate_icon_2.svg`;
const superBadgesOrange = `${COMMON}/superBadgesOrange.svg`;
const headerBanner = `${COURSETESTPREFIX}/header_banner.png`;

const rectangle = `${CERTIFICATEIMAGES}/rectangle.png`;
const Fileicon = `${CERTIFICATEIMAGES}/Fileicon.svg`;
const orange = `${CERTIFICATEIMAGES}/orange.svg`;
const bullets = `${COMMONICONS}/bullet-point.svg`;

const coeTimer = `/assets/images/common/coe-timer.svg`;
const waitingApproval = `/assets/images/common/waiting-for-approval.svg`;
const coeTik = `/assets/images/common/coe-approve-tik.svg`;
const backgroundMarket = `${MARKETDETAIL}/backgroundMarket.svg`;
const testMarket = `${MARKETDETAIL}/testMarket.svg`;
const testMarketMobile = `${MARKETDETAIL}/testMarketMobile.svg`;
const timers = `${MARKETDETAIL}/timers.svg`;
const timersMobile = `${MARKETDETAIL}/timersMobile.svg`;
const marketAssessment = `${MARKETDETAIL}/marketAssessment.svg`;
const marketAssessmentMobile = `${MARKETDETAIL}/marketAssessmentMobile.svg`;
const reload = `/assets/images/common/reload.svg`;
export {
  backgroundMorningDesktop,
  backgroundNoonDesktop,
  backgroundEveningDesktop,
  backgroundNightDesktop,
  profileEdit,
  backgroundNight,
  backgroundEvening,
  backgroundAfternoon,
  backgroundMorning,
  HackathonActiveIcon,
  HackathonIcon,
  quizzesIcon,
  quizzesActiveIcon,
  companySpecificTestActive,
  companySpecificTest,
  challengeActiveIcon,
  challengeIcon,
  labsActiveIcon,
  labsIcon,
  navAssessmentIcon,
  navAssessmentActiveIcon,
  learningPathActiveIcon,
  learningPathIcon,
  navPracticesActiveIcon,
  navPracticesIcon,
  examsActiveIcon,
  examsIcon,
  rankBook,
  leaderboardMenu,
  point,
  rankOne,
  rankTwo,
  rankThree,
  firstRankMedal,
  secondRankMedal,
  thirdRankMedal,
  lineImg,
  inputDeleteImg,
  inputHistoryImg,
  loginBg,
  loginPageBg,
  loginFormBg,
  neoLogo,
  accountSettingsImg,
  avatarLarge,
  notifyIcon,
  showEyeIcon,
  hideEyeIcon,
  courseIcon_1,
  courseIcon_2,
  courseIcon_3,
  cartImg,
  cartIcon,
  cartIcon2,
  cartIcon2GreyFill,
  bellImg,
  userImg,
  searchIcon,
  aiLogo,
  retakeImg,
  sectionLockImg,
  proctorImg,
  assessmentImg,
  projectImg,
  moduleCompImg,
  testCompImg,
  hamburger,
  arrowDown,
  arrowUp,
  assessmentIcon,
  practiceTestIcon,
  timerIcon,
  validityIcon,
  moduleNotCompletedImg,
  completedImg,
  notCompletedImg,
  inprogress,
  profileImg,
  ellipsisImg,
  splitterIcon,
  splitterBarIcon,
  splitters,
  arrowDownIcon,
  clockIcon,
  nextIcon,
  prevIcon,
  bookmarkIcon,
  reportErrorIcon,
  attendanceImg,
  profileSideNav,
  settingsImg,
  arrowDownImg,
  collapseIcon,
  expandIcon,
  projectArrow,
  projectTeam1,
  projectTeam2,
  projectTeam3,
  resetIcon,
  tickIcon,
  waveBg,
  bestSellerTag,
  closeIcon,
  bookmarkedIcon,
  bookmarkFilledIcon,
  lockedIcon,
  deleteIcon,
  startIcon,
  rectangleIcon,
  contentAudio,
  contentExcel,
  contentDownload,
  timeSpent,
  viewsIcon,
  chromeIcon,
  firefoxIcon,
  threeDotsIcon,
  clearIcon,
  addIcon,
  removeIcon,
  proctoringIcon,
  successIcon,
  failureIcon,
  backArrowImg,
  questionIcon,
  imageIcon,
  infoIcon,
  deleteBlackIcon,
  eyeIcon,
  noData,
  instructionIcon,
  whiteIcon,
  darkIcon,
  testStart,
  calc,
  hintIcon,
  testClearIcon,
  optIn,
  optOut,
  camera,
  linkShare,
  fbShare,
  linkedInShare,
  whatsappShare,
  payIcon,
  couponIcon,
  iconBg,
  expandImg,
  loginImg,
  signupImg,
  sectionCompleted,
  speedTest,
  info,
  attend,
  resumeImg,
  addImg,
  fileUpload,
  initBack,
  down,
  emptyProfile,
  maleProfile,
  femaleProfile,
  file,
  eye,
  x,
  attemptTimeSpentIcon,
  sidebarOpen,
  sidebarClose,
  date,
  info1,
  practiceIcon,
  internetLost,
  pauseTestIcon,
  reportIcon,
  calculatorIcon,
  editorTool,
  attachIcon,
  sendIcon,
  faqIcon,
  fAQIcon,
  fullScreenIcon,
  uploadedIcon,
  compilerInsIcon,
  addFileIcon,
  uploadQRIcon,
  uploadIcon,
  fileIcon,
  arrowIcon,
  back,
  superBadge,
  badge,
  paperPen,
  titleBanner,
  excelDownload,
  playImg,
  membersImg,
  pauseFilled,
  teamDetailImg,
  githubImg,
  unmuteImg,
  muteImg,
  pauseImg,
  lineLeft,
  lineRight,
  videoType,
  cardNoteBook,
  cardWriting,
  cardPrompt,
  descIcon,
  courseDate,
  filterIcon,
  leftArrow,
  rightArrow,
  nextActive,
  prevActive,
  Rect,
  certificateImg,
  certificateFilledImg,
  contestImg,
  contestFilledImg,
  openIde,
  reloadIcon,
  openIdeFilled,
  leaderboardImg,
  leaderboardFilledImg,
  dashboardImg,
  dashboardFilledImg,
  courseImg,
  courseFilledImg,
  marketplace,
  marketplaceFilled,
  engagementImg,
  engagementFilledImg,
  headerbg,
  whiteThreeDotImg,
  descIconActive,
  drivesImg,
  drivesFilledImg,
  warningIcon,
  filterClose,
  sectionOpen,
  sectionClose,
  animationDown,
  animationUp,
  driveTimer,
  driveLocation,
  driveMoney,
  drivePattern,
  filterDefault,
  eligible,
  whiteDownArrow,
  profType,
  jobType,
  location,
  driveDate,
  profileLock,
  hrRect,
  success,
  error,
  driveResume,
  driveUpload,
  driveDownload,
  edit,
  answerLockedImg,
  testPattern,
  resultAnalysis,
  ipAddress,
  resDownload,
  VectorCal,
  ends_On,
  prize,
  users,
  book,
  idea,
  noOfContest,
  notAttend,
  nextBlueIcon,
  copyIcon,
  prevBlueIcon,
  bannerPattern,
  osUsed,
  tabSwitch,
  raTimer,
  raDuration,
  selected,
  notAttended,
  notSelected,
  courseEnrolled,
  courseCompleted,
  superBadges,
  badges,
  campus,
  batch,
  department,
  degree,
  dialogClose,
  contestDateIcon,
  contestPrizeIcon,
  contestProfileIcon,
  contestUserIcon,
  contestMoneyIcon,
  VectorDel,
  selectedContest,
  notSelectedContest,
  alertIcon,
  calenderIcon,
  environmentCheck,
  requirementCheck,
  checkErrorIcon,
  defaultDrive,
  COUNTRYFLAG,
  menuDots,
  maximize,
  minimize,
  successIcon2,
  successIcon3,
  reaction1,
  reaction2,
  reaction3,
  reaction4,
  reaction5,
  correctAns,
  wrongAns,
  growlCloseIcon,
  growlSuccessIcon,
  growlFailureIcon,
  growlWarnIcon,
  raLocation,
  raResumeCount,
  project,
  projectFilledImg,
  noDataAvailable,
  codeDownload,
  toastPatternIcon,
  callAttendIcon,
  callDeclineIcon,
  videoCallIcon,
  testStartIcon,
  speedometerGIF,
  callPattern,
  startDate,
  endDate,tests,assessment,practiceTest,
  analysisNotFound,
  analysisNotPublished,
  checkURL,
  leadPoints,
  authFormBg,
  hackathonLabel,
  recuirementLabel,
  preAssesmentLabel,
  generalLabel,
  certificationLabel,
  noInternetFound,
  threedot,
  backBtn,
  chatbg,
  smiley,
  editor,
  fileChat,
  download,
  video,
  deletechat,
  editchat,
  tickmark,
  closeEdit,
  downEdit,
  courseBanner,
  badgesBlue,
  endDateRed,
  startDateGreen,
  testBlue,
  superBadgesOrange,
  headerBanner,
  cost,
  validity,
  assessment_Test,
  clock,
  background,
  downloadFile,
  firstMedal,
  secondMedal,
  thirdMedal,
  star,
  starFilled,
  payment,
  line,
  coeTimer,
  waitingApproval,
  coeTik,
  certificate_icon_1,
  certificate_icon_2,
  rectangle,
  Fileicon,
  orange,
  notAvailable,
  timers,
  timersMobile,
  backgroundMarket,
  testMarket,
  testMarketMobile,
  practiceTestIcons,
  validityIconMobiles,
  validityIcons,
  practiceTestIconMobile,
  marketAssessmentMobile,
  marketAssessment,
  bestseller,
  cart,
  consumedBadgeIcon,
  consumedSuperBadgeIcon,
  consumedBg,
  completed,
  expired,
  enrolled,
  inProgress,
  Eligible,
  Applied,
  placed,
  rejected,
  waiting,
  Location,
  reload,
  hand,
  durationIcon,
  bullets
};
