import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChange,
} from '@angular/core';
import { SocketService } from '../../../services/socket.service';
import { GlobalService } from '@services/global.service';
import { PublishtypesService } from '@services/publishtypes.service';
import { Router } from '@angular/router';


@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
})
export class BannerComponent implements OnChanges {
    // @ViewChild('growlcard') growlCardElement: ElementRef;

    // @Input() id: string = 'growl-card';
    @Input() data: any = "";
    @Output() dataChange = new EventEmitter<any>();
    @Input() pdRestoreStatusData: any ; 
    @Input() showResPopup: boolean ;
    showResDailog: boolean = false;
    pdData:any ;
    userData: any;
    userConnected: boolean = false;
    viewDialog: boolean = false;
    display_msg: string = "";
    redirectURL: any = [];
    origin:string = "";
    liveSessionID:string = "";
    moduleID:string = "";
    callState: number = 0
    joinBtn: any = { multipleBtn: true, btnName: 'Join' };
    cancelBtn: any = { multipleBtn: true, btnName: 'Cancel' };
    public dialogStyle:any = {
        width: "30%",
        top: "5%",
        'border-radius': '10px'
    };
    public headerStyle:any = {
        'display': 'none',
    };

    constructor(private socketService: SocketService,
                private globalService: GlobalService,
                private publishtypesService: PublishtypesService,
                private router: Router,
                ){}

    private socketHandler(){
     this.socketService.messageRecieved.subscribe((message: any ) => {
            if(message && message.action){
                if(message.action === "live-calssroom-reminder" ){
                    this.showDailog(message.data);
                }
            }

        })
    }

    public showDailog(data:any){
        if(data && !data.isEnded){
           let url = window.location.href;
           let origin = `${url.split('/')[0]}//${url.split('/')[2]}`; // constructing Current url
           let publishType = this.publishtypesService.getPublishTypeDetailsByType(data.course_type || 'Course').params
           this.display_msg = data.display_msg;
           this.redirectURL = { origin, c_id: data.c_id, Type: publishType }
           this.liveSessionID = data.ls_id
           this.moduleID = data.moduleID
           this.viewDialog = true;
           this.callState = data.state;
        }
    }

    public connectToSocket(){
        if(!this.userConnected){
            this.userData = JSON.parse(localStorage.getItem('token'));
            if(this.userData["school_id"] && this.userData["user_id"]){
                this.socketService.connect(this.userData["school_id"],this.userData["user_id"]);
                this.socketHandler();
                this.userConnected = true;
                setTimeout(() => {
                    this.globalService.checkMyMeetingReminder().subscribe(
                        (response: any) => {
                        }
                    );
                }, 3000);
            }

        }
    }

    public showRestorationDailog(){
        this.pdData = this.pdRestoreStatusData &&this.pdRestoreStatusData.data
        this.showResDailog = this.pdRestoreStatusData && this.pdRestoreStatusData.visible && this.showResPopup ? true : false;

    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        for (let propName in changes) {
            if (propName === 'data' && !this.userConnected) {
                this.connectToSocket()
                // alert("Alert Changes ::")
            }
            if(propName === "pdRestoreStatusData" || propName === "showResPopup"){
                this.showRestorationDailog() 
             }
        }
    }

    public closeDailog(type){
        if(type == 2 ){
            this.showResDailog = false;
        }else{
            this.viewDialog = false;
            this.display_msg = "";
            this.redirectURL = "";
        }}

    public navigateDailog(){
        this.viewDialog = false;
        let url  = `${this.redirectURL.origin}/mycourses/details?id=${this.redirectURL.c_id}&type=${this.redirectURL.Type}
                    &Ls_id=${this.liveSessionID}&popUp=true`
        window.location.href = url;
    }

}
