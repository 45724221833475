import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    Input,
    OnChanges,
    SimpleChanges,
    AfterContentInit,
    ElementRef
} from '@angular/core';
import { date } from 'src/assets/images';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ProfileService } from 'src/app/containers/init-form/init_form.service';
import { cloneDeep } from "lodash-es"
@Component({
    selector: 'app-form-builder',
    templateUrl: './form-builder.component.html',
    styleUrls: ['./form-builder.component.less'],
})
export class FormBuilderComponent implements OnInit, OnChanges, AfterContentInit {
    @Input() fields: any;
    @Input() formLength: any;
    @Input() layout: any;
    @Input() layoutInner: any;
    @Input() count: any;
    @Input() btnStyle: any = {};
    @Input() btnContStyle: any;
    @Input() padding: any;
    @Input() activeBranch: any;
    @Input() origin: any;
    @Input() params: any;
    @Input() ngStyleClass: any;
    @Input() floatLabel;
    @Input() grid;
    @Input() widthTextArea;
    @Input() hideLabel;
    @Input() otpError;
    @Output() termsChecked = new EventEmitter<any>();
    ly: any;
    phonereq;
    @Input() buttonList: any = [];
    @Output() displayForm = new EventEmitter();
    @Output() onSubmit = new EventEmitter();
    @Output() setgender = new EventEmitter();
    @Output() setresdrive =  new EventEmitter();
    @Output() enableother = new EventEmitter();
    @Output() backTo = new EventEmitter();
    @Output() inputFieldValue = new EventEmitter();
    @Output() fileFormatInfo = new EventEmitter<any>();
    @Output() OfferUpload = new EventEmitter();
    
    @Output() Forgot = new EventEmitter();

    @Input() customizeRowStyle: any = {};
    @Input() buttonPosition: any = 'bottom';
    @Input() submitData;
    @Input() p10;
    @Input() p1;
    @Input() time;
    @Input() max380;
    @Input() max434;

    form: FormGroup;
    typeC: any;
    index;
    rowStyle: any = {};
    schoolData: any = JSON.parse(localStorage.getItem('school_details'));
    type = '';
    indexOfCheckbox: any = [];
    copyIds: any[];
    dateImg = date;
    submit = false;
    firstErr =false;
    constructor(private serv: ProfileService, private el: ElementRef) {}

    ngOnInit() {
        this.generateForm();
    }
    ngOnChanges(_changes: SimpleChanges) {
        this.ly = this.layout;
        const dt = 100 / this.count;
        if (dt) {
            this.rowStyle = undefined;
            this.rowStyle = { width: 'calc(' + dt + '%' + ' - 25px)' };
        } else {
            this.rowStyle = this.customizeRowStyle;
        }
        for (let i = 0; i < this.fields.length; i++) {
            if (this.fields[i].type === 'policy') {
                const deleted = this.fields.splice(i, 1);
                if (deleted && deleted.length) {
                    this.fields.push(deleted[0]);
                }
            }
        }
        if (this.submitData === null) {
            this.generateForm();
        }
        if (this.submitData) {
            this.submitData = false;
            setTimeout(() => {
                this.btnType({
                    "valid": true,
                    "type": "Save"
                });
                this.onSubmits(this.form);
            }, 200);
        }
    }

    backto(event) {
        this.backTo.emit(event);
    }

    forgot() {
        this.Forgot.emit();
    }

    ngAfterContentInit() {
        if(this.displayForm) {
            this.displayForm.emit()
        }
    }

    generateQuestionsForCopy(){
        const labels = [];
        const copyIds = [];
        this.fields.forEach((field, i) => {
            if (field && field.copy_from && field.copyFrom){
                copyIds.push({
                    from: this.fields[i].copyFrom,
                    to: this.fields[i].cf_id,
                });
                this.indexOfCheckbox.push(i);
            }
        });
        this.copyIds = copyIds;
        this.fields.forEach((field, _i) => {
            if (field.copy_from){
                const from = this.fields.find((f) => {
                    return f.cf_id === field.copyFrom;
                });
                const to = field.question || field.name;
                if (from && ((from.question || from.name) &&
                 (from.question || from.name) !== 'resume') && ((field.question || field.name)
                  && (from.question || from.name) !== 'resume')){
                    labels.push(`Do you want to copy ${from.question || from.name } as ${to} ?`);
                }
            }
        });
        if (this.indexOfCheckbox && this.indexOfCheckbox.length > 0){
            let j = 0;
            let k = 0;
            for (const ind of this.indexOfCheckbox){
                const unit = {
                    type: 'radiobutton',
                    label: labels[k],
                    name: labels[k],
                    copy: true,
                    copyValue: false,
                    options: [{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}],
                    copyIndex: this.indexOfCheckbox[k],
                    copy_ids: [{ from: this.fields[ind + j].copyFrom, to: this.fields[ind + j].cf_id}],
                };
                this.fields.splice(ind + j , 0 , unit);
                j++;
                k++;
            }
        }
    }

    generateForm() {
        const fieldsCtrls = {};
        if (this.fields) {
            if (this.formLength && this.activeBranch) {
                for (let i = 0; i < this.formLength; i++) {
                    this.formbuilder(fieldsCtrls, this.fields[i]);
                }
            } else {
                this.formbuilder(fieldsCtrls, this.fields);
            }
        }
        this.autoCopyfun();
    }
    formbuilder(fieldsCtrls, fields) {
        this.generateQuestionsForCopy();
        for (const f of fields) {
            if (
                f.type !== 'checkbox' &&
                f.type !== 'file' &&
                f.type !== 'dropdown' &&
                f.type !== 'checked' &&
                f.type !== 'dob' &&
                f.type !== 'resume' &&
                f.type !== 'radio' &&
                f.type !== 'radiobutton' &&
                (f.type === 'text' || f.type === 'phone' || f.type === 'number' || f.type === 'shortanswer' || f.type === 'longanswer')
            ) {
                const validation: any = [
                    f.regex ? Validators.pattern(f.regex) : Validators.pattern(''),
                    f.minLength ? Validators.minLength(f.minLength) : Validators.minLength(0),
                ];
                if (f.required) {
                    validation.push(Validators.required);
                }
                fieldsCtrls[f.name] = new FormControl(f.value || '', validation);
            } else if (f.type === 'degree' || f.type === 'file' || f.type === 'resume' || f.type === 'file_upload' || f.type === 'policy') {
                if (f.required) {
                    fieldsCtrls[f.name] = new FormControl(f.value || '', [Validators.required]);
                }
                else {
                    fieldsCtrls[f.name] = new FormControl();
                }
            } else if (f.type === 'checked') {
                const opts = {};
                for (const opt of f.options) {
                    if (f.required) {
                        opts[opt.label] = new FormControl(f.value || '', [f.required ? Validators.required : null]);
                    }
                    else {
                        opts[opt.label] = new FormControl();
                    }
                }
                fieldsCtrls[f.name] = new FormArray([]);
                fieldsCtrls[f.name].push(opts);
            } else {
                if (f.required) {
                    fieldsCtrls[f.name] = new FormControl(f.value || null, [Validators.required]);
                }
                else {
                    fieldsCtrls[f.name] = new FormControl();
                }
            }
            if (f.type === 'phone') {
                this.phonereq = true;
            }
            if(f.name === 'resume') {
                f.value = false;
            }
        }
        this.form = undefined;
        this.form = new FormGroup(fieldsCtrls);
    }

    async onSubmits(val) {
        const loading: any = this.buttonList ? this.buttonList.find((f) => f && f.loading) : false;
        if (!loading) {
            if (val && val.from && val.from === 'additional') {
                this.type = val.type;
                val = this.form.getRawValue();
            }
            if (this.form.valid === false) {
                if (this.type === 'Cancel') {
                    this.form.reset();
                    this.onSubmit.emit({ value: val, type: this.type });
                } else if (this.type === 'Previous') {
                    this.onSubmit.emit({ value: val, type: this.type });
                } else {
                    this.markAlltouched();
                    this.onSubmit.emit(undefined);
                }
            } else {
                if (!val) {
                    val = this.form.getRawValue();
                }
                if (this.type === 'Cancel') {
                    this.form.reset();
                    this.onSubmit.emit({ value: val, type: this.type });
                } else {
                   const copyIds: any = [];
                   let profile;
                   this.fields.forEach((field) => {
                       if (field.type === 'radiobutton' && field.copyIndex && field.copy_ids &&
                        field.copy_ids.length && field.copy_ids[0].copyValue){
                            copyIds.push({
                                id: field.copy_ids
                            });
                       }
                       if ( field.profile === 'profile'){
                           profile = field.profile;
                       }
                   });
                   if (copyIds && copyIds.length){
                       let fromId;
                       let toId;
                       copyIds.forEach((id) => {
                           if (id.id[0].copyValue){
                                fromId = id.id[0].from;
                                toId = id.id[0].to;
                                let from;
                                let to;
                                this.fields.forEach((field) => {
                                    if (field.cf_id === fromId){
                                        from = field.name;
                                    } else if (field.cf_id === toId){
                                        to = field.name;
                                    }
                                });
                                if (profile === 'profile'){
                                    val.value[to] = val.value[from];
                                } else{
                                    val.value[to] = val.value[from];
                                }
                           }
                       });
                   }
                   if (val && val.value && (val.value.tenth_marks || val.value.twelfth_marks
                    || val.value.backlog_history || val.value.current_backlogs || val.value.diploma_marks ||
                    val.value.interested_for_placement || val.value.pg_marks || val.value.roll_no ||
                     val.value.ug_marks)) {
                        this.markAlltouched();
                        this.validateAcademic(val);
                   } else if (val && val.value && val.value.phone &&
                    this.phonereq && this.serv.fValue.country_code === '') {
                        this.onSubmit.emit(undefined);
                   } else if (val && val.value && val.value.gender===null&&!this.form.valid) {
                        this.markAlltouched();
                        this.onSubmit.emit(undefined);
                   } else {
                        this.markAlltouched();
                        this.validateRadio(val);
                   }
                }
            }
        }
    }

    validateRadio(val) {
        let validRadio = true;
        this.fields.forEach((each)=>{
            if((each.type === 'radio' || each.type === 'radiobutton' )&& each.required && 
            (val.value.hasOwnProperty(each.name)&&val.value[each.name]===null || val.value.hasOwnProperty(each.name)&&val.value[each.name]==='')) {
                validRadio = false;
            }
        })
        if(validRadio) {
            this.onSubmit.emit({ value: val, type: this.type });
        } else {
            this.onSubmit.emit(undefined);
        }
    }

    validateAcademic(val) {
        if ((this.serv.fValue.is_tenth_percentage && Number(val.value.tenth_marks) > 100) ||
         (this.serv.fValue.is_twelfth_percentage && Number(val.value.twelfth_marks) > 100) ||
        (this.serv.fValue.is_diploma_percentage && Number(val.value.diploma_marks) > 100) ||
        (this.serv.fValue.is_ug_percentage && Number(val.value.ug_marks) > 100) ||
        (this.serv.fValue.is_pg_percentage && Number(val.value.pg_marks) > 100)) {
            this.onSubmit.emit(undefined);
        } else if ((!this.serv.fValue.is_tenth_percentage && Number(val.value.tenth_marks) > 10) ||
        (!this.serv.fValue.is_twelfth_percentage && Number(val.value.twelfth_marks) > 10) ||
       (!this.serv.fValue.is_diploma_percentage && Number(val.value.diploma_marks) > 10) ||
       (!this.serv.fValue.is_ug_percentage && Number(val.value.ug_marks) > 10) ||
       (!this.serv.fValue.is_pg_percentage && Number(val.value.pg_marks) > 10)) {
            this.onSubmit.emit(undefined);
       } else if((this.serv.fValue.is_tenth_percentage === null && Number(val.value.tenth_marks)) ||
       (this.serv.fValue.is_twelfth_percentage === null && Number(val.value.twelfth_marks)) ||
      (this.serv.fValue.is_diploma_percentage === null && Number(val.value.diploma_marks)) ||
      (this.serv.fValue.is_ug_percentage === null && Number(val.value.ug_marks)) ||
      (this.serv.fValue.is_pg_percentage === null && Number(val.value.pg_marks))) {
        this.onSubmit.emit(undefined);
       } else {
        this.validateRadio(val);
       }
    }

    markAlltouched() {
        for (const i in this.form.controls) {
            if (i) {
            this.form.controls[i].markAsTouched();
            }
        }
    }
    btnType(val) {
            this.type = val.type;
    }
    selectedValue(event) {
        if (this.origin === 'publiccontest') {
            this.fields[2].required = true;
            if (this.schoolData && this.schoolData.institute_type === 'college') {
                this.fields[3].required = true;
                this.fields[2].options = this.params.batches.filter((one: any) => {
                    return one.branch_id === event;
                });
                this.fields[3].options = this.params.degrees.filter((one: any) => {
                    return one.branch_id === event;
                });
            } else {
                this.fields[2].options = this.params.targetexams.filter((one: any) => {
                    return one.branch_id === event;
                });
            }
        } else if (this.origin === 'signup') {
            if (this.schoolData && this.schoolData.institute_type === 'college') {
                for (const fieldData of this.fields) {
                    if (fieldData.name === 'Batch') {
                        fieldData.options = this.params.batches.filter((one: any) => {
                            return one.branch_id === event;
                        });
                        this.fields[2] = cloneDeep(this.fields[2]);
                    }
                    if (fieldData.name === 'Degree') {
                        fieldData.options = this.params.degrees.filter((one: any) => {
                            return one.branch_id === event;
                        });
                        this.fields[3] = cloneDeep(this.fields[3]);
                    }
                    if (fieldData.name === 'Department') {
                        fieldData.options = this.params.departments.filter((one: any) => {
                            return one.branch_id === event;
                        });
                        this.fields[1] = cloneDeep(this.fields[1]);
                    }
                }
            }
        }
    }
    termschecked(event) {
        this.termsChecked.emit(event);
    }

    setGender(event) {
        this.setgender.emit(event);
    }

    enableOther(event) {
        this.enableother.emit(event);
    }

    setResDrive(event) {
        this.setresdrive.emit(event);
    }

    fileFormatInfos(event) {
        this.fileFormatInfo.emit(event);
    }

    offerUpload(event) {
        this.OfferUpload.emit(event);
    }

    radioResponse(event){
        if (event.value === 'yes'){
            const ids = event.data;
            ids.forEach((id) => {
                this.fields.find(field => {
                    if (field.cf_id === id.to && field.label !== 'resume' && event.copyValue){
                        if (field.profile !== 'profile'){
                            field.copyValue = true;
                            field.copyHide = true;
                            this.form.controls[field.question].disable();
                        }else{
                            field.disabled = true;
                        }
                    }
                });
            });
        } else{
            const ids = event.data;
            ids.forEach((id) => {
                this.fields.find(field => {
                    if (field.cf_id === id.to && field.label !== 'resume'){
                        if (field.profile !== 'profile'){
                            field.copyValue = false;
                            field.copyHide = false;
                            this.form.controls[field.question].enable();
                        }else{
                            field.disabled = false;
                        }
                    }
                });
            });
        }
    }

    getValue(cf_id){
        return this.fields.find((f) => {
            return f.cf_id === cf_id;
        });
    }

    autoCopyfun(){
        this.fields.forEach((field: any, _ind: any) => {
            if (field.copy_from && field.copyFrom){
                if (field.profile === 'profile'){
                    if (field.value === this.getValue(field.copyFrom).value){
                        field.disabled = true;
                    }
                }
            }
        });
    }
    inputValue(event){
        this.inputFieldValue.emit(event);
    }

    enterOnSubmit(form) {
        const path = window.location.pathname;
        if (path === '/pwd' || path === '/signup' || path === '/setpassword' || '/forgot') {
            this.onSubmits(form)
        }
    }
}
