import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, catchError } from 'rxjs';
import { LoginService } from './login.service';
@Injectable({
    providedIn: 'root',
})
export class FeedsService {
    api = environment.HOST.link;
    setFeed = new BehaviorSubject<any>({});
    recentHashtag = new BehaviorSubject<any>([]);
    public defaultqbimage: any;
    public defaulttestimage: any;
    public defaultcbimage: any;
    public defaultcourseimage: any;

    constructor(private http: HttpClient, private loginService: LoginService) {
        this.defaultqbimage = environment.s3Objectstudentassets + 'assets/common-images/market/Content.jpg';
        this.defaulttestimage = environment.s3Objectstudentassets + 'assets/common-images/market/QuestionBank.jpg';
        this.defaultcbimage = environment.s3Objectstudentassets + 'assets/common-images/market/Test.jpg';
        this.defaultcourseimage = environment.s3Objectstudentassets + 'assets/common-images/market/Course.png';
    }

    createTextLinks(text) {
        return (text || '').replace(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi, (match, space, url) => {
            let hyperlink: any = url;
            if (!hyperlink.match('^https?://')) {
                hyperlink = 'https://' + hyperlink;
            }
            return space + '<a href="' + hyperlink + '" target="_blank">' + url + '</a>';
        });
    }
    formatpost(text) {
        return (text || '').replace(/\n/g, '<br>');
    }

    getFeeds(payload) {
        return this.http.post(this.api + '/activity/getPublicfeeds', payload);
    }

    getFeatureCourse(payload) {
        return this.http.post(this.api + '/student/listproducts', payload);
    }
    getBoughtList(payload) {
        return this.http
            .post(this.api + '/products/boughtlist', payload, this.loginService.options(null))
            .pipe(catchError(this.loginService.handleError.bind(this)));
    }
    getFeatureData(payload) {
        return this.http.post(this.api + '/student/listproductscount', payload);
    }
    getFeatureDataWithJWT(payload) {
        return this.http.post(this.api + '/student/v2/listproductscount', payload, this.loginService.options(null));
    }
    getRecentHashtags(payload) {
        return this.http.post(this.api + '/student/getrecenthashtags', payload);
    }
    addactivity(payload) {
        return this.http.post(this.api + '/activity/addactivity', payload, this.loginService.options(null));
    }
    addReaction(payload) {
        return this.http.post(this.api + '/activity/addReaction', payload, this.loginService.options(null));
    }
    shareactivity(payload) {
        return this.http.post(this.api + '/activity/shareactivity', payload, this.loginService.options(null));
    }
    removereaction(payload) {
        return this.http.post(this.api + '/activity/removereaction', payload, this.loginService.options(null));
    }
    removeactivity(payload) {
        return this.http.post(this.api + '/activity/removeactivity', payload, this.loginService.options(null));
    }
}
