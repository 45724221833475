import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkTestComponent } from './link-test.component';

@NgModule({
    imports: [CommonModule],
    declarations: [LinkTestComponent],
    exports: [LinkTestComponent],
})
export class LinkTestModule {}
