import {
  Component,
  OnInit,
  HostListener,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";

import { GlobalService } from "@services/global.service";
import { LoginService } from "@services/login.service";
import { environment } from "./../environments/environment";
import { aiLogo } from "../assets/images";

declare let InstallTrigger: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit, OnChanges {
  public showTop: boolean;
  public showHeader: boolean;
  public growlData: any;
  public isBrowserSupported: boolean;
  setLocalStorage: boolean;
  loginRoute: boolean;
  toggleBtn: boolean;
  clientLogo = aiLogo;
  hideSearch = false;
  screenWidth;
  any;
  mobile = false;
  isSmallMobile: boolean = false;
  screenHeight;
  width: any;
  martop: any;
  cartClicked:boolean;
  path;
  isLogin;
  showSearch;
  constructor(
    private globalService: GlobalService,
    private router: Router,
    private loginService: LoginService
  ) {
    let src: any;
    const schoolDetails: any = localStorage.getItem("school_details");
    if (environment.production) {
      src = "https://images-smartica-co-in.s3.amazonaws.com/icons2/smart.css";
    } else {
      src = "https://images-smartica-co-in.s3.amazonaws.com/icons2/smart.css";
    }
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.isLogin = this.loginService.loginStatus();
        this.path = window.location.pathname;
      }
    });
    this.globalService.loadScript("link", src, "text/css", "", "customIcons");
    if (
      schoolDetails &&
      JSON.parse(schoolDetails).school_code === "randstadindia288"
    ) {
      src =
        "https://images.examly.io/randstadindia288/randstadStudentCustom.css";
      this.globalService.loadScript("link", src, "text/css", "", "customCss");
    }
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(_event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.setSize();
  }

  setSize() {
    if (this.screenWidth < 1024) {
      this.width = this.screenWidth;
      this.martop = 48;
      this.mobile = true;
      this.hideSearch = true;
    } else {
      this.width = this.screenWidth;
      this.martop = 0;
      this.mobile = false;
      this.hideSearch = false;
    }
    if (this.screenWidth < 1024) {
      this.isSmallMobile = true;
    } else {
      this.isSmallMobile = false;
    }
  }

  async ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.setSize();
    this.path = window.location.pathname;
    if (window.location.pathname === '/loginredirect') {
      localStorage.setItem('ssologin', 'true');
  }
  // if(window.location.pathname === '/loginredirect' && window.location.href.includes('jobId') && window.location.href.includes('stageId')){
  //   const url = window.location.href;
  //   console.log("URL -- ", url);
  //   const searchParams = new URLSearchParams(url.substring(url.indexOf('?') + 1));
  //   let jobId = searchParams.get('jobId');
  //   console.log("jobId -- ", jobId);
  //   localStorage.setItem('jobId', jobId);
  //   let stageId = searchParams.get('stageId');
  //   console.log("stageId -- ", stageId);
  //   localStorage.setItem('stageId', stageId);
  // }
  // if (window.location.pathname === '/loginredirect' && window.location.href.includes('userId') && window.location.href.includes('eventId') && window.location.href.includes('stageId')) {
  //   const url = window.location.href;
  //   const searchParams = new URLSearchParams(url.substring(url.indexOf('?') + 1));
  //   let eventId = searchParams.get('eventId');
  //   localStorage.setItem('eventId', eventId);
  //   let stageId = searchParams.get('stageId');
  //   localStorage.setItem('stageId', stageId);
  //   localStorage.setItem('hireIDE', 'true');
  // }
  
  if (window.location.pathname === '/loginredirect' && window.location.href.includes('stageId')) {
    const url = window.location.href;
    const searchParams = new URLSearchParams(url.substring(url.indexOf('?') + 1));
    const candidateName = searchParams.get("candidateName");
    const candidateId = searchParams.get("candidateId");

    if (searchParams.get('jobId')) {
      localStorage.setItem('jobId', searchParams.get('jobId'));
    } else if (searchParams.get('eventId')) {
      localStorage.setItem('eventId', searchParams.get('eventId'));
    }
    localStorage.setItem('stageId', searchParams.get('stageId'));

    if (searchParams.get('userId')) {
      localStorage.setItem('hireIDE', 'true');
    }

    if (candidateName) {
      localStorage.setItem('candidateName', candidateName);
    } else {
      localStorage.setItem('candidateName', null); 
    }
    if (candidateId) {
      localStorage.setItem('candidateId', candidateId);
    } else {
      localStorage.setItem('candidateId', null);
    }
  }
    
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isNotEdge = navigator.userAgent.indexOf('Edg') === -1;
  const isNotOpera = !window['opr'];
  const isChrome = window['chrome'] || navigator.userAgent.indexOf('CriOS') > 0;
  const isSafari = navigator.userAgent.indexOf('Safari') > 0;
  let isNotSafari = true;
  window.parent.postMessage(JSON.stringify({​subject: 'lti.frameResize', height: 650}​), '*');

    if (!isChrome && isSafari) {
      isNotSafari = false;
    }
    if (isNotSafari && isNotOpera && (isFirefox || isChrome || !isNotEdge)) {
      this.isBrowserSupported = true;
      localStorage.removeItem("path");
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.isLogin = this.loginService.loginStatus();
          // this.path = window.location.pathname;
          if (this.globalService.inIframe()) {
            const einit = localStorage.getItem("einit");
            if (
              einit &&
              ![
                "/project",
                "/test",
                "/result",
                "/login",
                "/loginredirect",
              ].includes(location.pathname)
            ) {
              this.globalService.clearLocalStorage();
            }
          }
        }
      });
      const einitLoc = localStorage.getItem("einit");
      const userData = localStorage.getItem('formData');
      if(userData){
        const userId = JSON.parse(userData).users_domain_id;
        const payload = {uid : userId }
        this.loginService.checkIfStudentExist(payload).subscribe(
          (response: any) => {
              if(response.deletedAt !== null){
                 localStorage.clear();
              }
          })
      }

      if (
        einitLoc &&
        !this.globalService.inIframe() &&
        !["/test"].includes(location.pathname)
      ) {
        this.globalService.commonGrowl.next({
          time: 30000,
          severity: "warning",
          summary: "Direct login access has been disabled.",
        });
        this.globalService.sleep(1000).then(() => {
          this.globalService.clearLocalStorage();
        });
        this.globalService.sleep(1000).then(() => {
          this.router.navigateByUrl("login");
        });
      }
      await this.redirection();

      // top nav bar
      this.globalService.showTop.subscribe((data: any) => {
        if (this.showTop !== data) {
          this.showTop = data;
        }
      });

      this.globalService.showHeader.subscribe((data: any) => {
        this.showHeader = data;
      });

      // growl message
      this.globalService.commonGrowl.subscribe((data: any) => {
        const iskey = Object.keys(data).length;
        if (data && iskey) {
          this.growlData = data;
        }
      });

      // google analytics
      this.globalService.addGoogleAnalytics();
    }
  }

  async ngOnChanges(changes: SimpleChanges) {}

  private redirection() {
    return new Promise((resolved, _rejected) => {
      this.loginService.getSchoolLoginDetails().subscribe(
        async (response: any) => {
          if (response && response.data) {
            localStorage.removeItem("redirect");
            await this.loginService.setLocalstorage(response);
            this.setLocalStorage = true;
            // school logo
            if (response.data.school_logo) {
              this.globalService.schoolLogo.next(response.data.school_logo);
            }

            if (response.data.school_name) {
              this.globalService.schoolName.next(response.data.school_name);
            }

            if (response.data.schools_metadata && response.data.school_status) {
              const schoolMeta = response.data.schools_metadata;
              const enableFeatures = response.data.enable_features;
              if (schoolMeta && schoolMeta.allowsignup) {
                this.globalService.isSignup.next(true);
              }
              this.globalService.updateLoginURLS(schoolMeta, enableFeatures);
              this.globalService.publicNavigation(schoolMeta, enableFeatures);

              // premium feature - update every 5mins
              this.globalService.resetToken();

              if (this.loginService.loginStatus()) {
                if (
                  localStorage.getItem("noRedirect") &&
                  window.location.pathname != "/ide"
                ) {
                  this.router.navigateByUrl("create-profile");
                } else if (location.pathname === "/") {
                  this.router.navigateByUrl(
                    this.globalService.defaultRedirection()
                  );
                }
              } else if (localStorage.getItem("path")) {
                this.router.navigateByUrl(localStorage.getItem("path"));
              } else if (location.pathname === "/") {
                const navUrl: any = this.globalService.publicNavigation(
                  schoolMeta,
                  enableFeatures
                );
                this.router.navigateByUrl(navUrl);
              }
            } else {
              this.router.navigateByUrl("/login");
              this.loginRoute = false;
              this.showGrowl(3000, 'error', 'Inactive account', 'Please contact the administrator for more details');
            }
          }
          resolved(true);
        },
        () => {
          resolved(false);
        }
      );
    });
  }

  cart(event) {
    this.cartClicked = event;
  }

  handletoggleBtn(event) {
    this.toggleBtn = event;
  }
  handleSideBarHidden(event) {
    this.toggleBtn = event;
  }
  closeDropDown(event) {
    const arr: any = [];
    if (event && event.path && event.path.length) {
      event.path.forEach((each: any, _index: any) => {
        arr.push(each.localName);
      });
    }
    if (!arr.includes("app-select-dropdown")) {
      this.globalService.toggleVisibility();
    }
  }
  private showGrowl(time: number, severity: string, summary: string, detail: string) {
    this.globalService.commonGrowl.next({
        time,
        severity,
        summary,
        detail,
    });
  }
}
