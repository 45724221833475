import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
@Component({
    selector: 'app-link-test',
    templateUrl: './link-test.component.html',
    styleUrls: ['./link-test.component.less'],
})
export class LinkTestComponent {
    @Input() data: any;
    @Input() target: any;
    @Input() routerLink: any;
    @Input() color: any;
    @Input() id: any;
    @Input() params: any;
    @Input() fontSize: any;
    constructor(private router: Router, public loginservice: LoginService) {}

    loadLink() {
        if (this.params) {
            if (this.params.email) {
                this.loginservice.getEmail.next(this.params);
            }
        }
        this.router.navigateByUrl('/' + this.routerLink);
    }

    
}
