import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, catchError } from 'rxjs';
import { LoginService } from './login.service';
import { GlobalService } from './global.service';
import { TestService } from './test.service';
import { Router } from '@angular/router';

export interface currentChannelData {
    channel_id: string;
    name: string;
    description: string;
    channel_grp_id: string;
    slowMode: number;
    slowModeSetTime: Date;
    unreadMsgCount: number;
    isUnreadMsg: boolean;
    restrictMessaging: string;
}

declare let PubNub;

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    public chatLeftPanelEvents = new BehaviorSubject<any>({});
    public newMessage = new BehaviorSubject<any>({});
    public typingData = new BehaviorSubject<any>({});
    public updateMessage = new BehaviorSubject<any>({});
    public isOffline = new BehaviorSubject<boolean>(false);
    private messageSource = new BehaviorSubject<any>('');
    // currentMessage = this.messageSource.asObservable();
    // public changeMessage(message: any) {
    //     this.messageSource.next(message);
    // }
 

    api_url = environment.HOST.link;
    userData: any;

    sclMD: any;
    pubNub: any;
    currentChannel: currentChannelData;
    pubNubListener: any;
    subscriptionList: any = [];

    constructor(
        private http: HttpClient,
        private router: Router,
        private globalService: GlobalService,
        private loginService: LoginService,
        private testService: TestService,
    ) {
        const schoolData: any = JSON.parse(localStorage.getItem('school_details'));
        if (schoolData && schoolData.schools_metadata) {
            this.sclMD = schoolData.schools_metadata;
        }
        this.userData = JSON.parse(localStorage.getItem('token'));
        this.logoutListener();
    }

    public setUserData() {
        this.userData = JSON.parse(localStorage.getItem('token'));
    }

    public getPubNub() {
        return new Promise(async (resolved, _rejected) => {
            if (this.loginService.loginStatus() && this.checkIfEngagementAllowed()) {
                if (!this.pubNub) {
                    const script: any = {
                        element: 'script',
                        url: 'https://cdn.pubnub.com/sdk/javascript/pubnub.4.29.9.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'pubnub',
                    };
                    await this.globalService.loadScript(
                        script.element,
                        script.url,
                        script.type,
                        script.rel,
                        script.id,
                        script.child,
                    );

                    this.getToken().subscribe(async (token: any) => {
                        const CryptoJS = await this.testService.getCryptoJS();

                        if (token && token.success) {
                            const decrryptedData: any = JSON.parse(
                                CryptoJS.AES.decrypt(
                                    token.token,
                                    this.userData.school_id + '_' + this.userData.user_id,
                                ).toString(CryptoJS.enc.Utf8),
                            );

                            if (decrryptedData) {
                                this.pubNub = new PubNub({
                                    publishKey: decrryptedData.publishKey,
                                    subscribeKey: decrryptedData.subscribeKey,
                                    uuid: this.userData.user_id + '_' + this.userData.school_id,
                                    authKey: this.userData.user_id + '_' + this.userData.school_id,
                                    keepAlive: true,
                                    autoNetworkDetection: true,
                                });

                                await this.addListeners();

                                resolved(this.pubNub);
                            } else {
                                resolved(false);
                            }
                        } else {
                            if (window.location.pathname.includes('chat')) {
                                this.globalService.commonGrowl.next({
                                    time: 3000,
                                    severity: 'error',
                                    summary: 'Chat initialization failed!',
                                    detail: 'Kindly retry after some time',
                                });
                            }
                            resolved(false);
                        }
                    });
                } else {
                    resolved(this.pubNub);
                }
            } else {
                resolved(false);
            }
        });
    }

    public addListeners() {
        return new Promise((resolved, _rejected) => {
            this.pubNub.addListener({
                message: (msgEvent) => {
                    if (this.currentChannel && this.currentChannel.channel_id === msgEvent.channel) {
                        this.newMessage.next({
                            action: 'message',
                            event: msgEvent,
                        });
                    } else {
                        this.chatLeftPanelEvents.next({
                            action: 'message',
                            event: msgEvent,
                        });
                    }
                    if (this.userData.user_id !== msgEvent.message.user_id) {
                        let textMsg = document.createElement('div');
                        textMsg.innerHTML = msgEvent.message.message;
                        this.showNotification(
                            msgEvent.message.channel_name,
                            msgEvent.message.sender + ': ' + textMsg.innerText,
                            msgEvent.message.profile_pic,
                            'chat',
                        );
                    }
                },
                presence: (_p) => {
                      // This is intentional
                },
                signal: (s) => {
                    this.typingData.next({
                        channelName : s.channel,
                        channelGroup : s.subscription,
                        pubTT : s.timetoken,
                        msg : s.message,
                        publisher : s.publisher
                    });
                },
                objects: (objectEvent) => {
                    if (objectEvent && objectEvent.message && objectEvent.message.type === 'channel') {
                        this.chatLeftPanelEvents.next({
                            action: 'channel',
                            event: objectEvent,
                        });
                    }
                },
                messageAction: (ma) => {
                    if (this.currentChannel && this.currentChannel.channel_id === ma.channel) {
                        this.updateMessage.next({
                            action: 'updated',
                            event: ma,
                        });
                    }
                },
                file: (fileEvent) => {
                    if (this.currentChannel && this.currentChannel.channel_id === fileEvent.channel) {
                        this.newMessage.next({
                            action: 'file',
                            event: fileEvent,
                        });
                    } else {
                        this.chatLeftPanelEvents.next({
                            action: 'message',
                            event: fileEvent,
                        });
                    }
                },
                status: (_s) => {
                      // This is intentional
                },
                membership: (memberEvent) => {
                    if (
                        memberEvent &&
                        memberEvent.message &&
                        memberEvent.message.data &&
                        memberEvent.message.data.uuid &&
                        memberEvent.message.data.uuid.id === this.userData.user_id + '_' + this.userData.school_id
                    ) {
                        if (
                            memberEvent.message.type === 'membership' &&
                            (memberEvent.message.event === 'delete' || memberEvent.message.event === 'set')
                        ) {
                            this.chatLeftPanelEvents.next({
                                action: 'member',
                                type: memberEvent.message.event,
                                event: memberEvent,
                            });
                            if (
                                memberEvent.message.event === 'delete' &&
                                this.subscriptionList &&
                                this.subscriptionList.includes(memberEvent.message.data.channel.id)
                            ) {
                                const findIndex: any = this.subscriptionList.findIndex((each: any) => {
                                    return each === memberEvent.message.data.channel.id;
                                });

                                this.subscriptionList.splice(findIndex, 1);
                                this.updateSubscription();
                            }
                        }

                        if (
                            this.subscriptionList.includes(memberEvent.message.data.channel.id) === false &&
                            memberEvent.message.event !== 'delete'
                        ) {
                            this.subscriptionList.push(memberEvent.message.data.channel.id);
                            this.updateSubscription();
                        }
                    }
                },
            });
            resolved(true);
        });
    }

    public showNotification(title, body, icon, tag) {
        const notification = new Notification(title, {
            body,
            icon,
            tag,
        });
        notification.onshow = () => {
            setTimeout(() => {
                notification.close();
            }, 10000);
        };
        notification.onclick = (_event) => {
            if (window.location.pathname !== '/chat' && notification.tag === 'chat') {
                this.router.navigateByUrl('chat');
            }
        };
    }

    getcompanyLogo(search) {
        return this.http.get('https://autocomplete.clearbit.com/v1/companies/suggest?query=' + search);
    }

    public getToken() {
        return this.http
            .post(
                this.api_url + '/chat/token',
                {
                    user_id: this.userData.user_id,
                },
                this.loginService.options(null),
            )
            .pipe(catchError(this.loginService.handleError.bind(this)));
    }

    public removeListeners() {
        this.pubNub.removeListener(this.pubNubListener);
    }

    public getChannelGroups() {
        return this.http
            .post(
                this.api_url + '/getchannelgroups',
                {
                    user_id: this.userData.user_id,
                },
                this.loginService.options(null),
            )
            .pipe(catchError(this.loginService.handleError.bind(this)));
    }

    public getMembersList(payload) {
        return this.http
            .post(this.api_url + '/channel/getmembers', payload, this.loginService.options(null))
            .pipe(catchError(this.loginService.handleError.bind(this)));
    }

    /**
     * Add Channel to Subscription
     * @param channelName Channel Id
     */
    public addPubnubSub(channelName: string) {
        if (this.pubNub && channelName) {
            this.pubNub.subscribe({
                channels: [channelName],
            });
        }
    }

    /**
     * Remove Channel from Subscription
     * @param channelName Channel Id
     */
    public removePubnubSub(channelName: string) {
        if (this.pubNub && channelName) {
            this.pubNub.unsubscribe({
                channels: [channelName],
            });
        }
    }

    private logoutListener() {
        this.globalService.logoutTrigger.subscribe(() => {
            this.flushPubNub();
        });
    }

    private flushPubNub() {
        if (this.pubNub) {
            this.pubNub.unsubscribeAll();
            this.pubNub = null;
        }
    }

    public getSubscriptionList(nextPage?: any) {
        if (this.pubNub) {
            const payload: any = {
                uuid: this.userData.user_id + '_' + this.userData.school_id,
                include: {
                    channelFields: true,
                    customFields: true,
                    customChannelFields: true,
                },
            };

            if (nextPage) {
                payload.page = {
                    next: nextPage
                };
            }
            this.pubNub.objects
                .getMemberships(payload)
                .then((channelsResp: any) => {
                    if (channelsResp && channelsResp.status === 200 && channelsResp.data) {
                        channelsResp.data.forEach((eachChannel: any) => {
                            if (
                                eachChannel.channel &&
                                eachChannel.channel.custom &&
                                eachChannel.channel.custom.channel_grp_id &&
                                !eachChannel.channel.custom.deletedAt
                            ) {
                                if (!this.subscriptionList.includes(eachChannel.channel.id)) {
                                    this.subscriptionList.push(eachChannel.channel.id);
                                }
                            }
                        });

                        if (
                            channelsResp.next &&
                            channelsResp.data.length >= 100 &&
                            channelsResp.next !== channelsResp.prev
                        ) {
                            this.getSubscriptionList(channelsResp.next);
                        }
                    }
                    this.updateSubscription();
                });
        }
    }

    public updateSubscription() {
        const payload: any = {};

        if (!this.subscriptionList.includes(this.userData.user_id + '_' + this.userData.school_id)) {
            this.subscriptionList.push(this.userData.user_id + '_' + this.userData.school_id);
        }

        if (this.subscriptionList && this.subscriptionList.length) {
            if (this.subscriptionList.length >= 100) {
                payload.channelGroups = [this.userData.user_id + '_' + this.userData.school_id + '_group'];

                this.pubNub.channelGroups.addChannels({
                    channels: this.subscriptionList,
                    channelGroup: this.userData.user_id + '_' + this.userData.school_id + '_group',
                });
            } else {
                payload.channels = this.subscriptionList;
            }

            this.pubNub.subscribe(payload);
        }
    }

    private onlineHandle() {
        this.isOffline.next(false);
    }

    // offline handler
    private offlineHandle() {
        this.isOffline.next(true);
    }

    // add online offline listener
    public detectOffline() {
        window.ononline = this.onlineHandle.bind(this);
        window.onoffline = this.offlineHandle.bind(this);
    }

    // remove online offline listener
    public removeDetectOffline() {
        this.isOffline.next(false);
        window.ononline = null;
        window.onoffline = null;
    }

    public checkIfEngagementAllowed() {
        if (
            this.sclMD &&
            this.userData &&
            (this.sclMD.allow_engagement || this.userData.enable_features.allow_manual_proctoring) &&
            this.userData.enable_features &&
            (this.userData.enable_features.allow_engagement || this.userData.enable_features.allow_manual_proctoring)
        ) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function to Users Membership Data
     */
    public getMemberShips(filter?: any) {
        return new Promise(async (resolve, reject) => {
            if (this.pubNub) {
                const payload: any = {
                    uuid: this.userData.user_id + '_' + this.userData.school_id,
                    include: {
                        channelFields: true,
                        customFields: true,
                        customChannelFields: true,
                    },
                };
                if (filter) {
                    payload.filter = filter;
                }
                let response: any = {
                    data: []
                };
                let nextPageToken: string;
                let isDataFetched: boolean = false;
                let isErrorOccured: boolean = false;
                const fetchMembers = () => {
                    return new Promise((resolved, rejected) => {
                        if (nextPageToken) {
                            payload.page = {
                                next: nextPageToken
                            };
                        }
                        this.pubNub.objects
                            .getMemberships(payload)
                            .then((memberData: any) => {
                                if (memberData && memberData.data && memberData.status === 200) {
                                    response.data = response.data.concat(memberData.data);
                                }
                                resolved(memberData);
                            })
                            .catch((error: any) => {
                                rejected(error);
                            });
                    });
                }
                while (!isDataFetched) {
                    const result: any = await fetchMembers().catch((error) => {
                        reject(error);
                        isErrorOccured = true;
                    });
                    if (result.next &&
                        result.data &&
                        result.data.length >= 100 &&
                        result.next !== result.prev) {
                        nextPageToken = result.next;
                    } else {
                        isDataFetched = true;
                    }
                    if (isErrorOccured) {
                        break;
                    }
                }
                if (!isErrorOccured) {
                    resolve(response);
                }
            } else {
                resolve(false);
            }
        });
    }

    emoji={
        "grinning":"😀",
        "grin":"😁",
        "joy":"😂",
        "smiley":"😃",
        "smile":"😄",
        "sweat_smile":"😅",
        "face-with-tears-of-joy":"😂",
        "laughing":"😆",
        "satisfied":"😆",
        "innocent":"😇",
        "smiling_imp":"😈",
        "wink":"😉",
        "blush":"😊",
        "yum":"😋",
        "relieved":"😌",
        "heart_eyes":"😍",
        "sunglasses":"😎",
        "smirk":"😏",
        "neutral_face":"😐",
        "expressionless":"😑",
        "unamused":"😒",
        "sweat":"😓",
        "pensive":"😔",
        "confused":"😕",
        "confounded":"😖",
        "kissing":"😗",
        "kissing_heart":"😘",
        "kissing_smiling_eyes":"😙",
        "kissing_closed_eyes":"😚",
        "stuck_out_tongue":"😛",
        "stuck_out_tongue_winking_eye":"😜",
        "stuck_out_tongue_closed_eyes":"😝",
        "disappointed":"😞",
        "worried":"😟",
        "angry":"😠",
        "rage":"😡",
        "cry":"😢",
        "persevere":"😣",
        "triumph":"😤",
        "disappointed_relieved":"😥",
        "frowning":"😦",
        "anguished":"😧",
        "fearful":"😨",
        "weary":"😩",
        "sleepy":"😪",
        "tired_face":"😫",
        "grimacing":"😬",
        "sob":"😭",
        "open_mouth":"😮",
        "hushed":"😯",
        "cold_sweat":"😰",
        "scream":"😱",
        "astonished":"😲",
        "flushed":"😳",
        "sleeping":"😴",
        "dizzy_face":"😵",
        "no_mouth":"😶",
        "mask":"😷",
        "smile_cat":"😸",
        "joy_cat":"😹",
        "smiley_cat":"😺",
        "heart_eyes_cat":"😻",
        "smirk_cat":"😼",
        "kissing_cat":"😽",
        "pouting_cat":"😾",
        "crying_cat_face":"😿",
        "scream_cat":"🙀",
        "slightly_frowning_face":"🙁",
        "slightly_smiling_face":"🙂",
        "upside_down_face":"🙃",
        "face_with_rolling_eyes":"🙄",
        "100":"💯",
        "woman-gesturing-no":"🙅‍♀️",
        "no_good":"🙅‍♀️",
        "man-gesturing-no":"🙅‍♂️",
        "woman-gesturing-ok":"🙆‍♀️",
        "ok_woman":"🙆‍♀️",
        "man-gesturing-ok":"🙆‍♂️",
        "woman-bowing":"🙇‍♀️",
        "man-bowing":"🙇‍♂️",
        "bow":"🙇‍♂️",
        "see_no_evil":"🙈",
        "hear_no_evil":"🙉",
        "speak_no_evil":"🙊",
        "woman-raising-hand":"🙋‍♀️",
        "raising_hand":"🙋‍♀️",
        "man-raising-hand":"🙋‍♂️",
        "raised_hands":"🙌",
        "woman-frowning":"🙍‍♀️",
        "person_frowning":"🙍‍♀️",
        "man-frowning":"🙍‍♂️",
        "woman-pouting":"🙎‍♀️",
        "person_with_pouting_face":"🙎‍♀️",
        "man-pouting":"🙎‍♂️",
        "pray":"🙏",
        "rocket":"🚀",
        "helicopter":"🚁",
        "steam_locomotive":"🚂",
        "railway_car":"🚃",
        "bullettrain_side":"🚄",
        "bullettrain_front":"🚅",
        "train2":"🚆",
        "metro":"🚇",
        "light_rail":"🚈",
        "station":"🚉",
        "tram":"🚊",
        "train":"🚋",
        "bus":"🚌",
        "oncoming_bus":"🚍",
        "trolleybus":"🚎",
        "busstop":"🚏",
        "minibus":"🚐",
        "ambulance":"🚑",
        "fire_engine":"🚒",
        "police_car":"🚓",
        "oncoming_police_car":"🚔",
        "taxi":"🚕",
        "oncoming_taxi":"🚖",
        "car":"🚗",
        "red_car":"🚗",
        "oncoming_automobile":"🚘",
        "blue_car":"🚙",
        "truck":"🚚",
        "articulated_lorry":"🚛",
        "tractor":"🚜",
        "monorail":"🚝",
        "mountain_railway":"🚞",
        "suspension_railway":"🚟",
        "mountain_cableway":"🚠",
        "aerial_tramway":"🚡",
        "ship":"🚢",
        "woman-rowing-boat":"🚣‍♀️",
        "man-rowing-boat":"🚣‍♂️",
        "rowboat":"🚣‍♂️",
        "speedboat":"🚤",
        "traffic_light":"🚥",
        "vertical_traffic_light":"🚦",
        "construction":"🚧",
        "rotating_light":"🚨",
        "triangular_flag_on_post":"🚩",
        "door":"🚪",
        "no_entry_sign":"🚫",
        "smoking":"🚬",
        "no_smoking":"🚭",
        "put_litter_in_its_place":"🚮",
        "do_not_litter":"🚯",
        "potable_water":"🚰",
        "non-potable_water":"🚱",
        "bike":"🚲",
        "no_bicycles":"🚳",
        "woman-biking":"🚴‍♀️",
        "man-biking":"🚴‍♂️",
        "bicyclist":"🚴‍♂️",
        "woman-mountain-biking":"🚵‍♀️",
        "man-mountain-biking":"🚵‍♂️",
        "mountain_bicyclist":"🚵‍♂️",
        "woman-walking":"🚶‍♀️",
        "man-walking":"🚶‍♂️",
        "walking":"🚶‍♂️",
        "no_pedestrians":"🚷",
        "children_crossing":"🚸",
        "mens":"🚹",
        "womens":"🚺",
        "restroom":"🚻",
        "baby_symbol":"🚼",
        "toilet":"🚽",
        "wc":"🚾",
        "shower":"🚿",
        "bath":"🛀",
        "bathtub":"🛁",
        "passport_control":"🛂",
        "customs":"🛃",
        "baggage_claim":"🛄",
        "left_luggage":"🛅",
        "couch_and_lamp":"🛋️",
        "sleeping_accommodation":"🛌",
        "shopping_bags":"🛍️",
        "bellhop_bell":"🛎️",
        "bed":"🛏️",
        "place_of_worship":"🛐",
        "octagonal_sign":"🛑",
        "shopping_trolley":"🛒",
        "hammer_and_wrench":"🛠️",
        "shield":"🛡️",
        "oil_drum":"🛢️",
        "motorway":"🛣️",
        "railway_track":"🛤️",
        "motor_boat":"🛥️",
        "small_airplane":"🛩️",
        "airplane_departure":"🛫",
        "airplane_arriving":"🛬",
        "satellite":"🛰️",
        "passenger_ship":"🛳️",
        "scooter":"🛴",
        "motor_scooter":"🛵",
        "canoe":"🛶",
        "sled":"🛷",
        "flying_saucer":"🛸",
        "zipper_mouth_face":"🤐",
        "money_mouth_face":"🤑",
        "face_with_thermometer":"🤒",
        "nerd_face":"🤓",
        "thinking_face":"🤔",
        "face_with_head_bandage":"🤕",
        "robot_face":"🤖",
        "hugging_face":"🤗",
        "the_horns":"🤘",
        "sign_of_the_horns":"🤘",
        "call_me_hand":"🤙",
        "raised_back_of_hand":"🤚",
        "left-facing_fist":"🤛",
        "right-facing_fist":"🤜",
        "handshake":"🤝",
        "crossed_fingers":"🤞",
        "hand_with_index_and_middle_fingers_crossed":"🤞",
        "i_love_you_hand_sign":"🤟",
        "face_with_cowboy_hat":"🤠",
        "clown_face":"🤡",
        "nauseated_face":"🤢",
        "rolling_on_the_floor_laughing":"🤣",
        "drooling_face":"🤤",
        "lying_face":"🤥",
        "woman-facepalming":"🤦‍♀️",
        "man-facepalming":"🤦‍♂️",
        "face_palm":"🤦",
        "sneezing_face":"🤧",
        "face_with_raised_eyebrow":"🤨",
        "face_with_one_eyebrow_raised":"🤨",
        "star-struck":"🤩",
        "grinning_face_with_star_eyes":"🤩",
        "zany_face":"🤪",
        "grinning_face_with_one_large_and_one_small_eye":"🤪",
        "shushing_face":"🤫",
        "face_with_finger_covering_closed_lips":"🤫",
        "face_with_symbols_on_mouth":"🤬",
        "serious_face_with_symbols_covering_mouth":"🤬",
        "face_with_hand_over_mouth":"🤭",
        "smiling_face_with_smiling_eyes_and_hand_covering_mouth":"🤭",
        "face_vomiting":"🤮",
        "face_with_open_mouth_vomiting":"🤮",
        "exploding_head":"🤯",
        "shocked_face_with_exploding_head":"🤯",
        "pregnant_woman":"🤰",
        "breast-feeding":"🤱",
        "palms_up_together":"🤲",
        "selfie":"🤳",
        "prince":"🤴",
        "man_in_tuxedo":"🤵",
        "mrs_claus":"🤶",
        "mother_christmas":"🤶",
        "woman-shrugging":"🤷‍♀️",
        "man-shrugging":"🤷‍♂️",
        "shrug":"🤷",
        "woman-cartwheeling":"🤸‍♀️",
        "man-cartwheeling":"🤸‍♂️",
        "person_doing_cartwheel":"🤸",
        "woman-juggling":"🤹‍♀️",
        "man-juggling":"🤹‍♂️",
        "juggling":"🤹",
        "fencer":"🤺",
        "woman-wrestling":"🤼‍♀️",
        "man-wrestling":"🤼‍♂️",
        "wrestlers":"🤼",
        "woman-playing-water-polo":"🤽‍♀️",
        "man-playing-water-polo":"🤽‍♂️",
        "water_polo":"🤽",
        "woman-playing-handball":"🤾‍♀️",
        "man-playing-handball":"🤾‍♂️",
        "handball":"🤾",
        "wilted_flower":"🥀",
        "drum_with_drumsticks":"🥁",
        "clinking_glasses":"🥂",
        "tumbler_glass":"🥃",
        "spoon":"🥄",
        "goal_net":"🥅",
        "first_place_medal":"🥇",
        "second_place_medal":"🥈",
        "third_place_medal":"🥉",
        "boxing_glove":"🥊",
        "martial_arts_uniform":"🥋",
        "curling_stone":"🥌",
        "croissant":"🥐",
        "avocado":"🥑",
        "cucumber":"🥒",
        "bacon":"🥓",
        "potato":"🥔",
        "carrot":"🥕",
        "baguette_bread":"🥖",
        "green_salad":"🥗",
        "shallow_pan_of_food":"🥘",
        "stuffed_flatbread":"🥙",
        "egg":"🥚",
        "glass_of_milk":"🥛",
        "peanuts":"🥜",
        "kiwifruit":"🥝",
        "pancakes":"🥞",
        "dumpling":"🥟",
        "fortune_cookie":"🥠",
        "takeout_box":"🥡",
        "chopsticks":"🥢",
        "bowl_with_spoon":"🥣",
        "cup_with_straw":"🥤",
        "coconut":"🥥",
        "broccoli":"🥦",
        "pie":"🥧",
        "pretzel":"🥨",
        "cut_of_meat":"🥩",
        "sandwich":"🥪",
        "canned_food":"🥫",
        "crab":"🦀",
        "lion_face":"🦁",
        "scorpion":"🦂",
        "turkey":"🦃",
        "unicorn_face":"🦄",
        "eagle":"🦅",
        "duck":"🦆",
        "bat":"🦇",
        "shark":"🦈",
        "owl":"🦉",
        "fox_face":"🦊",
        "butterfly":"🦋",
        "deer":"🦌",
        "gorilla":"🦍",
        "lizard":"🦎",
        "rhinoceros":"🦏",
        "shrimp":"🦐",
        "squid":"🦑",
        "giraffe_face":"🦒",
        "zebra_face":"🦓",
        "hedgehog":"🦔",
        "sauropod":"🦕",
        "t-rex":"🦖",
        "cricket":"🦗",
        "cheese_wedge":"🧀",
        "face_with_monocle":"🧐",
        "adult":"🧑",
        "child":"🧒",
        "older_adult":"🧓",
        "bearded_person":"🧔",
        "person_with_headscarf":"🧕",
        "woman_in_steamy_room":"🧖‍♀️",
        "man_in_steamy_room":"🧖‍♂️",
        "person_in_steamy_room":"🧖‍♂️",
        "woman_climbing":"🧗‍♀️",
        "person_climbing":"🧗‍♀️",
        "man_climbing":"🧗‍♂️",
        "woman_in_lotus_position":"🧘‍♀️",
        "person_in_lotus_position":"🧘‍♀️",
        "man_in_lotus_position":"🧘‍♂️",
        "female_mage":"🧙‍♀️",
        "mage":"🧙‍♀️",
        "male_mage":"🧙‍♂️",
        "female_fairy":"🧚‍♀️",
        "fairy":"🧚‍♀️",
        "male_fairy":"🧚‍♂️",
        "female_vampire":"🧛‍♀️",
        "vampire":"🧛‍♀️",
        "male_vampire":"🧛‍♂️",
        "mermaid":"🧜‍♀️",
        "merman":"🧜‍♂️",
        "merperson":"🧜‍♂️",
        "female_elf":"🧝‍♀️",
        "male_elf":"🧝‍♂️",
        "elf":"🧝‍♂️",
        "female_genie":"🧞‍♀️",
        "male_genie":"🧞‍♂️",
        "genie":"🧞‍♂️",
        "female_zombie":"🧟‍♀️",
        "male_zombie":"🧟‍♂️",
        "zombie":"🧟‍♂️",
        "brain":"🧠",
        "orange_heart":"🧡",
        "billed_cap":"🧢",
        "scarf":"🧣",
        "gloves":"🧤",
        "coat":"🧥",
        "socks":"🧦",
        "bangbang":"‼️",
        "interrobang":"⁉️",
        "tm":"™️",
        "information_source":"ℹ️",
        "left_right_arrow":"↔️",
        "arrow_up_down":"↕️",
        "arrow_upper_left":"↖️",
        "arrow_upper_right":"↗️",
        "arrow_lower_right":"↘️",
        "arrow_lower_left":"↙️",
        "leftwards_arrow_with_hook":"↩️",
        "arrow_right_hook":"↪️",
        "watch":"⌚",
        "hourglass":"⌛",
        "keyboard":"⌨️",
        "eject":"⏏️",
        "fast_forward":"⏩",
        "rewind":"⏪",
        "arrow_double_up":"⏫",
        "arrow_double_down":"⏬",
        "black_right_pointing_double_triangle_with_vertical_bar":"⏭️",
        "black_left_pointing_double_triangle_with_vertical_bar":"⏮️",
        "black_right_pointing_triangle_with_double_vertical_bar":"⏯️",
        "alarm_clock":"⏰",
        "stopwatch":"⏱️",
        "timer_clock":"⏲️",
        "hourglass_flowing_sand":"⏳",
        "double_vertical_bar":"⏸️",
        "black_square_for_stop":"⏹️",
        "black_circle_for_record":"⏺️",
        "m":"Ⓜ️",
        "black_small_square":"▪️",
        "white_small_square":"▫️",
        "arrow_forward":"▶️",
        "arrow_backward":"◀️",
        "white_medium_square":"◻️",
        "black_medium_square":"◼️",
        "white_medium_small_square":"◽",
        "black_medium_small_square":"◾",
        "sunny":"☀️",
        "cloud":"☁️",
        "umbrella":"☂️",
        "snowman":"☃️",
        "comet":"☄️",
        "phone":"☎️",
        "telephone":"☎️",
        "ballot_box_with_check":"☑️",
        "shamrock":"☘️",
        "point_up":"☝️",
        "skull_and_crossbones":"☠️",
        "radioactive_sign":"☢️",
        "biohazard_sign":"☣️",
        "orthodox_cross":"☦️",
        "star_and_crescent":"☪️",
        "peace_symbol":"☮️",
        "yin_yang":"☯️",
        "wheel_of_dharma":"☸️",
        "white_frowning_face":"☹️",
        "relaxed":"☺️",
        "female_sign":"♀️",
        "male_sign":"♂️",
        "gemini":"♊",
        "cancer":"♋",
        "leo":"♌",
        "virgo":"♍",
        "libra":"♎",
        "scorpius":"♏",
        "spades":"♠️",
        "clubs":"♣️",
        "hearts":"♥️",
        "diamonds":"♦️",
        "hotsprings":"♨️",
        "recycle":"♻️",
        "wheelchair":"♿",
        "hammer_and_pick":"⚒️",
        "crossed_swords":"⚔️",
        "medical_symbol":"⚕️",
        "staff_of_aesculapius":"⚕️",
        "scales":"⚖️",
        "alembic":"⚗️",
        "gear":"⚙️",
        "atom_symbol":"⚛️",
        "fleur_de_lis":"⚜️",
        "warning":"⚠️",
        "zap":"⚡",
        "white_circle":"⚪",
        "black_circle":"⚫",
        "coffin":"⚰️",
        "funeral_urn":"⚱️",
        "soccer":"⚽",
        "baseball":"⚾",
        "snowman_without_snow":"⛄",
        "partly_sunny":"⛅",
        "thunder_cloud_and_rain":"⛈️",
        "ophiuchus":"⛎",
        "pick":"⛏️",
        "helmet_with_white_cross":"⛑️",
        "chains":"⛓️",
        "no_entry":"⛔",
        "shinto_shrine":"⛩️",
        "church":"⛪",
        "mountain":"⛰️",
        "umbrella_on_ground":"⛱️",
        "fountain":"⛲",
        "golf":"⛳",
        "ferry":"⛴️",
        "boat":"⛵",
        "sailboat":"⛵",
        "skier":"⛷️",
        "ice_skate":"⛸️",
        "woman-bouncing-ball":"⛹️‍♀️",
        "man-bouncing-ball":"⛹️‍♂️",
        "person_with_ball":"⛹️‍♂️",
        "tent":"⛺",
        "fuelpump":"⛽",
        "scissors":"✂️",
        "airplane":"✈️",
        "envelope":"✉️",
        "fist":"✊",
        "hand":"✋",
        "raised_hand":"✋",
        "v":"✌️",
        "writing_hand":"✍️",
        "pencil2":"✏️",
        "black_nib":"✒️",
        "heavy_check_mark":"✔️",
        "heavy_multiplication_x":"✖️",
        "latin_cross":"✝️",
        "star_of_david":"✡️",
        "eight_spoked_asterisk":"✳️",
        "eight_pointed_black_star":"✴️",
        "snowflake":"❄️",
        "sparkle":"❇️",
        "x":"❌",
        "negative_squared_cross_mark":"❎",
        "heavy_heart_exclamation_mark_ornament":"❣️",
        "heart":"❤️",
        "arrow_right":"➡️",
        "curly_loop":"➰",
        "loop":"➿",
        "arrow_heading_up":"⤴️",
        "arrow_heading_down":"⤵️",
        "arrow_left":"⬅️",
        "arrow_up":"⬆️",
        "arrow_down":"⬇️",
        "black_large_square":"⬛",
        "white_large_square":"⬜",
        "star":"⭐",
        "o":"⭕",
        "wavy_dash":"〰️",
        "part_alternation_mark":"〽️",
        "congratulations":"㊗️",
        "secret":"㊙️",
        "soap":"🧼",
        "virus":"🦠",
        "thumbsup_2":"👍🏾",
        "thumbsup_3":"👍🏽",
        "fist_1":"✊🏾",
        "fist_2":"✊🏿"
     }

     getEmoji(){
        return this.emoji;
     }

    /**
     * Function to get Unread Message Counts
     * @param payload Request Payload
     */
    public getUnreadMessageCount(payload: { channels: string[]; channelTimetokens: string[] }) {
        return new Promise((resolve, reject) => {
            if (this.pubNub) {
                this.pubNub
                    .messageCounts(payload)
                    .then((result: any) => {
                        resolve(result);
                    })
                    .catch((error: any) => {
                        reject(error);
                    });
            }
        });
    }
}
