import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoginService } from './login.service';
import { catchError } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
@Injectable({
    providedIn: 'root',
})
export class DriveService {
    api = environment.HOST.MS_drives;
    backend_api_url = environment.HOST.link;
    public drivesNameFormatView: any;
    public drivesNameFormat = {
        drivesNamelist: [
            { label: 'Drives', value: 'drives', singler: 'Drive' },
            { label: 'Jobs', value: 'job', singler: 'Job' }
        ],
        drivesOptinlist: [
            { label: 'Opt-In', value: 'optin', doneState:'Opted-In' },
            { label: 'Apply', value: 'apply', doneState:'Applied' }          
        ],
        drivesOptOutlist: [
            { label: 'Opt-Out', value: 'optout' , doneState:'Opted-Out'},
            { label: 'Decline', value: 'decline', doneState:'Declined'}          
        ],
        drivesCategorylist: [
            { label: 'Drive Category ', value: 'drivecategory' },
            { label: 'Company Category', value: 'companycategaory' }
        ],
        drivesOtherDetailslist: [
            { label: 'Drive other details', value: 'driveotherdetails' },
            { label: 'Change to Company / Job details', value: 'changetoCompanyjobdetails' }
        ],
        drivesSalarylist: [
            { label: 'Salary', value: 'salary' },
            { label: 'CTC', value: 'ctc' }
        ]
    };
    notprovidedtext: any = 'Not Provided';
    constructor(private http: HttpClient, public loginservice: LoginService, public globalService: GlobalService) {
        const schoolData = JSON.parse(localStorage.getItem('school_details'));
        this.drivesNameFormatView = {
            drivesNamelist: this.drivesNameFormat.drivesNamelist.find((each: any) => {
                return (schoolData.schools_metadata.defaultdrivenameformat)
                ? (each.value === schoolData.schools_metadata.defaultdrivenameformat)
                : each.value === this.drivesNameFormat.drivesNamelist[0].value;
            }),
            drivesOptinlist: this.drivesNameFormat.drivesOptinlist.find((each: any) => {
                return (schoolData.schools_metadata.defaultdriveoptinformat) ?
                each.value === schoolData.schools_metadata.defaultdriveoptinformat
                : each.value === this.drivesNameFormat.drivesOptinlist[0].value;
            }),
            drivesOptOutlist: this.drivesNameFormat.drivesOptOutlist.find((each: any) => {
                return (schoolData.schools_metadata.defaultdriveoptoutformat) ?
                each.value === schoolData.schools_metadata.defaultdriveoptoutformat
                : each.value === this.drivesNameFormat.drivesOptOutlist[0].value;
            }),
            drivesCategorylist: this.drivesNameFormat.drivesCategorylist.find((each: any) => {
                return (schoolData.schools_metadata.defaultdrivecategoryformat) ?
                each.value === schoolData.schools_metadata.defaultdrivecategoryformat
                : each.value === this.drivesNameFormat.drivesCategorylist[0].value;
            }),
            drivesOtherDetailslist: this.drivesNameFormat.drivesOtherDetailslist.find((each: any) => {
                return (schoolData.schools_metadata.defaultdriveotherdetailsformat) ?
                each.value === schoolData.schools_metadata.defaultdriveotherdetailsformat
                : each.value === this.drivesNameFormat.drivesOtherDetailslist[0].value;
            }),
            drivesSalarylist: this.drivesNameFormat.drivesSalarylist.find((each: any) => {
                return (schoolData.schools_metadata.defaultdrivesalaryformat) ?
                each.value === schoolData.schools_metadata.defaultdrivesalaryformat
                : each.value === this.drivesNameFormat.drivesSalarylist[0].value;
            })
        };
    }
    getDriveList(payload, u_id) {
        return this.http
            .post(this.api + '/v2/student/' + u_id + '/drives/filter', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }
    getDriveDetails(payload) {
        return this.http
            .post(this.api + '/drive/student/drivedetails', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }

    getDriveStudentDetails(payload) {
        return this.http
            .post(this.api + '/drive/student/details', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }
    getDriveEligibility(payload) {
        return this.http
            .post(this.api + '/drive/historyppa', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }
    getAppliedhistoryppa(payload) {
        return this.http
            .post(this.api + '/drive/appliedhistoryppa', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }
    getAdditionalEligibility(payload) {
        return this.http
            .get(this.api + '/drive/eligibile/' + payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }
    getFilters(type, school_id) {
        return this.http
            .get(this.api + '/drive/' + type + '/' + school_id, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }

    getDriveBadges(user_id) {
        return this.http
            .get(this.api + '/drive/getDriveDetailsForStudents/' + user_id, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }

    collegeApplyStudents(payload) {
        return this.http
            .post(this.api + '/drive/apply/student', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }
    setNotInterested(payload) {
        return this.http
            .post(this.api + '/drive/set/notInterested', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }

    uploadoffer(payload) {
        return this.http
            .post(this.api + '/add/offerletter', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }

    getSignedUrl(payload) {
        const pay = this.backend_api_url + '/getsignedurl';
        return this.http
            .post(pay, payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }

    uploadUsingSignedUrl(url, payload) {
        return this.http.put(url, payload);
    }

    attendancesystem(payload) {
        return this.http.post(this.api + '/drive/attendancesystem', payload, this.loginservice.options(null));
    }
    GetDrivestudentList(payload) {
        return this.http.post(this.api + '/drive/GetDrivestudentList', payload, this.loginservice.options(null));
    }
    GetStudentdrivedetails(payload) {
        return this.http.post(this.api + '/drive/getstudentdrivedetails', payload, this.loginservice.options(null));
    }
    updateStudentdrive(payload) {
        return this.http.post(this.api + '/drive/updatestudentdrivedetails', payload, this.loginservice.options(null));
    }
    additionalDetailsStudents(payload) {
        return this.http.post(this.api + '/drives/driveStudentAdditionalDetails', payload, this.loginservice.options(null));
    }
    driveWarning(payload) {
        return this.http
            .post(this.api + '/drive/driveWarning', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }
    getDriveStudentBranch(payload){
        return this.http
            .post(this.api + '/drive/student_branch', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }
    addDriveStudentResume(payload){
        return this.http
            .post(this.api + '/drive/addDriveStudentResume', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }
    studentResumePublicURL(payload){
        return this.http
            .post(this.api + '/drive/studentResumePublicURL', payload, this.loginservice.options(null))
            .pipe(catchError(this.loginservice.handleError.bind(this)));
    }
}
