import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { GrowlModule } from './atoms/shared/growl/growl.module';

import { GitRedirectComponent } from './containers/git-redirect/redirect.component';
import { AuthGuard } from './gaurds/auth.gaurd';
import { NetworkGuard } from './gaurds/network.gaurd';
import { ProfileGuard } from './gaurds/profile.guard';
import { PublicGuard } from './gaurds/public.gaurd';
import { TwoDigitPipe } from './pipes/twodigitpipe';

import { ButtonModule } from './atoms/shared/button/button.module';
import { LoaderModule } from './atoms/shared/loader/loader.module';
import { GithubRedirectHandlerComponent } from './components/github-redirect-handler/github-redirect-handler.component';

import { HeaderComponent } from './components/header/header.component';
import { SearchBarModule } from './atoms/shared/search-bar/search-bar.module';
import { PublicSidebarComponent } from './components/public-sidebar/public-sidebar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { GlobalErrorHandler } from './error';
import {InputTextModule} from 'primeng/inputtext';
import { DialogModule } from 'src/app/atoms/shared/dialog/dialog.module';
import { DialogSidebarModule } from './atoms/shared/dialog-sidebar/dialog-sidebar.module';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import { BannerModule } from './atoms/shared/banner/banner.module';


const importArray: any = [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GrowlModule,
    LoaderModule,
    ButtonModule,
    InputTextModule,
    SearchBarModule,
    DialogModule,
    DialogSidebarModule,
    BannerModule,
    RoundProgressModule
];

if (!navigator.userAgent.includes('SEB')) {
    importArray.push(ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }));
}
@NgModule({
    imports: importArray,
    declarations: [AppComponent, TwoDigitPipe, GitRedirectComponent, GithubRedirectHandlerComponent, SidebarComponent, PublicSidebarComponent, HeaderComponent],
    providers: [
        AuthGuard,
        PublicGuard,
        NetworkGuard,
        ProfileGuard,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
    ],

    bootstrap: [AppComponent],

    exports: [

    ],
})
export class AppModule {}
