import {
    Component,
    Output,
    EventEmitter,
    Input,
    ViewEncapsulation,
    OnChanges,
    SimpleChange,
    ViewChild,
    Renderer2,
    ElementRef,
} from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnChanges {
    @ViewChild('button', { static: true }) button: ElementRef;

    @Output() but = new EventEmitter();
    @Input() disable: boolean;
    @Input() loading: boolean;
    @Input() btnProperties: any = '';
    @Input() padding: any = '';
    @Input() mobile: any = '';
    @Input() icons: any;
    @Input() fontSize: any;
    @Input() background: any = '';
    @Input() fontColor: any = '';
    @Input() border: any = '';
    @Input() classNames: string;
    @Input() id: any;
    @Input() tabindex: any;
    @Input() authBtn: boolean;
    bty: any = { 'font-size': '12px' };
    constructor(private renderer: Renderer2) {
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        for (let propName in changes) {
            if (propName === 'loading') {
                if(this.button){
                    this.loadingHandle();
                }
            }
        }
    }
        private loadingHandle() {
        if (this.loading) {
            this.renderer.setStyle(this.button.nativeElement, 'cursor', 'not-allowed');
        } else {
            this.renderer.setStyle(this.button.nativeElement, 'cursor', 'pointer');
        }
    }

    emitCall(type: string) {
        if (!this.loading || !this.disable) {
            this.but.emit({ valid: true, type: type });
        }
    }

// Set Dynamic Classes
setClasses() {
        let butIcon=this.icons?'butIcon':'';
        let disableBtn= this.disable?'disabled':'';
        let customClasses=!this.classNames?'submitBtn formSubmit':this.classNames;
    return butIcon+' '+disableBtn+' '+customClasses;
  }  
}
