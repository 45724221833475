import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalService } from '@services/global.service';
import { LoginService } from '@services/login.service';

import { environment } from '../../environments/environment';

// packages
declare let MathJax: any;
declare let CryptoJS: any;
declare let JitsiMeetExternalAPI: any;
declare let QRCode: any;
declare let AWS: any;
declare let StackBlitzSDK: any;
declare let ace: any;
declare let RecordRTC: any;
declare let firebase: any;
declare let Quill: any;
declare let DiagramEditor: any;
declare let posenet: any;
declare let speechCommands: any;
declare let Blockly: any;
declare let faceapi: any;
declare let blazeface: any;
declare let Twilio :any;
// debugger function
declare let createAllVisualizersFromHtmlAttrs: any;

type FileType = 'image'|'audio'|'video' | 'screen';

export interface TestTakingData {
    u_name?: string;
    email?: string;
    drive_id?: number;
    contest_id?: string;
    c_id: string;
    c_name: string;
    c_modules: any;
    c_type:string;
    others?:any;
    course_progress: number;
    test_count: number;
    entity: string;
    attempt_no: number;
    attempt_type?: string;
    instanceTime?: Date;
    t_id: string;
    t_name: string;
    t_type: string;
    isTestruletype?: string;
    test_type?: string;
    template_data?: any;
    t_marks?: number;
    t_total_marks?: number;
    t_total_duration?: number;
    t_duration?: string;
    t_counter?: number;
    t_start_time?: Date;
    t_submit_time?: Date;
    test_tracking_data?: {
        audio_proctor_analysis?: any[];
    }
    retakeenable?: boolean;
    client_data?: {
        OS?: {
            name: string;
            version: string;
        };
        browser?: {
            name: string;
            major: string;
            version: string;
        };
        Public_IP?: string;
        tabswitched?: number;
        suspicious_scr_shots?: string[];
        hasExternalMonitor?: number;
        proctoring_alerts?: number;
        total_proc_img?: number;
        resume_no?: number;
        exp_resume?: number;
        submit_type?: string;
        resume_time?: Date;
        liveness_alerts?: number;
        restarted_by?: string;
        session_id?: string;
        ltiData?: string;   
        offScreen?: number;
        user_ID_proof? : string;
        copyPasted?: number;
        answer_s3_link?:any;
        pdfName?:any
    };
    frozen_data?: {
        client_data?: any;
        test_tracking_data?: any;
        frozen_test_data: any[];
        resume_question?: any;
        id: number;
    };
    current_q?: any;
    attempts?: any[];
    submit_proctoring?: boolean;
    section_report?: any[];
    mod_id?: any;
    sub_mod_id?: any;
    ct_id?: number;
    manual_proctoring?: any;
    internal_teststart_metrics?: any;
    max_edit_allowed?: any;
    curr_submit_count?: any;
    editAfterSectionComplete?: boolean;
    isExternal?: boolean;
    base_image?: any;
    first_image?: any;
    public_analysis?: boolean;
    isForceSubmit?: boolean;
    daily_time_spent?: number;
    c_marks_data?:any;
    compile_total?:any;
    submit_total?:any;
    compile_count?: any;
    submit_count?:any;
}

export interface ProjectData {
    ip?: string;
    domain?: string;
    c_id: string;
    project_id: string;
    name: string;
    c_type:string;
    project_data: string;
    pb_id?: string;
    sub_topic_id?: string;
    topic_id?: string;
    subject_id?: string;
    themes?: string[];
    image?: string;
    node_pool?: string;
    createdBy?: string;
    has_auto_evaluation: boolean;
    daily_time_spent?: number;
    save_to_git?: boolean,
    code_quality_report?: boolean,
    instanceTime?: Date;
    student_course_project?: {
        id: number;
        user_id: string;
        c_id: string;
        project_id: string;
        isactiveNow: boolean;
        notes: string;
        createdAt?: string;
        p_marks: number;
        p_total_marks: number;
        p_status: number;
        test_case_wise_marks: any;
        start_time: string;
        submit_time: string;
        attempt_no: number;
    };
}

@Injectable({
    providedIn: 'root',
})
export class TestService {
    // packages
    private MathJax: any;
    private CryptoJS: any;
    private JitsiMeetExternalAPI: any;
    private QRCode: any;
    private AWS: any;
    private createAllVisualizersFromHtmlAttrs: any;
    private RecordRTC: any;
    private ace: any;
    private StackBlitzSDK: any;
    private firebase: any;
    private Quill: any;
    private DiagramEditor: any;
    private posenet: any;
    private speechCommands: any;
    private Blockly: any;
    private faceapi: any;
    private blazeFace: any;

    private api_link = environment.HOST.link;
    private recognLink = environment.HOST.RECOGN_LINK;
    private cloud_provider = environment.HOST.CLOUD_PROVIDER;
    private compile_url = environment.program_compiler_handler.cloudUrl;
    private essay_compile_url = environment.essay_compiler_handler.cloudUrl;
    private presentationAvailability: any;
    public copiedText: any;
    public disableCopyPaste: boolean;
    public userData: any;
    public schoolData: any;
    public studentData: any;
    public ppaData: any;
    public testData: TestTakingData;
    public projectData: ProjectData;
    public timeOutSub: any;
    public leaderBoardConstants: any;
    public programmingLangs = [
        { label: 'Bash', value: 'Bash', aceCode: 'csharp', mainFile: 'file.sh', code: 11 },
        { label: 'C#', value: 'C#', aceCode: 'csharp', mainFile: 'file.cs', code: 10 },
        { label: 'C', value: 'C', aceCode: 'c_cpp', mainFile: 'file.cpp', code: 21 },
        { label: 'C++', value: 'C++', aceCode: 'c_cpp', mainFile: 'file.cpp', code: 7 },
        // { label: 'Clojure (1.4)', value: 'Clojure', aceCode: 'clojure', mainFile: 'file.clj', code: 2 },
        { label: 'Go', value: 'Go', aceCode: 'golang', mainFile: 'file.go', code: 6 },
        { label: 'Java', value: 'Java', aceCode: 'java', mainFile: 'file.java', code: 8 },
        { label: 'Java JDBC connectivity', value: 'Java_jdbc', aceCode: 'java', mainFile: 'file.java', code: 19 },
        { label: 'MySQL', value: 'MySQL', aceCode: 'mysql', mainFile: 'file.sql', code: 13 },
        // { label: 'Objective-C (4.8)', value: 'Objective-C', aceCode: 'objectivec', mainFile: 'file.m', code: 12 },
        { label: 'Perl', value: 'Perl', aceCode: 'perl', mainFile: 'file.pl', code: 14 },
        { label: 'PHP', value: 'PHP', aceCode: 'php', mainFile: 'file.php', code: 3 },
        { label: 'Plain Javascript', value: 'Plain Javascript', aceCode: 'javascript',mainFile: 'file.js',code: 4,},
        { label: 'Python', value: 'Python', aceCode: 'python', mainFile: 'file.py', code: 0 },
        { label: 'Ruby', value: 'Ruby', aceCode: 'ruby', mainFile: 'file.rb', code: 1 },
        { label: 'Rust', value: 'Rust', aceCode: 'rust', mainFile: 'file.rs', code: 15 },
        { label: 'VB.NET', value: 'VB.NET', aceCode: 'vbscript', mainFile: 'file.vb', code: 9 },
        // { label: 'R', value: 'R', aceCode: 'r', mainFile: 'file.r', code: 16 },
        { label: 'MySQL Verbose', value: 'MySQL Verbose', aceCode: 'mysql', mainFile: 'file.sql', code: 18 },
        { label: 'C (std=c++17)', value: 'Cc++11', aceCode: 'c_cpp', mainFile: 'file.cpp', code: 20 },
        { label: 'C++ (std=c++17)', value: 'C++c++11', aceCode: 'c_cpp', mainFile: 'file.cpp', code: 20 },
    ];
    public progFileBasedLangs = [
        {
            label: 'Java',
            value: 'Java',
            aceCode: 'java',
            defaultFiles: [{ label: 'Main.java', data: '', id: 'main', blockdelete: true }],
        },
        {
            label: 'JDBC',
            value: 'Javadb',
            aceCode: 'java',
            defaultFiles: [{ label: 'Main.java', data: '', id: 'main', blockdelete: true }],
        },
        {
            label: 'Python',
            value: 'Python',
            aceCode: 'python',
            defaultFiles: [{ label: 'main.py', data: '', id: 'main', blockdelete: true }],
        },
        // {
        //     label: 'Python with mysql',
        //     value: 'Pythondb',
        //     aceCode: 'python',
        //     defaultFiles: [{ label: 'main.py', data: '', id: 'main', blockdelete: true }],
        // },
        {
            label: 'Python Datascience',
            value: 'PyDatascience',
            aceCode: 'python',
            defaultFiles: [{ label: 'main.py', data: '', id: 'main', blockdelete: true }],
        },
        // {
        //     label: 'R',
        //     value: 'R',
        //     aceCode: 'r',
        //     defaultFiles: [{ label: 'main.R', data: '', id: 'main', blockdelete: true }],
        // },
        {
            label: 'JavaScript',
            value: 'JavaScript',
            aceCode: 'javascript',
            defaultFiles: [{ label: 'index.js', data: '', id: 'main', blockdelete: true }],
        },
        {
            label: 'Verilog',
            value: 'Verilog',
            aceCode: 'Verilog',
            defaultFiles: [
                { label: 'code.v', data: '', id: 'main', blockdelete: true },
                { label: 'codetb.v', data: '', id: 'testbench', blockdelete: true },
            ],
        },
        // {
        //     label: 'Vhdl',
        //     value: 'Vhdl',
        //     aceCode: 'Vhdl',
        //     defaultFiles: [
        //         { label: 'code.vhdl', data: '', id: 'main', blockdelete: true },
        //         { label: 'codetb.vhdl', data: '', id: 'testbench', blockdelete: true },
        //     ],
        // },
        {
            label: 'JavaScript mongodb',
            value: 'JavaScriptMongo',
            aceCode: 'javascript',
            defaultFiles: [{ label: 'index.js', data: '', id: 'main', blockdelete: true }],
        },
        {
            label: 'Mongodb',
            value: 'Mongo',
            aceCode: 'javascript',
            defaultFiles: [{ label: 'mongo.js', data: '', id: 'main', blockdelete: true }],
        },
        // {
        //     label: 'Java with Oracledb',
        //     value: 'JavaOracledb',
        //     aceCode: 'java',
        //     defaultFiles: [{ label: 'Main.java', data: '', id: 'main', blockdelete: true }],
        // },
        // {
        //     label: 'Oracledb Standalone',
        //     value: 'Oracledb',
        //     aceCode: 'mysql',
        //     defaultFiles: [{ label: 'oracle.sql', data: '', id: 'main', blockdelete: true }],
        // },
        // {
        //     label: 'DotNET with MsSQL',
        //     value: 'DotNetMssql',
        //     aceCode: 'DotNetMssql',
        //     defaultFiles: [{ label: 'Program.cs', data: '', id: 'main', blockdelete: true }],
        // }
        // ,
        // {
        //     label: 'SQL Server (2019)',
        //     value: 'SqlServer',
        //     aceCode: 'mysql',
        //     defaultFiles: [{ label: 'main.sql', data: '', id: 'main', blockdelete: true }],
        // },
        {
            label: 'C++',
            value: 'CPP',
            aceCode: 'cpp',
            defaultFiles: [{ label: 'Main.cpp', data: '', id: 'main', blockdelete: true }],
        },
        // {
        //     label: 'Cassandra Standalone',
        //     value: 'Cassandra',
        //     aceCode: 'Cassandra',
        //     defaultFiles: [{ label: 'main.cql', data: '', id: 'main', blockdelete: true }],
        // },
        // {
        //     label: 'Java Cassandra Connectivity',
        //     value: 'JavaCassandra',
        //     aceCode: 'java',
        //     defaultFiles: [{ label: 'Main.java', data: '', id: 'main', blockdelete: true }],
        // },
        {
            label: 'MySQL',
            value: 'MySQL',
            aceCode: 'mysql',
            defaultFiles: [{ label: 'file.sql', data: '', id: 'main', blockdelete: true }],
        },
        // {
        //     label: 'PostgreSQL (13.6)',
        //     value: 'PSQL',
        //     aceCode: 'mysql',
        //     defaultFiles: [{ label: 'main.sql', data: '', id: 'main', blockdelete: true }],
        // },
        {
            label: 'Gnu Prolog',
            value: 'GnuProlog',
            aceCode: 'prolog',
            defaultFiles: [{ label: 'main.pg', data: '', id: 'main', blockdelete: true }],
        },
        {
            label: 'Cobol',
            value: 'Cobol',
            aceCode: 'cobol',
            defaultFiles: [{ label: 'main.cbl', data: '', id: 'main', blockdelete: true }],
        },
        {
            label: 'Scala',
            value: 'Scala',
            aceCode: 'scala',
            defaultFiles: [{ label: 'Main.scala', data: '', id: 'main', blockdelete: true }],
        }
    ];
    public complexities = [
        {
            label: 'O(1)',
            value: 1,
        },
        {
            label: 'O(loglog N)',
            value: 2,
        },
        {
            label: 'O(log N)',
            value: 3,
        },
        {
            label: 'O(N)',
            value: 4,
        },
        {
            label: 'O(N log N)',
            value: 5,
        },
        {
            label: 'O(N^2)',
            value: 6,
        },
        {
            label: 'O(N^C)',
            value: 7,
        },
    ];
    public editor_theme = 'ace/theme/monokai';
    public screenShareMedia: MediaStream;
    public isOffline = new BehaviorSubject<boolean>(false);
    public isPresentationChange = new BehaviorSubject<number>(0);
    public isWindowChange = new BehaviorSubject<boolean>(false);
    public isWindowResize = new BehaviorSubject<boolean>(false);
    public updateQuestionCount = new BehaviorSubject<boolean>(false);
    public topicAnalysisDisable = ['6e9f386c-f4f2-4f17-a400-84830a96c52d', '69cb8138-b47b-4cd0-98b8-44dbd5da2b3c'];
    public markDisable = ['b105beab-33a5-41df-b8e2-943e10d1eb76'];
    public projectHeaderDisable = ['28eded5d-1a5f-4ff9-97b2-d40ca32ab5f6', 'fa4cb1e4-ce65-4288-91e0-1468e724cc56'];
    public embedSchoolIds = ['b105beab-33a5-41df-b8e2-943e10d1eb76', 'f061d0d2-2b67-4249-85a4-1a5a845c274b', 'c1201bea-e18b-49e9-a604-dd3d96f40042', 'de1e754f-d84c-45f3-a27a-2a381be77e09', '99449c5f-0ee5-4211-a326-7881cb9e4387'];
    public crispVideoEnabledSchools = ['158608c5-6868-4850-8baf-f1da3d041bb2', '2d016bdf-3b5f-4482-bcac-fe60e17cfa41', '513a4bac-95a4-4863-8b8b-7a0f38c172c5', '29d3ca55-dccd-4151-9f73-a8dd1c81d7ec'];
    public ipSkipSchools = ['f515cb79-3b45-4acf-a78b-d972f34b31df', 'fe6502f0-dfe5-4ec3-b181-3e8e34b19894'];
    public lockTestParams: any;
    public previousAttemptId: number;
    public previousAttemptNo: number;
    public isTestPaused = false;
    public redirectToInstaSchools = ['ea11b177-c518-4467-9c3f-e721e26506a8', '781936e1-7cba-4666-a975-0a7ec447cace'];
    public driveCustomLables = ["781936e1-7cba-4666-a975-0a7ec447cace","d5986deb-f358-4156-8ac0-373bd93591ae"];
    private TwilioMeetExternalAPI: any;
    CourseURL? : any;
    // posenet configuration settings
    public POSENET_CONFIG = {
        decodingMethod: 'multi-person',
        flipPoseHorizontal: true,
        minPoseConfidence: 0.25, // 0.15
        minPartConfidence: 0.15, // 0.1
        maxPoseDetections: 5,
        nmsRadius: 30.0,
        showVideo: false,
        showPoints: false,
    };
    // network speed test data size in MB
    public NET_SPEED_TEST_SIZE = 2;
    // proctoring image local indexedDB settings
    public PROC_DB_CONFIG: {
        dbName: string;
        objectName: string;
        maxImgCount: number;
        db: IDBDatabase;
    } = {
        dbName: 'data',
        objectName: 'pi',
        maxImgCount: 30,
        db: undefined,
    };
    // proctoring media error config
    public PROC_ERR_CONFIG: {
        reconnectMaxCount: number;
        reconnectInterval: number; // seconds
    } = {
        reconnectMaxCount: 3,
        reconnectInterval: 300,
    };
    private webcamBlackList = ['OBS Virtual Camera', 'AlterCam Virtual Camera'];
    public baseImage: string;
    public FACEAPI_CONFIG: any = {
        inputSize: 320,
        scoreThreshold: 0.5,
        distance: 0.5,
        schoolIds: [
            '158608c5-6868-4850-8baf-f1da3d041bb2',
            '513a4bac-95a4-4863-8b8b-7a0f38c172c5',
            '3caaf750-d2be-4174-97f5-ee646ad838d3',
            '5fd4397f-29d0-47b3-b877-245b55f6fcb3',
        ]
    };
    public testStartMetrics: any;
    public externalScoresArray: any = ['learn.wileynxt', 'itrackglobal', 'www.wileynxt.com', 'uat.wileynxt.com'];
    public BLAZEFACE_CONFIG: any = {
        minPoseConfidence: 0.95,
        minAngleConfidence: 25,
        MODEL_HEAD_EAR_COORD_X: 0.7,
        MODEL_HEAD_RIGHT_EYE_COORD: [ 0.3, 0.3, 0.7 ],
        MODEL_HEAD_BOUNDING_SPHERE_CENTER_COORD: [ 0, 0.3, 0.3 ],
        MODEL_HEAD_BOUNDING_SPHERE_RADIUS: 1.35,
    };

    public clipboard_data:any;
    currAnswer:any;
    userscreenHeight: number;
    skipscreenCheck:boolean;
    allowcopycheck: boolean;

    constructor(private http: HttpClient, public globalService: GlobalService, private ls: LoginService, private router: Router) {
        this.setUserDetails();
    }

    public setUserDetails() {
        this.userData = JSON.parse(localStorage.getItem('token'));
        this.schoolData = JSON.parse(localStorage.getItem('school_details'));
        this.studentData = JSON.parse(localStorage.getItem('studentData'));
        this.ppaData = JSON.parse(localStorage.getItem('ppaData'));
    }

    // remove copy paste handler
    public removeCopyPasteHandler() {

        window.removeEventListener('copy', this.handleCopy, true);
        window.removeEventListener('cut', this.handleCopy, true);
        window.removeEventListener('paste', this.handlePaste, true);
        window.removeEventListener('drop', this.handlePaste, true);
        window.removeEventListener('dragstart', this.handleDragStart, true);
    }

    // copy paste handlerS
    public copyPasteHandler() {
        this.disableCopyPaste = true;
        window.addEventListener('copy', this.handleCopy, true);
        window.addEventListener('cut', this.handleCopy, true);
        window.addEventListener('paste', this.handlePaste, true);
        window.addEventListener('drop', this.handlePaste, true);
        window.addEventListener('dragstart', this.handleDragStart, true);
    }

    private handleCopy(event: ClipboardEvent) {
        const selection = document.getSelection();
        // if (
        //     // @ts-ignore
        //     !selection.anchorNode.id || !(selection.anchorNode.id && selection.anchorNode.id.includes('Editor'))
        // ) {
        //     this.copiedText = selection.toString();
        // }
        if (
            !(
                window.location.pathname === '/project' &&
                this.projectData &&
                this.projectData.image &&
                this.projectData.image.includes('jupyter')
            )
        ) {
            event.clipboardData.setData('text/plain', 'Could not copy');
            event.preventDefault();
        }
    }

    private handlePaste (event: any) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
        document.execCommand('insertTEXT', false, this.copiedText ? this.copiedText : 'Could not paste');
    }

    public removePasteHandler() {
        window.removeEventListener('paste', this.handlePaste, true);
    }

    private handleDragStart(event: any) {
        event.dataTransfer.clearData();
        event.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();
    }

    private httpRequestOptions() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.userData ? this.userData.token : '',
        });
        return { headers };
    }

    // online handler
    private onlineHandle() {
        this.isOffline.next(false);
        this.fireStoredEvent();
    }

    // offline handler
    private offlineHandle() {
        this.isOffline.next(true);
    }

    // add online offline listener
    public detectOffline() {
        window.ononline = this.onlineHandle.bind(this);
        window.onoffline = this.offlineHandle.bind(this);
    }

    // remove online offline listener
    public removeDetectOffline() {
        this.isOffline.next(false);
        window.ononline = null;
        window.onoffline = null;
    }

    // presentation handler
    private presentationHandler() {
        if (!navigator.userAgent.includes('SEB')) {
            if (this.presentationAvailability) {
                this.isPresentationChange.next(this.presentationAvailability.value ? 1 : 0);
            } else {
                this.isPresentationChange.next(2);
            }
        } else {
            this.isPresentationChange.next(0);
        }
        this.testData.client_data.hasExternalMonitor = this.isPresentationChange.getValue();
    }

    // add presentation listener
    public detectPresentation() {
        return new Promise(async (resolved, _rejected) => {
            try {
                // @ts-ignore
                if (window.PresentationRequest) {
                    // @ts-ignore
                    const presentationRequest = new window.PresentationRequest(window.location.href);
                    this.presentationAvailability = await presentationRequest.getAvailability();
                    if (this.presentationAvailability) {
                        this.isPresentationChange.next(this.presentationAvailability.value ? 1 : 0);
                        this.testData.client_data.hasExternalMonitor = this.isPresentationChange.getValue();
                        this.presentationAvailability.onchange = this.presentationHandler.bind(this);
                        resolved(this.presentationAvailability.value);
                    } else {
                        this.isPresentationChange.next(2);
                        this.testData.client_data.hasExternalMonitor = 2;
                        resolved(false);
                    }
                } else {
                    this.isPresentationChange.next(2);
                    this.testData.client_data.hasExternalMonitor = 2;
                    resolved(false);
                }
            } catch (error) {
                this.isPresentationChange.next(2);
                this.testData.client_data.hasExternalMonitor = 2;
                resolved(false);
            }
        });
    }

    // remove presentation listener
    public removePresentationLeave() {
        this.isPresentationChange.next(0);
        if (this.presentationAvailability) {
            this.presentationAvailability.onchange = undefined;
            this.presentationAvailability = undefined;
        }
    }

    // window blur handler
    private windowBlurHandler() {
        this.isWindowChange.next(true);
    }

    // add window move listener
    public detectWindowMove() {
        let deduct = false;
        window.onblur = (event : any) => {
            this.isWindowChange.next(true);
            deduct = true;
        }
        window.onfocus = (event : any) => {
            if(!deduct) {
                this.isWindowChange.next(true);
            }            
        }
    }

    // remove window move listener
    public removeWindowMove() {
        this.isWindowChange.next(false);
        // window.onblur = null;
    }

    // add leave alert
    public detectLeaveAlert() {
        window.onbeforeunload = (event: any) => {
            event.preventDefault();
            event.returnValue = '';
        };
    }

    public detectLeaveScreen() {
        window.onbeforeunload = (_event: any) => {
           localStorage.removeItem('alreadyRedirected');
        };
    }

    // remove leave alert
    public removeLeaveAlert() {
        window.onbeforeunload = null;
    }

    // premium - manual proctoring
    public checkManualProcAllowed() {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token && token.enable_features && token.enable_features.allow_manual_proctoring) {
            return true;
        }
        return false;
    }

     // premium - HD videos
    public checkHDvideosEnabled() {
        const token = JSON.parse(localStorage.getItem('token'));
        return token && token.enable_features && token.enable_features.allow_hd_videos;
    }

    public getLeaderboardConstants() {
        return new Promise((resolved, _rejected) => {
            if (!this.leaderBoardConstants) {
                let bucket = '';
                if (environment.production) {
                    bucket = 'smartica-users-asset';
                } else {
                    bucket = 'smartica-users-asset';
                }
                this.http.get('https://smartica-users-asset.s3.ap-south-1.amazonaws.com/assets/lecvalues.json').subscribe(
                    (response: any) => {
                        this.leaderBoardConstants = response;
                        resolved(response);
                    },
                    (_errored: any) => {
                        resolved(false);
                    },
                );
            } else {
                resolved(this.leaderBoardConstants);
            }
        });
    }

    /**
     * Donwload sonarQubeReport
     */
    public downloadSonarQubeReport(path) {
        return new Promise((resolved, _rejected) => {
            let bucket = 'dev-users-asset';
            if (environment.production) {  
                bucket = 'prod-users-asset';
            }
            this.http
                .get(`https://${bucket}.s3.amazonaws.com/sonarQubeReports/${this.schoolData.school_id}/${path}`)
                .subscribe(
                    (response: any) => {
                        resolved(response);
                    },
                    (_errored: any) => {
                        resolved(false);
                    },
                );
        });
    }
    // load digital font
    public async loadDigitalFont() {
        const script = {
            element: 'link',
            url: 'https://images.examly.io/icons2/examly-digital.css',
            type: 'text/css',
            rel: 'stylesheet',
            id: 'examly-digital',
        };
        await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
        return true;
    }

    public async getMathJax() {
        if (!this.MathJax) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/MathJax/tex-mml-chtml.js',
                type: 'text/javascript',
                rel: '',
                id: 'MathJax',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.MathJax = MathJax;
        }
        return this.MathJax;
    }

    // get CryptoJS
    public async getCryptoJS() {
        if (!this.CryptoJS) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/aes.js',
                type: 'text/javascript',
                rel: '',
                id: 'cryptojs',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.CryptoJS = CryptoJS;
        }
        return this.CryptoJS;
    }

    // get JitsiMeetExternalAPI
    public async getJitsi() {
        if (!this.JitsiMeetExternalAPI) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/jitsi/external_api.js',
                type: 'text/javascript',
                rel: '',
                id: 'JitsiMeetExternalAPI',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.JitsiMeetExternalAPI = JitsiMeetExternalAPI;
        }
        return this.JitsiMeetExternalAPI;
    }


    public async getTwilio() {
        if (!this.TwilioMeetExternalAPI) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/twilio/twilio-video.min.js',
                type: 'text/javascript',
                rel: '',
                id: 'TwilioMeetExternalAPI',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.TwilioMeetExternalAPI = Twilio;
        }
        return this.TwilioMeetExternalAPI;
    }

    public async getQrCode() {
        if (!this.QRCode) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/qrcode/qrcode',
                type: 'text/javascript',
                rel: '',
                id: 'QRCode',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.QRCode = QRCode;
        }
        return this.QRCode;
    }

    // get aws-sdk
    public async getAWS() {
        if (!this.AWS) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/aws-sdk-2.676.0.min.js',
                type: 'text/javascript',
                rel: '',
                id: 'awssdk',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.AWS = AWS;
            this.AWS.events.on('retry', (response: any) => {
                if (response && response.error && response.error.name === 'RequestTimeTooSkewed') {
                    let serverTime = Date.parse(response.httpResponse.headers.date);
                    let timeNow = new Date().getTime();
                    this.AWS.config.systemClockOffset = Math.abs(timeNow - serverTime);
                    response.error.retryable = true;
                }
            });
        }
        return this.AWS;
    }

    // get Blockly
    public async getBlockly() {
        if (!this.Blockly) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/blockly/blockly_compressed.min.js',
                type: 'text/javascript',
                rel: '',
                id: 'blockly',
                child: [
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/blockly/blocks_compressed.min.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'blocks',
                    },
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/blockly/javascript_compressed.min.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'javascriptGenerator',
                    },
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/blockly/en.min.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'english',
                    },
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/blockly/python_compressed.min.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'pythonGenerator',
                    },
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/blockly/php_compressed.min.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'PHPGenerator',
                    },
                ],
            };
            await this.globalService.loadScript(
                script.element,
                script.url,
                script.type,
                script.rel,
                script.id,
                script.child,
            );
            this.Blockly = Blockly;
        }
        return this.Blockly;
    }

    // get firebase
    public async getFirebase() {
        if (!this.firebase) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/FireBase/firebase-app.js',
                type: 'text/javascript',
                rel: '',
                id: 'firebaseapp',
                child: [
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/FireBase/firebase-auth.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'firebaseauth',
                    },
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/FireBase/firebase-firestore.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'firebasefirestore',
                    },
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/FireBase/firebase-messaging.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'firebasmessaging',
                    },
                ],
            };
            await this.globalService.loadScript(
                script.element,
                script.url,
                script.type,
                script.rel,
                script.id,
                script.child,
            );
            this.firebase = firebase;
            this.firebase.initializeApp(environment.FireBaseKeys);
        }
        return this.firebase;
    }

    // get drawio
    public async getDrawio() {
        if (!this.DiagramEditor) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/diagram/diagram-editor.js',
                type: 'text/javascript',
                rel: '',
                id: 'drawio',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.DiagramEditor = DiagramEditor;
        }
        return this.DiagramEditor;
    }

    // get stackBlitz
    public async getStackBlitz() {
        if (!this.StackBlitzSDK) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/stackblitz.js',
                type: 'text/javascript',
                rel: '',
                id: 'stackblitz',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.StackBlitzSDK = StackBlitzSDK;
        }
        return this.StackBlitzSDK;
    }

    // get ace
    public async getAce() {
        if (!this.ace) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/ace/ace.js',
                type: 'text/javascript',
                rel: '',
                id: 'aceeditor',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.ace = ace;
        }
        return this.ace;
    }

    // get recordRTC
    public async getRecordRTC() {
        if (!this.RecordRTC) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/RecordRTC.js',
                type: 'text/javascript',
                rel: '',
                id: 'recordrtc',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.RecordRTC = RecordRTC;
        }
        return this.RecordRTC;
    }

    // get Quill
    public async getQuill() {
        if (!this.Quill) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/quill-new.min.js',
                type: 'text/javascript',
                rel: '',
                id: 'quill-editor',
                child: [
                    {
                        element: 'link',
                        url: 'https://images.examly.io/scripts/quill-new.snow.css',
                        type: 'text/css',
                        rel: 'stylesheet',
                        id: 'quill-style',
                    },
                ],
            };
            await this.globalService.loadScript(
                script.element,
                script.url,
                script.type,
                script.rel,
                script.id,
                script.child,
            );
            this.Quill = Quill;
        }
        return this.Quill;
    }

    // get inline debugger
    public async getInlineDebugger() {
        if (!this.createAllVisualizersFromHtmlAttrs) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/inline-debugger.js',
                type: 'text/javascript',
                rel: '',
                id: 'inlinedebugger',
            };
            await this.globalService.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.createAllVisualizersFromHtmlAttrs = createAllVisualizersFromHtmlAttrs;
        }
        return this.createAllVisualizersFromHtmlAttrs;
    }

    // get posenet
    public async getPosenet() {
        if (!this.posenet) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/TensorFlow/tf.min.js',
                type: 'text/javascript',
                rel: '',
                id: 'tfjs',
                child: [
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/TensorFlow/posenet.min.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'tfjs-posenet',
                    },
                ],
            };
            await this.globalService.loadScript(
                script.element,
                script.url,
                script.type,
                script.rel,
                script.id,
                script.child,
            );
            this.posenet = await posenet.load();
        }
        return this.posenet;
    }

    //get speech commands
    public async getSpeechCommands() {
        if (!this.speechCommands) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/TensorFlow/tf.min.js',
                type: 'text/javascript',
                rel: '',
                id: 'tfjs',
                child: [
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/TensorFlow/speech-commands.min.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'tfjs-speech-commands',
                    },
                ],
            };
            await this.globalService.loadScript(
                script.element,
                script.url,
                script.type,
                script.rel,
                script.id,
                script.child,
            );
            this.speechCommands = speechCommands;
        }
        return this.speechCommands;
    }

    public async getFaceApi() {
        if (!this.faceapi) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/TensorFlow/faceApi/face-api.min.js',
                type: 'text/javascript',
                rel: '',
                id: 'tfjs-faceapi',

            };
            await this.globalService.loadScript(
                script.element,
                script.url,
                script.type,
                script.rel,
                script.id,
            );
            await faceapi.nets.tinyFaceDetector.loadFromUri('https://images.examly.io/scripts/TensorFlow/faceApi/weights');
            await faceapi.nets.faceLandmark68TinyNet.loadFromUri('https://images.examly.io/scripts/TensorFlow/faceApi/weights');
            await faceapi.nets.faceRecognitionNet.loadFromUri('https://images.examly.io/scripts/TensorFlow/faceApi/weights');

            this.faceapi = faceapi;
        }
        return this.faceapi;
    }

    public async getBlazeFace() {
        if (!this.blazeFace) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/TensorFlow/blazeFace/tfjs.js',
                type: 'text/javascript',
                rel: '',
                id: 'tfjs-2.0',
                child: [
                    {
                        element: 'script',
                        url: 'https://images.examly.io/scripts/TensorFlow/blazeFace/blazeface.min.js',
                        type: 'text/javascript',
                        rel: '',
                        id: 'tfjs-blazeface',
                    },
                ],
            };
            await this.globalService.loadScript(
                script.element,
                script.url,
                script.type,
                script.rel,
                script.id,
                script.child
            );
            this.blazeFace = await blazeface.load({
                maxFaces: 100,
                iouThreshold: 0.5,
            });
        }
        return this.blazeFace;
    }

    public authSDK() {
        return new Promise((resolved, _rejected) => {
            this.http.get(this.api_link + '/getToken', this.httpRequestOptions()).subscribe(
                async (tokens: any) => {
                    const CryptoJS = await this.getCryptoJS();

                    tokens = JSON.parse(
                        CryptoJS.AES.decrypt(tokens.data, this.userData.user_id).toString(CryptoJS.enc.Utf8),
                    );

                    const cognitoPromise = new Promise(async (cogRes, _cogRej) => {
                        if (tokens && tokens.c) {
                            const aws = await this.getAWS();
                            aws.config.correctClockSkew = true;
                            aws.config.region = environment.cognito_identity.region;
                            aws.config.credentials = new aws.CognitoIdentityCredentials({
                                IdentityPoolId: tokens.c.IdentityPoolId,
                                IdentityId: tokens.c.IdentityId,
                                Logins: {
                                    'cognito-identity.amazonaws.com': tokens.c.Token,
                                },
                            });
                            aws.config.credentials.get((err: any) => {
                                if (err) {
                                    cogRes(false);
                                } else {
                                    cogRes(true);
                                }
                            });
                        } else {
                            cogRes(false);
                        }
                    });

                    const firebasePromise = new Promise(async (fireRes, _fireRej) => {
                        if (tokens && tokens.f) {
                            const firebaseRef = await this.getFirebase();
                            firebaseRef
                                .auth()
                                .signInWithCustomToken(tokens.f)
                                .then(() => {
                                    fireRes(true);
                                })
                                .catch((_error: any) => {
                                    fireRes(false);
                                });
                        } else {
                            fireRes(false);
                        }
                    });

                    Promise.all([cognitoPromise, firebasePromise]).then((result) => {
                        if (result[0] && result[1]) {
                            resolved(true);
                        } else {
                            resolved(false);
                        }
                    });
                },
                (_err: any) => {
                    resolved(false);
                },
            );
        });
    }

    public getTestType() {
        if (!this.testData.test_type) {
            if (this.testData.t_type === 'Rule Based Test') {
                if (this.testData.isTestruletype === 'Dynamic') {
                    this.testData.test_type = 'dynamic';
                } else {
                    this.testData.test_type = 'static';
                }
            } else {
                this.testData.test_type = 'normal';
            }
        }
        return this.testData.test_type;
    }

    //to check compile and submission limit
    public getSubmissionLimit() {
        let submissionData = { compile_count: 0, submit_count: 0, compile_total: 0, submit_total: 0}
        if(this.testData && this.testData.compile_total && this.testData.compile_total > 0) {
            submissionData.compile_total = this.testData.compile_total;
        }
        if(this.testData && this.testData.submit_total && this.testData.submit_total > 0) {
            submissionData.submit_total = this.testData.submit_total;
        }
        if(this.testData && this.testData.compile_count && this.testData.compile_count > 0) {
            submissionData.compile_count = this.testData.compile_count;
        }
        if(this.testData && this.testData.submit_count && this.testData.submit_count > 0) {
            submissionData.submit_count = this.testData.submit_count;
        }
        return submissionData;
    }

    public checkAccessKey(access_key: string) {
        return new Promise((resolved, rejected) => {
            if (this.userData && this.testData) {
                return this.http
                    .post(
                        this.api_link +
                            '/student/' +
                            this.userData.user_id +
                            '/course/' +
                            this.testData.c_id +
                            '/test/' +
                            this.testData.t_id +
                            '/pretake',
                        {
                            access_key,
                        },
                        this.httpRequestOptions(),
                    )
                    .subscribe(
                        (response: any) => {
                            resolved(response);
                        },
                        () => {
                            rejected(false);
                        },
                    );
            } else {
                resolved(false);
            }
        });
    }

    public getBasicData() {
        return new Promise(async (resolved, _rejected) => {
            this.testData.client_data = {
                OS: {
                    name: '',
                    version: '',
                },
                browser: {
                    name: '',
                    major: '',
                    version: '',
                },
                Public_IP: '',
                answer_s3_link:'',
                pdfName:''
            };

            Promise.all([this.getIpAddress(), this.getBrowserName(), this.getOS()])
                .then((result: any) => {
                    if (result && result.length) {
                        if (result[0] && result[1] && result[2]) {
                            resolved(true);
                        } else {
                            resolved(false);
                        }
                    }
                })
                .catch((_errored: any) => {
                    resolved(false);
                });
        });
    }

    public getObject(url: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            return this.http.get(url, { responseType: 'text' }).subscribe(
                (response: any) => {
                    resolved(response);
                },
                (_errored: any) => {
                    resolved(false);
                },
            );
        });
    }

    /**
     * Function to upload data to S3 using SDK
     * @param key S3 object Key
     * @param file Content to upload
     */
    public uploadUsingSDK(
        key: string,
        file: any,
        bucketType?:any
    ): Observable<{
        progress: number;
        Key?: string;
    }> {
        return new Observable((observer) => {
            const s3 = new AWS.S3({
                httpOptions: {
                    timeout: 5 * 60 * 1000, // 5mins Timeout
                },
            });
            const params = {
                Bucket: 'exams-media-stage',
                Key: key,
                Body: file,
            };
            if (this.api_link.includes('.io')) {
                params.Bucket = 'exams-media';
            }
            if ( ( key.includes('screen')) || (bucketType === "proctoring") ) {
                params.Bucket = 'smartica-proctoring-screen';
                if (this.api_link.includes('.io')) {
                    params.Bucket = 'smartica-proctoring-screen';
                }
            }
            const options = {
                partSize: 5 * 1024 * 1024, // part size 5MB
                queueSize: 1, // number of concurrent upload
            };
            s3.upload(params, options, (err: any, data: any) => {
                if (err) {
                    observer.error(err);
                } else {
                    observer.next({
                        progress: 100,
                        Key: data.Key,
                    });
                    observer.complete();
                }
            }).on('httpUploadProgress', (progress: any) => {
                const uploaded = parseInt(((progress.loaded * 100) / progress.total).toString(), 10);
                observer.next({
                    progress: uploaded,
                });
            });
        });
    }

    private getIpAddress() {
        return new Promise((resolved, _rejected) => {
            if (this.schoolData &&
                this.ipSkipSchools.includes(this.schoolData.school_id)) {
                this.testData.client_data.Public_IP = '';
                resolved(true);
            } else {
                this.http
                    .get('https://icanhazip.com/', {
                        responseType: 'text',
                    })
                    .subscribe(
                        (ip) => {
                            this.testData.client_data.Public_IP = ip.trim();
                            resolved(true);
                        },
                        (_errored: any) => {
                            this.http
                                .get('https://api.ipify.org/', {
                                    responseType: 'text',
                                })
                                .subscribe(
                                    (ip) => {
                                        this.testData.client_data.Public_IP = ip.toString().trim();
                                        resolved(true);
                                    },
                                    (_error: any) => {
                                        resolved(false);
                                    },
                                );
                        },
                    );
            }
        });
    }

    private getBrowserName() {
        return new Promise((resolved, _rejected) => {
            let nAgt = navigator.userAgent;
            let browserName = navigator.appName;
            let fullVersion = '' + parseFloat(navigator.appVersion);
            let majorVersion = parseInt(navigator.appVersion, 10);
            let nameOffset = -1;
            let verOffset = -1;
            let ix = -1;

            // In Opera 15+, the true version is after "OPR/"
            if ((verOffset = nAgt.indexOf('OPR/')) != -1) {
                browserName = 'Opera';
                fullVersion = nAgt.substring(verOffset + 4);
            }
            // In older Opera, the true version is after "Opera" or after "Version"
            else if ((verOffset = nAgt.indexOf('Opera')) != -1) {
                browserName = 'Opera';
                fullVersion = nAgt.substring(verOffset + 6);
                if ((verOffset = nAgt.indexOf('Version')) != -1) {
                    fullVersion = nAgt.substring(verOffset + 8);
                }
            }
            // In Edg, the true version is after "Edg" in userAgent
            else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
                browserName = 'Microsoft Edge';
                fullVersion = nAgt.substring(verOffset + 4);
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
                browserName = 'Microsoft Internet Explorer';
                fullVersion = nAgt.substring(verOffset + 5);
            }
            // In Chrome, the true version is after "Chrome"
            else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
                browserName = 'Chrome';
                fullVersion = nAgt.substring(verOffset + 7);
            }
            // In Chrome, the true version is after "CriOS" for iOS devices
            else if ((verOffset = nAgt.indexOf('CriOS')) != -1) {
                browserName = 'Chrome';
                fullVersion = nAgt.substring(verOffset + 6);
            }
            // In Safari, the true version is after "Safari" or after "Version"
            else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
                browserName = 'Safari';
                fullVersion = nAgt.substring(verOffset + 7);
                if ((verOffset = nAgt.indexOf('Version')) != -1) {
                    fullVersion = nAgt.substring(verOffset + 8);
                }
            }
            // In Firefox, the true version is after "Firefox"
            else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
                browserName = 'Firefox';
                fullVersion = nAgt.substring(verOffset + 8);
            }
            // In most other browsers, "name/version" is at the end of userAgent
            else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
                browserName = nAgt.substring(nameOffset, verOffset);
                fullVersion = nAgt.substring(verOffset + 1);
                if (browserName.toLowerCase() == browserName.toUpperCase()) {
                    browserName = navigator.appName;
                }
            }
            if(navigator["userAgentData"] && navigator["userAgentData"].brands) {
                navigator["userAgentData"].brands.forEach((brand: any) => {
                    if(brand.brand === "Brave") {
                        browserName = "Brave";
                        fullVersion = nAgt.substring(verOffset + 7);
                    }
                });
            }

            // trim the fullVersion string at semicolon/space if present
            if ((ix = fullVersion.indexOf(';')) != -1) {
                fullVersion = fullVersion.substring(0, ix);
            }
            if ((ix = fullVersion.indexOf(' ')) != -1) {
                fullVersion = fullVersion.substring(0, ix);
            }

            majorVersion = parseInt('' + fullVersion, 10);
            if (isNaN(majorVersion)) {
                fullVersion = '' + parseFloat(navigator.appVersion);
                majorVersion = parseInt(navigator.appVersion, 10);
            }

            this.testData.client_data.browser.name = browserName;
            this.testData.client_data.browser.major = '' + majorVersion;
            this.testData.client_data.browser.version = fullVersion;
            resolved(true);
        });
    }

    private getOS() {
        return new Promise((resolved, _rejected) => {
            let OSName = 'Unknown OS';
            if (navigator.appVersion.indexOf('Win') != -1) {
                OSName = 'Windows';
            }
            if (navigator.appVersion.indexOf('Mac') != -1) {
                OSName = 'MacOS';
            }
            if (navigator.appVersion.indexOf('X11') != -1) {
                OSName = 'UNIX';
            }
            if (navigator.appVersion.indexOf('Linux') != -1) {
                OSName = 'Linux';
            }
            if (navigator.appVersion.indexOf('Android') != -1) {
                OSName = 'Android';
            }
            if (navigator.appVersion.indexOf('iPhone') != -1 || navigator.appVersion.indexOf('iPad') != -1) {
                OSName = 'iOS';
            }

            this.testData.client_data.OS.name = OSName;
            resolved(true);
        });
    }

    public checkTestState() {
        const ltikey = 'itl-' + this.testData.c_id + this.testData.t_id;
        let ltidata = JSON.parse(localStorage.getItem(ltikey));
        this.testData.client_data.ltiData = ltidata ? ltidata : false;
        return this.http.post(
            this.api_link + '/mM5YsmcTM6Msoj5r',
            {
                user_id: this.userData.user_id,
                c_id: this.testData.c_id,
                t_id: this.testData.t_id,
                attempt_no: this.testData.attempt_no,
                client_data: this.testData.client_data,
                test_type: this.getTestType(),
                test_tracking_data: this.testData.test_tracking_data,
                retakeenable: this.testData.retakeenable,
                mod_id: this.testData.mod_id,
                sub_mod_id: this.testData.sub_mod_id,
            },
            this.httpRequestOptions(),
        );
    }

    // API - get test taking detail if required
    public getTestTakingDetail(id: string): Promise<any> {
        return new Promise((resolved, _rejected) => {
            this.http
                .post(
                    this.api_link + '/testtakingdetails',
                    {
                        id,
                    },
                    this.httpRequestOptions(),
                )
                .subscribe(
                    async (response: any) => {
                        if (response && response.success) {
                            const CryptoJS = await this.getCryptoJS();
                            response.data = CryptoJS.AES.decrypt(response.data, this.userData.user_id).toString(
                                CryptoJS.enc.Utf8,
                            );
                        }
                        resolved(response);
                    },
                    (_errored: any) => {
                        resolved(false);
                    },
                );
        });
    }

    // API - start & retake test
    public startTest(payload: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            this.http.post(this.api_link + '/9DECJfxqhu0cgJAQ', payload, this.httpRequestOptions()).subscribe(
                async (response: any) => {
                    const CryptoJS = await this.getCryptoJS();
                    response = JSON.parse(
                        CryptoJS.AES.decrypt(response.data, this.userData.user_id).toString(CryptoJS.enc.Utf8),
                    );
                    resolved(response);
                },
                (_errored: any) => {
                    resolved(false);
                },
            );
        });
    }

    // API - resume test
    public resumeTest(payload: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            this.http.post(this.api_link + '/sEKMRyOJKjIzZbUa', payload, this.httpRequestOptions()).subscribe(
                async (response: any) => {
                    const CryptoJS = await this.getCryptoJS();
                    response = JSON.parse(
                        CryptoJS.AES.decrypt(response.data, this.userData.user_id).toString(CryptoJS.enc.Utf8),
                    );
                    resolved(response);
                },
                (_errored: any) => {
                    resolved(false);
                },
            );
        });
    }

    public generateSerachKeys(searchKeys: any) {
        let search_terms = [];
        for (let key in searchKeys) {
            if (searchKeys[key]) {
                let whole_word = searchKeys[key];
                whole_word = whole_word.split(' ');
                whole_word.forEach((word: any) => {
                    for (let i = 0; i < word.length; i += 1) {
                        for (let j = i + 1; j <= word.length; j += 1) {
                            search_terms.push(word.substring(i, j));
                        }
                    }
                });
            }
        }
        return search_terms;
    }

    private setHistoryEvent(data: any): Promise<any> {
        return new Promise(async (resolved, _rejected) => {
            const firebaseRef = await this.getFirebase();
            const fireStoreDb = firebaseRef.firestore();
            fireStoreDb
                .collection('student_programming_events')
                .add(
                    JSON.parse(
                        JSON.stringify({
                            user_id: data.user_id,
                            c_id: data.c_id,
                            t_id: data.t_id,
                            q_id: data.q_id,
                            attempt_no: data.attempt_no,
                            s_question_id: data.s_question_id,
                            question_type: data.question_type,
                            event_type: data.event_type,
                            testcase_passed: data.testcase_passed,
                            no_test_cases: data.no_test_cases,
                            testcase_percentage: data.testcase_percentage,
                            event_data: data.l_event_data,
                            updatedAt: data.updatedAt,
                        }),
                    ),
                )
                .then(() => {
                    resolved(true);
                })
                .catch((_errored: any) => {
                    resolved(false);
                });
        });
    }

    // fire stored api calls
    fireStoredEvent() {
        return new Promise((resolved, _rejected) => {
            let eventPayload: any = localStorage.getItem('session_data');
            if (eventPayload) {
                eventPayload = JSON.parse(eventPayload);
                const notCompletedEvents = [];
                const promiseArray = [];
                eventPayload.forEach((eventData: any, _index: number) => {
                    promiseArray.push(
                        new Promise((resCall, _rejCall) => {
                            this.hitHttpCall(eventData).then((success) => {
                                if (success) {
                                    let sQuestionId: any;
                                    let eventType: any;
                                    let counter: any = 0;
                                    if (eventData && eventData.payload) {
                                        sQuestionId = eventData.payload.s_question_id ? eventData.payload.s_question_id : null;
                                        eventType = eventData.payload.event_type ? eventData.payload.event_type : null;
                                    }
                                    if (eventType === 'submit-program' || eventType === 'option-click') {
                                        this.testData.frozen_data.frozen_test_data.forEach((each: any) => {
                                            each.questions.forEach((each1: any) => {
                                                if (each1.s_question_id === sQuestionId) {
                                                    counter++;
                                                    each1.online = true;
                                                }
                                            });
                                        });
                                        if (counter) {
                                            this.updateQuestionCount.next(true);
                                        }
                                    }
                                    resCall(true);
                                } else {
                                    notCompletedEvents.push(eventData);
                                    resCall(false);
                                }
                            });
                        }),
                    );
                });

                Promise.all(promiseArray).then(() => {
                    if (notCompletedEvents && notCompletedEvents.length) {
                        localStorage.setItem('session_data', JSON.stringify(notCompletedEvents));
                    } else {
                        localStorage.removeItem('session_data');
                    }
                });
            } else {
                resolved(true);
            }
        });
    }

    private hitHttpCall(eventData: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: eventData.token,
            });
            if (eventData.payload === '') {
                return this.http.get(eventData.url, { headers }).subscribe(
                    () => {
                        resolved(true);
                    },
                    (errored: any) => {
                        httpError(errored, resolved);
                    },
                );
            } else {
                return this.http.post(eventData.url, eventData.payload, { headers }).subscribe(
                    () => {
                        resolved(true);
                    },
                    (errored: any) => {
                        httpError(errored, resolved);
                    },
                );
            }
        });

        function httpError(errored: any, resolved: (value: any) => void) {
            if (errored && (errored.status === 401 || errored.status === 400)) {
                resolved(true);
            } else {
                resolved(false);
            }
        }
    }

    // set localstorage event
    private storeInLocal(event: any) {
        const timestamp = Date.now();
        const authToken = this.userData.token;
        const storage_payload = {
            payload: event,
            timestamp,
            url: environment.HOST.link2 + '/question/all/event',
            token: authToken,
        };
        let session_data: any = localStorage.getItem('session_data');
        if (session_data) {
            session_data = JSON.parse(session_data);
            session_data.push(storage_payload);
            localStorage.setItem('session_data', JSON.stringify(session_data));
        } else {
            localStorage.setItem('session_data', JSON.stringify([storage_payload]));
        }
    }

    // set questions events - big query
    private setEvent(event: any): Promise<any> {
        return new Promise((resolved, rejected) => {
            this.http.post(`${environment.HOST.link2 }/question/all/event`, event, this.httpRequestOptions()).subscribe(
                (data: any) => {
                    resolved(data);
                },
                (_error) => {
                    this.storeInLocal(event);
                    rejected(false);
                },
            );
        });
    }

    // set question last event - firestore
    public setLastEvent(s_question_id: any, data: any): Promise<any> {
        return new Promise(async (resolved, _rejected) => {
            if (
                data.event_type === 'submit-program' &&
                (data.question_type === 'programming' ||
                    data.question_type === 'programming_file_based' ||
                    data.question_type === 'html_css_js' ||
                    data.question_type === 'frontend_technology' ||
                    data.question_type === 'diagram')
            ) {
                this.setHistoryEvent(data);
            }

            const firebaseRef = await this.getFirebase();
            const fireStoreDb = firebaseRef.firestore();

            const promiseArray = [];

            promiseArray.push(
                fireStoreDb
                    .collection('student_questions')
                    .doc('' + s_question_id)
                    .set(JSON.parse(JSON.stringify(data))),
            );

            if (data.l_event_data && data.l_event_data.remaining_time) {
                const lEventData = {
                    answer: data.l_event_data.answer,
                    remaining_time: {
                        overall: data.l_event_data.remaining_time.overall,
                        time_spent: data.l_event_data.remaining_time.time_spent,
                        tabswitched: data.l_event_data.remaining_time.tabswitched,
                        resume_no: data.l_event_data.remaining_time.resume_no,
                        exp_resume: data.l_event_data.remaining_time.exp_resume,
                        hasExternalMonitor: data.l_event_data.remaining_time.hasExternalMonitor,
                    },
                };
                promiseArray.push(
                    this.setEvent({
                        s_question_id,
                        event_type: data.event_type,
                        event_data: JSON.stringify(lEventData),
                        attempt_no: data.attempt_no,
                        browser_createdAt: data.updatedAt,
                    }),
                );
            }

            Promise.all(promiseArray)
                .then(() => {
                    resolved({
                        success:true,
                        s_question_id:data.s_question_id,
                        event_type: data.event_type,
                        sectionIndex:data.sectionIndex
                    });                
                })
                .catch((_errored: any) => {
                    resolved({
                        success:false,
                        s_question_id:data.s_question_id,
                        event_type: data.event_type,
                        sectionIndex:data.sectionIndex
                    });                
                });
        });
    }

    public setLiveData(data: any): Promise<any> {
        return new Promise(async (resolved, _rejected) => {
            const firebaseRef = await this.getFirebase();
            const fireStoreDb = firebaseRef.firestore();
            const payload = {
                ...JSON.parse(JSON.stringify(data)),
            };
            fireStoreDb
                .collection(
                    `school_live_metrics/${data.school_id}/live_course_test/${data.c_id}_${data.t_id}/live_students`,
                )
                .doc(data.user_id)
                .set(payload, {
                    merge: true,
                })
                .then(() => {
                    resolved(true);
                })
                .catch((_errored: any) => {
                    resolved(false);
                });
        });
    }

    public updateTestDailyDurationSpent(data: any): Promise<any> {
        return new Promise(async (resolved, _rejected) => {
            const firebaseRef = await this.getFirebase();
            const fireStoreDb = firebaseRef.firestore();
            let dateObj = new Date(data.updatedAt).toLocaleDateString('fr-CA',{ timeZone: 'Asia/Kolkata' });
            let currDate: string = dateObj.split("-")[2];
            let currMonth: string = dateObj.split("-")[1];
            let currYear: string = dateObj.split("-")[0];
            const formatDate:string = currDate+"_"+currMonth+"_"+currYear;
            const payload = {
                ...JSON.parse(JSON.stringify(data)),
            };
            let atmptWisePayload = {};
            if(data.attempt_no){
                let attemptNo:number = payload.attempt_no;
                atmptWisePayload[attemptNo]=payload;
            }else{
                atmptWisePayload = payload;
            }

            fireStoreDb
                .collection(
                    `students_dailytime_spent/${formatDate}/schools/${data.school_id}/users/${data.user_id}/courses/${data.c_id}/entity`,
                )
                .doc(data.entity_id)
                .set(atmptWisePayload, {
                    merge: true,
                })
                .then(() => {
                    resolved(true);
                })
                .catch((_errored: any) => {
                    resolved(false);
                });
        });
    }

    // GET - URL
    public getURL(url: string) {
        return this.http.get(url);
    }

    public testRating(payload) {
        return this.http.post(this.api_link + '/test/student/rating', payload, this.httpRequestOptions());
    }

    public publishToStream(payload: any) {
        return this.http.post(environment.HOST.link2 + '/publishPubSub', payload, this.httpRequestOptions());
    }

    public publishToVIStream(payload: any) {
        return this.http.post(environment.HOST.link2 + '/vidPubSub', payload, this.httpRequestOptions());
    }

    // submit test in BE
    public testSubmitBE(school_id: string, c_id: string, t_id: string, user_id: string, attempt_no: number, data: any) {
        return new Promise((resolved, _rejected) => {
            this.http
                .post(
                    this.api_link +
                        '/school/' +
                        school_id +
                        '/student/' +
                        user_id +
                        '/course/' +
                        c_id +
                        '/test/' +
                        t_id +
                        '/submit/' +
                        attempt_no,
                    data,
                    this.httpRequestOptions(),
                )
                .subscribe(
                    (success: any) => {
                        resolved(success);
                    },
                    () => {
                        resolved(false);
                    },
                );
        });
    }

    // generate SonarQube Reports
    public sonarQubeReport(payload) {
        return this.http.post(this.api_link + '/generateSonarQubeReport', payload, this.httpRequestOptions());
    }

    // report a question
    public reportQuestion(payload: any) {
        return this.http.post(this.api_link + '/addfeedback', payload, this.httpRequestOptions());
    }

    public getTestPublicAnalysis(payload: any) {
        return this.http.post(this.api_link + '/v2/test/student/publicresultanalysis', payload);
    }

    public getTestAnalysisById(encyPayload: string) {
        return this.http.post(this.api_link + '/v2/test/student/resultanalysis', {id: encyPayload}, this.httpRequestOptions());
    }

    public getContent(content_id: string) {
        return this.http.get(this.api_link + '/get_contentbank/' + content_id, this.httpRequestOptions());
    }

    public updateViewORDownloadCount(payload) {
        return this.http.put(this.api_link + '/studentcontent/viewORdownloadCount', payload, this.httpRequestOptions());
    }

    public createInstance(key: string, image: string, project_id: string, c_id: string, node_pool: string) {
        return this.http.post(
            this.api_link + '/courses/createInstance',
            {
                key,
                image,
                project_id,
                course_id: c_id,
                node_pool,
            },
            this.httpRequestOptions(),
        );
    }

    public fetchCompileResult(payload) {
    return this.http.post(this.compile_url, payload);
    }

    public fetchEssayCompileResult(payload) {
        return this.http.post(this.essay_compile_url, payload);
    }

    /**
     * Method to start instance test-taking
     * @param payload
     * @returns
     */
    public createInstanceForTest(payload: Array<{
        q_id: string,
        key: string,
        image: string,
        node_pool: string,
        has_auto_evaluation: boolean,
        boilerPlate: any,
        config: any,
        c_id: string,
        t_id: string,
        attempt_no: number
    }>) {
        return this.http.post(
            this.api_link + '/test/student/createInstance', { data: payload }, this.httpRequestOptions(),
        );
    }

    public getProject(project_id: string, c_id: string, user_id: string) {
        return this.http.post(
            this.api_link + '/courses/project',
            {
                project_id,
                c_id,
                user_id,
            },
            this.httpRequestOptions(),
        );
    }

    public stopInstance(key: string, project_id: string, c_id: string, domain?: string, isBackup?: boolean) {
        return this.http.post(
            this.api_link + '/courses/stopInstance',
            {
                key,
                project_id,
                course_id: c_id,
                domain,
                isBackup
            },
            this.httpRequestOptions(),
        );
    }

    /**
     * Method to stop instance in test-taking
     * @param arr
     * @returns
     */
    public stopInstanceBulk(arr: Array<string>, domain?: string) {
        return this.http.post(
            this.api_link + '/test/student/stopInstance',
            {
                data: arr,
                domain,
                isBackup: true
            },
            this.httpRequestOptions(),
        );
    }

    public instanceHeartBeat(key: string, id: number) {
        return this.http.post(
            this.api_link + '/courses/instanceHeartBeat',
            {
                key,
                id,
            },
            this.httpRequestOptions(),
        );
    }

    public initGit(key: string, domain?: string, c_id?: string) {
        let payload = {key, domain, c_id}
        return this.http.post(this.api_link + '/courses/gitInit', payload, this.httpRequestOptions())
    }

    public backupProject(payload: any){
        return this.http.post(this.api_link + '/courses/backupInstance', payload, this.httpRequestOptions());
    }

    public isSiteUp(url: string) {
        return this.http.post(
            this.api_link + '/courses/isReachable',
            {
                url,
            },
            this.httpRequestOptions(),
        );
    }

    public submitProject(payload: any) {
        return this.http.post(this.api_link + '/courses/submitproject', payload, this.httpRequestOptions());
    }

    public runTestCases(payload: any) {
        return this.http.post(this.api_link + '/courses/runtestcase', payload, this.httpRequestOptions());
    }

    public runProjectTestCases(payload: any) {
        return this.http.post(this.api_link + '/test/student/runprojecttestcase', payload, this.httpRequestOptions());
    }

    // window resize handler
    private resizeHandler() {
        if (window.innerHeight >= screen.height - 30 && window.innerWidth >= screen.width - 30) {
            this.isWindowResize.next(false);
        } else {
            this.isWindowResize.next(true);
        }
    }

    // add window resize listener
    public detectWindowResize() {
        window.onresize = this.resizeHandler.bind(this);
    }

    // remove window resize listener
    public removeWindowResize() {
        this.isWindowResize.next(false);
        window.onresize = null;
    }

    // go full screen
    public goFullScreen() {
        if (document.fullscreenEnabled) {
            const docElement = document.documentElement;
            if (docElement.requestFullscreen) {
                try {
                    docElement.requestFullscreen().then(() => {                        
                        setTimeout(() => {
                            if(this.testData.c_modules.off_screen && this.testData.c_modules.offscreencount 
                                && !this.userscreenHeight && !this.globalService.skipScreenCheck) {
                                this.userscreenHeight = document.scrollingElement.clientHeight;
                            }                            
                        }, 1000);
                    }).catch(() => {});
                } catch (err) {                  
                }
                addEventListener('fullscreenchange', (event) => {                                         
                    if(!this.skipscreenCheck && !this.globalService.skipScreenCheck && this.userscreenHeight) {
                        setTimeout(() => {
                            this.checkuserHeight()
                        }, 1000);                        
                    }
                });
            }
        }
    }

    public checkuserHeight() {  
        if(this.testData && this.testData.c_modules 
            && this.testData.c_modules.off_screen && 
            this.testData.c_modules.offscreencount && !this.globalService.skipScreenCheck
            && this.userscreenHeight != document.scrollingElement.clientHeight) { 
           this.isWindowResize.next(true)
        }
    }

    // exit full screen
    public exitFullScreen() {
        if (document.fullscreenElement && document.exitFullscreen) {
            document.exitFullscreen().catch(() => {
                // This is intentional
            });
        }
    }

    // premium - proctoring
    public checkProctoringAllowed() {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token && token.enable_features && token.enable_features.allow_proctoring) {
            return true;
        }
        return false;
    }

    /**
     * Function to get User MediaDevice by Constraints
     * @param constraints MediaStreamConstraints
     */
    public async getMediaDevice(constraints: MediaStreamConstraints): Promise<MediaStream> {
        return new Promise((resolve, reject) => {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then((stream) => {
                        resolve(stream);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject('Could not access camera or microphone');
            }
        });
    }

    /**
     * Get user camera media stream
     */
    public getUserCamera(): Promise<any> {
        return new Promise((resolved, rejected) => {
            const onError = (error: any) => {
                let errorMsg = '';
                switch (error.name) {
                    case 'AbortError': {
                        errorMsg = 'Could not access camera or microphone';
                        break;
                    }
                    case 'NotAllowedError': {
                        errorMsg = 'Please unblock camera and microphone';
                        break;
                    }
                    case 'NotFoundError': {
                        errorMsg = 'Camera or microphone not found';
                        break;
                    }
                    default: {
                        errorMsg = 'Could not access camera or microphone';
                    }
                }
                rejected(errorMsg);
            };
            this.getMediaDevice({
                video: {
                    facingMode: 'user',
                },
                audio: true,
            })
                .then((stream) => {
                    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                        return resolved(stream);
                    }
                    navigator.mediaDevices
                        .enumerateDevices()
                        .then(async (mediaDevices) => {
                            let isVirtual = false;
                            const streamTracks = stream.getVideoTracks();
                            streamTracks.forEach((track) => {
                                const constraint = track.getSettings();
                                const assignedDevice = mediaDevices.find((deviceInfo) => {
                                    return deviceInfo.deviceId === constraint.deviceId;
                                });
                                isVirtual = this.webcamBlackList.find((bl) => {
                                    return assignedDevice.label.includes(bl);
                                })
                                    ? true
                                    : false;
                            });
                            if (isVirtual) {
                                const videoDevice = mediaDevices.filter((deviceInfo) => {
                                    return (
                                        deviceInfo.kind === 'videoinput' &&
                                        !this.webcamBlackList.find((bl) => {
                                            return deviceInfo.label.includes(bl);
                                        })
                                    );
                                });
                                try {
                                    let errorOccurred: any;
                                    for (const deviceInfo of videoDevice) {
                                        const correctedStream = await this.getMediaDevice({
                                            video: {
                                                deviceId: deviceInfo.deviceId,
                                                facingMode: 'user',
                                            },
                                            audio: true,
                                        }).catch((error) => {
                                            errorOccurred = error;
                                        });
                                        if (correctedStream) {
                                            resolved(correctedStream);
                                            break;
                                        }
                                    }
                                    if (errorOccurred) {
                                        onError(errorOccurred);
                                    }
                                } catch (error) {
                                    onError(error);
                                }
                            } else {
                                resolved(stream);
                            }
                        })
                        .catch((error) => {
                            onError(error);
                        });
                })
                .catch((errored: any) => {
                    onError(errored);
                });
        });
    }

    // get blob data from recording
    public getRTCBlob(recordRTCIns: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            if (recordRTCIns && recordRTCIns.getState() === 'recording') {
                recordRTCIns.stopRecording(() => {
                    resolved(recordRTCIns.getBlob());
                });
            } else {
                resolved(false);
            }
        });
    }

    /**
     * Upload to S3 by using signed url with retry
     * @param fileName file name with path
     * @param blobData file data in Blob format
     * @param MAX_TRY number of times to try, default 2
     */
     public uploadToS3WithRetry(fileName: string, blobData: Blob, MAX_TRY = 2, bucketType?:any) {
        return new Promise(async (resolved, rejected) => {
            const AWS = await this.getAWS();
            while (MAX_TRY) {
                MAX_TRY -= 1;
                    let fallBackAuth: any;
                    if (AWS && AWS.config && AWS.config.credentials && AWS.config.credentials && !AWS.config.credentials.expired) {
                        fallBackAuth = true;
                    } else {
                        fallBackAuth = await this.authSDK();
                    }

                    if (fallBackAuth) {
                        const status: any = await this.uploadToS3WithSDK(fileName, blobData, bucketType);

                        if (status) {
                            MAX_TRY = 0;
                            resolved(true);
                        } else if (!MAX_TRY) {
                            rejected('AWS Error');
                        }
                    } else if (!MAX_TRY) {
                        rejected('AWS Error');
                    }
                // } else {
                //     const uploadStatus = await this.uploadToS3(fileName, blobData).catch((err) => {
                //         if (!MAX_TRY) {
                //             rejected(err);
                //         }
                //     });
                //     if (uploadStatus) {
                //         MAX_TRY = 0;
                //         resolved(true);
                //     }
                // }
            }
        });
    }

    /**
     * Upload to S3 by using signed url
     * @param fileName file name with path
     * @param blobData file data in Blob format
     */
    public uploadToS3WithSDK(fileName: string, blobData: Blob, bucketType?:any) {
        return new Promise(async (resolved, _rejected) => {
            try {
                await this.uploadUsingSDK(fileName, blobData, bucketType).subscribe(
                    () => {
                        // Upload progress
                    },
                    (_error) => {
                        // Error Occured
                        resolved(false);
                    },
                    () => {
                        // SuccessFull Upload
                        resolved(true);
                    }
                );
            } catch (error) {
                resolved(false);
            }
        });
    }

    /**
     * Upload to S3 by using signed url
     * @param fileName file name with path
     * @param blobData file data in Blob format
     */
     public uploadToS3(fileName: string, blobData: Blob, bucketType?: any) {
        return new Promise(async (resolved, rejected) => {
            let pay = {
                file_name: fileName,
                type: blobData.type,
                extend: true,
                Bucket_name : null
            };
            if(bucketType) {
                pay.Bucket_name = bucketType;
            }
            this.globalService.getSignedUrl(pay).subscribe(
                (success: any) => {
                    if (success && success.data) {
                        this.globalService.uploadUsingSignedUrl(success.data.url, blobData).subscribe(
                            (r: any) => {
                                if (r && r.status === 200) {
                                    resolved(true);
                                } else {
                                    rejected('Network error occured!');
                                }
                            },
                            (error: HttpErrorResponse) => {
                                rejected(error.message);
                            },
                        );
                    } else {
                        rejected('Network error occured!');
                    }
                },
                (error: HttpErrorResponse) => {
                    rejected(error.message);
                },
            );
        });
    }

    /**
     * Method to calculate Mbps
     * @param sizeInBytes Number of bytes transferred in bytes
     * @param duration Time taken to transfer in millisecond
     */
    public calculateMbps(sizeInBytes: number, duration: number): number {
        if (duration) {
            const bitsLoaded = sizeInBytes * 8;
            const speedBps = Math.round(bitsLoaded / duration);
            const speedKbps = (speedBps / 1024).toFixed(2);
            return Number((Number(speedKbps) / 1024).toFixed(2));
        }
        return 0;
    }

    /**
     * Method to generate random string for given size
     * @param sizeInMb size of the string to be generated in MB
     * @returns random string
     */
    private generateRandomData(sizeInMb: number) {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;':,./<>?";
        const iterations = sizeInMb * 1024 * 1024;
        let result = '';
        for (let index = 0; index < iterations; index++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    }

    /**
     * Method to get Network upload speed in Mbps
     */
    public getUploadSpeed(): Observable<number> {
        return new Observable((observer) => {
            let startTime = 0;
            let endTime = 0;
            const uploadSize = this.NET_SPEED_TEST_SIZE * 1024 * 1024;
            const predictSpeed = (event: any) => {
                endTime = new Date().getTime();
                if (startTime < endTime) {
                    const times = event.loaded > uploadSize ? 1 : uploadSize / event.loaded;
                    const duration = times * ((endTime - startTime) / 1000);
                    const speedMbps = this.calculateMbps(uploadSize, duration);
                    observer.next(speedMbps);
                    return speedMbps;
                } else {
                    return 0;
                }
            };

            const options = this.httpRequestOptions();
            this.http
                .post(
                    environment.speed_test,
                    {
                        message: this.generateRandomData(this.NET_SPEED_TEST_SIZE),
                    },
                    {
                        ...options,
                        observe: 'events',
                        reportProgress: true,
                        params: {
                            'ngsw-bypass': 'true',
                            'api_key': 'BxSp@6878%qws'
                        },
                    },
                )
                .subscribe(
                    (event) => {
                        switch (event.type) {
                            case HttpEventType.Sent: {
                                startTime = new Date().getTime();
                                break;
                            }
                            case HttpEventType.UploadProgress: {
                                predictSpeed(event);
                                if (event.loaded >= uploadSize) {
                                    observer.complete();
                                }
                                break;
                            }
                            case HttpEventType.Response: {
                                predictSpeed({ loaded: this.NET_SPEED_TEST_SIZE * 1024 * 1024 });
                                observer.complete();
                                break;
                            }
                        }
                    },
                    (error: HttpErrorResponse) => {
                        observer.error(error.message);
                    },
                );
        });
    }

    /**
     * Method to get Network download speed in Mbps
     */
    public getDownloadSpeed(): Observable<number> {
        return new Observable((observer) => {
            let startTime = 0;
            let endTime = 0;
            const downloadSize = this.NET_SPEED_TEST_SIZE * 1024 * 1024;
            const predictSpeed = (event: any) => {
                endTime = new Date().getTime();
                if (startTime < endTime) {
                    const times = event.loaded > downloadSize ? 1 : downloadSize / event.loaded;
                    const duration = times * ((endTime - startTime) / 1000);
                    const speedMbps = this.calculateMbps(downloadSize, duration);
                    observer.next(speedMbps);
                    return speedMbps;
                } else {
                    return 0;
                }
            };

            const options = this.httpRequestOptions();
            this.http
                .get(environment.speed_test, {
                    ...options,
                    observe: 'events',
                    reportProgress: true,
                    params: {
                        'ngsw-bypass': 'true',
                        'api_key': 'BxSp@6878%qws'
                    },
                })
                .subscribe(
                    (event) => {
                        switch (event.type) {
                            case HttpEventType.ResponseHeader: {
                                startTime = new Date().getTime();
                                break;
                            }
                            case HttpEventType.DownloadProgress: {
                                predictSpeed(event);
                                if (event.loaded >= downloadSize) {
                                    observer.complete();
                                }
                                break;
                            }
                            case HttpEventType.Response: {
                                predictSpeed({ loaded: this.NET_SPEED_TEST_SIZE * 1024 * 1024 });
                                observer.complete();
                                break;
                            }
                        }
                    },
                    (error: HttpErrorResponse) => {
                        observer.error(error.message);
                    },
                );
        });
    }

    /**
     * Get user entire screen media stream
     */
    public getUserScreenShare(): Promise<any> {
        return new Promise((resolved, rejected) => {
            if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
                const errorMsg = 'Could not access screen share. Use Desktop/Laptop Google Chrome(>72) or FireFox(>66)';
                // @ts-ignore
                navigator.mediaDevices.getDisplayMedia()
                    .then((screenMedia: MediaStream) => {
                        this.screenShareMedia = screenMedia;
                        const videoNode = document.createElement('video');
                        videoNode.srcObject = screenMedia;
                        const videoTrack = videoNode.srcObject.getVideoTracks();
                        if (videoTrack && videoTrack[0]) {
                            const shareSettings = videoTrack[0].getSettings();
                            if (shareSettings) {
                                if (
                                    // @ts-ignore
                                    shareSettings.displaySurface === 'monitor' ||
                                    videoTrack[0].label === 'Primary Monitor'
                                ) {
                                    resolved(screenMedia);
                                } else {
                                    rejected('Please share <b>Entire screen</b>');
                                }
                            } else {
                                rejected(errorMsg);
                            }
                        } else {
                            rejected(errorMsg);
                        }
                    })
                    .catch((errored: DOMException) => {

                        if(errored.name == 'NotAllowedError'){
                            rejected('Permission Denied. Please share your screen!');
                        }else{
                            rejected(errorMsg);
                        }
                    });
            } else {
                rejected('Screen sharing is required. Use Desktop/Laptop Google Chrome (>72) or FireFox(>66)');
            }
        });
    }

    /**
     * Method to evaluate PoseNet 'nose', 'leftEye', 'rightEye', 'leftEar', 'rightEar' points
     * @param keypoints body parts array
     * @param minConfidence min. confidence threshold
     * @param ctx canvas context object
     * @param showPoints draw points on canvas
     * @returns distraction found("d") or not("ok")
     */
    public evaluateBodyParts(
        keypoints: any[],
        minConfidence: number,
        ctx: CanvasRenderingContext2D,
        showPoints: boolean,
    ) {
        const facePoints: ('nose' | 'leftEye' | 'rightEye' | 'leftEar' | 'rightEar')[] = [];
        const color = 'aqua';

        for (let i = 0; i < keypoints.length; i++) {
            const keypoint = keypoints[i];
            if (keypoint.score < minConfidence) {
                if (
                    keypoint.part === 'nose' ||
                    keypoint.part === 'leftEye' ||
                    keypoint.part === 'rightEye' ||
                    keypoint.part === 'leftEar' ||
                    keypoint.part === 'rightEar'
                ) {
                    facePoints.push(keypoint.part);
                }
                continue;
            }

            if (showPoints) {
                const { y, x } = keypoint.position;
                this.drawPoint(ctx, y, x, 3, color);
            }
        }
        if (facePoints && facePoints.length) {
            return 'd';
        }
        return 'ok';
    }

    /**
     * Method to draw point in canvas
     * @param ctx canvas context object
     * @param y Y point
     * @param x X point
     * @param r radius
     * @param color strock color
     */
    public drawPoint(ctx: CanvasRenderingContext2D, y: number, x: number, r: number, color: string) {
        ctx.beginPath();
        ctx.arc(x, y, r, 0, 2 * Math.PI);
        ctx.fillStyle = color;
        ctx.fill();
    }

    /**
     * Method to return error msg for PoseNet estimation
     * @param estimate
     * "lq" - image quality is less
     * "np" - no person found
     * "mp" - multiple person found
     * "d" - distraction
     * "ok" - no suspicious activity
     * "fs" - face spoofing
     */
    public estimationHandler(estimate: 'lq' | 'np' | 'mp' | 'd' | 'ok' | 'fs') {
        const data: { msg: string; severity: 'success' | 'warn' | 'error' } = {
            msg: '',
            severity: 'success',
        };
        switch (estimate) {
            case 'lq': {
                data.msg = 'Image quality is poor!';
                data.severity = 'error';
                break;
            }
            case 'np': {
                data.msg = 'Could not find your face!';
                data.severity = 'error';
                break;
            }
            case 'mp': {
                data.msg = 'May be another person found!';
                data.severity = 'error';
                break;
            }
            case 'd': {
                data.msg = 'You may be distracted or not looking at the screen!';
                data.severity = 'warn';
                break;
            }
            // case 'fs': {
            //     data.msg = 'Face Spoofing Detected';
            //     data.severity = 'error';
            //     break;
            // }
        }
        return data;
    }

    public getProjectData(c_id: string, project_id: string) {
        return this.http.post(
            this.api_link + '/project/details',
            {
                project_id,
                c_id,
            },
            this.httpRequestOptions(),
        );
    }

    /**
     * Function to check network connection status
     */
    public testNetworkStatus(): Promise<boolean> {
        return new Promise((resolve, _reject) => {
            const testUrl = environment.HOST.link2.split('api');
            const URL = testUrl[0];
            const timeOut = 5000;
            const onSuccess = (_ev: any) => {
                this.onlineHandle();
                resolve(true);
            };
            const onError = () => {
                this.offlineHandle();
                resolve(false);
            };
            const timeOutSub = setTimeout(() => {
                onError();
                sub.unsubscribe();
            }, timeOut);
            const sub = this.http
                .get(URL, {
                    responseType: 'text',
                })
                .subscribe(
                    (res) => {
                        clearTimeout(timeOutSub);
                        onSuccess(res);
                        sub.unsubscribe();
                    },
                    (_error) => {
                        clearTimeout(timeOutSub);
                        onError();
                        sub.unsubscribe();
                    },
                );
        });
    }

    /**
     * Function to get Proctoring IndexedDB
     */
    public getProcDB(): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            if (this.PROC_DB_CONFIG.db) {
                resolve(this.PROC_DB_CONFIG.db);
            } else {
                if ('indexedDB' in window) {
                    const indexedDb = window.indexedDB;
                    const procImages = indexedDb.open(this.PROC_DB_CONFIG.dbName);
                    procImages.onupgradeneeded = () => {
                        const procImageDb = procImages.result;
                        procImageDb.createObjectStore(this.PROC_DB_CONFIG.objectName);
                    };
                    procImages.onsuccess = () => {
                        this.PROC_DB_CONFIG.db = procImages.result;
                        resolve(this.PROC_DB_CONFIG.db);
                    };
                    procImages.onerror = (error) => {
                        console.error('PIQ(002): ', error);
                        reject(error);
                    };
                } else {
                    console.error('PIQ(001): ');
                    reject('IndexedDB not supported!');
                }
            }
        });
    }

    /**
     * Function to put image in Proctoring IndexedDB
     * @param db IDBDatabase
     * @param key File key
     * @param value File
     */
    public putProcImgInDB(db: IDBDatabase, key: string, value: Blob): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(this.PROC_DB_CONFIG.objectName, 'readwrite');
            const put = transaction.objectStore(this.PROC_DB_CONFIG.objectName).put(value, key);
            put.onerror = (error) => {
                console.error('PIQ(003): ', error);
                reject(error);
            };
            put.onsuccess = () => {
                resolve(true);
            };
        });
    }

    /**
     * Function to get a image from Proctoring IndexedDB
     * @param db IDBDatabase
     * @param key File key
     */
    public getProcImgInDB(db: IDBDatabase, key: string): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(this.PROC_DB_CONFIG.objectName, 'readonly');
            const get = transaction.objectStore(this.PROC_DB_CONFIG.objectName).get(key);
            get.onerror = (error) => {
                console.error('PIQ(004): ', error);
                reject(error);
            };
            get.onsuccess = (event: any) => {
                resolve(event.target.result);
            };
        });
    }

    /**
     * Function to get all images Keys from Proctoring IndexedDB
     * @param db IDBDatabase
     */
    public getAllProcImgKeysInDB(db: IDBDatabase): Promise<string[]> {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(this.PROC_DB_CONFIG.objectName, 'readonly');
            const get = transaction.objectStore(this.PROC_DB_CONFIG.objectName).getAllKeys();
            get.onerror = (error) => {
                console.error('PIQ(005): ', error);
                reject(error);
            };
            get.onsuccess = (event: any) => {
                resolve(event.target.result);
            };
        });
    }

    /**
     * Function to count the images in Proctoring IndexedDB
     * @param db IDBDatabase
     */
    public getProcImgCountInDB(db: IDBDatabase): Promise<number> {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(this.PROC_DB_CONFIG.objectName, 'readonly');
            const get = transaction.objectStore(this.PROC_DB_CONFIG.objectName).getAllKeys();
            get.onerror = (error) => {
                console.error('PIQ(006): ', error);
                reject(error);
            };
            get.onsuccess = (event: any) => {
                let count = 0;
                if (event.target.result && event.target.result.length) {
                    count = event.target.result.length;
                }
                resolve(count);
            };
        });
    }

    /**
     * Function to delete a image in Proctoring IndexedDB
     * @param db IDBDatabase
     * @param Key File key
     */
    public deleteProcImgInDB(db: IDBDatabase, key: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(this.PROC_DB_CONFIG.objectName, 'readwrite');
            const del = transaction.objectStore(this.PROC_DB_CONFIG.objectName).delete(key);
            del.onerror = (error) => {
                console.error('PIQ(007): ', error);
                reject(error);
            };
            del.onsuccess = (_event: any) => {
                resolve(true);
            };
        });
    }

    /**
     * Function to clear Proctoring Images IndexedDB
     * @param db IDBDatabase
     */
    public clearProcImgDB(db: IDBDatabase): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(this.PROC_DB_CONFIG.objectName, 'readwrite');
            const clear = transaction.objectStore(this.PROC_DB_CONFIG.objectName).clear();
            clear.onerror = (error) => {
                console.error('PIQ(008): ', error);
                reject(error);
            };
            clear.onsuccess = (_event) => {
                resolve(true);
            };
        });
    }

    public getStProcDetails(request: {
        c_id: string;
        t_id: string;
    }) {
        return this.http.post(this.api_link + '/proctor/stprocdetails', request, this.httpRequestOptions(),);
    }

    public downloadStudentAnalysis(payload) {
        return this.http.post(this.api_link + '/downloadStudentAnalysis', payload, this.httpRequestOptions(),)
    }

    public createQRString(payload: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            this.http.post(this.api_link + '/HlAKDlkpodASesd', payload, this.httpRequestOptions()).subscribe(
                async (response: any) => {
                    resolved(response);
                },
                (_errored: any) => {
                    resolved(false);
                },
            );
        });
    }

    public QRuploadToS3(payload: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            this.http.post(this.api_link + '/BdsaBDASbdASDU', payload, this.httpRequestOptions()).subscribe(
                async (response: any) => {
                    resolved(response);
                },
                (_errored: any) => {
                    resolved(false);
                },
            );
        });
    }

    public getServerTime(userData): Promise<any> {
        return new Promise((resolved, _rejected) => {
            this.userData = userData;
            this.http.get(this.api_link + '/DJKAnsdnauADsa', this.httpRequestOptions()).subscribe(
                async (response: any) => {
                    resolved(response);
                },
                (_errored: any) => {
                    resolved(false);
                },
            );
        });
    }

    public generateQR(text: any): Promise<any> {
        return new Promise(async (resolved, _rejected) => {
            try {
                const QRcode = await this.QRCode.toDataURL(text);
                resolved(QRcode);
            } catch (err) {
                resolved(false);
            }
        });
    }

    public getS3FileURL(payload: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            this.http.post(this.api_link + '/DmsdndqApkgwpko', payload, this.httpRequestOptions()).subscribe(
                async (response: any) => {
                    resolved(response);
                },
                (_errored: any) => {
                    resolved(false);
                },
            );
        });
    }

    /**
     *
     * @param {{string : string | RegExp }} type_delimiter -Type and Pattern
     * @param {string} code - Code
     * @returns {[{comment : string,line: number}]} - Array of object of comment and line
     * number.
     */

    public extractComments(type_delimiter: any, code: any) {
        let all_comments: any = [];
        if (type_delimiter && typeof code === 'string') {
            let entries = Object.entries(type_delimiter);
            for (const [type, delimiter] of entries) {
                switch (type) {
                    case 'singleLine_singleChar': {
                        let state = 0;
                        let current_comment = '';
                        let comments = [];
                        let line_counter = 1;
                        let string_char = '';
                        for (const char of code) {
                            if (state == 0) {
                                //Waiting for comment start character or beginning of string.
                                if (char == delimiter) {
                                    //Comment start is Detected
                                    state = 1;
                                } else if (['"', "'", '`'].includes(char)) {
                                    //First Char of comment is Detected
                                    string_char = char;
                                    state = 5;
                                }
                            } else if (state == 1) {
                                //In single-line comment, read characters until EOL.
                                if (char == '\n') {
                                    let comment = {
                                        comment: current_comment,
                                        line: line_counter,
                                    };
                                    comments.push(comment);
                                    current_comment = '';
                                    state = 0;
                                } else {
                                    current_comment += char;
                                }
                            } else if (state == 5) {
                                // In string literal, expect literal end or escape character.
                                if (char == string_char) {
                                    state = 0;
                                } else if (char == '\\') {
                                    state = 6;
                                }
                            } else if (state == 6) {
                                // In string literal, escaping current char.
                                state = 5;
                            }
                            if (char == '\n') {
                                line_counter += 1;
                            }
                        }
                        if (state == 1) {
                            let comment = {
                                comment: current_comment,
                                line: line_counter,
                            };
                            comments.push(comment);
                        }
                        all_comments.push(...comments);
                        break;
                    }
                    case 'singleLine_doubleChar': {
                        let state = 0;
                        let current_comment = '';
                        let comments = [];
                        let line_counter = 1;
                        let string_char = '';
                        for (const char of code) {
                            if (state == 0) {
                                //Waiting for comment start character or beginning of string.
                                if (char == delimiter) {
                                    state = 1;
                                } else if (['"', "'", '`'].includes(char)) {
                                    string_char = char;
                                    state = 5;
                                }
                            } else if (state == 1) {
                                if (char == delimiter) {
                                    state = 2;
                                } else {
                                    state = 0;
                                }
                            } else if (state == 2) {
                                //In single-line comment, read characters until EOL.
                                if (char == '\n') {
                                    let comment = {
                                        comment: current_comment,
                                        line: line_counter,
                                    };
                                    comments.push(comment);
                                    current_comment = '';
                                    state = 0;
                                } else {
                                    current_comment += char;
                                }
                            } else if (state == 5) {
                                // In string literal, expect literal end or escape character.
                                if (char == string_char) {
                                    state = 0;
                                } else if (char == '\\') {
                                    state = 6;
                                }
                            } else if (state == 6) {
                                // In string literal, escaping current char.
                                state = 5;
                            }
                            if (char == '\n') {
                                line_counter += 1;
                            }
                        }
                        if (state == 2) {
                            let comment = {
                                comment: current_comment,
                                line: line_counter,
                            };
                            comments.push(comment);
                        }
                        all_comments.push(...comments);
                        break;
                    }
                    case 'multiLine': {
                        let state = 0;
                        let current_comment = '';
                        let comments = [];
                        let line_counter = 1;
                        let comment_start = 1;
                        let string_char = '';
                        for (const char of code) {
                            if (state == 0) {
                                //Waiting for comment start character or beginning of string.
                                if (char == '/') {
                                    state = 1;
                                } else if (['"', "'", '`'].includes(char)) {
                                    //Sring literal is detected
                                    string_char = char;
                                    state = 5;
                                }
                            } else if (state == 1) {
                                //Found comment start character, classify next character and
                                //determine if single or multi-line comment.
                                if (char == '*') {
                                    comment_start = line_counter;
                                    state = 3;
                                } else {
                                    state = 0;
                                }
                            } else if (state == 3) {
                                //In multi-line comment, add characters until '*' is
                                //encountered.
                                if (char == '*') {
                                    state = 4;
                                } else {
                                    current_comment += char;
                                }
                            } else if (state == 4) {
                                //In multi-line comment with asterisk found. Determine if
                                //comment is ending.
                                if (char == '/') {
                                    let comment = {
                                        comment: current_comment,
                                        line: comment_start,
                                    };
                                    comments.push(comment);
                                    current_comment = '';
                                    state = 0;
                                } else {
                                    current_comment += '*';
                                    // Care for multiple '*' in a row
                                    if (char != '*') {
                                        current_comment += char;
                                        state = 3;
                                    }
                                }
                            } else if (state == 5) {
                                // In string literal, expect literal end or escape character.
                                if (char == string_char) {
                                    state = 0;
                                } else if (char == '\\') {
                                    state = 6;
                                }
                            } else if (state == 6) {
                                // In string literal, escaping current char.
                                state = 5;
                            }
                            if (char == '\n') {
                                line_counter += 1;
                            }
                        }
                        all_comments.push(...comments);

                        break;
                    }
                    case 'regex': {
                        let reg_delimiter: any = delimiter;
                        let each_comment;
                        let comments = [];
                        while ((each_comment = reg_delimiter.exec(code))) {
                            let line = 1;
                            for (let i = 0; i < each_comment.index; i++) {
                                if (code[i] == '\n') {
                                    line++;
                                }
                            }
                            let comment = {
                                comment: each_comment[0],
                                line: line,
                            };
                            comments.push(comment);
                        }
                        all_comments.push(...comments);
                        break;
                    }
                    default:
                        continue;
                }
            }
        }

        return all_comments;
    }
    // check strings in array are present in another string
    public checkArrayInString(str: string, arr: string[]) {
        return arr.some((each) => {
            if (str.includes(each)) {
                return true;
            } else {
                return false;
            }
        });
    }

    public updateEditCount(ques: any) {
        if (this.testData && this.testData.c_modules && this.testData.c_modules.answer_edit_restrict) {
            if (ques) {
                if (ques.program_submitted_count > 1) {
                    this.testData.curr_submit_count += 1;
                } else if (!this.testData.curr_submit_count) {
                    this.testData.curr_submit_count = 0;
                }
            }
            this.testData.max_edit_allowed = this.testData.c_modules.max_edit_count - this.testData.curr_submit_count;
        }
    }

    private vectorAvg(v1, v2) {
        return [
            (v1[0] + v2[0]) / 2,
            (v1[1] + v2[1]) / 2
        ];
    }

    private vectorSubtract(v1, v2) {
        return [
            v1[0] - v2[0],
            v1[1] - v2[1],
        ];
    }

    private vectorAddition(...args) {
        const out = [0, 0];
        for (const v of args) {
            out[0] += v[0];
            out[1] += v[1];
        }
        return out;
    }

    private vectorMultiply(v, m) {
        return [
            v[0] * m,
            v[1] * m
        ];
    }

    private calcangle(origin, pointA, pointB) {
        let slope1 = ((pointA[1] - origin[1]) / (pointA[0] - origin[0]));
        let slope2 = ((pointB[1] - origin[1]) / (pointB[0] - origin[0]));
        let angle = Math.abs(
            (
                (slope1 - slope2) /
                (1 + (slope1 * slope2))
            )
        );
        angle = Math.atan(angle) * 180 / Math.PI;
        return angle;
    }

    public estimatePoseForBlazeFace(landmarks) {
        try {
            const nose = landmarks[2];
            const rightEar = landmarks[4];
            const leftEar = landmarks[5];

            // Calculate the XYZ Axes
            const origin = this.vectorAvg(leftEar, rightEar);
            const unitZ = this.vectorSubtract(nose, origin);
            const unitX = this.vectorMultiply(this.vectorSubtract(rightEar, origin), 1 / this.BLAZEFACE_CONFIG.MODEL_HEAD_EAR_COORD_X);

            // Calculate Angles
            const xzAngle = this.calcangle([0, 0, 0], unitZ, unitX);

            if (xzAngle < this.BLAZEFACE_CONFIG.minAngleConfidence) {
                // Turning Left or right
                return 'd';
            }
        } catch (error) {
        }
        return 'ok';
    }

    public getBlobFromImageUrl(url: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            return this.http.get(url, { responseType: 'blob' }).subscribe(
                (response: any) => {
                    resolved(response);
                },
                (_errored: any) => {
                    resolved(false);
                },
            );
        });
    }

    /***
     * Funtion to get default scaffolding for frontendtechnology
     */
    getFrontEndLanguage() {
        return new Promise((resolved, _rejected) => {
            let url: string = environment.s3Objectstudentassets + 'assets/fetech.json';
            return this.http.get(url).subscribe(
                (response: any) => {
                    resolved(response);
                },
                (_errored: any) => {
                    resolved(false);
                },
            );
        });
    }

    generateProctorFileName(fileType: FileType): string {
        const epoch: any = this.globalService.convert_IST_TO_EPOCH(
            this.testData.instanceTime,
        );
        const fileName =
            this.userData.user_id +
            '/' +
            'remote-proctoring/' +
            this.schoolData.school_id +
            '/' +
            this.testData.c_id +
            '/' +
            this.testData.t_id +
            '/' +
            this.userData.user_id +
            '/' +
            this.testData.attempt_no;
        switch (fileType) {
            case 'image' : return fileName + `/images/image_${epoch}-curr_time-${this.testData.t_counter}`;
            case 'audio' : return fileName + `/audios/audio_${epoch}-curr_time-${this.testData.t_counter}`;
            case 'video': return fileName + `/videos/video_${epoch}-curr_time-${this.testData.t_counter}`;
            case 'screen': return fileName + `/screen_${epoch}-curr_time-${this.testData.t_counter}`;
        }
    }
    /**
     * Function to get Jaas Jitsi data
     * @param request Request Payload
     */
    public getLiveProctoringData(Payload?: string) {
        var request: any
        if (Payload){
            request = Payload
        } else {
            request = {
            c_id: this.testData.c_id,
            t_id: this.testData.t_id
            }
        }
    return this.http.post(this.api_link + '/student/test/liveProctoring', request, this.ls.options(this.userData));
    }

    /**
     * Function to get Jaas Jitsi data
     * @param request Request Payload
     */
    public getLiveScreenShareData() {
          let request = {
            c_id: this.testData.c_id,
            t_id: this.testData.t_id
            }
        return this.http.post(this.api_link + '/student/test/liveScreenShare', request, this.ls.options(this.userData));
    }
    public getLiveScreenShareDataTwilio() {
          let request = {
            c_id: this.testData.c_id,
            t_id: this.testData.t_id
            }
        return this.http.post(this.api_link + '/student/test/liveScreenShareTwilio', request, this.ls.options(this.userData));
    }

    public sendFileForAudioAnalysis(fileName: string) {
        const payload = {
            file_name: fileName
        };
        return this.http.post(this.recognLink + '/recognition', payload);
    }
    public sendMessageToSocket(payload: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            this.http.post(this.api_link + '/tests/sendMessageWS', payload, this.httpRequestOptions()).subscribe(
                async (response: any) => {
                    resolved(response);
                },
                (_errored: any) => {
                    resolved(false);
                },
            );
        });
    }

    public reOpenProject(payload) {
        return this.http.post(this.api_link + '/courses/reopenproject', payload, this.httpRequestOptions());
    }

    public commitToGit(payload) {
        return this.http.post(this.api_link + '/courses/savetogit', payload , this.httpRequestOptions());
    }
    public getBuildStatus(Payload){
        return this.http.post(this.api_link + '/courses/getBuildStatus', Payload, this.httpRequestOptions());
    }

    public getUserGithubDetail(){
        return this.http.get(this.api_link + '/users/getGithubDetail', this.httpRequestOptions());
    }

    public sendStudentTestData(payload){
        return this.http.post(this.api_link + '/sendTestDatathroughAPI', payload , this.httpRequestOptions());
    }

    public cloudProviderHandler(payload){
        return this.http.post(this.cloud_provider+ '/cloud-question-provider-handler', payload , this.httpRequestOptions());
    }
    
    public switchS3bucket(data: any) {
        data = JSON.stringify(data);
        data = data.replace(new RegExp(this.globalService.bucket + '/', 'g'), this.globalService.content_bucket + '/');
        // if (data.includes(this.globalService.content_bucket + '/cloudlab_starters/')) {
        //     data = this.switchCloudQuesS3bucket(data);
        // }
        return JSON.parse(data);
    }

    public getLiveTestData(payload: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            this.http.post(this.api_link + '/getTestLiveData', payload, this.httpRequestOptions()).subscribe(
                async (response: any) => {
                    resolved(response);
                },
                (errored: any) => {
                    resolved({
                        success : true,
                    });
                },
            );
        });
    }
    public switchCloudQuesS3bucket(data:any){
        return data.replace(new RegExp(this.globalService.content_bucket + '/cloudlab_starters/', 'g'), this.globalService.bucket + '/cloudlab_starters/');
    }
    public getclipboard(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            window.navigator['clipboard'].readText().then((event:any) => {                
                this.clipboard_data = event;
                resolve(true)
            }).catch((e:any) => {
                reject(e)
            });
                         
        });
    }

    public setCurrentQuesForCopyPaste() {
        if (this.testData.current_q) {
            if (this.testData.current_q.question_type === "programming_file_based") {
                if (this.testData.current_q.langList[this.testData.current_q.langListIndex].files) {
                    this.currAnswer = this.testData.current_q.langList[this.testData.current_q.langListIndex].files[0].data;
                } else {
                    this.currAnswer = null;
                }
            }
            else if (this.testData.current_q.question_type === "programming") {
                if (this.testData.current_q.langList[this.testData.current_q.langListIndex]) {
                    this.currAnswer = this.testData.current_q.langList[this.testData.current_q.langListIndex].codeEntered;
                } else {
                    this.currAnswer = null;
                }
            } else if( this.testData.current_q.question_type === "html_css_js")
            {
                this.currAnswer = {};
                this.currAnswer.htmlCodeEntered = this.testData.current_q.answer.htmlCodeEntered;
                this.currAnswer.cssCodeEntered = this.testData.current_q.answer.cssCodeEntered;
                this.currAnswer.jsCodeEntered = this.testData.current_q.answer.jsCodeEntered;
            } else if(
                this.testData.current_q.question_type === "essay_email_writing" || 
                this.testData.current_q.question_type === "descriptive_answer_writing_text" || 
                this.testData.current_q.question_type === "descriptive_answer_writing"
            ){
                this.currAnswer = this.testData.current_q.answer
            } else {
                this.currAnswer = null;
            }
            this.allowcopycheck = true;
        }
    }

    logoutAfterUpload() {
        let payload: any = {};
        if (this.userData && this.userData.user_id) {
            payload.user_id = this.userData.user_id;
            payload.school_id = this.userData.school_id;
            payload.user_role = 'student';
            payload.email = this.userData.email;
        }
        this.ls.logout(payload).subscribe((data: any) => {
            this.globalService.clearLocalStorage();                                
        });
    }
    public switchBucket(data: any) {
        data = JSON.stringify(data);
        data = data.replace(new RegExp(this.globalService.content_bucket + '/', 'g'), this.globalService.bucket + '/');
        return JSON.parse(data);  
    }

    formatBucktForFrozenData(frozenData) {
        return new Promise((resolve, reject) => {
            if(frozenData) {
                frozenData.forEach((data) => {
                    let questionsData = data.questions.map((question) => {
                            question = this.switchS3bucket(question);
                            if(question.answer) {
                                question.answer = this.switchBucket(question.answer);
                            }
                            if(question.last_event) {
                                question.last_event = this.switchBucket(question.last_event);
                            }
                            return question;
                        });
                        data.questions = questionsData;
                    });
            }
            resolve(frozenData)
        })
    }

    public populateData(test_detail_overview:any, section_table:any, moblieSectionList:any) {
        this.testData.template_data.sections.forEach((section: any) => {
          const data = {
            Sections: section.name,
            Questions: section.Questions,
            Duration: section.Durations,
            Marks: Math.round(section.Marks * 100) / 100,
          };
          if (
            this.testData.c_modules.practise_test ||
            this.testData.c_modules.disable_timer
          ) {
            data.Duration = "Unlimited";
          }
          if (section.Max_Attemptable) {
            data.Questions =
              section.Max_Attemptable + " out of " + section.Questions;
          }
          test_detail_overview[0].value += 1;
          if (section.Max_Attemptable) {
            test_detail_overview[1].value += section.Max_Attemptable;
          } else {
            test_detail_overview[1].value += data.Questions;
          }
          this.setDuration(data, test_detail_overview);
          let SummaryData = [];
          SummaryData.push({
            label: "Questions",
            value: data.Questions,
          });
          SummaryData.push({
            label: "Durations",
            value: data.Duration,
          });
          SummaryData.push({
            label: "Marks",
            value: data.Marks,
          });
          const summaryDatas = { ...data, sectionArray: SummaryData };
          section_table.data.push(summaryDatas);
          moblieSectionList.push(summaryDatas);
        });
        test_detail_overview[3].value =
          Math.round(test_detail_overview[3].value * 100) / 100;
        if (test_detail_overview[2].value !== "Unlimited") {
          test_detail_overview[2].value += " m";
        }
        const totalData = [
            {
                Sections: "total",
                Durations: this.getValueByTitle(test_detail_overview, 'Duration'),
                Questions: this.getValueByTitle(test_detail_overview, 'Questions'),
                Marks: this.getValueByTitle(test_detail_overview, 'Marks'),
            },
        ];

        const sectionTable = section_table.data.map(obj => {
            const { Duration, ...rest } = obj;
            return { Durations: Duration, ...rest };
        });

        section_table.data = [...sectionTable, ...totalData]
    }
    
    setDuration(data, test_detail_overview) {
        if (data.Duration !== "Unlimited") {
          test_detail_overview[2].value +=
            typeof data.Duration === "string" && data.Duration !== "Unlimited"
              ? Number(data.Duration.substring(0, data.Duration.length - 2))
              : data.Duration;
        } else {
          test_detail_overview[2].value = "Unlimited";
        }
        test_detail_overview[3].value += data.Marks;
        if (typeof data.Duration === "number") {
          data.Duration = data.Duration + " m";
        }
    }

    getValueByTitle(test_detail_overview, title) {
        return test_detail_overview.find(obj => obj.title === title).value;
    }

    leaderboardErrorTrack(payload) {
        return this.http.post(this.api_link + '/leaderboardErrorTrack', payload , this.httpRequestOptions());
    }
}
