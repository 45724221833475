import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FieldBuilderComponent } from './field-builder/field-builder.component';
import { TextBoxComponent } from './form-elements/textbox/textbox';
import { RadioComponent } from './form-elements/radio/radio';
import { DropDownComponent } from './form-elements/dropdown/dropdown';
import { CheckBoxComponent } from './form-elements/checkbox/checkbox';
import { EmailComponent } from './form-elements/email/email';
import { OTPBoxComponent } from './form-elements/otp/otp';
import { FormBuilderComponent } from './form-builder.component';
import { LinkTestModule } from '../link-test/link-test.module';
import { ButtonModule } from '../button/button.module';
import { FileUploadComponent } from './form-elements/file-upload/file-upload';
import { PolicyComponent } from './form-elements/policy/policy';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import { FormatTimeeModule } from 'src/app/pipes/formattime.module';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LinkTestModule,
        ButtonModule,
        PdfViewerModule,
        InputTextModule,
        CheckboxModule,
        RadioButtonModule,
        DropdownModule,
        CalendarModule,
        FormatTimeeModule,
        LoaderModule
    ],
    declarations: [
        FormBuilderComponent,
        FieldBuilderComponent,
        TextBoxComponent,
        EmailComponent,
        RadioComponent,
        DropDownComponent,
        CheckBoxComponent,
        FileUploadComponent,
        PolicyComponent,
        OTPBoxComponent,
    ],
    exports: [
        FormBuilderComponent,
        FieldBuilderComponent,
        TextBoxComponent,
        EmailComponent,
        RadioComponent,
        DropDownComponent,
        CheckBoxComponent,
        FileUploadComponent,
        PolicyComponent,
        OTPBoxComponent,
    ],
})
export class FormBuilderModule {}
