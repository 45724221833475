import { NgZone, NgModule, ModuleWithProviders, Inject, InjectionToken, Injectable } from '@angular/core';
import { of } from "rxjs";
import { Route } from '@angular/router';

export const REQUEST_IDLE_CALLBACK = new InjectionToken<string>('REQUEST_IDLE_CALLBACK');

export function __requestIdle(zone: NgZone) {
  if (typeof window === 'undefined') {
    return (fn) => setTimeout(fn);
  }
  let win: any = window;
  if (win.requestIdleCallback) {
    return (fn) => win.requestIdleCallback(fn);
  }
  return (fn) => zone.runOutsideAngular(() => win.setTimeout(fn, 10));
}

@Injectable()

export class IdlePreload /*implements PreloadingStrategy*/ {
    constructor(private _ngZone: NgZone, @Inject(REQUEST_IDLE_CALLBACK) private requestIdleCallback: any) {}
    loadingRoute = new Set<Route>();
    loading = true;

  preload(route: /*Route*/ any, fn: any /* () => Observable<any>*/ ): any/* Observable<any> */ {

    if (this.loadingRoute.has(route)) {
        this.loading = false;
    }

    const conn = typeof navigator !== 'undefined' ? (navigator as any).connection : undefined;
    if (conn) {
        if ((conn.effectiveType || '').includes('2g') || (conn.effectiveType || '').includes('3g') || conn.saveData) { this.loading = false};
    }

    if (this.loading && route.data && route.data.preload) {
        this.requestIdleCallback(fn);
        return of(null);
    }
  }
}

export const IDLE_PRELOAD_PROVIDERS: any[] = [
  { provide: IdlePreload, useClass: IdlePreload, deps: [ NgZone, REQUEST_IDLE_CALLBACK ] }
];

export const REQUEST_IDLE_CALLBACK_PROVIDERS: any[] = [
  { provide: REQUEST_IDLE_CALLBACK, useFactory: __requestIdle, deps: [ NgZone ] }
];

export interface IdlePreloadConfig {
  requestIdleCallback?: boolean;
}

@NgModule({
})
export class IdlePreloadModule {
  static forRoot(config: IdlePreloadConfig = {}): ModuleWithProviders<IdlePreloadModule> {
    return {
      ngModule: IdlePreloadModule,
      providers: [
        ...(config.requestIdleCallback === false ? [] : REQUEST_IDLE_CALLBACK_PROVIDERS),
        ...IDLE_PRELOAD_PROVIDERS
      ]
    };
  }

  static IdleStrategy() {
    return IdlePreload;
  }

}