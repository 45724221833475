import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, throwError, catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';

declare let shaka: any;
declare let gtag: Function;
declare let html2canvas: any;
declare let jspdf: any;

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    public commonGrowl = new BehaviorSubject<object>({});
    verifiedOtp: boolean = false;
    api = environment.HOST.link;
    abcSchool = ['2dc2d7d3-f10f-4f51-ba1e-148f87cd94d0', '158608c5-6868-4850-8baf-f1da3d041bb2'];
    public timezones = [
        { label: '(GMT -12:00) Eniwetok, Kwajalein', value: '-12:00' },
        { label: '(GMT -11:00) Midway Island, Samoa', value: '-11:00' },
        { label: '(GMT -10:00) Hawaii', value: '-10:00' },
        { label: '(GMT -9:30) Taiohae', value: '-09:30' },
        { label: '(GMT -9:00) Alaska', value: '-09:00' },
        { label: '(GMT -8:00) Pacific Time (US & Canada)', value: '-08:00' },
        { label: '(GMT -7:00) Mountain Time (US & Canada)', value: '-07:00' },
        { label: '(GMT -6:00) Central Time (US & Canada), Mexico City', value: '-06:00' },
        { label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima', value: '-05:00' },
        { label: '(GMT -4:30) Caracas', value: '-04:30' },
        { label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz', value: '-04:00' },
        { label: '(GMT -3:30) Newfoundland', value: '-03:30' },
        { label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown', value: '-03:00' },
        { label: '(GMT -2:00) Mid-Atlantic', value: '-02:00' },
        { label: '(GMT -1:00) Azores, Cape Verde Islands', value: '-01:00' },
        { label: '(GMT) Western Europe Time, London, Lisbon, Casablanca', value: '+00:00' },
        { label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris', value: '+01:00' },
        { label: '(GMT +2:00) Kaliningrad, South Africa', value: '+02:00' },
        { label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg', value: '+03:00' },
        { label: '(GMT +3:30) Tehran', value: '+03:30' },
        { label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', value: '+04:00' },
        { label: '(GMT +4:30) Kabul', value: '+04:30' },
        { label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent', value: '+05:00' },
        { label: '(GMT +5:30) Mumbai, Kolkata, Chennai, New Delhi', value: '+05:30' },
        { label: '(GMT +5:45) Kathmandu, Pokhara', value: '+05:45' },
        { label: '(GMT +6:00) Almaty, Dhaka, Colombo', value: '+06:00' },
        { label: '(GMT +6:30) Yangon, Mandalay', value: '+06:30' },
        { label: '(GMT +7:00) Bangkok, Hanoi, Jakarta', value: '+07:00' },
        { label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong', value: '+08:00' },
        { label: '(GMT +8:45) Eucla', value: '+08:45' },
        { label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk', value: '+09:00' },
        { label: '(GMT +9:30) Adelaide, Darwin', value: '+09:30' },
        { label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok', value: '+10:00' },
        { label: '(GMT +10:30) Lord Howe Island', value: '+10:30' },
        { label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia', value: '+11:00' },
        { label: '(GMT +11:30) Norfolk Island', value: '+11:30' },
        { label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka', value: '+12:00' },
        { label: '(GMT +12:45) Chatham Islands', value: '+12:45' },
        { label: '(GMT +13:00) Apia, Nukualofa', value: '+13:00' },
        { label: '(GMT +14:00) Line Islands, Tokelau', value: '+14:00' },
    ];
    public symbols = [
        '¡',
        '¢',
        '£',
        '¤',
        '¥',
        '¦',
        '§',
        '¨',
        '©',
        'ª',
        '«',
        '¬',
        '®',
        '¯',
        '°',
        '±',
        '²',
        '³',
        '⨯',
        '´',
        'µ',
        '¶',
        '·',
        '¸',
        '¹',
        'º',
        '»',
        '¼',
        '½',
        '¾',
        '¿',
        'À',
        'Á',
        'Â',
        'Ã',
        'Ä',
        'Å',
        'Æ',
        'Ç',
        'È',
        'É',
        'Ê',
        'Ë',
        'Ì',
        'Í',
        'Î',
        'Ï',
        'Ð',
        'Ñ',
        'Ò',
        'Ó',
        'Ô',
        'Õ',
        'Ö',
        '×',
        'Ø',
        'Ù',
        'Ú',
        'Û',
        'Ü',
        'Ý',
        'Þ',
        'ß',
        'à',
        'á',
        'â',
        'ã',
        'ä',
        'å',
        'æ',
        'ç',
        'è',
        'é',
        'ê',
        'ë',
        'ì',
        'í',
        'î',
        'ï',
        'ð',
        'ñ',
        'ò',
        'ó',
        'ô',
        'õ',
        'ö',
        '÷',
        'ø',
        'ù',
        'ú',
        'û',
        'ü',
        'ý',
        'þ',
        'ÿ',
        'Ā',
        'ā',
        'Ă',
        'ă',
        'Ą',
        'ą',
        'Ć',
        'ć',
        'Ĉ',
        'ĉ',
        'Ċ',
        'ċ',
        'Č',
        'č',
        'Ď',
        'ď',
        'Đ',
        'đ',
        'Ē',
        'ē',
        'Ė',
        'ė',
        'Ę',
        'ę',
        'Ě',
        'ě',
        'Ĝ',
        'ĝ',
        'Ğ',
        'ğ',
        'Ġ',
        'ġ',
        'Ģ',
        'Ĥ',
        'ĥ',
        'Ħ',
        'ħ',
        'Ĩ',
        'ĩ',
        'Ī',
        'ī',
        'Į',
        'į',
        'İ',
        'ı',
        'Ĳ',
        'ĳ',
        'Ĵ',
        'ĵ',
        'Ķ',
        'ķ',
        'ĸ',
        'Ĺ',
        'ĺ',
        'Ļ',
        'ļ',
        'Ľ',
        'ľ',
        'Ŀ',
        'ŀ',
        'Ł',
        'ł',
        'Ń',
        'ń',
        'Ņ',
        'ņ',
        'Ň',
        'ň',
        'ŉ',
        'Ŋ',
        'ŋ',
        'Ō',
        'ō',
        'Ő',
        'ő',
        'Œ',
        'œ',
        'Ŕ',
        'ŕ',
        'Ŗ',
        'ŗ',
        'Ř',
        'ř',
        'Ś',
        'ś',
        'Ŝ',
        'ŝ',
        'Ş',
        'ş',
        'Š',
        'š',
        'Ţ',
        'ţ',
        'Ť',
        'ť',
        'Ŧ',
        'ŧ',
        'Ũ',
        'ũ',
        'Ū',
        'ū',
        'Ŭ',
        'ŭ',
        'Ů',
        'ů',
        'Ű',
        'ű',
        'Ų',
        'ų',
        'Ŵ',
        'ŵ',
        'Ŷ',
        'ŷ',
        'Ÿ',
        'Ź',
        'ź',
        'Ż',
        'ż',
        'Ž',
        'ž',
        'ƒ',
        'Ƶ',
        'ǵ',
        'ȷ',
        'ˆ',
        'ˇ',
        '˘',
        '˙',
        '˚',
        '˛',
        '˜',
        'Λ',
        'Π',
        'Σ',
        'Φ',
        'Ψ',
        'Ω',
        'α',
        'β',
        'γ',
        'δ',
        'ε',
        'ζ',
        'η',
        'θ',
        'ι',
        'κ',
        'λ',
        'μ',
        'ν',
        'ξ',
        'ο',
        'π',
        'ρ',
        'ς',
        'σ',
        'τ',
        'υ',
        'φ',
        'χ',
        'ψ',
        'ω',
        'ϑ',
        'ϒ',
        'ϕ',
        'ϖ',
        'Ϝ',
        'ϝ',
        'ϰ',
        'ϱ',
        'ϵ',
        '϶',
        'Ё',
        'Ђ',
        'Ѓ',
        'Є',
        'Ѕ',
        'І',
        'Ї',
        'Ј',
        'Љ',
        'Њ',
        'Ћ',
        'Ќ',
        'Ў',
        'Џ',
        'А',
        'Б',
        'В',
        'Г',
        'Д',
        'Е',
        'Ж',
        'З',
        'И',
        'Й',
        'К',
        'Л',
        'М',
        'Н',
        'О',
        'П',
        'Р',
        'С',
        'Т',
        'У',
        'Ф',
        'Х',
        'Ц',
        'Ч',
        'Ш',
        'Щ',
        'Ъ',
        'Ы',
        'Ь',
        'Э',
        'Ю',
        'Я',
        'а',
        'б',
        'в',
        'г',
        'д',
        'е',
        'ж',
        'з',
        'и',
        'й',
        'к',
        'л',
        'м',
        'н',
        'о',
        'п',
        'р',
        'с',
        'т',
        'у',
        'ф',
        'х',
        'ц',
        'ч',
        'ш',
        'щ',
        'ъ',
        'ы',
        'ь',
        'э',
        'ю',
        'я',
        'ё',
        'ђ',
        'ѓ',
        'є',
        'ѕ',
        'і',
        'ї',
        'ј',
        'љ',
        'њ',
        'ћ',
        'ќ',
        'ў',
        'џ',
        '‐',
        '–',
        '—',
        '―',
        '‖',
        '‘',
        '’',
        '‚',
        '“',
        '”',
        '„',
        '†',
        '‡',
        '•',
        '‥',
        '…',
        '‰',
        '‱',
        '′',
        '″',
        '‴',
        '‵',
        '‹',
        '›',
        '‾',
        '⁁',
        '⁃',
        '⁄',
        '⁏',
        '⁗',
        '€',
        '℅',
        '№',
        '℗',
        'ℝ',
        '℞',
        '™',
        'ℤ',
        'Ω',
        '℧',
        'ℨ',
        '℩',
        'Å',
        'ℵ',
        'ℶ',
        'ℷ',
        'ℸ',
        '⅓',
        '⅔',
        '⅕',
        '⅖',
        '⅗',
        '⅘',
        '⅙',
        '⅚',
        '⅛',
        '⅜',
        '⅝',
        '⅞',
        '←',
        '↑',
        '→',
        '↓',
        '↔',
        '↕',
        '↖',
        '↗',
        '↘',
        '↙',
        '↚',
        '↛',
        '↝',
        '↞',
        '↟',
        '↠',
        '↡',
        '↢',
        '↣',
        '↤',
        '↥',
        '↦',
        '↧',
        '↩',
        '↪',
        '↫',
        '↬',
        '↭',
        '↮',
        '↰',
        '↱',
        '↲',
        '↳',
        '↵',
        '↶',
        '↷',
        '↺',
        '↻',
        '↼',
        '↽',
        '↾',
        '↿',
        '⇀',
        '⇁',
        '⇂',
        '⇃',
        '⇄',
        '⇅',
        '⇆',
        '⇇',
        '⇈',
        '⇉',
        '⇊',
        '⇋',
        '⇌',
        '⇍',
        '⇎',
        '⇏',
        '⇐',
        '⇑',
        '⇒',
        '⇓',
        '⇔',
        '⇕',
        '⇖',
        '⇗',
        '⇘',
        '⇙',
        '⇚',
        '⇛',
        '⇝',
        '⇤',
        '⇥',
        '⇵',
        '⇽',
        '⇾',
        '⇿',
        '∀',
        '∁',
        '∂',
        '∃',
        '∄',
        '∅',
        '∇',
        '∈',
        '∉',
        '∋',
        '∌',
        '∏',
        '∐',
        '∑',
        '−',
        '∓',
        '∔',
        '∖',
        '∗',
        '∘',
        '√',
        '∝',
        '∞',
        '∟',
        '∠',
        '∡',
        '∢',
        '∣',
        '∤',
        '∥',
        '∦',
        '∧',
        '∨',
        '∩',
        '∪',
        '∫',
        '∬',
        '∭',
        '∮',
        '∯',
        '∰',
        '∱',
        '∲',
        '∳',
        '∴',
        '∵',
        '∶',
        '∷',
        '∸',
        '∺',
        '∻',
        '∼',
        '∽',
        '∾',
        '∿',
        '≀',
        '≁',
        '≂',
        '≃',
        '≄',
        '≅',
        '≆',
        '≇',
        '≈',
        '≉',
        '≊',
        '≋',
        '≌',
        '≍',
        '≎',
        '≏',
        '≐',
        '≑',
        '≒',
        '≓',
        '≔',
        '≕',
        '≖',
        '≗',
        '≙',
        '≚',
        '≜',
        '≟',
        '≠',
        '≡',
        '≢',
        '≤',
        '≥',
        '≦',
        '≧',
        '≨',
        '≩',
        '≪',
        '≫',
        '≬',
        '≭',
        '≮',
        '≯',
        '≰',
        '≱',
        '≲',
        '≳',
        '≴',
        '≵',
        '≶',
        '≷',
        '≸',
        '≹',
        '≺',
        '≻',
        '≼',
        '≽',
        '≾',
        '≿',
        '⊀',
        '⊁',
        '⊂',
        '⊃',
        '⊄',
        '⊅',
        '⊆',
        '⊇',
        '⊈',
        '⊉',
        '⊊',
        '⊋',
        '⊍',
        '⊎',
        '⊏',
        '⊐',
        '⊑',
        '⊒',
        '⊓',
        '⊔',
        '⊕',
        '⊖',
        '⊗',
        '⊘',
        '⊙',
        '⊚',
        '⊛',
        '⊝',
        '⊞',
        '⊟',
        '⊠',
        '⊡',
        '⊢',
        '⊣',
        '⊤',
        '⊥',
        '⊧',
        '⊨',
        '⊩',
        '⊪',
        '⊫',
        '⊬',
        '⊭',
        '⊮',
        '⊯',
        '⊰',
        '⊲',
        '⊳',
        '⊴',
        '⊵',
        '⊶',
        '⊷',
        '⊸',
        '⊹',
        '⊺',
        '⊻',
        '⊽',
        '⊾',
        '⊿',
        '⋀',
        '⋁',
        '⋂',
        '⋃',
        '⋄',
        '⋅',
        '⋆',
        '⋇',
        '⋈',
        '⋉',
        '⋊',
        '⋋',
        '⋌',
        '⋍',
        '⋎',
        '⋏',
        '⋐',
        '⋑',
        '⋒',
        '⋓',
        '⋔',
        '⋕',
        '⋖',
        '⋗',
        '⋘',
        '⋙',
        '⋚',
        '⋛',
        '⋞',
        '⋟',
        '⋠',
        '⋡',
        '⋢',
        '⋣',
        '⋦',
        '⋧',
        '⋨',
        '⋩',
        '⋪',
        '⋫',
        '⋬',
        '⋭',
        '⋮',
        '⋯',
        '⋰',
        '⋱',
        '⋲',
        '⋳',
        '⋴',
        '⋵',
        '⋶',
        '⋷',
        '⋹',
        '⋺',
        '⋻',
        '⋼',
        '⋽',
        '⋾',
        '⌅',
        '⌆',
        '⌈',
        '⌉',
        '⌊',
        '⌋',
        '⌌',
        '⌍',
        '⌎',
        '⌏',
        '⌐',
        '⌒',
        '⌓',
        '⌕',
        '⌖',
        '⌜',
        '⌝',
        '⌞',
        '⌟',
        '⌢',
        '⌣',
        '⌭',
        '⌮',
        '⌶',
        '⌽',
        '⌿',
        '⍼',
        '⎰',
        '⎱',
        '⎴',
        '⎵',
        '⎶',
        '⏜',
        '⏝',
        '⏟',
        '⏢',
        '⏧',
        '␣',
        'Ⓢ',
        '─',
        '│',
        '┌',
        '┐',
        '└',
        '┘',
        '├',
        '┤',
        '┬',
        '┴',
        '┼',
        '═',
        '║',
        '╒',
        '╓',
        '╔',
        '╕',
        '╖',
        '╗',
        '╘',
        '╙',
        '╚',
        '╛',
        '╜',
        '╝',
        '╞',
        '╟',
        '╠',
        '╡',
        '╢',
        '╣',
        '╤',
        '╥',
        '╦',
        '╧',
        '╨',
        '╩',
        '□',
        '▪',
        '▫',
        '▭',
        '▮',
        '▱',
        '△',
        '▴',
        '▵',
        '▸',
        '▹',
        '▽',
        '▾',
        '▿',
        '◂',
        '◃',
        '◊',
        '○',
        '◬',
        '◯',
        '◸',
        '◹',
        '◺',
        '◻',
        '◼',
        '★',
        '☆',
        '♀',
        '♂',
        '♠',
        '♣',
        '♥',
        '♦',
        '♪',
        '♭',
        '♮',
        '♯',
        '✓',
        '✗',
        '✠',
        '✶',
        '❘',
        '⟵',
        '⟶',
        '⟷',
        '⟸',
        '⟹',
        '⟺',
        '⟼',
        '⟿',
        '⤂',
        '⤃',
        '⤄',
        '⤅',
        '⤌',
        '⤍',
        '⤎',
        '⤏',
        '⤐',
        '⤑',
        '⤒',
        '⤓',
        '⤖',
        '⤙',
        '⤚',
        '⤛',
        '⤜',
        '⤝',
        '⤞',
        '⤟',
        '⤠',
        '⤣',
        '⤤',
        '⤥',
        '⤦',
        '⤧',
        '⤨',
        '⤩',
        '⤪',
        '⤳',
        '⤵',
        '⤶',
        '⤷',
        '⤸',
        '⤹',
        '⤼',
        '⤽',
        '⥅',
        '⥈',
        '⥉',
        '⥊',
        '⥋',
        '⥎',
        '⥏',
        '⥐',
        '⥑',
        '⥒',
        '⥓',
        '⥔',
        '⥕',
        '⥖',
        '⥗',
        '⥘',
        '⥚',
        '⥛',
        '⥜',
        '⥝',
        '⥞',
        '⥟',
        '⥡',
        '⥢',
        '⥣',
        '⥤',
        '⥥',
        '⥦',
        '⥧',
        '⥨',
        '⥩',
        '⥪',
        '⥫',
        '⥬',
        '⥭',
        '⥮',
        '⥯',
        '⥰',
        '⥱',
        '⥲',
        '⥳',
        '⥴',
        '⥵',
        '⦳',
        '⦴',
        '⦵',
        '⦶',
        '⦷',
        '⦹',
        '⦻',
        '⦼',
        '⦾',
        '⦿',
        '⧀',
        '⧁',
        '⧂',
        '⧃',
        '⧄',
        '⧅',
        '⧉',
        '⧍',
        '⧎',
        '⧏',
        '⧐',
        '⧚',
        '⧜',
        '⧝',
        '⧞',
        '⧫',
        '⧴',
        '⧶',
        '⨀',
        '⨁',
        '⨂',
        '⨄',
        '⨆',
        '⨌',
        '⨍',
        '⨐',
        '⨑',
        '⨒',
        '⨓',
        '⨔',
        '⨕',
        '⨖',
        '⨗',
        '⨢',
        '⨣',
        '⨤',
        '⨥',
        '⨦',
        '⨧',
        '⨩',
        '⨪',
    ];
    private api_link = environment.HOST.link;
    private html2canvas: any;
    private jspdf: any;
    isTeamCourse: boolean;
    showTop = new BehaviorSubject<any>(false);
    showHeader = new BehaviorSubject<any>(false);
    enableSignUp = new BehaviorSubject<any>(false);
    activetab = new BehaviorSubject<any>('');
    isSignup = new BehaviorSubject<any>(false);
    ispublicCourse = new BehaviorSubject<any>(false);
    isPublicFeeds = new BehaviorSubject<any>(false);
    isPublicFeedsandCourses = new BehaviorSubject<any>(false);
    isAllowFeedsMAin = new BehaviorSubject<any>(false);
    logoutTrigger = new BehaviorSubject<any>(false);
    cartTotalItems: any = new BehaviorSubject<any>(0);
    search: any = new BehaviorSubject<any>({});
    schoolLogo: any = new BehaviorSubject<any>('');
    schoolName: any = new BehaviorSubject<any>('');
    publishType: any = new BehaviorSubject<any>('');
    confirmFlag: any = new BehaviorSubject<any>(false);
    confirmFlagLogOff: any = new BehaviorSubject<any>(false);
    previousUrl = '/';
    loginUrls: any = ['/attendancesettings', '/course', '/cart', '/accountsettings', '/dashboard'];
    publicUrls: any = [
        '/result',
        '/contest/public',
        '/setpassword',
        '/forgot',
        'contest/account',
        '/loginredirect',
        '/course/product',
        '/resetpassword',
        '/mycourses/details',
        '/mydrivedetails',
        '/mycdetails',
        '/test',
        'no-network',
        '/compiler-instruction',
    ];
    sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();
    searchUrl = '';
    contestToTestSchoolId = ['a8037be9-b7d2-4a86-ae87-9b160f7c81a9', '69cb8138-b47b-4cd0-98b8-44dbd5da2b3c', 'e50db77c-513c-4486-a2a8-bfdb1aba3fa4'];
    bucket: string;
    verify_img_bucket: string;
    assets_bucket: string;
    proctoring_bucket: string;
    drive_bucket : string;
    content_bucket: string;
    hirebucket: string;
    skipScreenCheck: boolean;
    vimeoTempBucket: string;
    constructor(private http: HttpClient) {
        if (environment.production) {
            this.bucket = 'smartica-media';
            this.verify_img_bucket = 'smartica-student-verification-images';
            this.assets_bucket = 'smartica-users-asset';
            this.proctoring_bucket = 'smartica-proctoring-screen';
            this.drive_bucket = 'examly-data';
            this.content_bucket = "smartica-media-content";
            this.hirebucket = "neo-hire";
            this.vimeoTempBucket = "prod-video-answer-vimeo";
        } else {
            this.bucket = 'smartica-media';
            this.verify_img_bucket = 'smartica-student-verification-images';
            this.assets_bucket = 'smartica-users-asset';
            this.proctoring_bucket = 'smartica-proctoring-screen';
            this.drive_bucket = 'examly-data-stage';
            this.content_bucket = "smartica-media-content";
            this.hirebucket = "neo-hire";
            this.vimeoTempBucket = "dev-video-answer-vimeo";
        }
    }

    // set google analytics
    public addGoogleAnalytics() {
        return new Promise((resolved, _rejected) => {
            if (!document.getElementById('google-analytics') && environment.g_measurement_id) {
                const node = document.createElement('script');
                node.src = `https://www.googletagmanager.com/gtag/js?id=${environment.g_measurement_id}`;
                node.type = 'text/javascript';
                node.id = 'google-analytics';
                document.getElementsByTagName('head')[0].appendChild(node);
                node.onload = () => {
                    const scriptNode = document.createElement('script');
                    scriptNode.text = `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${environment.g_measurement_id}');`;
                    node.append(scriptNode);
                    resolved(true);
                };
                node.onerror = () => {
                    resolved(false);
                };
            } else {
                resolved(true);
            }
        });
    }

    // google analytics path config
    public pathConfigGA() {
        if (document.getElementById('google-analytics') && environment.g_measurement_id && window['dataLayer']) {
            gtag('config', environment.g_measurement_id, {
                page_path: location.pathname,
            });
        }
    }

    // google analytics user config
    public userConfigGA(user_id: string) {
        if (document.getElementById('google-analytics') && environment.g_measurement_id && window['dataLayer']) {
            gtag('config', environment.g_measurement_id, {
                user_id: `student-${user_id}`
            });
        }
    }

    // google analytics send event
    public eventEmitterGA(eventAction: string, data: any) {
        if (document.getElementById('google-analytics') && environment.g_measurement_id && window['dataLayer']) {
            gtag('event', eventAction, data);
        }
    }

    toggleVisibility() {
        this.sidebarVisibilityChange.next(false);
    }

    private getUserToken() {
        const userData = JSON.parse(localStorage.getItem('token'));
        if (userData && userData.token) {
            return userData.token;
        }
        return '';
    }

    options(userData) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: userData && userData.token ? userData.token : this.getUserToken(),
        });
        return { headers };
    }

    // get school login details while loading
    public getSchoolLoginDetails() {
        return this.http.get(this.api_link + '/school/login');
    }

    public getSignedUrl(payload: any) {
        return this.http.post(this.api_link + '/getsignedurl', payload, this.options(null));
    }

    public getPrivateSignedDownloadUrl(payload: any) {
        return this.http.post(this.api_link + '/getprivatesigneddownloadurl', payload, this.options(null));
    }

    public getSignedDownloadUrlWIthoutAuthorization(payload: any) {
        return this.http.post(this.api_link + '/getSignedDownloadUrlWIthoutAuthorization', payload, this.options(null));
    }

    public getGSignedUrl(payload: any) {
        return this.http.post(this.api_link + '/getGSignedUrl', payload, this.options(null));
    }

    public uploadUsingSignedUrl(url: string, payload: any) {
        return this.http.put(url, payload, { observe: 'response' });
    }

    public uploadUsingSignedUrlWithProgress(url: string, payload: any) {
        return this.http
            .put(url, payload, {
                observe: 'events',
                reportProgress: true,
                // params: {
                //     'ngsw-bypass': 'true',
                // },
            })
            .pipe(
                map((event: any) => this.getUploadStatus(event)),
                catchError((err: any) => this.uploadError(err)),
            );
    }

    private getUploadStatus(event: HttpEvent<any>) {
        switch (event.type) {
            case HttpEventType.Sent: {
                return { severity: 'warn', status: 'Upload started' };
            }
            case HttpEventType.UploadProgress: {
                const percentDone = Math.round((100 * event.loaded) / event.total);
                return { severity: 'warn', status: `Uploading: (${percentDone}%)` };
            }
            case HttpEventType.Response: {
                return { severity: 'success', status: 'Upload completed' };
            }
            default: {
                return { severity: 'error', status: 'Upload failed!' };
            }
        }
    }

    private uploadError(error: HttpErrorResponse) {
        let errorMsg = { severity: 'error', status: 'Upload failed. Try again later' };
        if (error.error instanceof ErrorEvent) {
            errorMsg = { severity: 'error', status: 'Network error. Upload failed' };
        }
        return throwError(errorMsg);
    }

    public uploadGCPUsingSignedUrl(url, payload) {
        const headers = new HttpHeaders({});
        if (payload && payload.type) {
            headers.set('Content-Type', payload.type);
        } else {
            headers.set('Content-Type', 'application/octet-stream');
        }
        return this.http.put(url, payload, { headers, observe: 'response' });
    }

    public uploadGCPUsingSignedUrlWithProgress(url, payload) {
        const headers = new HttpHeaders({});
        if (payload && payload.type) {
            headers.set('Content-Type', payload.type);
        } else {
            headers.set('Content-Type', 'application/octet-stream');
        }
        return this.http
            .put(url, payload, {
                headers,
                observe: 'events',
                reportProgress: true,
                params: {
                    'ngsw-bypass': 'true',
                },
            })
            .pipe(
                map((event: any) => this.getUploadStatus(event)),
                catchError((err: any) => this.uploadError(err)),
            );
    }

    public convert_IST_TO_EPOCH(ist: any) {
        const GMT: any = new Date(ist);
        return GMT.getTime();
    }
    getBranchName() {
        const userData: any = JSON.parse(localStorage.getItem('token'));
        if (userData && userData.purpose && userData.institute_type) {
            if (userData.purpose === 'Placement Process App') {
                return 'Campus';
            } else if (userData.purpose === 'Exams App' && userData.institute_type !== 'training_institute') {
                return 'Colleges / Schools';
            } else {
                return 'Branch';
            }
        } else {
            return 'Branch';
        }
    }
    school_code() {
        const schoolData = JSON.parse(localStorage.getItem('school_details'));
        return schoolData.school_code;
    }
    sendPushDetails(payload) {
        payload.school_code = this.school_code();
        return this.http
            .post(this.api_link + '/users/sendpushmsg', payload, this.options(null))
            .pipe(catchError(this.handleError));
    }
    sendPushToken(payload) {
        return this.http.post(this.api_link + '/users/sendpushtoken', payload, this.options(null));
    }

    updateLoginURLS(metadata, _enableFeatures) {
        if (metadata && metadata.allow_drives) {
            this.loginUrls.push('/drives');
        }
        if (metadata && (metadata.allow_courses ||
            metadata.allow_course_projects || metadata.allow_learning_paths
            || metadata.allow_practices || metadata.allow_quizzes || metadata.allow_exams
            || metadata.allow_lab_courses || metadata.allow_hackathons || metadata.allow_challenges
            || metadata.allow_company_specific_tests || metadata.allow_assessments)) {
            this.loginUrls.push('/mycourses');
        }
        if ((metadata && metadata.allow_dashboard && metadata.enableTwoFactorAuthentication && this.verifiedOtp) ||
            (metadata && metadata.allow_dashboard && !metadata.enableTwoFactorAuthentication)
        ) {
            this.loginUrls.push('/dashboard');
        }
        if (metadata && metadata.allow_leaderboard) {
            this.loginUrls.push('/leaderboard');
        }
        if (metadata && metadata.allow_contest) {
            this.loginUrls.push('/mycontest');
        }
        if (metadata && metadata.allowselling) {
            this.loginUrls.push('/course');
        }
        if (metadata && metadata.allow_certificates) {
            this.loginUrls.push('/certificates');
        }
        if (!metadata || !metadata.disableProfile) {
            this.loginUrls.push('/profile');
        }
    }

    defaultRedirection() {
        let sclMD: any = JSON.parse(localStorage.getItem('school_details'));
        sclMD = sclMD && sclMD.schools_metadata ? sclMD.schools_metadata : false;
        const token: any = JSON.parse(localStorage.getItem('token'));
        const path = localStorage.getItem('path');
        switch (true) {
            case (path && path.includes('contest') && sclMD && sclMD.allow_contest): {
                return '/mycontest';
            }
            case (path && path.includes('drives') && sclMD && sclMD.allow_drives): {
                return '/drives';
            }
            case (path && path.includes('project?id') && token && token.enableFeatures && token.enableFeatures.allow_projects): {
                return path;
            }
            case (path && path.includes('QR-code-scanner?qr_id')): {
                return path;
            }
            case (path && path.includes('profile') && (!sclMD || !sclMD.disableProfile)): {
                return '/profile';
            }
            case ((sclMD && sclMD.allow_dashboard && sclMD.enableTwoFactorAuthentication && this.verifiedOtp) ||
                (sclMD && sclMD.allow_dashboard && !sclMD.enableTwoFactorAuthentication)
            ): {
                    return '/dashboard';
                }
            case ((sclMD && sclMD.allow_courses && sclMD.enableTwoFactorAuthentication && this.verifiedOtp) ||
                (sclMD && sclMD.allow_courses && !sclMD.enableTwoFactorAuthentication)
            ): {
                    return '/mycourses?type=mycourses';
                }
            case ((sclMD && sclMD.allow_contest && sclMD.enableTwoFactorAuthentication && this.verifiedOtp) ||
                (sclMD && sclMD.allow_contest && !sclMD.enableTwoFactorAuthentication)
            ): {
                    return '/mycontest';
                }
            case ((sclMD && sclMD.allow_drives && sclMD.enableTwoFactorAuthentication && this.verifiedOtp) ||
                (sclMD && sclMD.allow_drives && !sclMD.enableTwoFactorAuthentication)
            ): {
                    return '/drives';
                }
            case ((path && path.includes('type=mycourses') && sclMD && sclMD.allow_courses) || (sclMD && sclMD.allow_courses)): {
                return '/mycourses?type=mycourses';
            }
            case ((path && path.includes('type=mylearningpaths') && sclMD && sclMD.allow_learning_paths) || (sclMD && sclMD.allow_learning_paths)): {
                return '/mycourses?type=mylearningpaths';
            }
            case ((path && path.includes('type=mypractices') && sclMD && sclMD.allow_practices) || (sclMD && sclMD.allow_practices)): {
                return '/mycourses?type=mypractices';
            }
            case ((path && path.includes('type=myprojects') && sclMD && sclMD.allow_course_projects) || (sclMD && sclMD.allow_course_projects)): {
                return '/mycourses?type=myprojects';
            }
            case ((path && path.includes('type=myhackathons') && sclMD && sclMD.allow_hackathons) || (sclMD && sclMD.allow_hackathons)): {
                return '/mycourses?type=myhackathons';
            }
            case ((path && path.includes('type=myquizzes') && sclMD && sclMD.allow_quizzes) || (sclMD && sclMD.allow_quizzes)): {
                return '/mycourses?type=myquizzes';
            }
            case ((path && path.includes('type=myexams') && sclMD && sclMD.allow_exams) || (sclMD && sclMD.allow_exams)): {
                return '/mycourses?type=myexams';
            }
            case ((path && path.includes('type=myassessments') && sclMD && sclMD.allow_assessments) || (sclMD && sclMD.allow_assessments)): {
                return '/mycourses?type=myassessments';
            }
            case ((path && path.includes('type=mylabs') && sclMD && sclMD.allow_lab_courses) || (sclMD && sclMD.allow_lab_courses)): {
                return '/mycourses?type=mylabs';
            }
            case ((path && path.includes('type=mychallenges') && sclMD && sclMD.allow_challenges) || (sclMD && sclMD.allow_challenges)): {
                return '/mycourses?type=mychallenges';
            }
            case ((path && path.includes('type=mytests') && sclMD && sclMD.allow_company_specific_tests) || (sclMD && sclMD.allow_company_specific_tests)): {
                return '/mycourses?type=mytests';
            }
            case ((path && path.includes('type=mytests2') && sclMD && sclMD.allow_company_specific_tests) || (sclMD && sclMD.allow_company_specific_tests)): {
                return '/mycourses?type=mytests2';
            }
            default: {
                return '/accountsettings';
            }
        }
    }

    timezone(givenDate, selectedtimezone) {
        let isPlus = selectedtimezone.charAt(0) === '+' ? true : false;
        selectedtimezone = isPlus ? selectedtimezone.replace('+', '') : selectedtimezone.replace('-', '');
        let timearray = selectedtimezone.split(':');
        let temporary1 = parseInt(timearray[0]);
        let temporary2 = parseInt(timearray[1]);
        givenDate = new Date(givenDate);
        givenDate = givenDate.setTime(givenDate.getTime() + givenDate.getTimezoneOffset() * 60 * 1000);
        givenDate = new Date(givenDate);
        if (isPlus) {
            givenDate = new Date(givenDate.setHours(givenDate.getHours() + temporary1));
            givenDate = new Date(givenDate.setMinutes(givenDate.getMinutes() + temporary2));
        } else {
            givenDate = new Date(givenDate.setHours(givenDate.getHours() - temporary1));
            givenDate = new Date(givenDate.setMinutes(givenDate.getMinutes() - temporary2));
        }
        return givenDate;
    }

    getPlatformSettings(payload) {
        return this.http.post(this.api_link + '/school/getPlatformSettings', payload, this.options(null))
            .pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * set enabledFeatures in localStorage token
     * @param enabledFeatures enabled features object
     */
    public setEnabledFeatures(enabledFeatures: any) {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token && enabledFeatures) {
            token.enable_features = enabledFeatures;
            localStorage.setItem('token', JSON.stringify(token));
        }
    }

    private getSetEnabledFeatures() {
        if (localStorage.getItem('token')) {
            const token: any = JSON.parse(localStorage.getItem('token'));
            const payload = {
                school_id: token.school_id,
            };
            this.getPlatformSettings(payload).subscribe((response: any) => {
                if (response && response.enabledFeatures) {
                    this.setEnabledFeatures(response.enabledFeatures);
                }
            });
        }
    }

    setEnableFeatures() {
        this.getSetEnabledFeatures();
    }

    resetToken() {
        this.getSetEnabledFeatures();
        setInterval(() => this.getSetEnabledFeatures(), 300000);
    }

    publicNavigation(schlMetadata, _enableFeatures) {
        let navigateUrl: any = '/login';
        this.ispublicCourse.next(false);

        // if (schlMetadata.allow_public_buying) {
        //     this.ispublicCourse.next(true);
        //     navigateUrl = '/course';
        // } else {
        //     this.ispublicCourse.next(false);
        // }

        return navigateUrl;
    }

    public loadCaptchaScript(captcha: string) {
        return new Promise(async (resolve, _reject) => {
            const src = 'https://www.google.com/recaptcha/api.js?render=' + captcha;
            const scriptsrc = 'https://www.gstatic.com/recaptcha';
            if (document.querySelector('script[src^="' + scriptsrc + '"]')) {
                resolve({ script: captcha, loaded: true, status: 'Loaded' });
            } else {
                const onload: any = {
                    script: captcha,
                    loaded: true,
                    status: 'Loaded',
                };
                const onerror: any = {
                    script: captcha,
                    loaded: false,
                    status: 'Loaded',
                };
                await this.loadScript('script', src, 'text/javascript', '', 'captcha', [], true, onload, onerror)
                    .then((res: any) => {
                        resolve(res);
                    })
                    .catch((_err: any) => {
                        console.error(_err);
                    });
            }
        });
    }

    public getCaptchaToken(captcha: string) {
        return new Promise((resolve) => {
            if (captcha) {
                try {
                    const schoolData = JSON.parse(localStorage.getItem('school_details'));
                    if (schoolData && schoolData.captcha) {
                        if (window['grecaptcha']) {
                            this.reCaptcha(schoolData, captcha, resolve);
                        } else {
                            this.loadCaptchaScript(schoolData.captcha).then((_res: any) => {
                                let interval: any = setInterval(() => {
                                    if (window['grecaptcha']) {
                                        clearInterval(interval);
                                        this.reCaptcha(schoolData, captcha, resolve);
                                    }
                                }, 500);
                            });
                        }
                    } else {
                        this.getSchoolLoginDetails().subscribe((response: any) => {
                            if (response && response.data && response.data.captcha) {
                                this.loadCaptchaScript(response.data.captcha).then((_res: any) => {
                                    let interval: any = setInterval(() => {
                                        if (window['grecaptcha']) {
                                            clearInterval(interval);
                                            window['grecaptcha'].ready(() => {
                                                window['grecaptcha']
                                                    .execute(response.data.captcha, {
                                                        action: captcha,
                                                    })
                                                    .then((token: any) => {
                                                        resolve(token);
                                                    });
                                            });
                                        }
                                    }, 500);
                                });
                            } else {
                                resolve('');
                            }
                        });
                    }
                } catch (error) {
                    resolve(false);
                }
            } else {
                resolve('');
            }
        });
    }

    private reCaptcha(schoolData: any, captcha: string, resolve: (value: unknown) => void) {
        window['grecaptcha'].ready(() => {
            window['grecaptcha']
                .execute(schoolData.captcha, {
                    action: captcha,
                })
                .then((token: any) => {
                    resolve(token);
                });
        });
    }

    public loadScript(
        element: string,
        url: string,
        type: string,
        _rel: string,
        id: string,
        child?: any[],
        defer?: any,
        onload?: any,
        onerror?: any,
    ): Promise<any> {
        return new Promise(async (resolved, rejected) => {
            if (document.getElementById(id)) {
                resolved(true);
            } else {
                const node: any = document.createElement(element);
                if (element === 'link') {
                    node.href = url;
                    node.rel = 'stylesheet';
                } else if (element === 'script') {
                    node.src = url;
                }

                if (defer) {
                    node.defer = defer;
                }

                if (onload) {
                    node.onload = resolved(onload);
                }

                if (onerror) {
                    node.onerror = resolved(onerror);
                }

                if (id === 'MathJax') {
                    await this.loadMathDelimiter();
                }

                node.type = type;
                document.getElementsByTagName('head')[0].appendChild(node);
                node.onload = () => {
                    node.id = id;
                    if (id === 'shaka') {
                        shaka.polyfill.installAll();
                    } else if (child && child.length) {
                        const promiseArray: any[] = [];
                        child.forEach((script: any) => {
                            promiseArray.push(
                                this.loadScript(
                                    script.element,
                                    script.url,
                                    script.type,
                                    script.rel,
                                    script.id,
                                    script.child,
                                ),
                            );
                        });
                        Promise.all(promiseArray).then(() => {
                            resolved(true);
                        });
                    } else {
                        resolved(true);
                    }
                };
                node.onerror = () => {
                    rejected(false);
                };
            }
        });
    }

    private loadMathDelimiter() {
        return new Promise((resolved, _rejected) => {
            const node = document.createElement('script');
            node.text = `MathJax = {
    tex: {
      inlineMath: [['$', '$']]
    },
    svg: {
      fontCache: 'global'
    }
  };`;
            document.getElementsByTagName('head')[0].appendChild(node);
            resolved(true);
        });
    }

    clearLocalStorage() {
        localStorage.removeItem('token');
        localStorage.removeItem('ssologin');
        this.logoutTrigger.next(true);
        const keyLength = localStorage.length;
        for (let index = keyLength - 1; index > -1; index -= 1) {
            const key = localStorage.key(index);

            if (key && key !== 'session_data' && key !== 'school_details') {
                localStorage.removeItem(key);
            }
        }
    }

    public uniqBy(collection, keyToFind) {
        const cb = typeof keyToFind === 'function' ? keyToFind : (o) => o[keyToFind];
        return [
            ...collection
                .reduce((maps, item) => {
                    const key = item === null || item === undefined ? item : cb(item);
                    maps.has(key) || maps.set(key, item);
                    return maps;
                }, new Map())
                .values(),
        ];
    }

    roundDigit(numberValue, decimal) {
        return Math.floor(numberValue * Math.pow(10, decimal)) / Math.pow(10, decimal);
    }

    addZeroInfront(value: any) {
        if (value < 0 || value > 9) {
            return value;
        } else {
            return '0' + value;
        }
    }

    /**
     * Function to sleep
     * @param delay millisecond
     */
    public sleep(delay: number): Promise<void> {
        return new Promise((res, _rej) => {
            setTimeout(() => {
                res();
            }, delay);
        });
    }

    // check if inside iframe
    public inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    public getResultfromRedis(data: any) {
        return this.http.post(this.api_link + '/xor1pE8aM9R', data, this.options(null));
    }

    public getMentorDetails(data: any) {
      return this.http.post(this.api_link + '/users/getMentorDetails', data, this.options(null));
    }

    public neoPATRedirection(payload: any) {
        return this.http.post(this.api_link + '/nSdo1IkS8d0', payload, this.options(null));
    }
    public async getHtml2Canvas() {
        if (!this.html2canvas) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/html2canvas/html2canvas.js',
                type: 'text/javascript',
                rel: '',
                id: 'html2canvas',
            };
            await this.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.html2canvas = html2canvas;
        }
        return this.html2canvas;
    }

    public async getjsPDF() {
        if (!this.jspdf) {
            const script = {
                element: 'script',
                url: 'https://images.examly.io/scripts/jsPDF/jspdf.js',
                type: 'text/javascript',
                rel: '',
                id: 'jspdf',
            };
            await this.loadScript(script.element, script.url, script.type, script.rel, script.id);
            this.jspdf = jspdf;
        }
        return this.jspdf;
    }
    public async handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            //   console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            //     Backend returned code ${error.status},  +
            //     body was: ${error.error});let ltidata = null;
            let ltiData;
            let confirm :any = false;
            try {
                ltiData = JSON.parse(localStorage.getItem('ssologin'));
            } catch (e) {
                ltiData = null;
            }
            if ( (error && error.status === 401 || error.status === 406) && !ltiData ) {
                if(error.status === 401){
                    this.commonGrowl.next({
                        severity: 'error',
                        summary: 'Session expired!',
                        detail: 'Refresh and try logging in again',
                    });
                    confirm = true;
                }
                if(error.status === 406){
                   this.setRedirectionToLogin();
                    confirm  = await this.getconfirmation();
                }
                if(confirm){
                    localStorage.clear();
                    setTimeout(() => {
                        const hostName = window.location.host;
                        if (
                            hostName === 'app.examly.net' ||
                            hostName === 'app.exam.ly' ||
                            hostName === 'app.examly.io' ||
                            hostName.split(':')[0] === 'app.examly.test'
                        ) {
                            if (window.location.pathname !== '/admin/login') {
                                window.location.href = '/admin/login';
                            }
                        } else {
                            if (window.location.pathname !== '/login') {
                                window.location.href = '/login';
                            }
                        }
                    }, 1000);
                }
                this.resetRedirectionToLogin();
            }
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; Please try again later.');
    }

    getconfirmation(){
        return new Promise(async (resolved, _rejected) => {
            this.confirmFlag.next(true);
            this.confirmFlagLogOff.subscribe((data: any) => {
                if(data) {
                    resolved(true)
                }
            });
        });
    }

    blockRedirection(){
        const v: any = {
            noRedirection: true,
        };
        localStorage.removeItem('noRedirect');
        localStorage.setItem('noRedirect', JSON.stringify(v));
    }
    setRedirectionToLogin(){
        localStorage.removeItem('redirect');
        localStorage.setItem('redirect', JSON.stringify({
            redirect:'Login'
        }));
    }
    resetRedirectionToLogin(){
        localStorage.removeItem('redirect');
    }

    public checkMyMeetingReminder() {
        return this.http.post(this.api_link + '/live_session/checkMyMeetReminder',{}, this.options(null))
        .pipe(catchError(this.handleError.bind(this)))
    }

    checkValidFile(file: any): any {
        return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function(evt:any) {
                  let blob = evt.target.result.substr(0, 8);
                  let regex = /%PDF-1.[0-7]/;
                  if(blob.match(regex)) {
                    resolve(true);
                  }
                  else {
                    reject(false);
                  }
                };
                reader.readAsText(file)
            }
      )}

    public convertImageUrlToBase64(url: any): Promise<any> {
        return new Promise((resolved, _rejected) => {
            //convert to blob
            this.http
                .get(url, { responseType: 'blob' }).subscribe((res: any) => {
                    this.blobToBase64(res).then((base64: any) => {
                        base64 = JSON.parse(JSON.stringify(base64));
                        resolved(base64);
                    })
                });
        });
    }

    public loadDragScript()
    {
        let dynamicScript = document.createElement('script');
        dynamicScript.type = 'text/javascript';
        dynamicScript.async = true;
        dynamicScript.src = 'https://images.examly.io/scripts/drag-script.js';
        dynamicScript.id = 'dynamic_drag_script';
        document.body.appendChild(dynamicScript);
    }

    public removeDragScript(){
        let dragScipt = document.getElementById('dynamic_drag_script');
        if(dragScipt != null) {
            dragScipt.remove();
        }
    }

    public blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    getAdmissionReportSchoolDetailsForStudents(payload: any) {
        return this.http
            .post(this.api_link + '/school/getAdmissionReportSchoolDetailsForStudents', payload)
            .pipe(catchError(this.handleError.bind(this)));
    }

    generateSsoQrUrl(url, response) {
        let qrString = url[0] + '//' + url[2] + '/loginredirect/QR-code-scanner?qr_id=' + response.data;
        let tokenDetails = JSON.parse(localStorage.getItem("ssologintoken"));
        let loggedInUserToken = JSON.parse(localStorage.getItem("token"));
        let loggedInUserSchoolDetails = JSON.parse(localStorage.getItem("school_details"));
        qrString = `${qrString}&tokenid=${tokenDetails.token}&email=${loggedInUserToken.email}&school_code=${loggedInUserSchoolDetails.school_code}`;
        return qrString;
    }

    public changeS3bucket(url:any, targetbucket:any, changeBucket = this.bucket) {
        if(url && url.includes(changeBucket + '/')) {
            url = url.replace(new RegExp(changeBucket + '/', 'g'),targetbucket + '/')
        }
        return url;
    }

    public switchBucket(data: any, targetbucket: any) {
        return JSON.parse(this.changeS3bucket(
            JSON.stringify(data), targetbucket
        ))
    }

    public sentenceCase(input) {
        input = ( input === undefined || input === null ) ? '' : input;
        input = input.toLowerCase();
        return input.toString().replace( /(^|\. *)([a-z])/g, function(match, separator, char) {
            return separator + char.toUpperCase();
        });
    }

    public captiliseEachWord(input) {
        input = ( input === undefined || input === null ) ? '' : input;
        const splitStr = input.toLowerCase().split(" ");
        const exceptions = ["and", "the", "a", "an", "for", "to","but", "at","by", "of"];
        const result = splitStr.map(word => {
            const formattedWord = exceptions.indexOf(word) == -1 ?
            word.charAt(0).toUpperCase() + word.substring(1) : word;
            return formattedWord;
        })
        let final = result.toString();
        final = final.replaceAll(',',' ');
        return final;
    }

    public getAiBasedScore(payload){
        return this.http.post("https://ai-video-analyze-wfxs7gf3oq-uc.a.run.app", payload)
    }

    public getFirstLetters(str) {
        const str1=str.replace(/[^a-zA-Z ]/g," ");
         const firstLetters = str1
           .split(' ')
           .map(word => word.charAt(0))
           .join('')
           .toUpperCase()
           .slice(0,2)
         return firstLetters;
       }
}
