import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'two_digit',
})
export class TwoDigitPipe implements PipeTransform {
    transform(value: number): any {
        if (value < 10 && value !== 0) {
            return '0' + value;
        } else {
            return value;
        }
    }
}
