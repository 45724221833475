import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';

import { LoaderModule } from '../loader/loader.module';

@NgModule({
    imports: [CommonModule, LoaderModule],
    declarations: [ButtonComponent],
    exports: [ButtonComponent],
})
export class ButtonModule {}
