import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formattime',
})
export class FormattimePipe implements PipeTransform {
    transform(value: number): string {
        const minutes: number = Number(('00' + Math.floor(value / 60)).slice(-3));
        return minutes + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
    }
}
