import { ErrorHandler, Injectable } from '@angular/core';

import { GlobalService } from './services/global.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private globalService: GlobalService) {}

    handleError(error: any): void {
        console.error(error);
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }

        // google analytics error event
        this.globalService.eventEmitterGA('exception', {
            description: error,
            fatal: true,
        });
    }
}
