import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { GlobalService } from '@services/global.service';
import { searchIcon } from 'src/assets/images/index';
import { Router, NavigationStart, NavigationEnd } from "@angular/router";

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.css'],
})
export class SearchBarComponent  {
    searchValue: any = '';
    showBar: any = false;
    @Input() fvalues;
    @Input() fprop;
    @Input() radius: any;
    @Input() background;
    @Input() borderColor;
    @Input() type;
    @Input() height;
    @Input() width;
    searchIcon  = searchIcon;
    @Output() emitSearch = new EventEmitter<any>();
    @Output() emitChip = new EventEmitter<any>();
    constructor( private router: Router, private globalService: GlobalService) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.searchValue = '';
            }
          });
    }
    @HostListener('document:click', ['$event'])
    onClick(event) {
        const closebar = document.querySelector('.baroutlet');
        if (closebar && !closebar.contains(event.target)) {
            this.showBar = false;
        }
    }
    getSearch() {
        this.showBar = false;
        if(this.type === 'header') {
            this.globalService.search.next({
                path: window.location.pathname,
                value: this.searchValue,
                search: this.searchValue,
                chips: "",
              });
        } else {
            const cvalue = this.fvalues.find((data) => {
                if (data.check) {
                    return true;
                }
                return false;
            });
            this.emitSearch.emit({ search: this.searchValue, chips: cvalue });
        }
    }

    sendSelectedChips(event) {
        this.showBar = false;
        this.emitChip.emit({ search: this.searchValue, chips: event });
    }
}
