import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormattimePipe } from './formattime.pipe';
import { CompactNumFormatPipe } from './compactNumFormat.pipe';

@NgModule({
    declarations: [	FormattimePipe,
      CompactNumFormatPipe
   ],
    imports: [CommonModule],
    exports: [FormattimePipe],
})
export class FormatTimeeModule {}
