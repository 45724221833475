import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { dialogClose } from 'src/assets/images';

@Component({
  selector: 'app-dialog-sidebar',
  templateUrl: './dialog-sidebar.component.html',
  styleUrls: []
})
export class DialogSidebarComponent {
  @Input() header: any;
  @Input() visible: boolean;
  @Input() isClose: boolean;
  @Input() screenSize;
  @Input() type: any;
  @Output() submitDialog = new EventEmitter<any>();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() outSideClick = new EventEmitter();
  filterClose=dialogClose;
  sharedEmails: any;

  constructor() {
    if(this.isClose === false) {
      this.isClose = false;
    } else {
      this.isClose = true;
    }
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  wholeclick(event) {
    this.outSideClick.emit(event)
  }

  emitSubmit(event) {
    this.submitDialog.emit(event);
}
emitEmails() {
    this.submitDialog.emit(this.sharedEmails);
}

}
