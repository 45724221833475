import { Component, Input,OnInit, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '@services/global.service';
@Component({
    selector: 'radio',
    templateUrl: './radio.html',
    styleUrls: ['./../profile-form-elements.less'],
})
export class RadioComponent implements AfterViewInit, OnChanges,OnInit {
    enable = false;
    reason = '';
    showError = false;
    data: any = {};
    active;
    selectedValue;
    required;
    lenLabel;
    inpVal;
    @Input() field: any = {};
    @Input() form: FormGroup;
    @Input() radioType: any;
    @Input() max434;
    @Output() response = new EventEmitter();
    @Output() setGender = new EventEmitter();
    @Output() setResDrive = new EventEmitter();
    @Output() enableOther = new EventEmitter();
    get isValid() {
        return this.form.controls[this.field.name].valid;
    }
    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }
    constructor( public globalService: GlobalService ) {}
    ngAfterViewInit() {
        if(this.active) {
            this.setVal(this.field.value,this.active);
        } else {
            this.setVal(this.field.value,null);
        }
    }

    ngOnInit() {
        if(this.field.value !== null && this.field.value !== '' && this.field.value !== true) {
            this.setValueActive();       
        }
        this.applyRadio();
        this.field.options.forEach((each)=>{
            if(each.label.length>14) {
                this.lenLabel = true;
            }
        })
    }

    setValueActive() {
        if(this.field.value!==true && this.field.value!=='' && this.field.value!==null) {
            this.selectedValue = this.field.value;
            this.field.options.forEach((each,i)=>{
                if(each.value === this.selectedValue) {
                    setTimeout(() => {
                        this.active = i;
                    },600);
                }
            })
        } else if (this.field.formattedValue && this.field.formattedValue!=='') {
            this.field.options.forEach((each,i)=>{
                if(this.field.formattedValue === each.label) {
                    this.selectedValue=each.value;
                }
            });
        }
    }

    applyRadio() {
        if((this.field.name === 'interested_for_placement')&&(this.field.value === true || this.field.value === false)||
        (this.field.name==='backlog_history')&&(this.field.value === true || this.field.value === false)) {
            this.selectedValue = this.field.value;
            this.field.options.forEach((each,i)=>{
                if(each.value === this.selectedValue) {
                    setTimeout(() => {
                        this.active = i;
                    },600);
                }
            })
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            setTimeout(() => {
                if(this.active>=0) {
                    this.setVal(this.field.value,this.active);
                } else {
                    this.setVal(this.field.value,null);
                }
            }, 500);
        }
    }
    setVal(o,i) {
        if(this.field.name === 'gender') {
            this.setGender.emit(o);
        }
        if(this.field.name==='res_select_drive') {
            this.setResDrive.emit(o);
        }
        if(this.field.validateReason) {
            this.enableOther.emit(o);
        }
        this.active = i;
        if(o === 'other' && this.field.validateReason){
            // this.enableOther.emit(true);
            this.form.controls[this.field.name].setValue(o);
        }else{
            if(this.field.validateReason) {
                // this.enableOther.emit(false);
            }
            this.enable = false;
            this.showError = false;
            this.form.controls[this.field.name].clearValidators();
            this.form.controls[this.field.name].setValue(o);
        }
        if (this.field.name === 'detail_type') {
            if (o === false) {
                this.field.emitValue({
                    name: 'marks',
                    pattern: '^[0-9][.][0-9][0-9]?$|^[0-9]$|^10$',
                    secLabel: 'CGPA',
                });
                this.form.controls.marks.setValidators([
                    Validators.pattern('^[0-9][.][0-9][0-9]?$|^[0-9]$|^10$'),
                    Validators.required,
                ]);
                this.form.controls.marks.setValue('');
            } else if (o === true) {
                this.field.emitValue({
                    name: 'marks',
                    pattern: '^[1-9][0-9]?[.][0-9][0-9]?$|^[1-9][0-9]$|^100$',
                    secLabel: 'Percentage',
                });
                this.form.controls.marks.setValidators([
                    Validators.pattern('^[1-9][0-9]?[.][0-9][0-9]?$|^[1-9][0-9]$|^100$'),
                    Validators.required,
                ]);
                this.form.controls['marks'].setValue('');
            }
        } else if (this.field.name === 'not') {
            if (o === 'app') {
                this.field.emitValue({ key: 'Applicable', value: false, label: 'Applicable' });
                this.form.controls.detail_type.setValidators([
                    Validators.pattern('^(true|false)$'),
                    Validators.required,
                ]);
                this.form.controls.detail_type.setValue('');
            } else if (o === 'notapp') {
                this.field.emitValue({ key: 'Not Applicable', value: true, label: 'Not Applicable' });
                this.form.controls.detail_type.setValidators(null);
                this.form.controls.detail_type.setValue('');
                this.form.controls.marks.setValidators([Validators.pattern('NA'), Validators.required]);
                this.form.controls.marks.setValue('NA');
            }
        }
    }

    validText(event){
        if (event.target.value.length === 0){
            this.showError = true;
        }else{
            this.showError = false;
        }
    }

    validInput(event){
        if (event.target.value === ''){
            this.showError = true;
        } else{
            this.showError = false;
        }
    }

    validateText(event){
        let regex =  /\/^[A-z]+$\//
        if(event.target.value < 3 || regex.test(event.target.value)){
            this.showError = true
        }else{
            this.form.controls[this.field.name].setErrors(null);
            this.form.controls[this.field.name].clearValidators();
            this.showError = false;
        }
    }

    emitCall(value: string) {
        let copy_ids = this.field.copy_ids && this.field.copy_ids.length ? this.field.copy_ids : [];
        if (this.field && value === 'yes' && this.field.copy){
            this.field.copy_ids[0].copyValue = true;
            this.response.emit({ data: copy_ids && copy_ids.length ? copy_ids : [] , value: value, copyValue: true});
        } else if (this.field && value === 'no' && this.field.copy){
            this.field.copy_ids[0].copyValue = false;
            this.response.emit({ data: copy_ids && copy_ids.length ? copy_ids : [] , value: value, copyValue: false});
        }
    }
}
