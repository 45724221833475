import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-github-redirect-handler',
  templateUrl: './github-redirect-handler.component.html',
  styleUrls: ['./github-redirect-handler.component.css']
})
export class GithubRedirectHandlerComponent implements OnInit {

  constructor(
      private activedRouter: ActivatedRoute
    ) { }

  ngOnInit() {
    this.activedRouter.queryParams.subscribe(params =>{
      let data = params["id"].split('_');
      let origin =  data[0];
      let courseId =  data[1];
      let code =  params['code']
      const redirect_url = `${origin}/mycourses/details?id=${courseId}&code=${code}`
      window.location.href =  redirect_url
    })
  }

}
