import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from '@angular/router';
import { GlobalService } from '@services/global.service';

@Component({
  selector: "otp",
  templateUrl: './otp.html',
  styleUrls: ['./../profile-form-elements.less'],
})
  
export class OTPBoxComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  @Input() otpError;
  @Input() time;
  val = "icon-person";
  pc: any;
  createUserForm: FormGroup;
  get isValid() {
    return this.form.controls[this.field.name].valid;
  }
  get isDirty() {
    return this.form.controls[this.field.name].dirty;
  }

  constructor( private router:Router, public globalService: GlobalService) {}

  ngOnInit() {
    this.createUserForm = new FormGroup({
      otp1: new FormControl(null),
      otp2: new FormControl(null),
      otp3: new FormControl(null),
      otp4: new FormControl(null),
      otp5: new FormControl(null),
      otp6: new FormControl(null),
    });
  }

  ngAfterViewInit() {
    this.setVal(this.field.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      setTimeout(() => {
        this.setVal(this.field.value);
      }, 500);
    }
  }

  setVal(o) {
    this.form.controls[this.field.name].setValue(o);
  }

  navigate() {
    this.router.navigateByUrl(this.field.description.url);
}
  changeValue() {
    if (this.field.customError) {
      this.field.customError.error = false;
      this.field.customError.message = "";
    }
  }

  checkError() {
    if (
      this.form.get(this.field.name) &&
      this.form.get(this.field.name).touched &&
      this.form.get(this.field.name).hasError("required")
    ) {
      return true;
    } else if (
      this.form.get(this.field.name) &&
      this.form.get(this.field.name).touched &&
      this.form.get(this.field.name).hasError(this.field.error)
    ) {
      return true;
    }  else return false;
  }

  otpNumberCheck(index, name, event) {
    const KeyID: any = event.keyCode;
    if (KeyID !== 9) {
      if (name.value && index !== 6 && KeyID !== 8) {
        index = index + 1;
        this.createUserForm.get("otp" + index).setValue(null);
        const a: any = document.getElementById("otp" + index);
        a.focus();
      } else if (KeyID === 8 && index !== 1) {
        setTimeout(() => {
          index = index - 1;
          const a: any = document.getElementById("otp" + index);
          a.focus();
        }, 100);
      }
    }
  }

  checkOTP(name) {
    name.value = name.value.match(/^\d$/) ? name.value : null;
  }

  updateOTP() {
    const otp =
      this.createUserForm.get("otp1").value.toString() +
      this.createUserForm.get("otp2").value.toString() +
      this.createUserForm.get("otp3").value.toString() +
      this.createUserForm.get("otp4").value.toString() +
      this.createUserForm.get("otp5").value.toString() +
      this.createUserForm.get("otp6").value.toString();
    this.field.value = otp;
      this.otpError = { error: false, data: '' };
  }
}
