import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TeamService } from "../../services/team.service";
import { concatMap } from "rxjs";

@Component({
  selector: "app-github-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.css"],
})
export class GitRedirectComponent implements OnInit {
  constructor(
    private active: ActivatedRoute,
    private teamsService: TeamService,
    private router: Router
  ) {}

  ngOnInit() {
    this.active.queryParamMap
      .pipe(concatMap((x) => this.teamsService.getGitAcessToken(x.get("code"))))
      .subscribe(
        (_data) => {
          window.close()
        }
      );
  }
}
