import {
    Component,
    ViewChild,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChange,
    Renderer2,
} from '@angular/core';
import { growlSuccessIcon, growlCloseIcon, growlFailureIcon, growlWarnIcon, toastPatternIcon } from 'src/assets/images';

@Component({
    selector: 'app-growl',
    templateUrl: './growl.component.html',
    styleUrls: ['./growl.component.css'],
})
export class GrowlComponent implements OnChanges {
    @ViewChild('growlcard') growlCardElement: ElementRef;

    @Input() id: string = 'growl-card';
    @Input() data = {
        icon: '',
        severity: '',
        summary: '',
        detail: '',
        time: 0,
    };
    @Output() dataChange = new EventEmitter<any>();
    growlCloseIcon=growlCloseIcon;
    toastPatternIcon = toastPatternIcon;

    private intervalSub: any;

    constructor(private renderer: Renderer2) {
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        for (let propName in changes) {
            if (propName === 'data' && this.data) {
                this.show();
            }
        }
    }

    private show() {
        // clear timeout
        if (this.intervalSub) {
            clearTimeout(this.intervalSub);
        }

        switch (this.data.severity) {
            case 'success': {
                this.data.icon = growlSuccessIcon;
                this.renderer.setStyle(this.growlCardElement.nativeElement, 'background-color', '#55BA45');
                break;
            }
            case 'error': {
                this.data.icon = growlFailureIcon;
                this.renderer.setStyle(this.growlCardElement.nativeElement, 'background-color', '#FF5E5B');
                break;
            }
            case 'warn': {
                this.data.icon = growlWarnIcon;
                this.renderer.setStyle(this.growlCardElement.nativeElement, 'background-color', '#FFAD3A');
                break;
            }
            default: {
                this.data.icon = growlWarnIcon;
                this.renderer.setStyle(this.growlCardElement.nativeElement, 'background-color', '#FFAD3A');
            }
        }

        this.renderer.addClass(this.growlCardElement.nativeElement, 'show');
        const sec = (this.data.time ? this.data.time - 500 : 2500) / 1000;
        this.renderer.setStyle(
            this.growlCardElement.nativeElement,
            '-webkit-animation',
            'fadein 0.5s, fadeout 0.5s ' + sec + 's',
        );
        this.renderer.setStyle(
            this.growlCardElement.nativeElement,
            'animation',
            'fadein 0.5s, fadeout 0.5s ' + sec + 's',
        );
        this.intervalSub = setTimeout(
            () => {
                this.renderer.removeClass(
                    this.growlCardElement.nativeElement,
                    'severity-' + (this.data.severity ? this.data.severity : 'warn'),
                );
                this.renderer.removeClass(this.growlCardElement.nativeElement, 'show');
                this.renderer.removeStyle(this.growlCardElement.nativeElement, '-webkit-animation');
                this.renderer.removeStyle(this.growlCardElement.nativeElement, 'animation');
                this.dataChange.emit(undefined);
            },
            this.data.time ? this.data.time : 4000,
        );
    }

    public close() {
        this.renderer.removeClass(this.growlCardElement.nativeElement, 'show');
    }
}
