import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectDropdownComponent } from './select-dropdown.component';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {TooltipModule} from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
@NgModule({
    declarations: [SelectDropdownComponent],
    imports: [CommonModule, FormsModule, DropdownModule, MultiSelectModule, TooltipModule,CheckboxModule],
    exports: [SelectDropdownComponent],
})
export class SelectDropdownModule {}
