import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GlobalService } from '@services/global.service';
@Component({
    selector: 'dropdown',
    templateUrl: './dropdown.html',
    styleUrls: ['./../profile-form-elements.less'],
})
export class DropDownComponent implements OnInit {
    @Input() field: any = {};
    @Input() value: any = '';
    @Input() form: FormGroup;
    @Input() errorSignup;
    @Input() max380;
    @Input() max434;
    @Output() selectedvalue = new EventEmitter();
    selectedbranch: any;
    eachOptions: any = [];
    selectedVal;
    printError = false;
    overflow:boolean = true;
    langs: any;
    get isValid() {
        return this.form.controls[this.field.name].valid;
    }
    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }
    constructor(public globalService: GlobalService) {}
    ngOnInit() {
        if(this.field.value){
            this.selectedVal = {name: this.field.value,code: this.field.value};
            this.form.controls[this.field.name].setValue(this.selectedVal.code);
        }
        if (this.field.options.length) {
            this.field.options.forEach((each) => {
                this.eachOptions.push({name: each.label, code: each.value});
            });
        }
    }
    checkError() {
        if (this.errorSignup && !this.form.controls[this.field.name].value.length) {
            this.printError = true;
        }
    }
    selectedBranch() {
        if (this.field.name === 'Branch' && this.selectedVal && this.selectedVal.code) {
            this.selectedvalue.emit(this.selectedVal.code);
        }
        if(this.selectedVal) {
            this.form.controls[this.field.name].setValue(this.selectedVal.code);
        }
        if(!this.selectedVal && this.form.get(this.field.name).hasError('required')) {
            setTimeout(() => {
                this.printError = true;
            }, 500);
        } else {
            this.printError = false;
        }
    }
    overflowClose() {
        this.overflow = true;
        if (this.field.name === 'Branch' && this.selectedVal && this.selectedVal.code) {
            this.selectedvalue.emit(this.selectedVal.code);
        }
        if(this.selectedVal) {
            this.form.controls[this.field.name].setValue(this.selectedVal.code);
        }
        if(!this.selectedVal && this.form.get(this.field.name).hasError('required')) {
            setTimeout(() => {
                this.printError = true;
            }, 500);
        } else {
            this.printError = false;
        }
    }
    overflowOpen() {
        this.overflow = false;
    }
}