import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogSidebarComponent } from './dialog-sidebar.component';

@NgModule({
  declarations: [DialogSidebarComponent],
  imports: [CommonModule],
  exports: [DialogSidebarComponent]
})
export class DialogSidebarModule { }
