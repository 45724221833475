import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '@services/global.service';

@Component({
    selector: 'checkbox',
    templateUrl: './checkbox.html',
    styleUrls: ['./../profile-form-elements.less'],
})
export class CheckBoxComponent implements OnChanges, OnInit {
    enable: boolean = false;
    showError: boolean = false;
    active=[];
    sVal =[];
    data: any;
    lenLabel = false;
    noReason = true;
    @Input() field: any = {};
    @Input() max434: any;
    @Input() form: FormGroup;
    @Output() enableOther = new EventEmitter();
    value = [];
    get isValid() {
        return this.form.controls[this.field.name].valid;
    }
    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }

    constructor(public globalService: GlobalService) {}

    ngOnInit() {
        this.field.options.forEach((each)=>{
            this.active.push(false);
            this.sVal.push(false);
        });
        if(this.field.answer && this.field.answer.length) {
            this.field.options.forEach((each,i)=>{
                let abc = this.field.answer.includes(each.value);
                this.sVal[i]=abc;
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            setTimeout(() => {
                this.setDefaultArrayValue();
            }, 500);
        }
    }
    setDefaultArrayValue() {
        if (this.field && this.field.value && this.field.value.length>=1) {
            this.field.value.forEach((e: any) => {
                this.setValue(e,null);
            });
        }
    }
    setValue(o,i) {
        this.active[i] = !this.active[i];
        let value = this.value;
        if (value.indexOf(o) > -1) {
            value.splice(value.indexOf(o), 1);
        } else {
            value.push(o);
        }
        this.value = undefined;
        this.value = [...value];
        if(this.field.validateReason) {
            this.enableOther.emit(o);
        }
        this.form.controls[this.field.name].setValue(value);
    }

    inputVal(event){
        this.noReason = false;
        let regex =  /\/^[A-z]+$\//
        if(event.target.value.length < 3 || regex.test(event.target.value)){
            this.showError = true;
        }else{
            let customRes = event.target.value;
            if(this.form.controls[this.field.name].value.includes('other') && customRes){
                this.form.controls[this.field.name].value.splice(this.form.controls[this.field.name].value.indexOf('other') + 1, 0,customRes);
                this.form.controls[this.field.name].setErrors(null);
                this.form.controls[this.field.name].clearValidators();
            }   
            this.showError = false;
        }
    }

    validInput(event){
        if(event.target.value === ''){
            this.showError = true;
        } else{
            this.showError = false;
        }
        this.noReason = false;
    }
}
