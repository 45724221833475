import { Component, OnInit, OnChanges, Output,AfterViewInit, EventEmitter, Input, HostListener, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from '@services/global.service';
import { ChatService } from '@services/chat.service';
import { TestService } from '@services/test.service';
import { CartService } from '@services/cart.service';
import { LoginService } from '@services/login.service';
import { ProfileService } from 'src/app/containers/init-form/init_form.service';
import { DriveService } from '@services/drive.service';
import { environment } from './../../../environments/environment';
import {
  bellImg,
  profileImg,
  hamburger,
  neoLogo,
  searchIcon,
  cartIcon2GreyFill,
  backArrowImg,
  headerbg,
  arrowDownIcon,
  emptyProfile,
} from "../../../assets/images";

interface showListProps {
  text: string;
  icon: string;
  active: boolean;
  url: string;
  type?: string;
}
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.less"],
})
export class HeaderComponent implements OnInit, OnChanges ,AfterViewInit{
  @Output() toggleBtn = new EventEmitter<boolean>();
  @Output() cart = new EventEmitter();
  @Input() hambuergerToggle: boolean;
  @Input() shadow;
  @Input() hideSearch;
  @Input() path;
  userData = JSON.parse(localStorage.getItem("token"));
  localSchoolData: any;
  sclMD: any = {};
  redirection_url: any;
  isLog: boolean;
  userName: any;
  userImage: any;
  noRedirect: any;
  cartTotalItems: any = 0;
  currentNav = "";
  emptyProfile=emptyProfile;
  searchTerm: any;
  publicButtons: any[];
  notificationArray: any =[];
  dialogVisible = false;
  dialogHeader: any;
  dialogContentData: any;
  dialogheaderStyle: any;
  pushDialog: any = {};
  pushDialogMsg: any;
  pushDialogHeader: any;
  notificationPermission= false;
  headerbg = headerbg;
  showBg = false;
  message: any;
  type = 'header';
  width;
  searchBg="accent3";
  searchBorder="neutral2";
  headers:any;
  showList:any;
  popupList: any = [];


  headerPlaceholder = profileImg;
  notificationIcon = bellImg;
  searchIcon = searchIcon;
  hamburger = hamburger;
  neoLogo = neoLogo;
  cartIcon2GreyFill = cartIcon2GreyFill;
  backArrowImg = backArrowImg;
  mobile=false;
  showprof = false;
  arrowDownIcon = arrowDownIcon;
  showSearch = true;
  screenWidth;
  visible = false;
  confirmFlag = false;
  schoolLogo:any;
  ngStyleClass = {
    'width': '500px'
  }
  screenSize = '560';
  public okButton = {
    btnName: 'Ok',
    id: 'logOffID',
    width:'140px',
  };
  public materialDailogData: any;
  constructor(
    private elementRef: ElementRef,
    private chatService: ChatService,
    private router: Router,
    private globalService: GlobalService,
    private testService: TestService,
    public loginservice: LoginService,
    public cartservice: CartService,
    public serv: ProfileService,
    public driveService: DriveService,
  ) {
    this.isLog = this.isLogged();
    this.serv.noRedirect.subscribe((data: any) => {
      if (
        localStorage.getItem("noRedirect") ||
        localStorage.getItem("redirect") ||
        (data && data.status)
      ) {
        this.noRedirect = true;
      } else {
        this.noRedirect = false;
      }
    });
    if (localStorage.getItem("token")) {
      localStorage.removeItem("cart");
      this.cartTotalItems = 0;
      this.cartservice.getUserCartList(this.userData);
      this.materialDailogData = "Refersh"
    }
    
  }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      if(this.showprof){
        this.showprof = !this.showprof;
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
    this.setSize();
  }

  setSize() {
    if(this.screenWidth >= 1600) {
      this.width = '650px';
    } else if(this.screenWidth >= 1024) {
      this.width = '400px';
      this.mobile = false;
      this.showBg =false;
    }else {
      this.width = '93vw';
      this.mobile = true;
      this.showBg =true;
    }
  }

  readPath() {
    this.path = window.location.pathname;
    if(this.path === '/create-profile' 
      || (this.path === '/chat' && this.mobile)) {
      this.showSearch = false;
    } else {
      this.showSearch = true;
    }
  }

  async ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.headers =  [
      {
        text: 'Dashboard',
        type: 'dashboard',
        active: true,
        url: '/dashboard',
      },
      {
        text: 'Courses',
        type: 'mycourse',
        subType: 'mycourses',
        active: false,
        url: '/mycourses?type=mycourses',
      },
      {
        text: 'Tests',
        type: 'mycourse',
        subType: 'myquizzes',
        active: false,
        url: '/mycourses?type=myquizzes',
      },
      {
        text: 'Smart IDE', 
        active: false, 
        url: '/ide', 
      },
    ]
    this.showList = this.headers;
    this.setSize();
    this.readPath();
    // navigator.serviceWorker.addEventListener('message', (event) => {
    //   let notification = {
    //     title: event.data.firebaseMessaging.payload.data.title,
    //     body: event.data.firebaseMessaging.payload.data.body,
    //     icon: event.data.firebaseMessaging.payload.data.icon,
    //     image: event.data.firebaseMessaging.payload.data.image,
    //     tag: event.data.firebaseMessaging.payload.data.tag,
    //     color: this.getRandomColor(),
    //   }
    //   this.notificationArray.push(notification);
    //   this.chatService.showNotification(notification.title,
    //     notification.body, notification.icon,notification.tag);

    // });
    this.screenWidth = document.documentElement.clientWidth;
    this.setSize();
    if (!this.isLog) {
      this.loadPublicButtons();
    }
    this.localSchoolData = JSON.parse(localStorage.getItem("school_details"));
    if (this.localSchoolData && this.localSchoolData.schools_metadata) {
      this.sclMD = this.localSchoolData.schools_metadata;
    }
    this.globalService.search.subscribe((searchData: any) => {
      this.searchTerm = searchData.value ?? "";
    });
    this.globalService.cartTotalItems.subscribe((data: any) => {
      this.cartTotalItems = data;
    });
    this.globalService.logoutTrigger.subscribe((_data: any) => {
      this.cartTotalItems = 0;
    });
    const cartLength = JSON.parse(localStorage.getItem("cart"));
    if (cartLength) {
      this.cartTotalItems = cartLength.length;
    }

    this.globalService.confirmFlag.subscribe((data: any) => {
      if(data) {
          this.visible = true;
          this.confirmFlag = true;
      }
    });
  }

  ngOnChanges() {
    this.readPath();
  }
  ngAfterViewInit(){
    this.localSchoolData = JSON.parse(localStorage.getItem("school_details"));
    if (this.localSchoolData && this.localSchoolData.school_logo) {
      this.schoolLogo = this.localSchoolData.school_logo;
    }
  }
  showprofile() {
    this.showprof = !this.showprof;
  }

  handleToggleBtn() {
    this.hambuergerToggle = !this.hambuergerToggle;
    this.toggleBtn.emit(this.hambuergerToggle);
  }
  private getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
}
  isLogged() {
    const lcstorage = JSON.parse(this.loginservice.loginStatus());
    const basicFields: any = JSON.parse(localStorage.getItem("basicFiels"));
    let isProPicAllowed: any = false;
    if (basicFields && basicFields.length) {
      basicFields.forEach((eachField: any) => {
        if (eachField && eachField.label === "Profile Picture") {
          isProPicAllowed = eachField.hide ? true : false;
        }
      });
    }

    if (lcstorage) {
      this.userName = lcstorage.name ? lcstorage.name.split("$")[0] : "";
      if (!this.userName) {
        this.userName = lcstorage.email;
      }
      if (lcstorage.profile_pic && isProPicAllowed) {
        this.userImage = this.globalService.changeS3bucket(lcstorage.profile_pic, this.globalService.assets_bucket);
      }
      if (!this.userImage) {
        this.userImage = emptyProfile;
      }
      return true;
    }
    return false;
  }

  dialogClose(_event) {
    this.dialogVisible = false;
    this.dialogHeader = undefined;
    this.dialogContentData = undefined;
  }
  submitDialog(_event) {
    if (this.dialogContentData === 'push') {
      this.openNotificationPermission();
  }
  }
  async openNotificationPermission() {
    const userdata = JSON.parse(localStorage.getItem('token'));
    const firebaseRef = await this.testService.getFirebase();
    const messaging = firebaseRef.messaging();
    this.dialogClose(null);
    if (this.localSchoolData.push_enabled) {
        if (Notification.permission === 'denied') {
            this.dialogVisible = true;
            this.dialogContentData = 'pushBlocked';
            this.dialogHeader = 'Instruction';
        } else {
            messaging
                .requestPermission()
                .then(() => {
                    messaging
                        .getToken()
                        .then((currentToken: any) => {
                            if (currentToken) {
                                const tokenpayload = {
                                    school_id: userdata.school_id,
                                    user_id: userdata.user_id,
                                    token: currentToken,
                                };
                                this.globalService.sendPushToken(tokenpayload).subscribe((_tokenresp: any) => {
                                  // This is intentional
                                });
                                this.notificationPermission = true;
                            }
                        })
                        .catch(function (err) {
                        });
                })
                .catch(function (err) {
                });
        }
    }
  }
  onPushClick() {
    this.dialogVisible = true;
    this.dialogContentData = 'push';
    this.dialogHeader = 'Push notification';
    this.pushDialog.pushText =
        window.location.hostname.split('/')[0] +
        ' wants to start sending you push notifications. Click allow to subscribe';
    this.pushDialog.btn1 = {
        btnName: `I'll do this later`,
        background: 'var(--three)',
        fontColor: 'var(--seven)',
        border: '1px solid var(--seven)',
    };
    this.pushDialog.btn2 = {
        btnName: `Allow`,
    };
    this.dialogheaderStyle = {
        'border-bottom': '2px solid var(--ten)',
    };
  }
  logout() {
    const payload: any = {};
    if (this.userData && this.userData.user_id) {
      payload.user_id = this.userData.user_id;
      payload.school_id = this.userData.school_id;
      payload.user_role = "student";
      payload.email = this.userData.email;
    }
    this.loginservice.logout(payload).subscribe((_data: any) => {
      this.isLog = false;
      this.globalService.clearLocalStorage();
      this.serv.noRedirect.next({ status: false });
      this.testService.removeCopyPasteHandler();
      if (this.sclMD && this.sclMD.redirection_url) {
        if (
          this.sclMD.redirection_url.includes("http://") ||
          this.sclMD.redirection_url.includes("https://")
        ) {
          this.redirection_url = this.sclMD.redirection_url;
        } else {
          this.redirection_url = "https://" + this.sclMD.redirection_url;
        }
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = this.redirection_url;
        link.click();
        document.body.removeChild(link);
      } else {
        const navUrl: any = this.globalService.publicNavigation(this.sclMD, this.userData.enable_features);
        this.router.navigateByUrl(navUrl);
      }
      if (!this.isLog) {
        this.loadPublicButtons();
      }
    });
  }

  navHam(type) {
    if (!this.noRedirect) {
      this.globalService.search.next({ path: "/" + type, value: "" });
      if (type === "profile") {
        this.currentNav = "profile";
        this.router.navigateByUrl("/profile");
      } else if (type === "signup") {
        this.router.navigateByUrl("/signup");
      } else if (type === "login") {
        this.router.navigateByUrl("/login");
      }
    }
  }

  listenForSearch(event: any) {
    this.globalService.search.next({
      path: window.location.pathname,
      value: event.target.value,
      search: event.target.value,
      chips: "",
    });
  }

  loadPublicButtons() {
    const publicButtons = [
      {
        multipleBtn: false,
        btnName: "Sign In",
        className: "btn-styles outlined-btn-color",
        type: "login",
      },
      {
        multipleBtn: false,
        btnName: "Sign Up",
        className: "btn-styles primary-btn-color",
        marginleft: "10px",
        type: "signup",
      },
    ];

    this.globalService.isSignup.subscribe((showSignupBtn: any) => {
      this.publicButtons = showSignupBtn
        ? publicButtons
        : publicButtons.slice(0, 1);
    });
  }

  gotoCart() {
    this.router.navigateByUrl("/cart");
    this.cart.emit(event);
  }

  confiormLogedOff(){
    this.globalService.confirmFlagLogOff.next(true); 
  }
  
  navigateToURL(item: showListProps) {
    // this.cartClicked=false;
    const { url, type } = item;
    if (type === 'mycourse') {
      this.router.navigateByUrl(url);
        this.changeActiveState(url);
    } else if (url === '/ide') {
      window.open(window.location.protocol + url, '_blank');
    } else {
      this.router.navigateByUrl(url);
        this.changeActiveState(url);
    }
    // this.sideBarHidden.emit(false);
    // if(this.moreListHide){
    //   this.moreListHandle.emit(this.moreListHide);
    // }
  }

  changeActiveState(url: string) {
    this.showList.forEach((showListItem: showListProps) => {
      if(url === showListItem.url) {
        showListItem.active = true;
      } else if(url==='/mydrivedetails' && showListItem.url==='/drives') {
          showListItem.active = true;
      } else if(url==='/mycdetails' && showListItem.url==='/mycontest') {
        showListItem.active = true;
      } else {
        showListItem.active = false;
      }
    });

    if (this.popupList.length > 0) {
      this.popupList.forEach((popListItem: showListProps) => {
        if(url === popListItem.url) {
          popListItem.active = true;
        } else {
          popListItem.active = false;
        }
      });
    }
    this.updateNavPopup();
  }

  updateNavPopup() {
    if (this.popupList.length > 0) {
      this.popupList.forEach((item, i) => {
        if(item.active === true) {
          const removedPopItem = this.popupList.splice(i, 1)
          const removedNavItem = this.showList.pop();
          this.showList.push(removedPopItem[0]);
          this.popupList.unshift(removedNavItem);
        }
      });
    }
  }
}
