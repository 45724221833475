import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, throwError, catchError } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { GlobalService } from 'src/app/services/global.service';
@Injectable({
    providedIn: 'root',
})
export class LoginService {
    api = environment.HOST.link;
    schoolData: any;
    setResetLogin: boolean = false;
    getEmail = new BehaviorSubject<any>(null);
    userInfo = new BehaviorSubject<any>(null);
    public Passvariable = new BehaviorSubject<any>(null);
    credential: any;
    userDetails: any;
    verificationTimeLeft: any = 0;
    userData = JSON.parse(localStorage.getItem('token'));
    schoolId;
    branchId;
    instituteType;
    constructor(private http: HttpClient, private title: Title, public globalService: GlobalService) {}

    // get school login details while loading
    getSchoolLoginDetails() {
        return this.http.get(this.api + '/school/login');
    }

    loginStatus() {
        return localStorage.getItem('token');
    }

    usersLogin(payload) {
        return this.http.post(this.api + '/users/login', payload);
    }

    checkotp(payload) {
        const schoolData = JSON.parse(localStorage.getItem('school_details'));
        if (schoolData && schoolData.school_id) {
            payload.school_id = schoolData.school_id;
        }
        return this.http.post(this.api + '/users/checkotp', payload);
    }

    networkStatus() {
        return this.http.get(this.api + '/school/status');
    }

    setLocalstorage(response) {
        return new Promise((resolved, _rejected) => {
            let school_details: any;
            if (response && response.data) {
                let school_id: any = response.data.school_id;
                this.globalService.getAdmissionReportSchoolDetailsForStudents({school_id: school_id}).subscribe((details: any) => {
                    if(details) {
                        school_details = {
                            school_domain: response.data.school_domain,
                            school_code: response.data.school_code,
                            school_logo: response.data.school_logo,
                            school_id: response.data.school_id,
                            school_name: response.data.school_name,
                            school_status: response.data.school_status,
                            push_enabled: response.data.push_enabled,
                            schools_metadata: response.data.schools_metadata,
                            captcha: response.data && response.data.captcha ? response.data.captcha : '',
                            institute_type: response.data.institute_type,
                            purpose: response.data.purpose,
                            enable_features: response.data.enable_features,
                            pwa_short_name: response.data.pwa_short_name,
                            drive_restrictions: response.data.drive_restrictions,
                            admission_report_permission : details,
                        };
                        if (response.data.pwa_short_name) {
                            this.title.setTitle(response.data.pwa_short_name);
                        } else {
                            this.title.setTitle('Examly');
                        }
                        if (localStorage.getItem('school_details')) {
                            localStorage.removeItem('school_details');
                        }
                        school_details = JSON.stringify(school_details);
                        localStorage.setItem('school_details', school_details);
                        this.schoolData = JSON.parse(localStorage.getItem('school_details'));
                        resolved(true)
                    }
                });
            }
        });        
    }

    sendSignup(payload) {
        return this.http.post(this.api + '/school/studentsignup', payload);
    }

    checkEmailExist(payload: any) {
        return this.http.post(this.api + '/signin/lookup', payload);
    }

    checkEmailExists(email, token) {
        let schoolDat: any;
        if (!this.schoolData) {
            this.schoolData = JSON.parse(localStorage.getItem('school_details'));
            schoolDat = this.schoolData;
        } else schoolDat = this.schoolData;
        const payload: any = {
            email: email,
            school_id: schoolDat.school_id,
            school_code: schoolDat.school_code,
            googleToken: token,
        };
        return this.http.post(this.api + '/signin/lookup', payload);
    }

    login(payload: any) {
        return this.http.post(this.api + '/signin/verify', payload);
    }

    loginUser(token, pwd) {
        let schoolDat: any;
        if (!this.schoolData) {
            this.schoolData = JSON.parse(localStorage.getItem('school_details'));
            schoolDat = this.schoolData;
        } else {
            schoolDat = this.schoolData;
        }
        const payload = {
            hash: this.userInfo.value.key,
            password: pwd,
            school_id: schoolDat.school_id,
            school_code: schoolDat.school_code,
            googleToken: token,
        };
        return this.http.post(this.api + '/signin/verify', payload);
    }
    getschool_id() {
        const userData = JSON.parse(localStorage.getItem('token'));
        let school_id;
        if (userData) {
            school_id = userData.school_branch_department_users[0].school_id;
        } else if (this.schoolData) {
            school_id = this.schoolData.school_id;
        }

        return school_id;
    }

    sendForgotPasswordLink(payload) {
        return this.http.post(this.api + '/users/sendemailforforget', payload);
    }

    checkEmailOtp(payload) {
        return this.http.post(this.api + '/users/checkotp', payload);
    }

    setPassword(payload) {
        return this.http.post(this.api + '/users/setpassword', payload);
    }

    sendActivationLink(payload) {
        return this.http.post(this.api + '/resendLink', payload);
    }

    userPermissionList(userData) {
        return this.http
            .get(this.api + '/school/' + userData.purpose + '/userpermissionList', this.options(userData))
            .pipe(catchError(this.handleError.bind(this)));
    }
    public handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            //   console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            //     Backend returned code ${error.status},  +
            //     body was: ${error.error});
            if (error && error.status === 401) {
                this.globalService.commonGrowl.next({
                    severity: 'error',
                    summary: 'Session expired!',
                    detail: 'Refresh and try logging in again',
                });
                const keyLength = localStorage.length;
                for (let index = keyLength - 1; index > -1; index -= 1) {
                    const key = localStorage.key(index);

                    if (key && key !== 'session_data') {
                        localStorage.removeItem(key);
                    }
                }
                setTimeout(() => {
                    const hostName = window.location.host;
                    if (
                        hostName === 'app.examly.net' ||
                        hostName === 'app.exam.ly' ||
                        hostName === 'app.examly.io' ||
                        hostName.split(':')[0] === 'app.examly.test'
                    ) {
                        if (window.location.pathname !== '/admin/login') {
                            window.location.href = '/admin/login';
                        }
                    } else {
                        if (window.location.pathname !== '/login') {
                            window.location.href = '/login';
                        }
                    }
                }, 3000);
            }
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; Please try again later.');
    }
    options(userData) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: userData && userData.token ? userData.token : this.getUserToken(),
        });
        return { headers };
    }
    getUserToken() {
        const userData = JSON.parse(localStorage.getItem('token')) ? JSON.parse(localStorage.getItem('token')) : this.userDetails;
        if (userData && userData.token) {
            return userData.token;
        }
        return '';
    }
    loginwithunp(p) {
        p.school_code = this.schoolData
            ? this.schoolData.school_code
            : JSON.parse(localStorage.getItem('school_details')).school_code;
        return this.http.post(this.api + '/users/login', p);
    }

    schoolstatus(school_id, userData) {
        const payload = {
            school_id: school_id,
        };
        return this.http.post(this.api + '/schoolStatus/', payload, this.options(userData));
    }

    getPpaProfile(s_id, school_id, userData) {
        const params: URLSearchParams = new URLSearchParams();
        params.set('s_id', s_id);
        params.set('school_id', school_id);
        params.set('user_id', userData.user_id);
        const options: any = this.options(userData);
        options.search = params;
        return this.http
            .get(this.api + '/users/getprofile', this.options(userData))
            .pipe(catchError(this.handleError.bind(this)));
    }

    getsso(payload) {
        return this.http.post(this.api + '/users/getsso', payload);
    }

    logout(payload) {
        return this.http.put(this.api + '/logout', payload, this.options(this.userData));
    }
    
    findOS() {
        let OSName = 'Unknown OS';
        if (navigator.appVersion.indexOf('Win') != -1) {
            OSName = 'Windows';
        }
        if (navigator.appVersion.indexOf('Mac') != -1) {
            OSName = 'MacOS';
        }
        if (navigator.appVersion.indexOf('X11') != -1) {
            OSName = 'UNIX';
        }
        if (navigator.appVersion.indexOf('Linux') != -1) {
            OSName = 'Linux';
        }
        if (navigator.appVersion.indexOf('Android') != -1) {
            OSName = 'Android';
        }
        if (navigator.appVersion.indexOf('iPhone') != -1 || navigator.appVersion.indexOf('iPad') != -1) {
            OSName = 'iOS';
        }
        return OSName;
    }

    checkIfStudentExist(payload) {
        return this.http.post(this.api + '/user/exist', payload);
    }
}
