import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginService } from '../services/login.service';

@Injectable()
export class NetworkGuard implements CanActivate {
    constructor(private loginservice: LoginService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.loginservice.networkStatus().subscribe(
            (res: any) => {
                return true;
            },
            (failed: any) => {
                this.router.navigateByUrl('/no-network');
                return false;
            },
        );
        return true;
    }
}
