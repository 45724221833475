import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrowlComponent } from './growl.component';
import {MessagesModule} from 'primeng/messages';

@NgModule({
    declarations: [GrowlComponent],
    imports: [CommonModule,MessagesModule],
    exports: [GrowlComponent],
})
export class GrowlModule {}
