import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NetworkGuard } from './gaurds/network.gaurd';
import { AuthGuard } from './gaurds/auth.gaurd';
import { PublicGuard } from './gaurds/public.gaurd';
import { ProfileGuard } from './gaurds/profile.guard';
import { GitRedirectComponent } from './containers/git-redirect/redirect.component';
import { GithubRedirectHandlerComponent} from './components/github-redirect-handler/github-redirect-handler.component';
import { IdlePreload, IdlePreloadModule } from './services/CustomPreloadingStrategy.service';
import { ExamsResultsComponent } from './components/exams-results/exams-results.component';
import { ExamsResultsLayoutComponent } from './containers/exams-results-layout/exams-results-layout.component';
const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
        canActivate: [NetworkGuard],
    },
    {
        path: 'pwd',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'otp',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'resendotp',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'signup',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'forgot',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'resetpassword',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'setpassword',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'activation',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'contest',
        loadChildren: () => import('./containers/contest-registration/contest-registration.module').then(m => m.ContestRegistrationModule),
    },
    {
        path: 'create-profile',
        loadChildren: () => import('./containers/init-form/init-form.module').then(m => m.InitFormModule),
        canActivate: [ProfileGuard],
    },
    {
        path: 'feeds',
        loadChildren: () => import('./containers/feeds-layout/feeds.module').then(m => m.FeedsModule),
        canActivate: [NetworkGuard, AuthGuard],
    },
    {
        path: 'course/product',
        loadChildren: () => import('./containers/course-details-layout/courselayout.module').then(m => m.CourselayoutModule),
        canActivate: [NetworkGuard],
        data: {preload: true}
    },
    {
        path: 'course',
        loadChildren: () => import('./containers/course-list-layout/courselist.module').then(m => m.CourselistModule),
        canActivate: [NetworkGuard],
        data: {preload: true}
    },
    {
        path: 'mycourses',
        loadChildren: () => import('./containers/my-course-layout/mycourse.module').then(m => m.MycourseModule),
        canActivate: [NetworkGuard, AuthGuard],
        data: {preload: true}
    },
    {
        path: 'cart',
        loadChildren: () => import('./containers/cart/cart.module').then(m => m.CartModule),
        canActivate: [NetworkGuard]
    },
    {
        path: 'home',
        loadChildren: () => import('./containers/feeds-layout/feeds.module').then(m => m.FeedsModule),
        canActivate: [NetworkGuard, PublicGuard],
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./containers/dashboard/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
        data: {preload: true}
    },
    {
        path: 'profile',
        loadChildren: () => import('./containers/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [NetworkGuard, AuthGuard]
    },
    {
        path: 'accountsettings',
        loadChildren: () => import('./containers/account-settings/account-settings.module').then(m => m.AccountSettingsModule),
    },
    {
        path: 'no-network',
        loadChildren: () => import('src/app/components/no-network/no-network.module').then(m => m.NoNetworkModule)
    },
    {
        path: 'test',
        loadChildren: () => import('./containers/test-taking/test-taking.module').then(m => m.TestTakingModule),
        canActivate: [NetworkGuard, AuthGuard]
    },
    {
        path: 'compiler-instruction',
        loadChildren: () => import('./containers/compiler-instruction/compiler-instruction.module').then(m => m.CompilerInstructionModule),
        canActivate: [NetworkGuard],
    },
    {
        path: 'result',
        loadChildren: () => import('./containers/result-analysis/result-analysis.module').then(m => m.ResultAnalysisModule),
        canActivate: [NetworkGuard],
    },
    {
        path: 'project',
        loadChildren: () => import('./containers/project/project.module').then(m => m.ProjectModule),
        canActivate: [NetworkGuard, AuthGuard],
    },
    {
        path: 'mycontest',
        loadChildren: () => import('./containers/my-course-layout/mycourse.module').then(m => m.MycourseModule),
        canActivate: [NetworkGuard, AuthGuard],
    },
    {
        path: 'mycdetails',
        loadChildren: () => import('./components/contest-details/contest-details.module').then(m => m.ContestDetailsModule),
        canActivate: [NetworkGuard, AuthGuard],
        data: {preload: true}
    },
    {
        path: 'drives',
        loadChildren: () => import('./containers/my-course-layout/mycourse.module').then(m => m.MycourseModule),
        canActivate: [NetworkGuard, AuthGuard],
        data: {preload: true}
    },
    {
        path: 'mydrivedetails',
        loadChildren: () => import('./components/drive-details/drive-details.module').then(m => m.DriveDetailsModule),
        canActivate: [NetworkGuard, AuthGuard],
        data: {preload: true}
    },
    {
        path: 'loginredirect',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'loginredirect/QR-code-scanner',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'leaderboard',
        loadChildren: () => import('./containers/leaderboard-layout/leaderboard-layout.module').then(m => m.LeaderboardLayoutModule),
        canActivate: [NetworkGuard, AuthGuard],
    },
    {
        path: 'examsandresults',
        loadChildren: () => import('./containers/exams-results-layout/exams-results-layout.module').then(m => m.ExamsResultsLayoutModule),

        canActivate: [NetworkGuard, AuthGuard],
    },
    {
        path: 'chat',
        loadChildren: () => import('./containers/chat-layout/chat-layout.module').then(m => m.ChatLayoutModule),
        canActivate: [NetworkGuard, AuthGuard],
    },
    {
        path: 'chat/:channel_id',
        loadChildren: () => import('./containers/chat-layout/chat-layout.module').then(m => m.ChatLayoutModule),
        canActivate: [NetworkGuard, AuthGuard],
    },
    {
        path: 'certificates',
        loadChildren: () => import('./containers/certificate-layout/certificate-layout.module').then(m => m.CertificateLayoutModule),
        canActivate: [NetworkGuard, AuthGuard],
    },
    {
        path: 'QR-code-scanner',
        loadChildren: () => import('./components/qr-code-scanner/qr-code-scanner.module').then(m => m.QRCodeScannerModule),
        canActivate: [NetworkGuard, AuthGuard],
    },
    {
        path: 'ide',
        loadChildren: () => import('./components/ide-playground/ide-playground.module').then(m => m.IDEPlaygroundModule),
        canActivate: [NetworkGuard],
    },
    {
        path: 'githubredirect',
        component: GitRedirectComponent,
        canActivate: [NetworkGuard],
    },
    {
        path: 'githubredirect-handler',
        component: GithubRedirectHandlerComponent,
        canActivate: [NetworkGuard],
    },
    {
        path: 'public-profile',
        loadChildren: () => import('./components/public-profile/public-profile.module').then(m => m.PublicProfileModule),
        canActivate: [NetworkGuard],
    },
    {
        path: 'network-check',
        loadChildren: () => import('./containers/network-check/network-check.module').then(m => m.NetworkCheckModule),
        canActivate: [NetworkGuard],
    },
];

@NgModule({
    imports: [IdlePreloadModule.forRoot(), RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', preloadingStrategy: IdlePreload  })  ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
