import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { GlobalService } from '@services/global.service';

@Injectable()
export class PublicGuard implements CanActivate {
    private schoolMetaData: any;
    private enableFeatures: any;

    constructor(private globalService: GlobalService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.schoolMetaData) {
            const schoolDetail = JSON.parse(localStorage.getItem('school_details'));
            this.schoolMetaData =
                schoolDetail && schoolDetail.schools_metadata ? schoolDetail.schools_metadata : undefined;
        }
        if (!this.enableFeatures) {
            const schoolDetail = JSON.parse(localStorage.getItem('school_details'));
            this.enableFeatures =
                schoolDetail && schoolDetail.enable_features ? schoolDetail.enable_features : undefined;
        }
        if (this.schoolMetaData && this.enableFeatures) {
            if (
                (state.url.includes('course') && !this.schoolMetaData.allow_public_buying) ||
                (state.url.includes('home') && !this.schoolMetaData.allow_public_feeds)
            ) {
                this.router.navigate([this.globalService.publicNavigation(this.schoolMetaData, this.enableFeatures)]);
                return false;
            }
            return true;
        } else {
            localStorage.setItem('path', state.url);
            return false;
        }
    }
}
