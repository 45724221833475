import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { GlobalService } from './global.service';
import { LoginService } from './login.service';
import { FeedsService } from './feeds.service';
@Injectable({
    providedIn: 'root',
})
export class CartService {
    api_url = environment.HOST.link;
    cartData: any = new BehaviorSubject<any>({});
    boughtListIds: any = [];
    freeProduct: any = false;
    constructor(
        private http: HttpClient,
        public globalSerive: GlobalService,
        public loginService: LoginService,
        public feedService: FeedsService,
    ) {}

    storeLocal(payload) {
        let localCart = JSON.parse(localStorage.getItem('cart'));
        if (localCart && localCart.length) {
            const checkExist = localCart.find((data: any) => {
                if (data && data.id) {
                    if (payload && payload.list) {
                        return data.id === payload.list.id;
                    }
                    return false;
                }
                return false;
            });
            if (!checkExist) {
                localCart.push(payload.list);
                this.globalSerive.cartTotalItems.next(localCart.length);
                localStorage.removeItem('cart');
                localStorage.setItem('cart', JSON.stringify(localCart));
            }
        } else {
            localCart = [];
            localCart.push(payload.list);
            this.globalSerive.cartTotalItems.next(localCart.length);
            localStorage.setItem('cart', JSON.stringify(localCart));
        }
    }
    updateCartlength() {
        let cartLength = [];
        cartLength = JSON.parse(localStorage.getItem('cart'));
        if (cartLength && cartLength.length) {
            this.globalSerive.cartTotalItems.next(cartLength.length);
        } else {
            this.globalSerive.cartTotalItems.next(0);
        }
    }
    checkProductValidity(payload): Observable<any> {
        return this.http.post(this.api_url + '/products/v2/checkvalidity', payload);
    }

    createOrder(payload): Observable<any> {
        return this.http.post(this.api_url + '/student/createpublicorder', payload);
    }
    // validate discount
    validateDiscount(payload): Observable<any> {
        return this.http.post(this.api_url + '/products/discounts/checkv2', payload);
    }

    addCartItemToDB(payload): Observable<any> {
        return this.http.post(this.api_url + '/products/addcart', payload, this.loginService.options(null));
    }

    removeCartItemFromDB(payload): Observable<any> {
        return this.http.post(this.api_url + '/products/removecart', payload, this.loginService.options(null));
    }

    getUserCartList(userData: any) {
        return new Promise(() => {
            const payload: any = {
                user_id: userData.user_id,
            };
            const schoolDetails: any = JSON.parse(localStorage.getItem('school_details'));
            this.getCartList(payload, userData).subscribe((response: any) => {
                if (response && response.success && response.data && response.data.length) {
                    const cartProducts: any = [];
                    let courseIds: any = [];
                    response.data.forEach((eP: any) => {
                        if (eP.products) {
                            const product: any = eP.products;
                            courseIds = [...product.entity_ids, ...courseIds];
                            courseIds = [...new Set(courseIds)];
                        }
                    });
                    const payloadCourse: any = {
                        c_id: courseIds,
                        school_code: schoolDetails.school_code,
                        googleToken: '',
                    };
                    this.globalSerive.getCaptchaToken('getCourseCount').then((googletoken: string) => {
                        payloadCourse.googleToken = googletoken;
                        this.feedService.getFeatureData(payloadCourse).subscribe((resp: any) => {
                            if (response.data && response.data.length) {
                                response.data.forEach((ele: any) => {
                                    if (ele && ele.products) {
                                        const product: any = ele.products;
                                        product.entity_ids.forEach((entitiy) => {
                                            const a = resp.data
                                                ? resp.data.filter((data) => entitiy === data.c_id)
                                                : [];
                                            a.forEach((d) => {
                                                product.practiceCount =
                                                    product.practise_count + d.practise_count ? d.practise_count : 0;
                                                product.assessmentCount =
                                                    product.assessmentCount + d.test_count ? d.test_count : 0;
                                            });
                                        });
                                        product.productName = product.title;
                                        if (!product.image) {
                                            product.image =
                                                environment.s3Objectstudentassets + 'assets/common-images/market/Course.png';
                                        }
                                        product.reviewCount = product.review_count;
                                        product.rating = product.ratings;
                                        product.hoursContent = product.product_duration;
                                        product.originalPrice = product.price ? product.price : 0;
                                        product.compare_price = product.compare_price ? product.compare_price : 0;
                                        cartProducts.push(product);
                                    }
                                });
                                localStorage.removeItem('cart');
                                localStorage.setItem('cart', JSON.stringify(cartProducts));
                                this.globalSerive.cartTotalItems.next(cartProducts.length);
                            } else {
                                localStorage.removeItem('cart');
                                this.globalSerive.cartTotalItems.next(0);
                            }
                        });
                    });
                }
            });
        });
    }
    getCartList(payload, userData): Observable<any> {
        return this.http.post(this.api_url + '/products/cartlist', payload, this.loginService.options(userData));
    }

    boughtList(enrolled) {
        this.boughtListIds = enrolled;
    }
    setFreeProduct(value) {
        this.freeProduct = value;
    }
    setReview(payload): Observable<any> {
        return this.http
            .post(this.api_url + '/products/setreview', payload, this.loginService.options(null))
            .pipe(catchError(this.loginService.handleError.bind(this)));
    }
}
