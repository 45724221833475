import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';
import { TestService } from './test.service';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    private socket: WebSocketSubject<any>;
    private socket_url: string = environment.HOST.SOCKET_LINK;
    public messageRecieved = new BehaviorSubject<any>(null);
    public connectionId = null;
    constructor(
        private testService: TestService,
    ) {}
    public connect(school_id: string, user_id: string) {
        this.socket = new WebSocketSubject({
            url: this.socket_url,
            closeObserver: {
                next: () => {
                    this.connect(school_id, user_id);
                },
            },
        });
        this.socket.next({
            action: 'userconnect',
            data: {
                school_id,
                user_id,
            },
        });
        this.socket.subscribe(
            (message: any) => { 
                if (message.connectionId) {
                    this.connectionId = message.connectionId;
                }
                this.messageRecieved.next(message);
            },
        );
    }

    // remove last message
    public removeMessage() {
        this.messageRecieved.next(undefined);
    }
    public sendMessage( payload: any) {
        
        this.testService.sendMessageToSocket(payload).then((_response: any) => {
              // This is intentional
        });
        
    }
}
