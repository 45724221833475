// This file can be replaced during build by using the fileReplacements array.
// ng build --prod replaces environment.ts with environment.prod.ts.
// The list of file replacements can be found in angular.json.

export const environment = {
  production: false,

  HOST: {
    COOKIE_LINK: 'https://api.smartica.co.in/api', //smartica api
    link: 'https://api.smartica.co.in/api', //smartica api
    link2: 'https://fb-server-wfxs7gf3oq-uc.a.run.app/api', //smartica api
    HOST_LINK: 'https://api.smartica.co.in/api', //smartica api
    SOCKET_LINK: 'wss://0i8kb3kr78.execute-api.us-east-1.amazonaws.com/dev',
    IDE_domain: 'ide.smartica.co.in',
    RECOGN_LINK: 'https://audio-recognition-wfxs7gf3oq-uc.a.run.app',
    COE_HOST_LINK: 'test'​​​​​​​,
    MS_drives: 'test',
    CLOUD_PROVIDER: "test"
  },


  FireBaseKeys: {
    apiKey: "AIzaSyDKQyBtYhup0ioQbiJ5UJGH70IHJ_wdfbs",
    authDomain: "smartica-production.firebaseapp.com",
    projectId: "smartica-production",
    storageBucket: "smartica-production.appspot.com",
    messagingSenderId: "252769160624",
    appId: "1:252769160624:web:e814497b2d79ef64665a75",
    measurementId: "G-HNS058SB3Z"
  },

  event_stream: {
    StreamName: 'test_event',
    region: 'us-east-1'
  },

  submit_stream: {
    StreamName: 'test_submit',
    region: 'us-east-1'
  },

  vi_stream: {
    StreamName: 'test',
    region: 'us-east-1'
  },

  program_compiler_handler: {
    cloudUrl: 'https://compiler-handler-wfxs7gf3oq-uc.a.run.app/',
 },
  
 essay_compiler_handler: {
  cloudUrl: 'https://essay-handler-wfxs7gf3oq-uc.a.run.app',
 },

  cognito_identity:{
    region: 'ap-south-1'
  },

  essay_compile_queue: {
    QueueUrl: 'https://sqs.us-east-1.amazonaws.com/691795667509/examly-essay-compile',
    region: 'us-east-1'
  },

  debugger_queue: {
    QueueUrl: 'https://sqs.us-east-1.amazonaws.com/691795667509/examly-debugger',
    region: 'us-east-1'
  },

  cloud_provider_queue: {
    QueueUrl: 'test',
    region: 'us-east-1',
  },

  speed_test: 'https://us-central1-smartica-production.cloudfunctions.net/speedTest',

  razorpay: {
    key: 'test',
  },

  g_measurement_id: 'G-0FNBXHRR67',

  instructionTime: 10,

  s3ObjectPrefix: 'https://exams-media.s3.amazonaws.com/',
  s3ObjectVerifiedPic : 'https://smartica-student-verification-images.s3.amazonaws.com/',
  s3Objectstudentassets : 'https://smartica-users-asset.s3.amazonaws.com/',
  s3ObjectDriveBucket : 'https://smartica-data.s3.amazonaws.com/',
  s3ObjectProctorBucket : 'https://smartica-proctoring-screen.s3.amazonaws.com/',

  gitHub: {
    authURL: "https://github.com/login/oauth/authorize?scope=read:user%20repo%20write:public_key%20user%20read:user%20user:email%20repo&allow_signup=true&client_id=",
    clientID: "a72a58a089c62295bce8",
    returnURL: "http://pscollege841.examly.net/githubredirect-handler?id=",
    organization_name: "neojarvis-testing"
  }
};