import { Component, Input, ViewEncapsulation, OnInit, OnChanges, SimpleChanges, Output, EventEmitter,
    AfterViewInit, ElementRef, ViewChild, HostListener} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProfileService } from 'src/app/containers/init-form/init_form.service';
import { down, date, arrowUp, searchIcon } from 'src/assets/images';
import { DatePipe } from '@angular/common';
import { GlobalService } from '@services/global.service';

@Component({
    selector: 'textbox',
    templateUrl: './textbox.html',
    styleUrls: ['./../profile-form-elements.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe],
})
export class TextBoxComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('dropdown') drop: ElementRef;
    @ViewChild('Arrow') a: ElementRef;
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.isOpen && !this.drop.nativeElement.contains(event.target) &&
        ( !this.a.nativeElement.contains(event.target))) {
            this.isOpen = false;
        }
    }
    showBorder;
    notHide;
    max;
    rows = 3;
    disInp = false;
    @Input() field: any = {};
    @Input() form: FormGroup;
    @Input() floatLabel;
    @Input() widthTextArea;
    @Input() hideLabel;
    @Input() max434;
    @Output() InputValue = new EventEmitter();
    selectedVal: any;
    placeholder;
    dateImg = date;
    isOpen = false;
    arrowDown=down;
    arrowUp=arrowUp;
    searchval:any;
    searchIcon = searchIcon;
    list = [];
    dateValue;
    dateP;
    degerror;
    errText = false;
    val = 'icon-person';
    fieldOpt;
    pc: any;
    taFilled;
    tarVal: any;
    onFocus = false;
    get isValid() {
        return this.form.controls[this.field.name].valid;
    }
    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }

    constructor(private serv: ProfileService, public datepipe: DatePipe, public globalService: GlobalService) {}

    ngOnInit() {
        if(this.field.value!==null) {
            this.form.controls[this.field.name].setValue(this.field.value);
        }
        const index: any = this.serv.countryList.findIndex((e, _i) => {
            return e.value === this.serv.fValue.country_code;
        });
        this.pc = index > -1 ? index : 0;
        if(this.field.options) {
            this.fieldOpt = this.field.options;
        }
        if(this.field.type === 'degree' && this.field.value) {
            this.setDegVal();
        }
        if(this.field.type==='phone') {
            if(this.serv.fValue.country_code) {
                this.getFlag();
            } else {
                this.selectedVal = this.field.options.find(i => i.value === '+91');
                this.serv.fValue.country_code = this.selectedVal.value;
                this.tarVal = this.selectedVal.label;
            }
            this.placeholder = 'XXXXX XXXXX';
        }
        if(this.field.type === 'dob') {
            if(this.field.value && this.field.value.length>1) {
                this.dateP = this.datepipe.transform(this.field.value, 'dd/MM/yyyy');
            }
        }
    }

    isNumber(evt) {
        if(this.field.type === 'degree' || this.field.type === 'number' || this.field.type === 'phone') {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode === 46 && this.field.type === 'degree') {
                return true;
            }
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                return false;
            }
            return true;
        } else {
            return true;
        }  
    }

    ngAfterViewInit() {
        this.setVal(this.field.value);
    }

    getFlag() {
        this.field.options.forEach((e)=>{
            if(e.value === this.serv.fValue.country_code) {
                this.selectedVal = e;
                this.tarVal = this.selectedVal.label;
            }
        })
    }

    setDegVal() {
        if (this.field.value !== 'Not Applicable' && ((this.field.label === '10th Mark' && this.serv.fValue.is_tenth_percentage)||
        (this.field.label === '10th' && this.field.markType === 'Percentage')||
        (this.field.label === '12th Mark' && this.serv.fValue.is_twelfth_percentage)||
        (this.field.label === '12th' && this.field.markType === 'Percentage')||
        (this.field.label === 'Diploma' && this.serv.fValue.is_diploma_percentage)||
        (this.field.label === 'Diploma' && this.field.markType === 'Percentage')||
        (this.field.label === 'Under Graduate' && this.serv.fValue.is_ug_percentage)||
        (this.field.label === 'Under Graduate' && this.field.markType === 'Percentage')||
        (this.field.label === 'Post Graduate' && this.serv.fValue.is_pg_percentage)||
        (this.field.label === 'Post Graduate' && this.field.markType === 'Percentage'))) {
            this.selectedVal={label : this.field.label, value:'Percentage'};
        } else if(this.field.value !== 'Not Applicable' && ((this.field.label === '10th Mark')||
        (this.field.label === '10th' && this.field.markType === 'CGPA')||
        (this.field.label === '12th Mark')||
        (this.field.label === '12th' && this.field.markType === 'CGPA')||
        (this.field.label === 'Diploma')||
        (this.field.label === 'Under Graduate')||
        (this.field.label === 'Under Graduate' && this.field.markType === 'CGPA')||
        (this.field.label === 'Post Graduate')||
        (this.field.label === 'Post Graduate' && this.field.markType === 'CGPA'))) {
            this.selectedVal={label : this.field.label, value:'CGPA'};
        } else {
            this.selectedVal={label : this.field.label, value:'NA'};
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            setTimeout(() => {
                this.setVal(this.field.value);
            }, 500);
        }
    }
    searchfn() {
        this.fieldOpt = this.field.options;
        let fieldOptions = [];
        this.fieldOpt.forEach((each) => {
            let eachLower = each.label.toLowerCase();
            let term = this.searchval.toLowerCase();
            if(eachLower.includes(term)) {
                fieldOptions.push(each);
            }
        })
        this.fieldOpt = fieldOptions;
    }

    setVal(o) {
        if (this.field.type === 'resume') {
            if (this.field.mandatory && this.field.value === true) {
                this.field.value = null;
            }
        }
        if (this.field.customError && !this.field.customError.error) {
            this.form.controls[this.field.name].setValue(o);
        }
    }

    selectClick() {
        setTimeout(() => {
        this.isOpen = !this.isOpen;
        },100);
        if(this.isOpen) {
            this.showBorder = false;
        }
    }

    setdate() {
        if (this.field.type === 'dob') {
            this.dateP = null;
            this.form.controls[this.field.name].setValue(this.dateValue);
            this.field.customError.error = false;
            this.field.customError.message = '';
        }
    }

    onBlur() {
        this.onFocus = false;
        this.showBorder=false;
        this.notHide = false;
        this.valDegree();
        setTimeout(() => {
            if(this.field.type === 'dob' && this.dateValue === undefined && this.field.required) {
                this.field.customError.error = true;
                this.field.customError.message = 'Enter Valid Date';
            }
        },500)
    }

    valDegree() {
        if (((this.field.type === 'degree'&& this.field.required) || 
        (this.field.type === 'phone' && this.form.get(this.field.name).value && this.form.get(this.field.name).value.toString().length === 10)) &&
         (this.tarVal === undefined || this.tarVal === 'Country') ) {
            this.tarVal = 'NIL';
        }
    }

    checkBorder(e) {
        if(e==='t') {
            this.showBorder = true;
        } else if (e=== 'f' && !this.notHide) {
            this.showBorder = false;
        }
    }

    changeValue() {
        this.showBorder=true;
        this.notHide = true;
        if (this.checkError()) {
            this.onFocus = true;
        }
        if (this.field.customError) {
            this.field.customError.error = false;
            this.field.customError.message = '';
        }        
        this.valDegree();
    }
    changeuppercase(_event, field, name){
        if((field.type === 'shortanswer' || field.type === 'longanswer' || field.type === 'text') && field.fieldcase_type){
            if(field.fieldcase_type === 'capitalcase'){
                this.form.controls[name].setValue(this.form.controls[name].value.toUpperCase());
            }
            if (field.fieldcase_type === 'smallcase'){
                this.form.controls[name].setValue(this.form.controls[name].value.toLowerCase());
            }
            if (field.fieldcase_type === 'titlecase'){
                this.form.controls[name].setValue(this.form.controls[name].value.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' '));
            }
        }
    }

    checkDegree() {
        if (this.selectedVal) {
            this.tarVal = this.selectedVal.value;
        }
        if((this.tarVal === 'Not Applicable' || this.tarVal === 'NA') && this.field.type === 'degree') {
            this.disInp = true;
        } else if(this.field.type === 'degree') {
            this.disInp = false;
        }
        if (this.tarVal === 'CGPA' && this.form.get(this.field.name).value > 10) {
            this.field.customError.error = true;
            this.field.customError.message = 'Enter Valid CGPA';
        } else if (this.tarVal === 'Percentage' && this.form.get(this.field.name).value > 100) {
            this.field.customError.error = true;
            this.field.customError.message = 'Enter Valid Percentage';
        } else if ((this.tarVal === 'Percentage' && this.form.get(this.field.name).value <= 100) || (this.tarVal === 'CGPA' && this.form.get(this.field.name).value <= 10)) {
            this.field.customError.error = false;
            this.field.customError.message = '';
        }
    }

    checkPhone() {
        if(this.selectedVal) {
            this.InputValue.emit({flag:this.selectedVal.value,value:this.form.get(this.field.name).value});
        }
    }

    checkError() {
        if (this.field.type === 'degree') {
            this.checkDegree();
        }

        if (this.field.type === 'phone') {
            this.checkPhone();
        }
        
        if(this.field.type === 'longanswer' && this.form.controls[this.field.name].value && 
        this.form.controls[this.field.name].value.length > 0) {
            this.taFilled = true;
        }
        else {
            this.taFilled = false;
        }
        if (
            this.form.get(this.field.name) &&
            this.form.get(this.field.name).touched &&
            this.form.get(this.field.name).hasError('required')
        ) {
            return true;
        } else if (
            this.form.get(this.field.name) &&
            this.form.get(this.field.name).touched &&
            this.form.get(this.field.name).hasError(this.field.error) &&
            !this.onFocus
        ) {
            return true;
        } else if (this.field.customError && this.field.customError.error) {
            return true;
        } else {
            return false;
        }
    }

    setDegreeType(e) {
        if ((this.field.label === '10th Mark' || this.field.label === '10th') && e.label === 'Percentage') {
            this.serv.fValue.is_tenth_percentage = true;
        } else if (this.field.label === '10th Mark'|| this.field.label === '10th') {
            this.serv.fValue.is_tenth_percentage = false;
        }
        if ((this.field.label === '12th Mark'|| this.field.label === '12th') && e.label === 'Percentage' ) {
            this.serv.fValue.is_twelfth_percentage = true;
        } else if (this.field.label === '12th Mark'|| this.field.label === '12th'){
            this.serv.fValue.is_twelfth_percentage = false;
        }
        if ((this.field.label === 'Diploma') && e.label === 'Percentage') {
            this.serv.fValue.is_diploma_percentage = true;
        } else if (this.field.label === 'Diploma'){
            this.serv.fValue.is_diploma_percentage = false;
        }
        if ((this.field.label === 'Under Graduate' || this.field.label === 'UG') && e.label === 'Percentage') {
            this.serv.fValue.is_ug_percentage = true;
        } else if (this.field.label === 'Under Graduate' || this.field.label === 'UG'){
            this.serv.fValue.is_ug_percentage = false;
        }
        if ((this.field.label === 'Post Graduate' || this.field.label === 'PG') && e.label === 'Percentage') {
            this.serv.fValue.is_pg_percentage = true;
        } else if (this.field.label === 'Post Graduate' || this.field.label === 'PG'){
            this.serv.fValue.is_pg_percentage = false;
        }
    }

    setOption(e) {
        this.selectClick();
        this.selectedVal = e;
        this.tarVal = e.label;
        if(e.label === 'Not Applicable') {
            this.field.not_applicable = true;
            this.form.controls[this.field.name].setValue(null);
        } 
        else if (e.label !== 'Percentage' && e.label !== 'CGPA' && e.label !== 'Not Applicable') {
            this.serv.fValue.country_code = e.value;
        }
        else {
            if(e.label === 'Percentage') {
                this.max =100;
            } else if (e.label === 'CGPA') {
                this.max=10;
            }
            this.setDegreeType(e);
        }
    }

    iconAction() {
        if (this.field.icon && this.field.icon.origin === 'contest') {
            this.field.icon.icon = this.field.type === 'password' ? 'icon-view-password' : 'icon-eye-o';
            this.field.type = this.field.type === 'password' ? 'text' : 'password';
        } else if (this.field.icon && this.field.icon.action === 'showpassword') {
            this.field.icon.icon = this.field.type === 'password' ? 'icon-unlock' : 'icon-ios-locked';
            this.field.type = this.field.type === 'password' ? 'text' : 'password';
        }
    }
}
