import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { ButtonModule } from '../button/button.module';
import { SelectDropdownModule } from '../select-dropdown/select-dropdown.module';
import { FormBuilderModule } from 'src/app/atoms/shared/form-builder/form-builder.module';
import { ChipsModule } from '../chips/chips.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [DialogComponent],
    imports: [CommonModule, ChipsModule, ButtonModule, SelectDropdownModule, FormBuilderModule, FormsModule],
    exports: [DialogComponent],
})
export class DialogModule {}
