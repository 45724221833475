import { Component, OnInit, Input, Output, ViewChild, EventEmitter, HostListener } from '@angular/core';

@Component({
    selector: 'app-chips',
    templateUrl: './chips.component.html',
    styleUrls: ['./chips.component.css'],
})
export class ChipsComponent implements OnInit {
    @Input() closable: any;
    @Input() options: [];
    @Input() class: any;
    @Input() backgroundcolor: any;
    @Input() color: any;
    @Input() properties: any;
    @Input() padding: any;
    @Input() fontSize: any;
    @Input() returnChips: any;
    @Input() normalBg: any;
    @Input() viewAllLength: any;
    @Output() emitChips = new EventEmitter<any>();
    @Output() removeOption = new EventEmitter<any>();
    @Output() selecChips = new EventEmitter<any>();
    @ViewChild('topOne', { static: false }) topOne;
    rightArrow: boolean;
    screenWidth: any;
    offWidth: any;
    opt: number;
    mobile: any;
    bigscreen: any;
    @Input() chipswidth = '';

    @HostListener('window:resize', ['$event'])
    getScreenSize(_event?: any) {
        if (window.innerWidth > 1440) {
            this.bigscreen = true;
            this.mobile = false;
            this.screenWidth = window.innerWidth;
        } else if (window.innerWidth > 768) {
            this.mobile = false;
            this.screenWidth = window.innerWidth;
        } else {
            this.mobile = true;
            this.screenWidth = window.innerWidth;
        }
    }

    ngOnInit() {
        if (window.innerWidth > 1440) {
            this.bigscreen = true;
            this.mobile = false;
            this.screenWidth = document.documentElement.clientWidth;
        } else if (document.documentElement.clientWidth > 768) {
            this.screenWidth = document.documentElement.clientWidth;
            this.mobile = false;
        } else {
            this.screenWidth = document.documentElement.clientWidth;
            this.mobile = true;
        }
    }
    btnEmit(_event: any, type: any) {
        this.emitChips.emit(type);
    }
    remove(value: any) {
        this.removeOption.emit(value);
    }
    returnSelectedChips(event: any) {
        if (this.returnChips) {
            this.selecChips.emit(event);
        }
    }
}
