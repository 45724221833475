import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PublishtypesService {
  private publishTypes = [
    {
      "type": "Learning Path",
      "text": "Learning Paths",
      "title": "My Learning Paths",
      "params": "mylearningpaths"
    },
    {
      "type": "Course",
      "text": "Courses",
      "title": "All Courses",
      "params": "mycourses"
    },
    {
      "type": "Practice",
      "text": "Practices",
      "title": "My Practices",
      "params": "mypractices"
    },
    {
      "type": "Project",
      "text": "Projects",
      "title": "My Projects",
      "params": "myprojects"
    },
    {
      "type": "Drive",
      "text": "Drives",
      "title": "My Drives",
      "params": "mydrives"
    },
    {
      "type": "Hiring",
      "text": "Hirings",
      "title": "My Hirings",
      "params": "myhirings"
    },
    {
      "type": "Contest",
      "text": "Contests",
      "title": "My Contests",
      "params": "mycontests"
    },
    {
      "type": "Hackathon",
      "text": "Hackathons",
      "title": "My Hackathons",
      "params": "myhackathons"
    },
    {
      "type": "Quiz",
      "text": "Quizzes",
      "title": "All Tests",
      "params": "myquizzes"
    },
    {
      "type": "Exam",
      "text": "Exams",
      "title": "My Exams",
      "params": "myexams"
    },
    {
      "type": "Assessment",
      "text": "Assessments",
      "title": "All Assessments",
      "params": "myassessments"
    },
    {
      "type": "Lab",
      "text": "Labs",
      "title": "My Labs",
      "params": "mylabs"
    },
    {
      "type": "Challenge",
      "text": "Challenges",
      "title": "My Challenges",
      "params": "mychallenges"
    },
    {
      "type": "Test",
      "text": "Tests",
      "title": "All Tests",
      "params": "mytests"
    }
  ]
  getPublishTypeDetailsByType(type: string) {
    return this.publishTypes.filter(publishType => publishType.type === type)[0];
  }
  getAllPublishTypeDetails() {
    return this.publishTypes;
  }

  getPublishTypeByParams(params: string) {
    let paramsType = params;
    let filteredPublishTypeDetails: { type: any; text?: string; title?: string; params?: string; };
    if(paramsType.includes('recent-')){
      paramsType= params.replace('recent-','');
      filteredPublishTypeDetails = this.publishTypes.filter(publishType => publishType.type == paramsType)[0];
    }else{
      filteredPublishTypeDetails = this.publishTypes.filter(publishType => publishType.params == paramsType)[0]
    }
    return filteredPublishTypeDetails ? filteredPublishTypeDetails.type : 'Course';
  }

  getPublishParamsByType(type:string){
    let filteredPublishTypeDetails = this.publishTypes.filter(publishType => publishType.type.toLowerCase() === (type || '').toLowerCase())[0]
    return filteredPublishTypeDetails ? filteredPublishTypeDetails.params : 'mycourses';
  }

}
