import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { ProfileService } from '../containers/init-form/init_form.service';
import { GlobalService } from '@services/global.service';

@Injectable()
export class ProfileGuard implements CanActivate {
    schoolMetaData: any;
    constructor(
        private loginservice: LoginService,
        private router: Router,
        private serv: ProfileService,
        private globalService: GlobalService,
    ) {}

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        if (!this.schoolMetaData) {
            const schoolDetail = JSON.parse(localStorage.getItem('school_details'));
            this.schoolMetaData =
                schoolDetail && schoolDetail.schools_metadata ? schoolDetail.schools_metadata : undefined;
        }

        if (this.schoolMetaData && this.schoolMetaData.disableProfile) {
            return new Promise((resolve) => {
                localStorage.removeItem('noRedirect');
                this.serv.noRedirect.next({ status: false });
                const navUrl: any = this.globalService.defaultRedirection();
                this.router.navigateByUrl(navUrl);
                resolve(false);
            });
        }

        if (this.serv.isRedirected) {
            this.serv.isRedirected = false;
            return Promise.resolve(true);
        } else {
            let response: any = JSON.parse(localStorage.getItem('token'));
            return new Promise((resolve) => {
                this.loginservice.userPermissionList(response).subscribe((ud: any) => {
                    localStorage.setItem('branches', JSON.stringify(ud.branches));
                    this.serv.getGenericMandatoryFields(response).subscribe((res: any) => {
                        let userFormData = res;
                        localStorage.setItem('formData', JSON.stringify(userFormData));
                        const data = JSON.stringify(userFormData.student_ppa_profiles[0]);
                        localStorage.setItem('ppaData', data);

                        this.serv
                            .getAllCustomFields(
                                response,
                                response.school_branch_department_users,
                                JSON.parse(localStorage.getItem('school_details')).school_id,
                            )
                            .subscribe((val: any) => {
                                let tr = [];
                                for (const element of val) {
                                        if(   element.studentCustomFields.student_custom_fields.fields &&
                                          element.studentCustomFields.student_custom_fields.fields.length > 0){
                                            tr.push(element.studentCustomFields.student_custom_fields.fields)
                                        } else {
                                          return '';
                                        }
                                }
                                this.serv
                                    .checkRedirect(
                                        res,
                                        tr,
                                        response.school_branch_department_users,
                                        userFormData,
                                        response,
                                    )
                                    .subscribe((dt: any) => {
                                        val = true;
                                        resolve(true);
                                        if (dt !== 'personal' && dt !== 'acad' && dt !== 'custom') {
                                            val = true;
                                            resolve(false);
                                        }
                                    });
                            });
                    });
                });
            });
        }
    }
}
